import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLayout } from '../UI/LayoutContext';

type DrawerContextValue = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DrawerContext = createContext<DrawerContextValue | undefined>(undefined);

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};

export const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const layout = useLayout();
  const [open, setOpen] = useState(layout === 'laptop' || layout === 'desktop');

  useEffect(() => {
    if (layout === 'tablet') {
      setOpen(false);
    } else if (layout === 'laptop' || layout === 'desktop') {
      setOpen(true);
    }
  }, [layout]);

  return <DrawerContext.Provider value={{ open, setOpen }}>{children}</DrawerContext.Provider>;
};
