import React, { createContext, useContext, useEffect, useState } from 'react';
import useWorkspace from '../Workspace/useWorkspace';
import { useLocation } from 'react-router-dom';

type ClientsContextValue = {
  clientsPath: string;
  recentClientsSection: ClientsSection;
  setRecentClientsSection: (section: ClientsSection) => void;
};

const ClientsContext = createContext<ClientsContextValue | undefined>(undefined);

export const useClients = () => {
  const context = useContext(ClientsContext);
  if (!context) {
    throw new Error('useClientContext must be used within a ClientProvider');
  }
  return context;
};

type ClientsProviderProps = {
  children: React.ReactNode;
};

type ClientsSection = 'dashboard' | 'list';

export const ClientsProvider = ({ children }: ClientsProviderProps) => {
  const { workspacePath } = useWorkspace();
  const { pathname } = useLocation();

  const [recentClientsSection, setRecentClientsSection] = useState<ClientsSection>('dashboard');
  useEffect(() => {
    if (pathname.endsWith('/clients')) {
      setRecentClientsSection('dashboard');
    }
    if (pathname.endsWith('/clients/list')) {
      setRecentClientsSection('list');
    }
  }, [pathname]);
  const clientsPath = recentClientsSection === 'dashboard' ? `${workspacePath}/clients/` : `${workspacePath}/clients/list`;
  //  console.log('clientsPath', clientsPath);

  return <ClientsContext.Provider value={{ clientsPath, recentClientsSection, setRecentClientsSection }}>{children}</ClientsContext.Provider>;
};
