import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, InputAdornment, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography, Link } from '@mui/material';

import ChannelSelect from './ChannelSelect';
import { useChannels } from './useChannels';
import { Scorable } from '../Scores/Scorable';
import { Channel } from './Channel';
import useWorkspace from '../Workspace/useWorkspace';
import { useAuthorizeSlack } from '../Account/useSlackIntegration';

const ChannelConnectionFields = ({ scorable }: { scorable: Scorable }) => {
  const { channels, loading } = useChannels();
  const [initialized, setInitialized] = useState(false);
  const { setValue, watch } = useFormContext();
  const channelType = watch('channelType');
  const channelName = watch('newChannel.name');

  // If the initial default channel name matches an existing channel name, switch the channel type to 'existing.'
  useEffect(() => {
    if (!loading && !initialized) {
      const existingChannel = channels.find(ch => ch.name === channelName);
      if (existingChannel) {
        setValue('channelType', 'existing');
        setValue('existingChannel', existingChannel);
      } else {
        setValue('channelType', 'new');
      }
      setInitialized(true);
    }
  }, [channelName, channels, loading, setValue, initialized]);

  const handleChannelTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('channelType', (event.target as HTMLInputElement).value as 'new' | 'existing');
  };

  return (
    <Stack spacing={2} minHeight={210}>
      {initialized && (
        <>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Channel Type</FormLabel>
            <RadioGroup row aria-label='channelType' name='channelType' value={channelType} onChange={handleChannelTypeChange}>
              <FormControlLabel value='new' control={<Radio />} label='New' />
              <FormControlLabel value='existing' control={<Radio />} label='Existing' />
            </RadioGroup>
          </FormControl>

          {channelType === 'new' && <NewChannelFields scorable={scorable} />}
          {channelType === 'existing' && <ExistingChannelFields scorable={scorable} channels={channels} />}
        </>
      )}
    </Stack>
  );
};

const NewChannelFields = ({ scorable }: { scorable: Scorable }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name='newChannel.name'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            variant='outlined'
            fullWidth
            label='Channel Name'
            error={!!error}
            helperText={error?.message}
            InputProps={{
              startAdornment: <InputAdornment position='start'>#</InputAdornment>,
            }}
          />
        )}
      />
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Visibility</FormLabel>
        <Controller
          name='newChannel.isPrivate'
          control={control}
          render={({ field }) => (
            <RadioGroup row aria-label='visibility' {...field} onChange={event => field.onChange(event.target.value === 'true')}>
              <FormControlLabel value={true} control={<Radio />} label='Private' />
              <FormControlLabel value={false} control={<Radio />} label='Public' />
            </RadioGroup>
          )}
        />
      </FormControl>
      <InviteMembersField scorable={scorable} />
    </>
  );
};

const ExistingChannelFields = ({ scorable, channels }: { scorable: Scorable; channels: Channel[] }) => {
  const { workspace } = useWorkspace();
  const { authorizeUserScopesURL } = useAuthorizeSlack();

  return (
    <>
      {!workspace.user.hasSlackUserAccess ? (
        <Typography>
          <Link href={authorizeUserScopesURL} color={'tertiary.main'}>
            Grant permission
          </Link>{' '}
          to connect to existing channels
        </Typography>
      ) : (
        <>
          <ChannelSelect options={channels} />
          <InviteMembersField scorable={scorable} />
        </>
      )}
    </>
  );
};

const InviteMembersField = ({ scorable }: { scorable: Scorable }) => {
  const { control } = useFormContext();
  return (
    <Stack>
      <FormLabel component='legend'>Add Members</FormLabel>
      <FormControlLabel
        control={<Controller name='addMembers' control={control} render={({ field }) => <Checkbox {...field} checked={field.value} />} />}
        label={`Invite current ${scorable.__typename.toLowerCase()} members to channel`}
      />
    </Stack>
  );
};

export default ChannelConnectionFields;
