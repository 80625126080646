import React, { Fragment } from 'react';
import { ScopeInterface } from '../Scope/Scope';
import { ScoreDimensionBase } from './ScoreDimensionBase';
import { AspectType } from '../Aspect/Aspect';
import { Scorable } from './Scorable';

type Determiner = 'the' | 'my' | 'your';

interface ScorablePhraseProps {
  scorable: Scorable;
  scoreDimension?: ScoreDimensionBase;
  metric?: 'score' | 'intelligence' | AspectType;
  determiner?: Determiner;
  capitalization?: 'title' | 'sentence';
  includeName?: boolean;
  includeType?: boolean; // e.g., workspace, client, project
  includeScore?: boolean;
  includeContext?: boolean;
}

const ScorablePhrase = ({
  scorable,
  metric,
  scoreDimension,
  determiner,
  capitalization,
  includeName = false,
  includeType = false,
  includeScore = false,
  includeContext = false,
}: ScorablePhraseProps) => {
  // My Hyve score
  // My Workspace
  // The workspace score
  // Your workspace Hyve intelligence
  // The Nike client
  // The Nike Air Max project
  // The Air Max project

  let context, name, type, score;
  let formattedDeterminer: string = '';
  let formattedMetric: string = '';
  let dimension = scoreDimension !== scorable ? scoreDimension?.name : '';

  switch (scorable.__typename) {
    case 'Workspace':
      if (includeName) {
        name = scorable.name;
      }
      if (includeType) {
        type = 'workspace';
      }
      break;
    case 'Client':
      if (includeName) {
        name = scorable.name;
      }
      if (includeType) {
        type = 'client';
      }
      break;
    case 'Project':
      if (includeContext) {
        context = scorable.client.name;
      }
      if (includeName) {
        name = scorable.name;
      }
      if (includeType) {
        type = 'project';
      }
      break;
    case 'Team':
      if (includeName) {
        name = scorable.name;
      }
      if (includeType) {
        type = 'team';
      }
      break;
    default:
      throw new Error(`Unexpected scorable type: ${scorable.type}`);
  }

  if (includeScore) {
    score = 'score';
  }

  if (capitalization === 'title') {
    if (determiner) {
      formattedDeterminer = capitalizeFirstLetter(determiner);
    }
    if (type) {
      type = capitalizeFirstLetter(type);
    }
    if (metric) {
      formattedMetric = capitalizeFirstLetter(metric);
    }
    if (score) {
      score = capitalizeFirstLetter(score);
    }
  } else if (capitalization === 'sentence') {
    if (determiner) {
      formattedDeterminer = capitalizeFirstLetter(determiner);
    }
  }

  const segments = [];

  if (formattedDeterminer) {
    segments.push(<span className='scorable-determiner'>{formattedDeterminer}</span>);
  }
  if (context) {
    segments.push(<span className='scorable-context'>{context}</span>);
  }
  if (name) {
    segments.push(<span className='scorable-name'>{name}</span>);
  }
  if (type) {
    segments.push(<span className='scorable-type'>{type}</span>);
  }
  if (dimension) {
    segments.push(<span>{dimension}</span>);
  }
  if (formattedMetric) {
    segments.push(<span className='scorable-metric'>{formattedMetric}</span>);
  }
  if (score) {
    segments.push(<span>{score}</span>);
  }

  return (
    <Fragment>
      {segments.map((segment, index) => (
        <Fragment key={index}>
          {index !== 0 && <Fragment>&nbsp;</Fragment>}
          {segment}
        </Fragment>
      ))}
    </Fragment>
  );
};

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const determiner = (scope: ScopeInterface, person = 'first') => {
  if (scope.type === 'Personal') {
    return person === 'first' ? 'my' : 'your';
  } else {
    return 'the';
  }
};

export default ScorablePhrase;
