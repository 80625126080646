import React, { createContext, useContext } from 'react';
import { ProjectInterface } from './ProjectInterface';
import useWorkspace from '../Workspace/useWorkspace';

type ProjectContextValue = {
  project: ProjectInterface;
  projectPath: string;
  refetch: () => Promise<any>;
};

const ProjectContext = createContext<ProjectContextValue | undefined>(undefined);

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectProvider');
  }
  return context;
};

type ProjectProviderProps = Omit<ProjectContextValue, 'projectPath'> & {
  children: React.ReactNode;
};

export const ProjectProvider = ({ project, refetch, children }: ProjectProviderProps) => {
  const { workspacePath } = useWorkspace();
  const projectPath = `${workspacePath}/projects/${project.id}`;

  return <ProjectContext.Provider value={{ project, projectPath, refetch }}>{children}</ProjectContext.Provider>;
};
