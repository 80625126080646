import { gql } from '@apollo/client';
import { LOW_MEDIUM_PROJECTS_FRAGMENT } from '../Projects/ProjectCollector';

export const PROJECTS_MODULE_FRAGMENT = gql`
  fragment ProjectsModuleFragment on ProjectBearerInterface {
    projectStats {
      count
      countByScore {
        high
        medium
        low
        collecting
      }
    }
    ...LowMediumProjects
  }
  ${LOW_MEDIUM_PROJECTS_FRAGMENT}
`;
