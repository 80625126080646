import { styled, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { HYVE_YELLOW } from '../Theme/AppTheme';

import { DRAWER_WIDTH } from './PrimaryDrawerNavigation';
import { NAVIGATION_THEME } from '../UI/NavigationTheme';
import WorkspaceActions from '../Workspace/WorkspaceActions';
import { useWorkspace } from '../Workspace/useWorkspace';
import React from 'react';
import HeaderLogo from '../UI/HeaderLogo';

const DesktopPrimaryNavigation = () => {
  const { workspace } = useWorkspace();

  return (
    <ThemeProvider theme={NAVIGATION_THEME}>
      <StyledAppBar
        position='sticky'
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: theme => theme.palette.common.black,
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Toolbar disableGutters>
          <Stack height='100%' direction='row' flexGrow='1' justifyContent='space-between' alignItems='center'>
            <Link to={`/workspace/${workspace.id}`}>
              <HeaderLogo width={DRAWER_WIDTH} />
            </Link>
            <Stack direction='row' alignItems='center'>
              <Divider orientation='vertical' />
              <WorkspaceActions />
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </ThemeProvider>
  );
};

export default DesktopPrimaryNavigation;

const StyledAppBar = styled(AppBar)`
  .MuiToolbar-root {
    .MuiButtonBase-root {
      .MuiIcon-root {
        font-size: 28px;
      }
    }

    .MuiTabs-root {
      .MuiTabs-indicator {
        display: none;
      }
      .MuiTab-root {
        &:hover {
          background-color: rgba(255, 255, 255, 0.08);
        }
        color: #fff;
        .navigation-icon {
          fill: #fff;
          g {
            fill: #fff;
          }
        }
        &.Mui-selected {
          color: ${HYVE_YELLOW};
        }
      }
    }
  }
`;
