import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';

export interface CompanyAvatarInterface {
  name: string;
  imageUrl?: string | null;
}

interface CompanyAvatarProps extends AvatarProps {
  company: CompanyAvatarInterface;
}

const CompanyAvatar = ({ company, ...props }: CompanyAvatarProps) => {
  return (
    <Avatar src={company.imageUrl || undefined} {...props}>
      <CorporateFareRoundedIcon />
    </Avatar>
  );
};

export default CompanyAvatar;
