import { gql, useQuery } from '@apollo/client';
import { Channel } from './Channel';
import useWorkspace from '../Workspace/useWorkspace';

export const useChannels = (): { channels: Channel[]; loading: boolean; error?: Error } => {
  const { workspace } = useWorkspace();

  const { data, loading, error } = useQuery(LIST_CHANNELS_QUERY, {
    variables: { workspaceId: workspace.id },
  });

  const channels = data?.workspace?.channels || [];
  return { channels, loading, error };
};

const LIST_CHANNELS_QUERY = gql`
  query ListChannels($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      channels {
        id
        name
        platform
        isPrivate
      }
    }
  }
`;
