import React, { useState, createRef, Fragment } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const iconSelectRef = createRef<Element>();

export interface IconSelectIcon {
  symbol: string;
  color: string;
}

type IconSelectProps = {
  symbols: string[];
  colors: string[];
  value: IconSelectIcon;
  onChange: (icon: IconSelectIcon) => void;
};

const IconSelect = React.forwardRef(({ symbols, colors, value, onChange, ...props }: IconSelectProps, ref) => {
  const [open, setOpen] = useState(false);
  const [selectOpen] = useState(false);

  if (!value) {
    value = { symbol: symbols[0], color: colors[0] };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    // setOpen(true)
  };

  const handleSelectSymbol = (symbol: string) => {
    onChange({ symbol, color: value.color });
  };

  const handleSelectColor = (color: string) => {
    onChange({ symbol: value.symbol, color });
  };

  return (
    <Fragment>
      <FormControl fullWidth data-cy='icon'>
        <InputLabel shrink={false} id='icon-label' sx={{ transform: 'translate(66px, 16px) scale(1)' }}>
          Select a team icon
        </InputLabel>
        <Select
          // id={id}
          labelId='icon-label'
          open={selectOpen}
          ref={iconSelectRef}
          inputProps={{ sx: { paddingTop: 1, paddingBottom: 1 } }}
          MenuProps={{ sx: { display: 'none' } }}
          {...props}
          value='icon'
          onClick={handleFocus}
          onOpen={() => setOpen(true)}
          // label='Select a team icon'
        >
          <ListItem key='icon' value='icon'>
            <ListItemIcon>
              <IconBox symbol={value.symbol} backgroundColor={value.color} color={'#ffffff'} />
            </ListItemIcon>
          </ListItem>
        </Select>
      </FormControl>
      {/*// Posible fix for text field
    // https://github.com/mui/material-ui/issues/13898#issuecomment-1109515244*/}

      <Popover
        // id='icon-select-popover'
        // className='asdf'
        open={open}
        anchorEl={iconSelectRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack>
          <ColorSelect colors={colors} selectedColor={value.color} onSelectColor={handleSelectColor} />
          <Divider />
          <SymbolSelect symbols={symbols} selectedSymbol={value.symbol} onSelectSymbol={handleSelectSymbol} />
        </Stack>
      </Popover>
    </Fragment>
  );
});

interface ColorSelectProps {
  colors: string[];
  selectedColor: string;
  onSelectColor: (color: string) => void;
}

const ColorSelect = ({ colors, selectedColor, onSelectColor }: ColorSelectProps) => {
  return (
    <Stack p={2} direction='row' width='522px' sx={{ flexWrap: 'wrap', gap: '10px' }}>
      {colors.map(color => (
        <ButtonBase name={color} className='color-option' key={color} onClick={() => onSelectColor(color)}>
          <Box width='40px' height='40px' borderRadius='4px' bgcolor={color} color='#ffffff' display='flex' alignItems='center' justifyContent='center'>
            <Icon>{color === selectedColor ? 'check' : ''}</Icon>
          </Box>
        </ButtonBase>
      ))}
    </Stack>
  );
};

interface SymbolSelectProps {
  symbols: string[];
  selectedSymbol: string;
  onSelectSymbol: (symbol: string) => void;
}

const SymbolSelect = ({ symbols, selectedSymbol, onSelectSymbol }: SymbolSelectProps) => {
  return (
    <Stack p={2} direction='row' width='540px' sx={{ flexWrap: 'wrap', gap: '10px' }}>
      {symbols.map(symbol => (
        <ButtonBase name={symbol} className='symbol-option' key={symbol} onClick={() => onSelectSymbol(symbol)}>
          <IconBox symbol={symbol} selected={symbol === selectedSymbol} color='#000000' />
        </ButtonBase>
      ))}
    </Stack>
  );
};

interface IconBoxProps {
  symbol?: string;
  color?: string;
  backgroundColor?: string;
  selected?: boolean;
}

export const IconBox = ({ symbol, color = '#fff', backgroundColor = '#e2e6e9', selected = false }: IconBoxProps) => (
  <Avatar variant='rounded' sx={{ color: selected ? '#ffffff' : color, backgroundColor: selected ? '#000000' : backgroundColor }}>
    <Icon>{symbol}</Icon>
  </Avatar>
);

export default IconSelect;
