import React, { createContext, useContext } from 'react';

type CategoryParentContextValue = {
  parentPath: string;
};

const CategoryParentContext = createContext<CategoryParentContextValue | undefined>(undefined);

export const useCategoryParentContext = () => {
  const context = useContext(CategoryParentContext);
  if (!context) {
    throw new Error('useCategoryParentContext must be used within a CategoryParentContext');
  }
  return context;
};

type CategoryProviderProps = CategoryParentContextValue & {
  children: React.ReactNode;
};

export const CategoryParentProvider = ({ parentPath, children }: CategoryProviderProps) => {
  return <CategoryParentContext.Provider value={{ parentPath }}>{children}</CategoryParentContext.Provider>;
};
