import React, { useState } from 'react';
import { Button, Link, Stack, StackProps, Typography } from '@mui/material';

import { AspectCardContent } from './AspectCard';
import { computeScoreLevel } from '../Scores/Score';
import HistoricalScores from '../Scores/HistoricalScores';
import { useLayout } from '../UI/LayoutContext';
import { ModuleInterface } from '../Module/Module';
import { Scorable } from '../Scores/Scorable';
import { Aspect } from './Aspect';

const DESCRIPTION_VIEW = 'description';
const TIMELINE_VIEW = 'timeline';

interface AspectSummaryProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspect: Aspect;
  onCollapse: () => void;
  onViewDetails: () => void;
  showRecommendations: boolean;
  setShowRecommendations: (showRecommendations: boolean) => void;
}

const AspectSummary = ({ scorable, module, aspect, onViewDetails, showRecommendations, setShowRecommendations }: AspectSummaryProps) => {
  const [view, setView] = useState(DESCRIPTION_VIEW);
  const healthLevel = computeScoreLevel(aspect.health);
  const layout = useLayout();
  const cardLayout = layout === 'mobile' ? 'vertical' : 'vertical';

  return (
    <div className='aspect-summary' color={healthLevel.color}>
      <div className='aspect-content'>
        <div className='aspect-card-description-timeline'>
          <AspectCardContent
            scorable={scorable}
            module={module}
            aspect={aspect}
            layout={cardLayout}
            onClickScore={() => setView(DESCRIPTION_VIEW)}
            onClickTrend={() => setView(TIMELINE_VIEW)}
          />

          <div className='aspect-card-divider' />

          {view === DESCRIPTION_VIEW && (
            <AspectSummaryText
              scorable={scorable}
              module={module}
              aspect={aspect}
              onViewDetails={onViewDetails}
              showRecommendations={showRecommendations}
              setShowRecommendations={setShowRecommendations}
            />
          )}

          {view === TIMELINE_VIEW && (
            <div className='aspect-timeline'>
              <HistoricalScores scorable={scorable} scoreDimension={aspect} aspect={aspect} aspectRatio={2.5} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface AspectSummaryTextProps extends StackProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspect: Aspect;
  onViewDetails?: () => void;
  showRecommendations: boolean;
  setShowRecommendations: (showRecommendations: boolean) => void;
}

export const AspectSummaryText = ({
  scorable,
  module,
  aspect,
  onViewDetails,
  showRecommendations,
  setShowRecommendations,
  ...stackProps
}: AspectSummaryTextProps) => {
  const healthLevel = computeScoreLevel(aspect.health);

  return (
    <Stack {...stackProps} spacing={2}>
      <Typography variant='h6'>
        {aspect.name} {healthLevel.attentionRequirement}
      </Typography>
      <Typography variant='body2'>{aspect.description}</Typography>
      {onViewDetails && (
        <Link component='button' onClick={onViewDetails}>
          View Details
        </Link>
      )}

      <Stack direction='row'>
        {!showRecommendations ? (
          <Button variant='outlined' onClick={() => setShowRecommendations(true)}>
            View Recommendations
          </Button>
        ) : (
          <Button variant='outlined' onClick={() => setShowRecommendations(false)}>
            Hide Recommendations
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default AspectSummary;
