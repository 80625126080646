import React from 'react';
import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';

import ClientAvatar from '../Client/ClientAvatar';
import ProjectClientSelector from './ProjectClientSelector';
import { userSchema } from '../User/User';
import { ClientOption, ClientOptionSchema } from '../Client/ClientSelector';
import MemberSelect from '../Membership/MemberSelect';
import { useMemberSelect } from '../Membership/MemberSelect';
import { ImportanceRatingField } from '../Scores/ImportanceRating';

export const projectSchema = z.object({
  id: z.string(),
  name: z.string().min(1, 'Project name is required'),
  startDate: z.string().datetime({ offset: true }).optional().nullable(),
  endDate: z.string().datetime({ offset: true }).optional().nullable(),
  description: z.string().max(500, 'Description must be 500 characters or less').optional().nullable(),
  importance: z.number().int().nullable().optional(),
  client: z.object(ClientOptionSchema.shape, { required_error: 'Client is required' }),
  users: z.array(userSchema),
});

export type ProjectValues = z.infer<typeof projectSchema>;

type ProjectFieldsMode = 'new-project' | 'new-project-for-client' | 'edit-project';

interface ProjectFieldsProps {
  mode: ProjectFieldsMode;
}

const ProjectFields = ({ mode }: ProjectFieldsProps) => {
  const { control } = useFormContext();
  const { workspaceUsers, loading } = useMemberSelect();

  return (
    <Stack spacing={2}>
      <ClientField mode={mode} />
      <ProjectNameField />
      <ProjectDescriptionField />
      <ProjectDateFields />
      <ProjectImportanceField />
      <Controller
        name='users'
        control={control}
        render={({ field, fieldState: { error } }) => <MemberSelect {...field} loading={loading} error={error} options={workspaceUsers} />}
      />
    </Stack>
  );
};

const ClientField = ({ mode }: { mode: ProjectFieldsMode }) => {
  const { control, getValues, setValue } = useFormContext<ProjectValues>();
  const handleSelectClient = (client: ClientOption) => {
    setValue('client', client);
  };

  let clientComponent;
  if (mode === 'new-project') {
    clientComponent = <ProjectClientSelector onSelect={handleSelectClient} />;
  } else if (mode === 'edit-project' || mode === 'new-project-for-client') {
    // existing project or new project for client; client cannot be changed
    clientComponent = (
      <Controller
        name='client.name'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            data-cy='client-select'
            variant='outlined'
            fullWidth
            disabled
            label='Client'
            error={!!error}
            helperText={error?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <ClientAvatar onClick={() => {}} client={getValues('client')} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    throw new Error(`Unexpected mode: ${mode}`);
  }

  return clientComponent;
};

const ProjectNameField = () => {
  const { control } = useFormContext<ProjectValues>();
  return (
    <Controller
      name='name'
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          //id='name'
          //type='text'
          variant='outlined'
          fullWidth
          label='Name (Required)'
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
};

const ProjectDescriptionField = () => {
  const { control } = useFormContext<ProjectValues>();
  return (
    <Controller
      name='description'
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={field.value || ''}
          variant='outlined'
          fullWidth
          multiline
          label='Description'
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
};

const ProjectDateFields = () => {
  const { control } = useFormContext<ProjectValues>();
  return (
    <Stack direction='row' spacing={2}>
      <Controller
        name='startDate'
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label='Start Date'
            value={field.value ? DateTime.fromISO(field.value) : null}
            onChange={newValue => {
              field.onChange(newValue?.toISO() || null);
            }}
            slotProps={{
              field: { clearable: true, onClear: () => field.onChange(null) },
            }}
          />
        )}
      />
      <Controller
        name='endDate'
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label='Due Date'
            value={field.value ? DateTime.fromISO(field.value) : null}
            onChange={newValue => {
              field.onChange(newValue?.toISO() || null);
            }}
            slotProps={{
              field: { clearable: true, onClear: () => field.onChange(null) },
            }}
          />
        )}
      />
    </Stack>
  );
};

const ProjectImportanceField = () => {
  return <ImportanceRatingField />;
};

export default ProjectFields;
