import { FetchResult } from '@apollo/client';
import { ValidationError } from './hyveApi';

type ValidationErrors = {
  fullMessages: string[];
  details: string;
};

export const handleMutationResponse = (
  response: FetchResult<any>,
  mutationName: string,
  errorMessage: string
): {
  success: boolean;
  errorMessage?: string;
} => {
  if (response.errors) {
    console.error(response.errors);
    return { success: false, errorMessage: errorMessage };
  }

  const mutationResponse = response.data?.[mutationName];
  if (!mutationResponse) {
    return { success: false, errorMessage: errorMessage };
  }

  const errors: ValidationError[] | ValidationErrors | null = mutationResponse.errors;
  if (errors) {
    if (Array.isArray(errors) && errors.length > 0) {
      // If `errors` is an array of `ValidationError`
      console.error(errors);
      return { success: false, errorMessage: errors.map((error: ValidationError) => error.fullMessage).join('. ') };
    } else if ('fullMessages' in errors && errors.fullMessages.length > 0) {
      // If `errors` is a `ValidationErrors` object
      return { success: false, errorMessage: errors.fullMessages.join('. ') };
    }
  }

  if (response.data?.success === false) {
    return { success: false, errorMessage };
  }

  return { success: true };
};
