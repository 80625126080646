import React, { useState } from 'react';
import { z } from 'zod';
import { useQuery, gql } from '@apollo/client';
import classnames from 'classnames';

import { Autocomplete, createFilterOptions, Avatar, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';

import { ClientInterface } from './ClientInterface';
import { useWorkspace } from '../Workspace/useWorkspace';
import { Controller, useFormContext } from 'react-hook-form';

type WorkspaceClientsData = {
  workspace: {
    clients: ClientInterface[];
  };
};

export const ClientOptionSchema = z.object({
  id: z.string(),
  name: z.string(),
  imageUrl: z.string().url().nullable().optional(),
  domain: z.string().nullable().optional(),
});
export type ClientOption = z.infer<typeof ClientOptionSchema>;

const ClientSelector = () => {
  const { control, setValue } = useFormContext();
  const { workspace } = useWorkspace();
  const [selectedClient, setSelectedClient] = useState<ClientOption | null>(null);

  const { data } = useQuery<WorkspaceClientsData>(WORKSPACE_CLIENTS_QUERY, { variables: { id: workspace.id } });

  const clients: ClientOption[] = data?.workspace?.clients || [];

  const handleChange = (_: any, value: ClientOption | null) => {
    if (value) {
      const client = clients.find(client => client.id === value.id);
      setSelectedClient(value);
      setValue('client', client);
    } else {
      setSelectedClient(null);
      setValue('client', undefined);
    }
  };

  return (
    <Controller
      name='client'
      control={control}
      //      defaultValue={null}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          value={selectedClient}
          // {...props}
          renderInput={params => (
            <TextField
              {...params}
              data-cy='client-select'
              variant='outlined'
              fullWidth
              label='Select Client'
              error={!!error}
              helperText={error ? error.message : null}
            />
            /*InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Avatar src={option.imageUrl} />
                          <AddIcon />
                        </InputAdornment>
                      )
                    }}*/
          )}
          autoHighlight
          onInputChange={event => {
            if (event) {
              // for some reason, the event is null when reseting the client value
              // console.log(event);
              // setInputValue(event.target.value);
            }
          }}
          onChange={handleChange}
          filterOptions={createFilterOptions({ matchFrom: 'start' })}
          options={clients}
          getOptionLabel={(option: ClientOption) => {
            return option.name;
          }}
          renderOption={(props, option) => {
            return (
              <ListItem {...props} className={classnames('client-option', props.className)}>
                <ListItemIcon>
                  <Avatar src={option.imageUrl || ''}>
                    <CorporateFareRoundedIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={option.name} secondary={option.domain} />
              </ListItem>
            );
          }}
        />
      )}
    />
  );
};

export default ClientSelector;

const WORKSPACE_CLIENTS_QUERY = gql`
  query Workspace($id: ID!) {
    workspace(id: $id) {
      id
      clients(internal: false) {
        id
        name
        imageUrl
        domain
      }
      users {
        id
        firstName
        lastName
        email
        avatar
        pendingInvitation
      }
    }
  }
`;
