import { IconBox } from '../UI/IconSelect';
import { TeamIconType } from './TeamSchema';

const TeamIcon = ({ icon }: { icon: TeamIconType }) => {
  return <IconBox symbol={icon?.symbol || 'people'} backgroundColor={icon?.color || '#000000'} />;
};

export const symbols = [
  'people',
  'forum',
  'hub',
  'key',
  'shield',
  'savings',
  'star',
  'tungsten',
  'widgets',
  'language',
  'account_balance',
  'gavel',
  'bug_report',
  'extension',
  'support',
  'code',
  'rocket_launch',
  'balance',
  'campaign',
  'home_work',
  'science',
  'storefront',
  'flash_on',
  'attach_money',
  'insert_chart',
  'support_agent',
  'computer',
  'coffee',
  'smart_toy',
  'alt_route',
];

export const colors = [
  '#000000',
  '#313131',
  '#6d7278',
  '#aeb2b5',
  '#0091ff',
  '#00355e',
  '#e73778',
  '#571047',
  '#fe9900',
  '#8b5300',
  '#01a8ac',
  '#005255',
  '#5da283',
  '#10573b',
  '#8cac01',
  '#63789a',
  '#8b1111',
  '#bd7474',
  '#493900',
  '#e2bb22',
];

export default TeamIcon;
