import { useWorkspace } from '../Workspace/useWorkspace';
import { useContributeIntelligence } from '../Intelligence/ContributeAndCollect';
import PageStyle from '../UI/PageStyle';
import SurveyMessage from './SurveyMessage';
import { Button, Stack, Typography } from '@mui/material';
import { CollectIntelligence } from './SurveyActions';
import Illustration from '../UI/Illustration';

const SurveysComplete = () => {
  const { workspace } = useWorkspace();
  const { contributeIntelligence, contributeIntelligenceDisabled } = useContributeIntelligence(workspace);

  const handleContribute = async () => {
    await contributeIntelligence();
  };

  return (
    <PageStyle design='illustrated'>
      <Stack spacing={2} alignItems='center'>
        <SurveyMessage>
          <Stack alignItems='center'>
            <Typography variant='h4' style={{ textAlign: 'center' }}>
              All Set.
            </Typography>
            <Typography variant='h5' style={{ textAlign: 'center' }}>
              We’ll notify you when we need more intelligence, or maybe you’re on a roll and want to keep going?
            </Typography>
          </Stack>

          <Stack direction='row' spacing={1} justifyContent='center'>
            <Button variant='contained' disableElevation color='primary' href={`/workspace/${workspace.id}`}>
              Nah, Go to Dashboard
            </Button>
            <Button variant='contained' disableElevation color='primary' onClick={handleContribute} disabled={contributeIntelligenceDisabled}>
              Keep Contributing
            </Button>
          </Stack>

          {workspace.canCollectIntelligence && <CollectIntelligence />}
        </SurveyMessage>

        <Illustration image='all-set' alt='No intelligence needed' />
      </Stack>
    </PageStyle>
  );
};

export default SurveysComplete;
