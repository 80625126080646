import { useContext } from 'react';
import UserContext from './UserContext';

const useUser = () => {
  const userContext = useContext(UserContext); 
  if (!userContext) {
    throw new Error('useUser should be called within a UserContext.Provider');
  }
  return userContext;
};

export default useUser;
