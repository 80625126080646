import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

export const getColumnVisibilityModel = (columns: GridColDef[], fieldPriority: string[], width: number) => {
  let cumulativeWidth = 0;
  const prioritizedColumns = fieldPriority.map(field => columns.find(column => column.field === field));
  return prioritizedColumns.reduce((model, column) => {
    if (column) {
      cumulativeWidth += column.width || column.minWidth || 0;
      model[column.field] = cumulativeWidth <= width;
    }
    return model;
  }, {} as GridColumnVisibilityModel);
};

// https://stackoverflow.com/questions/75943264/how-to-word-wrap-the-column-from-material-ui-datagrid/75944212#75944212
export const DATA_GRID_COLUMN_HEADER_STYLES = {
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal',
    lineHeight: 'normal',
  },
  '& .MuiDataGrid-columnHeader': {
    // Forced to use important since overriding inline styles
    // height: 'unset !important',
    height: '80px !important',
  },
  '& .MuiDataGrid-columnHeaders': {
    // Forced to use important since overriding inline styles
    maxHeight: '168px !important',
  },
};

const StyledDataGrid = styled(DataGrid)`
  border: none;

  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
      user-select: none;
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-of-type {
      padding-left: 16px;
    }

    &:last-of-type {
      padding-right: 16px;
    }
  }

  .MuiDataGrid-columnHeader:first-of-type {
    padding-left: 16px;
  }

  .MuiDataGrid-columnHeader:last-of-type {
    padding-right: 16px;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  /* Left align with text buttons */
  .MuiDataGrid-columnHeader--indented {
    padding-left: 16px;
  }

  .MuiListItem-gutters {
    padding-left: 0;
  }

  .MuiListItemText-root {
    .MuiListItemText-primary {
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  .MuiAvatarGroup-root {
    .MuiAvatar-root {
      width: 24px;
      height: 24px;
    }
  }

  .IconMuiButton-root {
    color: #666;
  }

  .membership-check {
    color: var(--green);
  }

  .project-membership-cell {
    a:hover {
      text-decoration: underline;
    }
  }
`;

export default StyledDataGrid;
