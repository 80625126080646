import React from 'react';
import { Theme, ThemeProvider, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

import Stack from '@mui/material/Stack';

import { NAVIGATION_THEME } from '../UI/NavigationTheme';

const DRAWER_WIDTH = 240;

interface MiniDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  drawerHeaderContent: React.ReactNode;
  renderDrawerContent: (open: boolean) => React.ReactNode;
  children: React.ReactNode;
}

const MiniDrawer = ({ open, setOpen, drawerHeaderContent, renderDrawerContent, children }: MiniDrawerProps) => {
  const theme = NAVIGATION_THEME;

  const drawerStyles = {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  };

  return (
    <Stack direction='row' flexGrow={1} className='mini-drawer' sx={{ overflowY: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <MuiDrawer variant='permanent' open={open} sx={drawerStyles}>
          <Toolbar sx={{ minHeight: '64px' }} />
          <DrawerHeader>
            {open ? (
              <Stack flexGrow={1} paddingLeft={1} direction='row' alignItems='center'>
                <Box flexGrow={1}>{drawerHeaderContent}</Box>
                <IconButton onClick={() => setOpen(false)}>
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Stack>
            ) : (
              <IconButton onClick={() => setOpen(true)}>
                <MenuRoundedIcon />
              </IconButton>
            )}
          </DrawerHeader>
          <Divider />
          {renderDrawerContent(open)}
        </MuiDrawer>
      </ThemeProvider>

      <Stack className='drawer-main' flexGrow={1} overflow='hidden' sx={{ overflowY: 'scroll' }}>
        {children}
      </Stack>
    </Stack>
  );
};

const openedMixin = (theme: Theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'flex-end',
        padding: theme.spacing(0, 1.5),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      }}
    >
      {children}
    </Box>
  );
};

export default MiniDrawer;
