import { deepmerge } from '@mui/utils';

import { DARK_THEME } from '../Theme/DarkTheme';

export const NAVIGATION_THEME = deepmerge(DARK_THEME, {
  palette: {
    divider: 'rgba(255, 255, 255, 0.12)',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '64px',
          minWidth: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'inherit',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
  },
});
