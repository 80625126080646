import { useWorkspace } from '../Workspace/useWorkspace';
import { Scorable } from '../Scores/Scorable';

export type ScopeType = 'Unscoped' | 'Personal' | 'Team';

export interface ScopeInterface {
  type: ScopeType;
  id?: string;
  key: string;
  prefix: string;
  selectorPrefix: string;
  variables: any;
}

export const PERSONAL_SCOPE: ScopeInterface = {
  key: 'Personal',
  type: 'Personal',
  prefix: `My `,
  selectorPrefix: `My `,
  variables: { scope: { type: 'Personal' } },
};

export const UNSCOPED_SCOPE: ScopeInterface = {
  key: 'Unscoped',
  type: 'Unscoped',
  prefix: `Workspace `,
  selectorPrefix: `All`,
  variables: { scope: { type: 'Unscoped' } },
};

export const useScopesForScorable = (
  scorable: Scorable
): {
  scopes: Array<ScopeInterface>;
  defaultScope: ScopeInterface;
} => {
  const { workspace } = useWorkspace();

  const teamScopes: ScopeInterface[] = workspace.teams.map(team => ({
    id: team.id,
    key: `Team:${team.id}`,
    type: 'Team',
    prefix: `${team.name} `,
    selectorPrefix: `${team.name} `,
    variables: {
      scope: { type: 'Team', id: team.id },
      teamId: team.id,
    },
  }));

  let scopes;
  let defaultScope;
  switch (scorable.__typename) {
    case 'Workspace':
      scopes = [PERSONAL_SCOPE, UNSCOPED_SCOPE, ...teamScopes];
      defaultScope = PERSONAL_SCOPE;
      break;
    case 'Client':
      scopes = [PERSONAL_SCOPE, UNSCOPED_SCOPE, ...teamScopes];
      defaultScope = PERSONAL_SCOPE;
      break;
    case 'Project':
      scopes = [UNSCOPED_SCOPE];
      defaultScope = UNSCOPED_SCOPE;
      break;
    case 'Team':
      const teamScope = teamScopes.find(teamScope => teamScope.id === scorable.id)!;
      scopes = [teamScope];
      defaultScope = teamScope;
      break;
    default:
      throw new Error('Unexpected scorable type');
  }
  return { scopes, defaultScope };
};
