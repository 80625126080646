import { Scorable } from '../Scores/Scorable';
import { ModuleInterface, ModuleType } from './Module';

export const useModule = (
  scorable: Scorable,
  moduleType: ModuleType
): {
  module: ModuleInterface | undefined;
  active: boolean;
} => {
  if (!scorable) {
    // for loading states
    return { module: undefined, active: false };
  }
  const module = getModule(scorable, moduleType);
  const active = module !== undefined;

  return { module, active };
};

export const getModule = (scorable: Scorable, moduleType: ModuleType) => {
  return scorable.categories.flatMap(category => category.modules).find(currentModule => currentModule.type === moduleType);
};
