import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { CompanyInterface } from './CompanyInterface';
import { CompanyOption } from '../UI/CompanyField';
import { debounce } from '@mui/material';

export type ClearbitCompany = {
  name: string;
  logo: string;
  domain: string;
};

export const useClearbitCompanies = () => {
  const [companyQuery, setCompanyQuery] = useState('');
  const [companies, setCompanies] = useState<CompanyOption[]>([]);

  const updateCompanies = async (query: string) => {
    try {
      const options = await getCompanies(query);
      setCompanies(options);
    } catch (error) {
      console.error('Failed to fetch companies:', error);
      // Handle the error appropriately
    }
  };

  const debouncedSetCompanyQuery = useCallback(
    debounce(async query => {
      await updateCompanies(query);
    }, 200),
    []
  ); // Adjust the 500ms delay as needed

  useEffect(() => {
    if (companyQuery) {
      debouncedSetCompanyQuery(companyQuery);
    }
  }, [companyQuery, debouncedSetCompanyQuery]);

  return { setCompanyQuery, companies };
};

const getCompanies = async (name: string): Promise<CompanyInterface[]> => {
  if (!name) {
    return [];
  }
  const { data } = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest`, { params: { query: name } });
  return data.map((company: ClearbitCompany) => ({ name: company.name, imageUrl: company.logo, domain: company.domain }));
};
