import { useState } from 'react';
import useActiveFeatures from '../Module/useActiveFeatures';
import { ActionInterface } from '../UI/ActionInterface';
import HexagonRoundedIcon from '@mui/icons-material/HexagonRounded';
import { ClientInterface } from '../Client/ClientInterface';
import useWorkspace from '../Workspace/useWorkspace';

export type AddProjectMode = 'client' | 'internal' | 'any';

export const useAddProject = (mode: AddProjectMode, client?: ClientInterface) => {
  const { workspace } = useWorkspace();
  const { clientProjectsActive, companyProjectsActive } = useActiveFeatures();
  const [addProjectClient, setAddProjectClient] = useState(client);
  const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false);

  const addProjectActions: ActionInterface[] = [];

  if (clientProjectsActive && (mode === 'client' || mode === 'any')) {
    addProjectActions.push({
      id: 'add-client-project',
      label: 'Add Client Project',
      icon: HexagonRoundedIcon,
      onSelect: () => {
        setAddProjectClient(client);
        setAddProjectDialogOpen(true);
      },
    });
  }

  if (companyProjectsActive && (mode === 'internal' || mode === 'any')) {
    addProjectActions.push({
      id: 'add-internal-project',
      label: 'Add Internal Project',
      icon: HexagonRoundedIcon,
      onSelect: () => {
        setAddProjectClient(workspace.company);
        setAddProjectDialogOpen(true);
      },
    });
  }

  return { addProjectActions, addProjectDialogOpen, addProjectClient, setAddProjectDialogOpen };
};


