import React from 'react';
import { Outlet, useOutletContext, Link as RouterLink } from 'react-router-dom';
import SecondaryNavigation, { SecondaryNavigationActions, SecondaryNavigationHeading } from '../Navigation/SecondaryNavigation';
import { ChevronRightRounded, CorporateFareRounded } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useScope } from '../Scope/useScope';
import { findCategoryScoreDimensionSettings } from '../Category/CategoryScoreDimensionSettings';
import { SecondaryNavigationTab, SecondaryNavigationTabs, useSecondaryNavigationTabs } from '../Navigation/SecondaryNavigationTabs';
import useActiveFeatures from '../Module/useActiveFeatures';
import ScopeSelector from '../Scope/ScopeSelector';
import { Button } from '@mui/material';
import { CategoryProvider, useCategoryContext } from '../Category/CategoryContext';
import { useCategory } from '../Category/useCategory';
import { CategoryParentProvider } from '../Category/CategoryParentContext';
import { Scorable } from '../Scores/Scorable';

const Clients = () => {
  const { workspace, workspacePath } = useWorkspace();
  const { scorable } = useOutletContext<{ scorable: Scorable }>();
  const category = scorable.categories.find(category => category.type === 'ClientsCategory')!;

  return (
    <CategoryParentProvider parentPath={workspacePath}>
      <CategoryProvider category={category}>
        <ClientsSecondaryNavigation />
        <Outlet context={{ clientContainer: workspace }} />
      </CategoryProvider>
    </CategoryParentProvider>
  );
};

const ClientsSecondaryNavigation = () => {
  const { workspace } = useWorkspace();
  const { category } = useCategoryContext();
  const { scopes } = useScope();

  // const clientsPath = recentClientsSection === 'dashboard' ? `${workspacePath}/clients/` : `${workspacePath}/clients/list`;

  const scopeOptions = scopes.map(scope => {
    return { label: `${scope.selectorPrefix} Clients`, scope: scope, count: undefined };
  });

  const { categoryPath } = useCategory('ClientsCategory');
  const iconColor = findCategoryScoreDimensionSettings('ClientsCategory').color;

  const dashboardTab = { key: 'dashboard', label: 'Dashboard', path: '' };
  const clientsTab = { key: 'list', label: 'Clients', path: 'list' };
  const projectsTab = { key: 'projects', label: 'Projects', path: 'projects' };
  const aspectsTab = { key: 'aspects', label: 'Aspects', path: `modules/${category.modules[0].id}/aspects` };

  const { clientProjectsActive } = useActiveFeatures();
  const tabs: SecondaryNavigationTab[] = [dashboardTab, clientsTab];
  if (clientProjectsActive) {
    tabs.push(projectsTab);
  }
  tabs.push(aspectsTab);

  const secondaryNavigationTabsProps = useSecondaryNavigationTabs(tabs, categoryPath);

  return (
    <SecondaryNavigation>
      <SecondaryNavigationHeading>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Avatar variant='rounded' sx={{ color: '#000', bgcolor: iconColor }}>
            <CorporateFareRounded sx={{ fontSize: '24px' }} />
          </Avatar>
          <Stack direction='row' alignItems='center' sx={{ fontSize: '14px', lineHeight: '1em' }}>
            <Button component={RouterLink} to={`/workspace/${workspace.id}/clients`} sx={{ textTransform: 'none' }}>
              Clients
            </Button>
            <ChevronRightRounded />
            <ScopeSelector options={scopeOptions} includeUnscopedForAllMembers />
          </Stack>
        </Stack>
      </SecondaryNavigationHeading>

      <SecondaryNavigationActions>
        <SecondaryNavigationTabs {...secondaryNavigationTabsProps} />
      </SecondaryNavigationActions>
    </SecondaryNavigation>
  );
};

export default Clients;
