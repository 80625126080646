import React from 'react';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useContributeIntelligence } from '../Intelligence/ContributeAndCollect';
import PageStyle from '../UI/PageStyle';
import { Button, Stack, Typography } from '@mui/material';
import SurveyMessage from './SurveyMessage';
import { CollectIntelligence } from './SurveyActions';
import Illustration from '../UI/Illustration';
import { useGlobalMessage } from '../UI/GlobalMessage';

const StartFirstSurvey = () => {
  const { workspace } = useWorkspace();
  const { setMessage } = useGlobalMessage();
  const { contributeIntelligence, contributeIntelligenceDisabled } = useContributeIntelligence(workspace);

  const startFirstSurvey = async () => {
    if (workspace.projectStats.count === 0) {
      setMessage('Please add some projects first!');
    } else {
      await contributeIntelligence();
    }
  };

  return (
    <PageStyle design='illustrated'>
      <Illustration image='surveys' alt='First Survey' />
      <SurveyMessage>
        <Stack alignItems='center'>
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            Ready to get your Hyve buzzing?
          </Typography>
          <Typography variant='h5' sx={{ textAlign: 'center', textWrap: 'balance' }}>
            If you've created and added members to all of your projects, then let’s get started.
          </Typography>
        </Stack>

        <Button
          disabled={contributeIntelligenceDisabled}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          variant='contained'
          disableElevation
          color='primary'
          onClick={startFirstSurvey}
        >
          Contribute Intelligence
        </Button>
        {workspace.canCollectIntelligence && <CollectIntelligence />}
      </SurveyMessage>
    </PageStyle>
  );
};

export default StartFirstSurvey;
