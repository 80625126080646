import { gql } from '@apollo/client';
import { CHANNEL_CONNECTIONS_FRAGMENT } from '../Chat/ChannelConnectable';

// TODO: move clients, projects and users out of this query
export const TEAM_FIELDS_FRAGMENT = gql`
  fragment TeamFields on Team {
    id
    name
    icon {
      symbol
      color
    }
    parentTeam {
      id
      name
    }
    ancestors {
      id
      name
    }
    status
    member
    health
    intelligence

    ...ChannelConnections

    manager {
      id
      firstName
      lastName
      avatar
    }
    users {
      id
      email
      firstName
      lastName
      avatar
      pendingInvitation
    }
    clients {
      id
      name
      internal
    }
    projects {
      id
      name
      client {
        id
        internal
      }
    }
  }
  ${CHANNEL_CONNECTIONS_FRAGMENT}
`;
