import { PossibleTypesMap, TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
  Answer: { keyFields: false },
  Aspect: {
    keyFields: false, // prevents caching outside of scope
    //      fields: { key: keySettings }
  },
  //    AspectWorkspace: { keyFields: false }, // prevents caching outside of scope
  //    AspectClient: { keyFields: false }, // prevents caching outside of scope
  //    AspectProject: { keyFields: false }, // prevents caching outside of scope
  AspectMetric: { keyFields: false }, // prevents caching outside of scope
  CategoryInterface: {
    fields: {
      health: {
        read() {
          return undefined; // Prevent caching of health field
        },
      },
    },
  },
  Client: {
    fields: {
      //        key: keySettings,
      projects: { merge: false },
      stats: { merge: false },
      users: { merge: false },
    },
  },
  //    ClientsCategory: {
  //      fields: { key: keySettings }
  //    },
  //    CompanyCategory: {
  //      fields: { key: keySettings }
  //    },
  //    EmployeeExperienceCategory: {
  //      fields: { key: keySettings }
  //    },
  ModuleInterface: {
    // keyFields: false,
    fields: {
      health: {
        read() {
          return undefined; // Prevent caching of health field
        },
      },
    },
  },
  Project: {
    fields: {
      //        key: keySettings,
      users: { merge: false },
    },
  },
  Response: { keyFields: false },
  Team: {
    fields: {
      //        key: keySettings,
      stats: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  }, // test
  Workspace: {
    fields: {
      health: { merge: false },
      intelligence: { merge: false },
      clients: { merge: false },
      categories: { merge: false },
      projects: { merge: false },
      surveyStats: { merge: true },
    },
  },
};

const CATEGORIES = ['ClientsCategory', 'EmployeeExperienceCategory', 'CompanyCategory'];
const MODULES = [
  'AggregateModule',
  'ClientsProjectsModule',
  'ClientsProductsAndServicesModule',
  'ClientsRelationshipModule',
  'EngagementModule',
  'DiversityEquityAndInclusionModule',
  'BurnoutModule',
  'HealthAndWellnessModule',
  'CompanyProjectsModule',
  // 'ProjectsModule',
  'CoreValuesModule',
  'SocialResponsibilityModule',
  'SeniorLeadershipModule',
];

export const possibleTypes: PossibleTypesMap = {
  ScorableInterface: ['Workspace', 'Client', 'Project', 'Team'],
  AggregatorInterface: [...CATEGORIES, ...MODULES],
  CategoryInterface: CATEGORIES,
  ModuleInterface: MODULES,
  ClientBearerInterface: ['Workspace', 'Team', 'ClientsCategory', 'ClientsProjectsModule', 'ClientsProductsAndServicesModule', 'ClientsRelationshipModule'],
  ProjectBearerInterface: ['Workspace', 'Team', 'Client', 'ClientsCategory', 'ClientsProjectsModule', 'CompanyCategory', 'CompanyProjectsModule'],
  ChannelConnectableInterface: ['Client', 'Project', 'Team'],
  MembershipInterface: [
    'Client',
    'Project',
    'Team',
    'EmployeeExperienceCategory',
    'EngagementModule',
    'DiversityEquityAndInclusionModule',
    'BurnoutModule',
    'HealthAndWellnessModule',
    'CompanyCategory',
    'CoreValuesModule',
    'SocialResponsibilityModule',
    'SeniorLeadershipModule',
  ],
};
