import React, { Fragment } from 'react';

import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { useLayout } from '../UI/LayoutContext';
import Section, { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import { formatUser, UserInterface } from '../User/User';
import { IconType } from '../UI/Icon';

interface MemberListOptions {
  Icon?: IconType;
  titlePrefix?: string;
  manager?: UserInterface | undefined;
  owners?: UserInterface[];
  members: UserInterface[];
  memberActionsResolver?: (member: UserInterface) => ActionListType;
  actions?: React.ReactNode;
  onAddMembers?: () => void;
  parentPath?: string;
}

const MemberList = ({ Icon, titlePrefix, manager, owners = [], members, memberActionsResolver, onAddMembers, parentPath, actions }: MemberListOptions) => {
  const layout = useLayout();

  return (
    <Section>
      <SectionHeader parentPath={parentPath}>
        {Icon && (
          <SectionHeaderIcon>
            <Icon />
          </SectionHeaderIcon>
        )}
        <SectionHeaderTitle>{`${titlePrefix ? titlePrefix + ' ' : ''} Members (${members.length})`}</SectionHeaderTitle>
        <SectionHeaderActions>
          {onAddMembers &&
            (layout === 'mobile' ? (
              <IconButton id='add-members' size='small' onClick={onAddMembers}>
                <PersonAddRoundedIcon />
              </IconButton>
            ) : (
              <Button id='add-members' size='small' startIcon={<PersonAddRoundedIcon />} onClick={onAddMembers}>
                Add Members
              </Button>
            ))}
          {actions}
        </SectionHeaderActions>
      </SectionHeader>

      <List disablePadding>
        {members.map(member => {
          let nameWithRole = formatUser(member);
          if (member.id === manager?.id) {
            nameWithRole += ' (Manager)';
          }
          if (owners.map(owner => owner.id).includes(member.id)) {
            nameWithRole += ' (Owner)';
          }
          const memberActions = memberActionsResolver ? memberActionsResolver(member) : [];

          return (
            <Fragment key={member.id}>
              <ListItem data-cy='member'>
                <ListItemAvatar>
                  <Avatar src={member.avatar} />
                </ListItemAvatar>
                <ListItemText primary={nameWithRole} />
                <ListItemSecondaryAction className='list-actions'>
                  <OverflowActions className='member-actions' actions={memberActions} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Section>
  );
};

export default MemberList;
