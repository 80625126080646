import React, { createContext, useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { WORKSPACE_FIELDS_FRAGMENT, WorkspaceInterface } from './WorkspaceInterface';
import useWorkspaceSelection from './useWorkspaceSelection';
import ApolloErrorPage from '../UI/ApolloErrorPage';
import WorkspaceLoading from './WorkspaceLoading';

export interface WorkspaceContextInterface {
  workspace: WorkspaceInterface;
  workspacePath: string;
  refetch: () => Promise<any>;
  isSystemAdmin: boolean;
  isAdmin: boolean;
  isSeniorLeader: boolean;
}

const WorkspaceContext = createContext<WorkspaceContextInterface | undefined>(undefined);

export const useWorkspace = (): WorkspaceContextInterface => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useWorkspace must be called within a WorkspaceProvider');
  }
  return context;
};

interface WorkspaceProviderInterface {
  children: React.ReactNode;
}

export const WorkspaceProvider = ({ children }: WorkspaceProviderInterface) => {
  const { workspaceId } = useWorkspaceSelection();
  const { data, loading, error, refetch } = useQuery(WORKSPACE_QUERY, { variables: { id: workspaceId } });

  if (error) {
    return <ApolloErrorPage error={error} type='Workspace' includeHeader />;
  }

  if (loading) {
    return <WorkspaceLoading />;
  }

  const { workspace }: { workspace: WorkspaceInterface } = data;
  const workspacePath = `/workspace/${workspace.id}`;

  const isSystemAdmin = workspace.effectiveRoles.includes('SystemAdmin');
  const isAdmin = workspace.effectiveRoles.includes('Admin') || isSystemAdmin;
  const isSeniorLeader = workspace.effectiveRoles.includes('SeniorLeader');

  const value = { workspace, workspacePath, refetch, isSystemAdmin, isAdmin, isSeniorLeader };

  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>;
};

export default useWorkspace;

const WORKSPACE_QUERY = gql`
  query Workspace($id: ID!) {
    workspace(id: $id, scope: { type: Personal }) {
      intelligence # for workspace dashboard intelligence notice; should move elsewhere
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;
