import React, { Fragment } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import ClientSecondaryNavigation from './ClientSecondaryNavigation';
import PageStyle from '../UI/PageStyle';
import { useClientActions } from './ClientActions';
import MembershipNotice from '../Membership/MembershipNotice';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import ApolloErrorPage from '../UI/ApolloErrorPage';
import ArchivedNotice from '../Archive/ArchivedNotice';
import { ClientProvider, useClientContext } from './ClientContext';
import { CLIENT_FIELDS_FRAGMENT } from './ClientInterface';

const Client = () => {
  const { clientId: id } = useParams<{ clientId: string }>();
  const { data, error, loading, refetch } = useQuery(CLIENT_QUERY, { variables: { id: id } });
  if (error) {
    return <ApolloErrorPage error={error} type='Client' />;
  }

  if (!data && loading) {
    return <PageStyle design='standard' loading={true} />;
  }

  const { client } = data;

  return (
    <ClientProvider client={client} refetch={refetch}>
      <ClientArea />
    </ClientProvider>
  );
};

const ClientArea = () => {
  const { client } = useClientContext();
  const { joinClient } = useClientActions();
  const membershipNotice = <MembershipNotice memberCollector={client} onJoin={joinClient} />;

  return (
    <Fragment>
      <ClientSecondaryNavigation />
      <ArchivedNotice archivable={client} />
      {membershipNotice}
      <Outlet />
    </Fragment>
  );
};

export default Client;

// Note: not including projects because cache gets reset when fetching filtered projects
const CLIENT_QUERY = gql`
  query Client($id: ID!, $scope: ScopeInput) {
    client(id: $id, scope: $scope) {
      ...ClientFields

      projectMember
      memberStats {
        count
      }

      health
      intelligence
      ...ScorableSurveyFields

      workspace {
        id
        name
      }
      projects {
        id
      }
      member
      owners: users(withRole: Owner) {
        id
      }
      users {
        id
        email
        firstName
        lastName
        avatar
        pendingInvitation
      }
      categories {
        id
        name
        modules {
          id
          name
        }
      }
    }
  }
  ${CLIENT_FIELDS_FRAGMENT}
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;
