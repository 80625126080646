import { gql } from '@apollo/client';

import { ASPECT_SCORES_FRAGMENT } from '../Aspect/AspectList';
import { LOWEST_SCORE_CLIENTS_FRAGMENT } from '../Client/ClientCollector';
import { LOWEST_SCORE_PROJECTS_FRAGMENT } from '../Projects/ProjectCollector';

export const WORKSPACE_ASPECT_SCORES_QUERY = gql`
  query WorkspaceAspectScores($id: ID!, $scope: ScopeInput, $days: Int, $categoryType: CategoryType, $moduleType: ModuleType) {
    scorable: workspace(id: $id, scope: $scope) {
      id
      projects {
        id
      }
      categories(type: $categoryType) {
        id
        modules(type: $moduleType) {
          id
          name
          type
          aspects {
            ...AspectScores
            ...LowestScoreClients
            ...LowestScoreProjects
          }
        }
      }
    }
  }
  ${ASPECT_SCORES_FRAGMENT}
  ${LOWEST_SCORE_CLIENTS_FRAGMENT}
  ${LOWEST_SCORE_PROJECTS_FRAGMENT}
`;
