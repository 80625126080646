import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Icon, Stack } from '@mui/material';

import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { useWorkspace } from '../Workspace/useWorkspace';
import WorkspaceModuleSettings, { activeModulesSchema, useModuleSettings } from './WorkspaceModuleSettings';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { z } from 'zod';

const moduleSettingsSchema = z.object({
  activeModules: activeModulesSchema,
});
type ModuleSettingsSchema = z.infer<typeof moduleSettingsSchema>;

type ModuleSettingsDialogProps = {
  workspaceSettings: WorkspaceInterface;
  open: boolean;
  onClose: () => void;
  refetch: () => Promise<any>;
};

const ModuleSettingsDialog = ({ workspaceSettings, open, onClose, refetch }: ModuleSettingsDialogProps) => {
  // workspaceSettings = cloneDeep(workspaceSettings); // do not modify original values in case the dialog is canceled
  const { workspace } = useWorkspace();
  const { activeModules } = useModuleSettings(workspaceSettings.categories);

  // workspaceSettings.activeModules = activeModules;

  const formDialogProps = useFormDialog<ModuleSettingsSchema>({ schema: moduleSettingsSchema, defaultValues: { activeModules } });

  const [updateModuleStatus, { error }] = useMutation(UPDATE_MODULE_STATUS_MUTATION);

  const handleSubmit = async (values: ModuleSettingsSchema) => {
    const { activeModules } = values;
    const modifiedModules = workspaceSettings.categories.flatMap(category => category.modules).filter(module => module.active !== activeModules[module.type]);
    await Promise.all(
      modifiedModules.map(async module => {
        await updateModuleStatus({ variables: { workspaceId: workspace.id, type: module.type, active: !module.active } });
      })
    );

    return await refetch();
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='edit-assessments'
      open={open}
      onClose={onClose}
      icon={<Icon>analytics</Icon>}
      title='Manage Assessments'
      subtitle='Select the assessments to enable for your workspace.'
      submitLabel='Save'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Stack px={2}>
        <WorkspaceModuleSettings categories={workspaceSettings.categories} interactive={true} />
      </Stack>
    </FormDialog>
  );
};

const UPDATE_MODULE_STATUS_MUTATION = gql`
  mutation UpdateModuleStatus($workspaceId: ID!, $type: ModuleType!, $active: Boolean!) {
    updateModuleStatus(workspaceId: $workspaceId, type: $type, active: $active) {
      module {
        id
        active
      }
    }
  }
`;

export default ModuleSettingsDialog;
