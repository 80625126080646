import React, { useState, useEffect, createContext, useContext } from 'react';

const LayoutContext = createContext<Layout | undefined>(undefined);

export type Layout = 'mobile' | 'tablet' | 'laptop' | 'desktop';

const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [layout, setLayout] = useState<Layout>(getLayout);

  useEffect(() => {
    const handleResize = () => {
      setLayout(getLayout());
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
  }, [layout]);

  return <LayoutContext.Provider value={layout}>{children}</LayoutContext.Provider>;
};

const getLayout = () => {
  let layout: Layout;
  if (window.matchMedia('(max-width: 640px)').matches) {
    layout = 'mobile';
  } else if (window.matchMedia('(max-width: 960px)').matches) {
    layout = 'tablet';
  } else if (window.matchMedia('(max-width: 1200px)').matches) {
    layout = 'laptop';
  } else {
    layout = 'desktop';
  }
  return layout;
};

const useLayout = () => {
  const layout = useContext<Layout | undefined>(LayoutContext);
  if (layout === undefined) {
    throw new Error("useLayout must be used within a LayoutContext.Provider");
  }
  return layout; 
};

export { useLayout, LayoutProvider };
