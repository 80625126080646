import React, { Fragment } from 'react';
import { z } from 'zod';
import { Avatar, Stack, Switch, Typography } from '@mui/material';

import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { findCategoryScoreDimensionSettings } from '../Category/CategoryScoreDimensionSettings';

import { ModuleInterface, moduleTypeSchema } from '../Module/Module';
import { Category } from '../Category/Category';
import { Controller, useFormContext } from 'react-hook-form';

export const activeModulesSchema = z.record(moduleTypeSchema, z.boolean());
export type ActiveModules = z.infer<typeof activeModulesSchema>;

export const useModuleSettings = (categories: Category[]) => {
  const modules = categories?.flatMap(category => category.modules) || [];
  const activeModules: ActiveModules = modules.reduce((modules, module) => ({ ...modules, [module.type]: module.active || false }), {});
  return { activeModules };
};

interface WorkspaceModuleSettingsProps {
  categories: Category[];
  interactive?: boolean;
}

export const ModuleSettingsFormContent = ({ categories }: { categories: Category[] }) => {
  return (
    <Fragment>
      <Typography fontSize={18} fontWeight='bold'>
        Select at least one assessment for your workspace
      </Typography>

      {categories.map(category => (
        <CategoryModuleSettings key={category.id} category={category} interactive={true} />
      ))}
    </Fragment>
  );
};

const WorkspaceModuleSettings = ({ categories, interactive }: WorkspaceModuleSettingsProps) => {
  return (
    <Stack spacing={2}>
      {categories.map(category => (
        <CategoryModuleSettings key={category.id} category={category} interactive={interactive} />
      ))}
    </Stack>
  );
};

const CategoryModuleSettings = ({ category, interactive = false }: { category: Category; interactive?: boolean }) => {
  return (
    <Stack key={category.id} spacing={2}>
      <Typography fontSize={14} fontWeight='bold'>
        {category.name}
      </Typography>
      {category.modules.map(module => {
        return <ModuleSettings key={module.id} category={category} module={module} interactive={interactive} />;
      })}
    </Stack>
  );
};

interface ModuleSettingsProps {
  category: Category;
  module: ModuleInterface;
  updateModule?: () => void;
  interactive: boolean;
}

const ModuleSettings = ({ category, module, interactive = false }: ModuleSettingsProps) => {
  const formContext = useFormContext();
  const control = formContext?.control;

  const ModuleDimensionSettings = findModuleScoreDimensionSettings(module.type);
  const categoryComponentSettings = findCategoryScoreDimensionSettings(category.type);

  const icon = ModuleDimensionSettings.icon;

  return (
    <Stack className='module-settings' key={module.id} direction='row' alignItems='center' spacing={2}>
      <Avatar variant='rounded' sx={{ bgcolor: categoryComponentSettings.color }}>
        {React.createElement(icon, { sx: { color: '#000' } })}
      </Avatar>

      <Stack flex='1' fontSize={14} direction='column'>
        <Typography>{module.name}</Typography>
        <Typography variant='body2'>{module.summary}</Typography>
      </Stack>

      {interactive ? (
        <Controller
          name={`activeModules.${module.type}`}
          control={control}
          render={({ field }) => {
            return <Switch {...field} type='checkbox' checked={field.value} />;
          }}
        />
      ) : (
        <Switch disabled checked={module.active} />
      )}
    </Stack>
  );
};

export default WorkspaceModuleSettings;
