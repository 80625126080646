import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import ClientAvatar from '../Client/ClientAvatar';
import SecondaryNavigation, { SecondaryNavigationHeading, SecondaryNavigationActions } from '../Navigation/SecondaryNavigation';
import WorkspaceActions from '../Workspace/WorkspaceActions';
import { useLayout } from '../UI/LayoutContext';
import { NAVIGATION_THEME } from '../UI/NavigationTheme';
import ScopeSelector, { ScopeSelectorOptionInterface } from '../Scope/ScopeSelector';
import { useWorkspace } from './useWorkspace';

interface WorkspaceSecondaryNavigationProps {
  scopeOptions: ScopeSelectorOptionInterface[];
  includeUnscopedForAllMembers?: boolean;
  iconElement?: React.ReactNode;
}

const WorkspaceSecondaryNavigation = ({ scopeOptions = [], includeUnscopedForAllMembers, iconElement }: WorkspaceSecondaryNavigationProps) => {
  const { workspace } = useWorkspace();
  const { company } = workspace;
  const layout = useLayout();

  return (
    <ThemeProvider theme={NAVIGATION_THEME}>
      <SecondaryNavigation>
        <SecondaryNavigationHeading>
          {layout === 'mobile' ? iconElement : iconElement || <ClientAvatar client={company} />}
          <ScopeSelector options={scopeOptions} includeUnscopedForAllMembers={includeUnscopedForAllMembers} />
        </SecondaryNavigationHeading>

        {layout === 'mobile' && (
          <SecondaryNavigationActions>
            <WorkspaceActions />
          </SecondaryNavigationActions>
        )}
      </SecondaryNavigation>
    </ThemeProvider>
  );
};

export const WorkspaceScopeMenu = ({ scopeOptions }: { scopeOptions: ScopeSelectorOptionInterface[] }) => {

  if (scopeOptions.length === 0) {
    return null;
  }

  return;
};

export default WorkspaceSecondaryNavigation;
