import { Link as RouterLink } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button, IconButton } from '@mui/material';
import { SecondaryNavigationActions, SecondaryNavigationHeading } from '../Navigation/SecondaryNavigation';
import TeamIcon from '../Team/TeamIcon';
import { useWorkspace } from '../Workspace/useWorkspace';
import useModules from '../Module/useModules';
import { TeamInterface } from './TeamSchema';
import { useCategory } from '../Category/useCategory';
import { SecondaryNavigationTabs, useSecondaryNavigationTabs } from '../Navigation/SecondaryNavigationTabs';
import SecondaryNavigation from '../Navigation/SecondaryNavigation';
import { useTeamContext } from './TeamContext';
import ChannelConnectionAction from '../Chat/ChannelConnectionAction';
import { generateChannelName } from '../Chat/Channel';

const TeamSecondaryNavigation = ({ team }: { team: TeamInterface }) => {
  const { workspace } = useWorkspace();
  const tabs = useTeamSecondaryNavigationTabs();
  const { teamPath } = useTeamContext();
  const secondaryNavigationTabsProps = useSecondaryNavigationTabs(tabs, teamPath);

  const { id, name, icon } = team;

  return (
    <SecondaryNavigation>
      <SecondaryNavigationHeading>
        <IconButton to={`/workspace/${workspace.id}/teams`} component={RouterLink}>
          <ArrowBackRoundedIcon />
        </IconButton>
        <Button component={RouterLink} to={`/workspace/${workspace.id}/teams/${id}`} startIcon={<TeamIcon icon={icon} />} sx={{ textTransform: 'none' }}>
          {name}
        </Button>
      </SecondaryNavigationHeading>

      <SecondaryNavigationActions>
        <SecondaryNavigationTabs {...secondaryNavigationTabsProps} />
        <ChannelConnectionAction scorable={team} defaultChannelName={generateChannelName(team.name, 'team')} />
      </SecondaryNavigationActions>
    </SecondaryNavigation>
  );
};

export default TeamSecondaryNavigation;

const useTeamSecondaryNavigationTabs = () => {
  const tabs = [];

  const { workspace } = useWorkspace();

  const { findModule } = useModules(workspace);
  const projectsModuleActive = findModule('ClientsProjectsModule') || findModule('CompanyProjectsModule');

  tabs.push({ key: 'dashboard', label: 'Dashboard', path: '' });
  if (useCategory('ClientsCategory').active) {
    tabs.push({ key: 'clients', label: 'Clients', path: 'clients' });
  }
  if (projectsModuleActive) {
    tabs.push({ key: 'projects', label: 'Projects', path: 'projects' });
  }
  if (useCategory('EmployeeExperienceCategory').active) {
    tabs.push({ key: 'employee-experience', label: 'Employee Experience', path: 'employee-experience' });
  }
  if (useCategory('CompanyCategory').active) {
    tabs.push({ key: 'company', label: 'Company', path: 'company' });
  }
  tabs.push({ key: 'members', label: 'Members', path: 'members' });

  return tabs;
};
