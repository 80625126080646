import { gql } from '@apollo/client';

export const SURVEY_RESPONSE_FIELDS_FRAGMENT = gql`
  fragment SurveyResponseFields on Survey {
    responses {
      edges {
        cursor
        node {
          id
          answer {
            id
          }
          scorable {
            id
            ... on Project {
              client {
                id
                name
                imageUrl
              }
            }
            ... on Client {
              id
              name
              imageUrl
            }
          }

          question {
            text
            answers {
              id
              text
            }
          }
          skippedAt
        }
      }
    }
  }
`;

export const ANSWER_RESPONSE_MUTATION = gql`
  mutation AnswerResponse($responseId: ID!, $answerId: ID!) {
    answerResponse(input: { responseId: $responseId, answerId: $answerId }) {
      success
    }
  }
`;

export const SKIP_RESPONSE_MUTATION = gql`
  mutation SkipResponse($id: ID!) {
    skipResponse(id: $id) {
      success
    }
  }
`;

export const SCORABLE_SURVEY_FIELDS_FRAGMENT = gql`
  fragment ScorableSurveyFields on ScorableInterface {
    hasActiveSurveys
    canContributeIntelligence
    canCollectIntelligence
    hasIncompleteIntelligenceRequest
    hasLowIntelligence
  }
`;

export const AGGREGATOR_SURVEY_FIELDS_FRAGMENT = gql`
  fragment AggregatorSurveyFields on AggregatorInterface {
    # hasActiveSurveys
    canCollectIntelligence
    canContributeIntelligence
    hasIncompleteIntelligenceRequest
    hasLowIntelligence
  }
`;
