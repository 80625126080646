import { useQuery } from '@apollo/client';
import { Icon, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { DashboardSurface } from './Dashboard';
import { ModuleInterface } from '../Module/Module';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { aspectIcon } from '../Aspect/AspectIcon';
import Box from '@mui/material/Box';
import { computeScoreLevel, sortByScore } from '../Scores/Score';
import { useScope } from '../Scope/useScope';
import { useTimeframe } from '../User/useTimeframe';
import { WORKSPACE_ASPECT_SCORES_QUERY } from '../Workspace/WorkspaceAspects';
import { Category } from '../Category/Category';
import { PROJECT_ASPECT_SCORES_QUERY } from '../Project/ProjectAspects';
import { CLIENT_ASPECT_SCORES_QUERY } from '../Client/ClientAspects';
import AspectContent, { DARK_THEME_SCORE_OPACITY } from '../Aspect/AspectContent';
import { Scorable } from '../Scores/Scorable';

interface DashboardAspectsProps {
  scorable: Scorable;
  category: Category;
  module: ModuleInterface;
}

const DashboardAspects = ({ scorable, category, module }: DashboardAspectsProps) => {
  const { scope } = useScope();
  const { days } = useTimeframe();

  //  let title;
  let aspectScoresQuery;
  if (scorable.__typename === 'Workspace') {
    //    title = 'Workspace Aspects';
    aspectScoresQuery = WORKSPACE_ASPECT_SCORES_QUERY;
  } else if (scorable.__typename === 'Client') {
    aspectScoresQuery = CLIENT_ASPECT_SCORES_QUERY;
  } else if (scorable.__typename === 'Project') {
    //    title = 'Project Aspects'
    aspectScoresQuery = PROJECT_ASPECT_SCORES_QUERY;
  } else {
    throw new Error('Unexpected scorable type');
  }

  const title = `${module.name} Aspects`;

  const { data } = useQuery(aspectScoresQuery, {
    variables: {
      id: scorable.id,
      ...scope.variables,
      days: days,
      categoryType: category ? category.type : undefined,
      moduleType: module ? module.type : undefined,
    },
    fetchPolicy: 'no-cache', // do not cache because the projects that are returned conflict with the general project cache
  });

  return (
    <DashboardSurface title={title} sx={{ minHeight: '400px' }}>
      <AspectScores scorable={data?.scorable} category={category} module={module} />
    </DashboardSurface>
  );
};

interface AspectScoresProps {
  scorable: Scorable;
  category: Category;
  module: ModuleInterface;
}

const AspectScores = ({ scorable, category, module }: AspectScoresProps) => {
  if (!scorable) {
    return null;
  }

  const categoryData = scorable.categories.find(currentCategory => currentCategory.id === category.id)!;
  const moduleData = categoryData.modules.find(currentModule => currentModule.id === module.id)!;
  if (!moduleData) {
    return null;
  } // The category cache may not have this module loaded yet.

  return (
    <ModuleAspects
      // key={selectedTabIndex}
      scorable={scorable}
      module={moduleData}
    />
  );
};

interface ModuleAspectsProps {
  scorable: Scorable;
  module: ModuleInterface;
}

const ModuleAspects = ({ scorable, module }: ModuleAspectsProps) => {
  const theme = useTheme();
  const { aspects } = module;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sortedAspects = sortByScore(aspects);

  return (
    <Stack spacing={1} sx={{ position: 'relative' }}>
      {/* may want to render all of these. otherwise, the components unmount when switching tabs and lose expansions state */}

      <div>
        {sortedAspects.map((aspect, index) => {
          const { health } = aspect;

          const level = computeScoreLevel(health);

          return (
            <Accordion key={index} expanded={expanded === `panel-${aspect.id}`} onChange={handleChange(`panel-${aspect.id}`)}>
              <AccordionSummary aria-controls='panel1bh-content' id={`aspect-panel-${aspect.id}`} sx={{}}>
                <Stack direction='column' alignItems='stretch' justifyContent='center' width='100%' spacing={1}>
                  <Stack zIndex={10} direction='row' px={0} spacing={1}>
                    <Icon>{aspectIcon(module.type, aspect.name)}</Icon>
                    <Box flex='1' zIndex={10} fontWeight={300}>
                      <Typography variant='body1'>{aspect.name}</Typography>
                    </Box>
                    <Box sx={{ fontWeight: 'bold' }}>{health || '-'}</Box>
                  </Stack>
                  <Box
                    style={{
                      position: 'absolute',
                      left: 0,
                      margin: 0,
                      width: `${health}%`,
                      height: '100%',
                      backgroundColor: level.color,
                      opacity: theme.palette.mode === 'dark' ? DARK_THEME_SCORE_OPACITY : 0.8,
                    }}
                  ></Box>
                  {/*<ScoreBar name={name} health={health} intelligence={intelligence} />*/}
                </Stack>
              </AccordionSummary>

              <AccordionDetails sx={{ padding: 0 }}>
                {/* Defer rendering details until an accordion is expanded. */}
                {expanded && <AspectContent scorable={scorable} module={module} aspect={aspect} />}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Stack>
  );
};

export default DashboardAspects;
