import React from 'react';
import { z } from 'zod';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { INVITE_MUTATION } from '../Invitation/Invite';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { ProjectInterface } from './ProjectInterface';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { Controller } from 'react-hook-form';
import MemberSelect, { useMemberSelect } from '../Membership/MemberSelect';
import { userSelectOrInviteEmailSchema } from '../User/User';
import { useMutation } from '@apollo/client';

interface ProjectInviteDialogProps {
  open: boolean;
  onClose: () => void;
  project: ProjectInterface;
  refetch: () => Promise<any>;
}

const projectInviteSchema = z.object({ users: z.array(userSelectOrInviteEmailSchema) });
type ProjectInviteValues = z.infer<typeof projectInviteSchema>;

const ProjectInviteDialog = ({ project, open, onClose, refetch }: ProjectInviteDialogProps) => {
  const { setSuccess } = useGlobalMessage();
  const { workspaceUsers, loading } = useMemberSelect();

  const formDialogProps = useFormDialog<ProjectInviteValues>({ schema: projectInviteSchema });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const [invite, { error }] = useMutation(INVITE_MUTATION);

  const handleSubmit = async ({ users }: ProjectInviteValues) => {
    const invites = users.map(user => ({
      email: user.email,
      memberships: [
        {
          id: project.id,
          type: 'Project',
          // roles: ['Contributor']
        },
      ],
    }));
    const response = await invite({ variables: { projectIds: [project.id], invites: invites } });
    const success = handleResponse(response, 'invite', 'Uh oh. There was an error inviting project members');
    if (success) {
      setSuccess(`Invited project members`);
      await refetch();
    }
    return success;
  };

  return (
    <FormDialog<ProjectInviteValues>
      {...formDialogProps}
      open={open}
      onClose={onClose}
      icon={<GroupAddIcon />}
      title={`Invite Project Members to ${project.name}`}
      subtitle='Invite people to join the project by name or email.'
      id='invite-to-project'
      submitLabel='Invite'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Controller
        name='users'
        control={control}
        render={({ field, fieldState: { error } }) => {
          return <MemberSelect {...field} loading={loading} error={error} options={workspaceUsers} />;
        }}
      />
    </FormDialog>
  );
};

export default ProjectInviteDialog;
