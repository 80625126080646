import React, { Fragment } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, TextField, Typography } from '@mui/material';

import { SubmitButton } from '../UI/BrandedForm';
import { Controller, useForm } from 'react-hook-form';
import { InviteEmailsValues, useInviteEmails } from '../Invitation/InviteEmails';
import { z } from 'zod';

interface CreateWorkspaceInviteProps {
  onSubmit: (values: InviteEmailsValues) => void;
  onBack: () => void;
}

const inviteEmailsSchema = z.object({
  emails: z.string().optional(),
});

const CreateWorkspaceInvite = ({ onSubmit, onBack }: CreateWorkspaceInviteProps) => {
  const { validateEmails } = useInviteEmails();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InviteEmailsValues>({
    resolver: zodResolver(inviteEmailsSchema),
  });

  return (
    <Fragment>
      <Stack spacing={4}>
        <Typography variant='h2' fontSize={18} textAlign='center' sx={{ textWrap: 'balance' }}>
          Your teammates will receive an email that allows them to sign up and access your workspace.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack alignItems='center' spacing={4}>
            <Controller
              name='emails'
              control={control}
              rules={{ validate: validateEmails }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type='text'
                  variant='outlined'
                  fullWidth
                  multiline
                  rows={8}
                  label='Email Addresses'
                  error={!!error}
                  helperText={error?.message || 'Enter emails comma separated (optional)'}
                />
              )}
            />
            <SubmitButton isSubmitting={isSubmitting}>Create Workspace</SubmitButton>
          </Stack>
        </form>
      </Stack>

      <Stack alignItems='center'>
        <Button onClick={onBack} sx={{ textTransform: 'none' }}>
          Go Back
        </Button>
      </Stack>
    </Fragment>
  );
};

export default CreateWorkspaceInvite;
