import React, { useState } from 'react';
import axios from 'axios';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { Autocomplete, AutocompleteRenderInputParams, Avatar, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';

import { ClearbitCompany } from '../Company/useClearbitCompanies';

export const companySchema = z.object({
  name: z.string().min(1, 'Name is required'),
  imageUrl: z.string().url().optional(),
  domain: z.string().url().optional(),
});

export type CompanyOption = z.infer<typeof companySchema>;

interface CompanyFieldOptions {
  name: string;
  label: string;
  autoFocus?: boolean;
}

const CompanyField = ({ label, autoFocus }: CompanyFieldOptions) => {
  const [options, setOptions] = useState<CompanyOption[]>([]);
  const { control, setValue } = useFormContext();

  const getCompanies = async (name: string) => {
    if (!name) {
      return [];
    }
    const { data } = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest`, { params: { query: name } });
    return data.map((company: ClearbitCompany) => ({
      name: company.name,
      imageUrl: company.logo,
      domain: company.domain,
    }));
  };

  const handleNameChange = async (_: any, value: string) => {
    setValue('name', value);
    const options = await getCompanies(value);
    setOptions(options);
  };

  const handleSelectCompany = async (_: any, value: string | CompanyOption | null) => {
    if (typeof value === 'string') {
      setValue('name', value);
    } else if (value) {
      setValue('name', value.name);
      setValue('imageUrl', value.imageUrl);
      setValue('domain', value.domain);
    } else {
      setValue('name', '');
      setValue('imageUrl', null);
      setValue('domain', null);
    }
  };

  return (
    <Controller
      name='name'
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          id='name'
          fullWidth
          options={options}
          getOptionLabel={option => {
            return typeof option === 'string' ? option : option.name;
          }}
          freeSolo
          autoHighlight
          openOnFocus={false}
          renderOption={(props: object, option: CompanyOption) => {
            return (
              <ListItem {...props} key={option.domain} className={`client-name-option`}>
                <ListItemIcon>
                  <Avatar src={option.imageUrl}>
                    <CorporateFareRoundedIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={option.name} secondary={option.domain} />
              </ListItem>
            );
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              id='name'
              variant='outlined'
              fullWidth
              required
              autoFocus={autoFocus}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
          onInputChange={handleNameChange}
          onChange={handleSelectCompany}
        />
      )}
    />
  );
};

export default CompanyField;
