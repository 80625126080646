export type Intelligence = number | undefined;

export const computeIntelligenceColor = (intelligence: Intelligence = 0) => {
  const value = Math.max((255.0 * intelligence) / 100, 48);
  return `rgb(${value},${value},${value})`;
};

export const computeIntelligenceColorLightTheme = (intelligence: number) => {
  const opacity = Math.max(intelligence / 100, 0.2);
  return `rgba(48, 48, 48, ${opacity})`;
};

export const intelligenceSortComparator = (a: number, b: number) => {
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return a - b;
};
