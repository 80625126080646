import { Drawer, Stack } from '@mui/material';

import { useAI } from './AIContext';
import Toolbar from '@mui/material/Toolbar';
import AIPanel from './AIPanel'; 

const AIDrawer = () => {
  const { open, setOpen } = useAI();
  
  return (
    <Drawer anchor='right' variant='persistent' open={open} onClose={() => setOpen(false)}>
      <Stack width={440} sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar />
        <AIPanel />
      </Stack>
    </Drawer>
  );
};

export default AIDrawer;
