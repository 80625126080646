import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ClientList from '../Client/ClientList';
import useClientList, { CLIENT_COLLECTOR_FRAGMENT } from '../Client/useClientList';

const TeamClientList = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const clientListProps = useClientList(TEAM_CLIENTS_QUERY, { id: teamId }, data => data.team);

  return <ClientList {...clientListProps} />;
};

export default TeamClientList;

export const TEAM_CLIENTS_QUERY = gql`
  query TeamClients($id: ID!, $healthLevel: [HealthLevel!], $statuses: [ClientStatus!], $scope: ScopeInput, $archived: [Boolean!]) {
    team(id: $id, scope: $scope) {
      id
      ...ClientCollector
    }
  }
  ${CLIENT_COLLECTOR_FRAGMENT}
`;
