const MODAL_CONTAINER_ID = 'modal-container';

export const useModalContainer = () => {
  return document.getElementById(MODAL_CONTAINER_ID);
};

const ModalContainer = () => {
  return <div id={MODAL_CONTAINER_ID} />;
};

export default ModalContainer;
