import { gql } from '@apollo/client';

export type ProjectStatus = 'upcoming' | 'active' | 'on_hold' | 'completed' | 'canceled';

export const PROJECT_STATUS_COMPONENT_SETTINGS: {
  [key in ProjectStatus]: {
    name: string;
    color: 'positive' | 'neutral';
  };
} = {
  active: { name: 'Active', color: 'positive' },
  upcoming: { name: 'Upcoming', color: 'neutral' },
  on_hold: { name: 'On Hold', color: 'neutral' },
  completed: { name: 'Completed', color: 'neutral' },
  canceled: { name: 'Canceled', color: 'neutral' },
};

export const statusSortComparator = (a: ProjectStatus, b: ProjectStatus): number => {
  const statusOrder: { [key in ProjectStatus]: number } = {
    active: 1,
    upcoming: 2,
    on_hold: 3,
    completed: 4,
    canceled: 5,
  };

  return statusOrder[a] - statusOrder[b];
};

export const UPDATE_PROJECT_STATUS_MUTATION = gql`
  mutation updateProjectStatus($id: ID!, $status: ProjectStatus!) {
    updateProjectStatus(id: $id, status: $status) {
      project {
        id
        name
        status
      }
    }
  }
`;
