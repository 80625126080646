import React, { useState } from 'react';
import { Stack } from '@mui/material';
import CreateWorkspaceInvite from './CreateWorkspaceInvite';
import CreateWorkspaceSettings, { useCreateWorkspaceSettings } from './CreateWorkspaceSettings';
import CreateWorkspaceSteps from './CreateWorkspaceSteps';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import { CreateWorkspaceSettingsValues } from './CreateWorkspaceSettingsForm';
import { InviteEmailsValues, emailArraySchema, useInviteEmails } from '../Invitation/InviteEmails';

const CreateWorkspace = () => {
  const [step, setStep] = useState(2);

  const { values, setValues, create } = useCreateWorkspaceSettings();
  const { inviteEmails } = useInviteEmails();
  const navigate = useNavigate();
  const { refetch: refetchUser } = useAuthenticatedUser();

  const handleContinue = (values: CreateWorkspaceSettingsValues) => {
    setValues(values);
    setStep(3);
  };

  const handleInviteSubmit = async (inviteValues: InviteEmailsValues) => {
    const { success, workspaceId } = await create(values!);
    await refetchUser();

    if (success) {
      const { emails } = inviteValues;
      const result = emailArraySchema.safeParse(emails);
      if (result.success) {
        await inviteEmails(workspaceId!, result.data);
      }

      navigate(`/workspace/${workspaceId}`);
    }
  };

  return (
    <CreateWorkspaceSteps step={step}>
      <Stack spacing={2}>
        <CreateWorkspaceStep visible={step === 2}>
          <CreateWorkspaceSettings onSubmit={handleContinue} />
        </CreateWorkspaceStep>

        <CreateWorkspaceStep visible={step === 3}>
          <CreateWorkspaceInvite onSubmit={handleInviteSubmit} onBack={() => setStep(2)} />
        </CreateWorkspaceStep>
      </Stack>
    </CreateWorkspaceSteps>
  );
};

export default CreateWorkspace;

interface CreateWorkspaceStepProps {
  visible: boolean;
  children: React.ReactNode;
}

const CreateWorkspaceStep = ({ visible, children }: CreateWorkspaceStepProps) => {
  return <Stack sx={{ display: visible ? 'flex' : 'none' }}>{children}</Stack>;
};
