import { useRouteError } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { Typography } from '@mui/material';
import PageStyle from './PageStyle';
import Illustration from './Illustration';
import useWorkspaceSelection from '../Workspace/useWorkspaceSelection';
import { LinkActionInterface } from './ActionInterface';

export interface ErrorType {
  title: string;
  message: string;
  image: string;
}

const GeneralError: ErrorType = {
  title: 'Oops!',
  message: 'Something went wrong.',
  image: 'uh-oh',
};

const RouteError: ErrorType = {
  title: 'Uh Oh',
  message: `We couldn't find the page you're looking for.`,
  image: 'uh-oh',
};

export const NotAuthorizedError: ErrorType = {
  title: 'Sorry...',
  message: 'Looks like you are not authorized for this.',
  image: 'sorry',
};

export const InviteExpiredError: ErrorType = {
  title: 'Ooops.',
  message: 'Looks like the invite has expired. Please reach out to the inviter!',
  image: 'ooops',
};

interface ErrorPageProps {
  errorType?: ErrorType;
  title?: string;
  message?: string;
  action?: LinkActionInterface; // Custom action for the button
  includeHeader?: boolean;
}

const ErrorPage = ({ errorType = GeneralError, title, message, action, includeHeader }: ErrorPageProps) => {
  const { workspaceId } = useWorkspaceSelection();
  if (!workspaceId) {
    includeHeader = true;
  }
  const theme = useTheme();

  title = title || errorType.title;
  message = message || errorType.message;

  return (
    <ThemeProvider theme={theme}>
      <PageStyle design='illustrated' includeHeader={includeHeader}>
        <Stack alignItems='stretch' justifyContent='stretch' flexGrow={1}>
          <Stack flexGrow={1} justifyContent='center' alignItems='center'>
            <Illustration image={errorType.image} alt='Error' />
            <Stack alignItems='center' px={10} pt={2} pb={3} spacing={2}>
              <Stack alignItems='center'>
                <h1 style={{ fontSize: '60px', lineHeight: 'normal', margin: 0 }}>{title}</h1>
                <Typography variant='body1' fontSize={21} fontWeight={300} sx={{ textAlign: 'center', textWrap: 'balance' }}>
                  {message}
                </Typography>
              </Stack>
              {action ? (
                <Button href={action.path} variant='contained' disabled={action.disabled} onClick={action.onSelect}>
                  {action.label}
                </Button>
              ) : (
                <Button href='/' variant='contained'>
                  Take Me Home
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </PageStyle>
    </ThemeProvider>
  );
};

export default ErrorPage;

export const RouteErrorPage = () => {
  return <ErrorPage errorType={RouteError} includeHeader />;
};

export const AppErrorBoundary = () => {
  const error: any = useRouteError();
  console.error('AppErrorFallback');
  console.error(error);

  return <ErrorPage errorType={GeneralError} includeHeader message={error.message} />;
};

export const WorkspaceErrorBoundary = () => {
  return <ErrorPage errorType={GeneralError} />;
};
