import React, { useState, useCallback } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';

import StyledDialog from '../UI/StyledDialog';

type ActionCallback = (confirmed: boolean) => void;

interface ConfirmationOptions {
  icon?: React.ReactElement;
  title: string;
  dialogContent: React.ReactNode;
  confirmLabel: string;
  actionCallback?: ActionCallback;
}

export const useConfirmation = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmationOptions | null>(null);

  const handleOpen = useCallback((callback: ActionCallback, newOptions: ConfirmationOptions) => {
    setOptions({ ...newOptions, actionCallback: callback });
    setOpen(true);
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    options?.actionCallback?.(true);
  }, [options]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    options?.actionCallback?.(false);
  }, [options]);

  const requestConfirmation = (newOptions: ConfirmationOptions) => {
    return new Promise<boolean>(resolve => {
      handleOpen(resolve, newOptions);
    });
  };

  return {
    requestConfirmation,
    confirmationOptions: {
      open,
      onClose: handleCancel,
      onConfirm: handleConfirm,
      ...options,
    },
  };
};

interface ConfirmationDialogProps extends DialogProps {
  icon?: React.ReactElement;
  title?: string;
  dialogContent?: React.ReactNode;
  confirmLabel?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

const ConfirmationDialog = ({ open, icon, title, dialogContent, confirmLabel, onClose, onConfirm }: ConfirmationDialogProps) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        {icon}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' disableElevation color='subtle' onClick={onClose}>
          Cancel
        </Button>
        <Button id='archive-button' variant='contained' disableElevation color='primary' onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConfirmationDialog;
