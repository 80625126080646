import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

import { useClient } from './useClient';
import ClientInviteDialog from '../Client/ClientInviteDialog';
import MemberList from '../Membership/MemberList';
import { useGlobalMessage } from '../UI/GlobalMessage';
import PageStyle from '../UI/PageStyle';
import { SET_USER_ROLES_MUTATION, SetUserRolesVariables } from '../User/Roles';
import { UserInterface } from '../User/User';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import { useClientContext } from './ClientContext';

const ClientMembers = () => {
  const { client } = useClientContext();
  const { users, owners } = client;
  const { setSuccess, setError } = useGlobalMessage();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const { isOwner } = useClient(client);

  const handleAddMembers = () => {
    setInviteDialogOpen(true);
  };

  const [setUserRoles] = useMutation<any, SetUserRolesVariables>(SET_USER_ROLES_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(client) });
      cache.gc();
    },
  });

  const [removeClientMembers] = useMutation(REMOVE_CLIENT_MEMBERS_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(client) });
      cache.gc();
    },
  });

  const memberActionsResolver = (user: UserInterface) => {
    const setClientOwner = {
      icon: StarsRoundedIcon,
      label: 'Set as the Client Owner',
      onSelect: async () => {
        const {
          data: {
            setUserRoles: { success, message },
          },
        } = await setUserRoles({
          variables: {
            userId: user.id,
            type: 'Client',
            id: client.id,
            roles: ['Owner'],
            operation: 'Add',
          },
        });
        if (success) {
          setSuccess('Set as the client owner');
        } else {
          setError(message);
        }
      },
    };

    const unsetClientOwner = {
      icon: StarsRoundedIcon,
      label: 'Unset as the Client Owner',
      onSelect: async () => {
        const {
          data: {
            setUserRoles: { success, message },
          },
        } = await setUserRoles({
          variables: {
            userId: user.id,
            type: 'Client',
            id: client.id,
            roles: ['Owner'],
            operation: 'Remove',
          },
        });
        if (success) {
          setSuccess('Unset as the client owner');
        } else {
          setError(message);
        }
      },
    };

    const removeFromClient = {
      icon: PersonRemoveRoundedIcon,
      label: 'Remove from Client',
      onSelect: async () => {
        const response = await removeClientMembers({ variables: { id: client.id, userIds: [user.id] } });
        const {
          data: {
            removeClientMembers: { success },
          },
        } = response;
        if (success) {
          setSuccess('Removed from client');
        } else {
          setError('Error removing client member');
        }
      },
    };

    const actions = [];
    if (!isOwner(user)) {
      actions.push(setClientOwner);
    } else {
      actions.push(unsetClientOwner);
    }
    actions.push(removeFromClient);

    return actions;
  };

  return (
    <PageStyle design='simple'>
      <MemberList members={users} owners={owners} memberActionsResolver={memberActionsResolver} onAddMembers={handleAddMembers} />
      <ClientInviteDialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} />
    </PageStyle>
  );
};

export default ClientMembers;

const REMOVE_CLIENT_MEMBERS_MUTATION = gql`
  mutation RemoveClientMembers($id: ID!, $userIds: [ID!]!) {
    removeClientMembers(id: $id, userIds: $userIds) {
      success
    }
  }
`;
