import { useCategory } from '../Category/useCategory';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useModule } from './useModule';

const useActiveFeatures = () => {
  const { workspace } = useWorkspace();

  const aiActive = workspace.aiEnabled;

  const { active: clientProjectsActive } = useModule(workspace, 'ClientsProjectsModule');
  const { active: companyProjectsActive } = useModule(workspace, 'CompanyProjectsModule');
  const { active: clientProductsAndServicesActive } = useModule(workspace, 'ClientsProductsAndServicesModule');
  const { active: clientRelationshipsActive } = useModule(workspace, 'ClientsRelationshipModule');

  const { active: clientsCategoryActive } = useCategory('ClientsCategory');
  const { active: employeeExperienceCategoryActive } = useCategory('EmployeeExperienceCategory');
  const { active: companyCategoryActive } = useCategory('CompanyCategory');

  const clientsActive = clientsCategoryActive;
  const projectsActive = clientProjectsActive || companyProjectsActive;
  const employeeExperienceActive = employeeExperienceCategoryActive;
  const companyActive = companyCategoryActive;
  const teamsActive = workspace.teamStats.allTeamCount > 0;

  return {
    aiActive,
    clientsActive,
    clientProductsAndServicesActive,
    clientRelationshipsActive,
    clientProjectsActive,
    employeeExperienceActive,
    companyActive,
    companyProjectsActive,
    projectsActive,
    teamsActive,
  };
};

export default useActiveFeatures;
