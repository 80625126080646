/**
 * Function to attempt opening a link with a custom protocol (e.g., slack://).
 * If the app is not installed, it will fallback to opening a specified URL in a new window.
 * The new window will automatically close after a specified delay.
 *
 * @param {string} protocolUrl - The URL with the custom protocol to open the app.
 * @param {string} fallbackUrl - The fallback URL to open if the app is not installed.
 * @param {number} delay - The delay in milliseconds to wait before checking if the app opened. Default is 1000 ms.
 * @param {number} closeDelay - The delay in milliseconds to wait before closing the fallback window. Default is 5000 ms.
 */
const openAppLink = (protocolUrl: string, fallbackUrl: string, delay: number = 1000, closeDelay: number = 5000) => {
  let appOpened = false;
  const startTime = Date.now();

  // Create an iframe to try and open the app using the protocol URL
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = protocolUrl;
  document.body.appendChild(iframe);

  // Event listener for the blur event to detect if the app was opened
  const onBlur = () => {
    appOpened = true;
    window.removeEventListener('blur', onBlur);
  };

  window.addEventListener('blur', onBlur);

  // Set a timeout to check if the app was opened and handle the fallback if not
  const timeout = setTimeout(() => {
    const elapsedTime = Date.now() - startTime; // Calculate the elapsed time
    if (!appOpened && elapsedTime < delay + 500) {
      // If the app was not opened, open the fallback URL in a new window
      const fallbackWindow = window.open(fallbackUrl, '_blank');

      // Close the fallback window after a specified delay
      setTimeout(() => {
        if (fallbackWindow) {
          fallbackWindow.close();
        }
      }, closeDelay);
    }

    // Clean up by removing the iframe
    document.body.removeChild(iframe);
  }, delay);

  return () => {
    clearTimeout(timeout);
    window.removeEventListener('blur', onBlur);
    if (iframe) {
      document.body.removeChild(iframe);
    }
  };
};

export default openAppLink;
