import Surveys from '../Survey/Surveys';
import { SurveysProvider } from '../Survey/SurveysContext';

const Intelligence = () => {
  return (
    <SurveysProvider>
      <Surveys />
    </SurveysProvider>
  );
};

export default Intelligence;
