import React from 'react';
import { useMutation } from '@apollo/client';
import { z } from 'zod';

import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { INVITE_MUTATION, inviteMembersSchema } from '../Invitation/Invite';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { useClientContext } from './ClientContext';
import MemberSelect from '../Membership/MemberSelect';
import { Controller } from 'react-hook-form';
import { useMemberSelect } from '../Membership/MemberSelect';

const ClientInviteSchema = inviteMembersSchema;
type ClientInviteValues = z.infer<typeof ClientInviteSchema>;

interface ClientInviteDialogProps {
  open: boolean;
  onClose: () => void;
}

const ClientInviteDialog = ({ open, onClose }: ClientInviteDialogProps) => {
  const { client, refetch } = useClientContext();
  const defaultValues = { members: [] };
  const formDialogProps = useFormDialog<ClientInviteValues>({ schema: ClientInviteSchema, defaultValues });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;
  const { setSuccess } = useGlobalMessage();
  const { workspaceUsers, loading } = useMemberSelect();

  const [invite, { error }] = useMutation(INVITE_MUTATION);

  const handleInvite = async (values: ClientInviteValues) => {
    const { members } = values;
    const invites = members.map(user => ({
      email: user.email,
      memberships: [{ id: client.id, type: 'Client' }],
    }));

    const response = await invite({ variables: { clientIds: [client.id], invites: invites } });
    const success = handleResponse(response, 'invite', `Uh oh. There was an error inviting members.`);
    if (success) {
      setSuccess(`Invited client members`);
      await refetch();
    }
    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='invite-to-client'
      icon={<GroupAddIcon />}
      title={`Invite Client Members to ${client.name}`}
      subtitle='Invite people to join the client by name or email.'
      submitLabel='Invite'
      open={open}
      onClose={onClose}
      onSubmit={handleInvite}
      mutationError={error}
    >
      <Controller
        name='members'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <MemberSelect {...field} loading={loading} error={error} options={workspaceUsers} disabledOptions={client.users} autoFocus />
        )}
      />
    </FormDialog>
  );
};

export default ClientInviteDialog;
