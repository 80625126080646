import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { z } from 'zod';
import { Icon, Stack, TextField } from '@mui/material';

import { useGlobalMessage } from '../UI/GlobalMessage';
import { MINIMUM_PASSWORD_LENGTH, UserInterface } from '../User/User';
import FormDialog, { useFormDialog } from '../UI/FormDialog';

const editPasswordSchema = z.object({
  currentPassword: z.string(),
  password: z.string().min(MINIMUM_PASSWORD_LENGTH, `New password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`),
});

type EditPasswordValues = z.infer<typeof editPasswordSchema>;

interface EditPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  user: UserInterface;
}

const EditPasswordDialog = ({ open, onClose, user }: EditPasswordDialogProps) => {
  const [updatePassword, { error }] = useMutation(UPDATE_PASSWORD_MUTATION);
  const { setSuccess } = useGlobalMessage();

  const formDialogProps = useFormDialog<EditPasswordValues>({ schema: editPasswordSchema });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const handleSubmit = async (values: EditPasswordValues) => {
    const response = await updatePassword({
      variables: {
        userId: user.id,
        currentPassword: values.currentPassword,
        password: values.password,
        passwordConfirmation: values.password,
      },
    });

    const success = handleResponse(response, 'updatePassword', 'Uh oh. There was a problem updating your password');
    if (success) {
      setSuccess('Changed password');
    }
    return success;
  };

  return (
    <FormDialog<EditPasswordValues>
      {...formDialogProps}
      id='edit-password'
      open={open}
      onClose={onClose}
      icon={<Icon>lock</Icon>}
      title='Change Password'
      subtitle='Enter your current password and new password to change your sign in credentials. Keep it secure!'
      submitLabel='Save'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Stack spacing={2}>
        <Controller
          name='currentPassword'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              type='password'
              variant='outlined'
              fullWidth
              label='Current Password'
              autoComplete='current-password'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              type='password'
              variant='outlined'
              fullWidth
              label='New Password'
              autoComplete='new-password'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
};

export default EditPasswordDialog;

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
      success
      errors {
        type
        fullMessage
      }
    }
  }
`;
