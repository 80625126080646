import { createMachine, assign } from 'xstate';
import { SurveyInterface } from './SurveyInterface';

export type SurveysMachineTypeState =
  | { value: 'initializing'; context: SurveysMachineContext }
  | { value: 'waiting'; context: SurveysMachineContext }
  | { value: 'evaluating'; context: SurveysMachineContext }
  | { value: 'selecting'; context: SurveysMachineContext }
  | { value: 'selectingAfterCompletion'; context: SurveysMachineContext }
  | { value: 'contributing'; context: SurveysMachineContext }
  | { value: 'collecting'; context: SurveysMachineContext }
  | { value: 'answering'; context: SurveysMachineContext }
  | { value: 'completing'; context: SurveysMachineContext }
  | { value: 'thanking'; context: SurveysMachineContext };

export interface SurveysMachineContext {
  surveys: SurveyInterface[];
  queue: SurveyInterface[];
}

type SetSurveysMachineEvent = { type: 'SET_SURVEYS'; surveys: SurveyInterface[] };
type StartFirstSurveyMachineEvent = { type: 'START_FIRST_SURVEY' };
type ContributeMachineEvent = { type: 'CONTRIBUTE' };
type CollectMachineEvent = { type: 'COLLECT' };
type SelectMachineEvent = { type: 'SELECT'; survey: SurveyInterface };
type SelectAllMachineEvent = { type: 'SELECT_ALL' };
type CompleteMachineEvent = { type: 'COMPLETE'; survey: SurveyInterface };
type StopMachineEvent = { type: 'STOP' };

export type SurveysMachineEvent =
  | SetSurveysMachineEvent
  | StartFirstSurveyMachineEvent
  | ContributeMachineEvent
  | CollectMachineEvent
  | SelectMachineEvent
  | SelectAllMachineEvent
  | CompleteMachineEvent
  | StopMachineEvent;

export const surveysMachine = createMachine<SurveysMachineContext, SurveysMachineEvent, SurveysMachineTypeState>(
  {
    predictableActionArguments: true,
    context: { surveys: [], queue: [] },
    initial: 'initializing',

    on: {
      CONTRIBUTE: { target: 'contributing', actions: 'stopSurveys' },
      COLLECT: { target: 'collecting' },
    },

    states: {
      initializing: {
        on: { SET_SURVEYS: { target: 'evaluating' } },
      },
      evaluating: {
        entry: 'storeSurveys',
        always: [
          { target: 'waiting', cond: 'noSurveys' },
          // { target: ANSWERING, cond: 'oneSurvey', actions: 'startFirstSurvey' },
          { target: 'selecting' },
        ],
      },
      waiting: {
        on: { SET_SURVEYS: { target: 'evaluating' } },
      },
      selecting: {
        on: {
          SET_SURVEYS: { actions: 'storeSurveys' },
          SELECT: { target: 'answering', actions: 'startSelectedSurvey' },
          SELECT_ALL: { target: 'answering', actions: 'startAllSurveys' },
        },
      },
      selectingAfterCompletion: {
        on: {
          SET_SURVEYS: { actions: 'storeSurveys' },
          SELECT: { target: 'answering', actions: 'startSelectedSurvey' },
          SELECT_ALL: { target: 'answering', actions: 'startAllSurveys' },
        },
      },
      contributing: {
        on: {
          SET_SURVEYS: { target: 'answering', actions: ['storeSurveys', 'startFirstSurvey'] },
        },
      },
      collecting: {
        on: {
          SET_SURVEYS: { target: 'evaluating' },
        },
      },
      answering: {
        on: {
          STOP: { target: 'selecting', actions: 'stopSurveys' },
          COMPLETE: { target: 'completing', actions: 'removeCompletedSurvey' },
        },
      },
      completing: {
        always: [
          { target: 'answering', cond: 'additionalSurveysInQueue' },
          { target: 'selectingAfterCompletion', cond: 'queueEmptyAndAdditionalSurveys' },
          { target: 'thanking' },
        ],
      },
      thanking: {
        entry: 'refetchSurveys',
        on: { SET_SURVEYS: { target: 'evaluating', cond: 'settingAtLeastOneSurvey' } },
      },
    },
  },
  {
    actions: {
      refetchSurveys: () => console.log('implemented externally'),
      storeSurveys: assign({ surveys: (_, event: SetSurveysMachineEvent) => event.surveys }),
      startFirstSurvey: assign({ queue: context => [context.surveys[0]] }),
      startSelectedSurvey: assign({ queue: (_, event: SelectMachineEvent) => [event.survey] }),
      startAllSurveys: assign({ queue: context => [...context.surveys] }),
      removeCompletedSurvey: assign({
        queue: (context, event: CompleteMachineEvent) => context.queue.filter(survey => survey !== event.survey),
        surveys: (context, event: CompleteMachineEvent) => context.surveys.filter(survey => survey !== event.survey),
      }),
      stopSurveys: assign({ queue: [] }),
    },
    guards: {
      noSurveys: context => context.surveys.length === 0,
      oneSurvey: context => context.surveys.length === 1,
      settingAtLeastOneSurvey: (_, event) => (event as SetSurveysMachineEvent).surveys.length > 0,
      additionalSurveysInQueue: context => context.queue.length > 0,
      queueEmptyAndAdditionalSurveys: context => context.queue.length === 0 && context.surveys.length > 0,
    },
  }
);
