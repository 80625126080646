import { gql, useMutation } from '@apollo/client';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { LinkOffRounded } from '@mui/icons-material';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { handleMutationResponse } from '../util/handleMutationResponse';
import { ChannelConnection } from './ChannelConnectable';

type DisconnectChannelDialogProps = {
  channelConnection: ChannelConnection;
  open: boolean;
  onClose: () => void;
};

const DisconnectChannelDialog = ({ channelConnection, open, onClose }: DisconnectChannelDialogProps) => {
  const { setSuccess, setError } = useGlobalMessage();

  const [removeChannelConnectionMutation] = useMutation(REMOVE_CHANNEL_CONNECTION_MUTATION, {
    onCompleted: response => {
      // console.log(response);
      const { success, errorMessage } = handleMutationResponse(
        { data: response },
        'removeChannelConnection',
        'Uh oh. There was a problem disconnecting from the channel.'
      );
      if (success) {
        setSuccess('Disconnected from the channel');
        onClose();
      } else {
        setError(errorMessage);
      }
    },
    onError: error => {
      setError(error.message);
    },
  });

  const handleConfirm = async () => {
    await removeChannelConnectionMutation({
      variables: { id: channelConnection.id },
    });
  };

  return (
    <ConfirmationDialog
      onConfirm={handleConfirm}
      onClose={onClose}
      open={open}
      icon={<LinkOffRounded />}
      title='Disconnect from Channel'
      dialogContent={
        <span>
          Are you sure you want to disconnect from the channel?
          <br />
        </span>
      }
      confirmLabel='Disconnect'
    />
  );
};

export default DisconnectChannelDialog;

const REMOVE_CHANNEL_CONNECTION_MUTATION = gql`
  mutation RemoveChannelConnection($id: ID!) {
    removeChannelConnection(id: $id) {
      channelConnectable {
        id
        name
        channelConnections {
          id
        }
      }

      success
      errors {
        type
        fullMessage
      }
    }
  }
`;
