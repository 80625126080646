import { gql, useQuery } from '@apollo/client';
import { ModuleInterface } from '../Module/Module';
import { useScope } from '../Scope/useScope';
import { useTimeframe } from '../User/useTimeframe';
import { ScoreDimensionBase } from './ScoreDimensionBase';
import { Scorable } from './Scorable';

export type HistoricalScoreDataPoint = {
  day: string;
  health: number;
};

export const useHistoricalScores = (
  scorable: Scorable,
  module: ModuleInterface | undefined,
  scoreDimension: ScoreDimensionBase
): HistoricalScoreDataPoint[] => {
  const { scope } = useScope();
  const { days } = useTimeframe();

  const variables: any = {
    scorable: {
      id: scorable.id,
      type: scorable.__typename,
    },
    scope: { type: scope.type, id: scope.id },
    days: days,
  };

  if (module) {
    variables.scorableContainerId = module.id;
  }

  if (scoreDimension.__typename === 'Aspect') {
    variables.aspectIds = [scoreDimension.id];
  }

  const { data, error } = useQuery(HISTORICAL_SCORES_QUERY, { variables });
  if (error) {
    console.error(error);
  }

  if (!data) {
    return [];
  }

  //  console.log(data);

  return data.historicalScores;
};

const HISTORICAL_SCORES_QUERY = gql`
  query HistoricalScores($scorable: ScorableInput!, $scope: ScopeInput, $scorableContainerId: ID, $aspectIds: [ID!], $days: Int!) {
    historicalScores(scorable: $scorable, scope: $scope, scorableContainerId: $scorableContainerId, aspectIds: $aspectIds, days: $days, deltaReference: $days) {
      day
      health
      healthDelta
    }
  }
`;
