import { useEffect } from 'react';
import { withErrorHandling } from './error';
import useUser from '../User/useUser';

export const useFullstory = withErrorHandling(() => {
  const { user } = useUser();

  const includeFullstory = process.env.REACT_APP_INCLUDE_FULLSTORY === 'true';

  useEffect(() => {
    if (includeFullstory && window.FS) {
      if (user) {
        // Identify the user in FullStory when they sign in or their info changes
        window.FS('setIdentity', {
          uid: user.id,
          properties: {
            displayName: `${user.firstName} ${user.lastName}`,
            email: user.email,
            environment: process.env.NODE_ENV,
          },
        });
      } else {
        window.FS('setIdentity', { anonymous: true });
      }
    }
  }, [user, includeFullstory]); // Re-run when `user` or `includeFullstory` changes
});
