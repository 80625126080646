import { Autocomplete, TextField } from '@mui/material';

import { UserInterface, UserInviteEmail } from '../User/User';
import React from 'react';

interface ManagerSelectorFieldProps {
  value: UserInterface | null;
  onChange: (manager: UserInterface | UserInviteEmail | null) => void;
  error?: boolean;
  helperText?: string;
  users: UserInterface[];
  members: UserInterface[];
  allowInvites: boolean;
}

const ManagerSelectorField = React.forwardRef(({ users, members, value, onChange, error, helperText, allowInvites = true }: ManagerSelectorFieldProps, ref) => {
  const handleChange = async (_: any, value: UserInterface | string | null) => {
    const manager = typeof value === 'string' ? { email: value } : value;
    onChange(manager);
  };

  // Managers cannot already belong to another team or be a standard member of this team
  const availableUsers = users.filter(user => user.team === null && !members.map(member => member.id).includes(user.id));

  const label = allowInvites ? 'Add a manager by name or e⁣mail' : 'Select a manager'; // using an invisible comma between 'e' and 'mail' to prevent password managers from trying to auto-complete

  return (
    <Autocomplete
      data-cy='manager'
      options={users}
      value={value}
      getOptionDisabled={(option: UserInterface) => !availableUsers.includes(option)}
      getOptionLabel={(option: UserInterface | string) => (typeof option === 'string' ? option : `${option.firstName} ${option.lastName} - ${option.email}`)}
      freeSolo={allowInvites}
      autoHighlight
      // autoSelect
      filterSelectedOptions={true}
      openOnFocus={false}
      onChange={handleChange}
      renderInput={params => <TextField {...params} inputRef={ref} variant='outlined' fullWidth label={label} error={!!error} helperText={helperText} />}
    />
  );
});

export default ManagerSelectorField;
