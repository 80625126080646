import React from 'react';
import { useResolvedPath } from 'react-router-dom';
import { Stack } from '@mui/material';
import { DashboardCard } from '../Dashboard/DashboardCard';
import { SCORE_LEVELS } from '../Scores/Score';
import { ScoreBreakdown, ScoreBreakdownLevel } from '../Scores/ScoreBreakdown';
import { ProjectCollector } from './ProjectCollector';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import ModuleDashboard, { ModuleStats } from '../Module/ModuleDashboard';
import { Scorable } from '../Scores/Scorable';
import { ModuleInterface } from '../Module/Module';
import { Category } from '../Category/Category';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { ProjectStats } from './ProjectCollector';
import { MembershipInterface } from '../Membership/Membership';

interface ProjectsModuleDashboardProps {
  scorable: Scorable & ProjectCollector & MembershipInterface;
  category: Category;
  module: ModuleInterface;
  scopePrefix: string;
}

const ProjectsModuleDashboard = ({ scorable, category, module, scopePrefix }: ProjectsModuleDashboardProps) => {
  const { icon } = findModuleScoreDimensionSettings(module.type);
  const projectsPath = useResolvedPath('../projects').pathname;
  const title = `${module.name} - ${scopePrefix}Projects`;

  return (
    <ModuleDashboard scorable={scorable} category={category} module={module} icon={icon} title={title} direction='column'>
      <ModuleStats scorable={scorable} module={module} direction='row' />
      <ProjectsModuleDashboardDetails projectCollector={module} projectsPath={projectsPath} />
    </ModuleDashboard>
  );
};

export default ProjectsModuleDashboard;

interface ProjectsModuleDashboardDetailsProps {
  projectCollector: ProjectCollector;
  projectsPath: string;
}

export const ProjectsModuleDashboardDetails = ({ projectCollector, projectsPath }: ProjectsModuleDashboardDetailsProps) => {
  if (!projectCollector) {
    return null;
  }

  const { projectStats } = projectCollector;

  // TODO: remove the client-side project sort

  return (
    <Stack>
      <ProjectScoreBreakdown projectStats={projectStats} projectsPath={projectsPath} />
    </Stack>
  );
};

const ProjectScoreBreakdown = ({ projectStats, projectsPath }: { projectStats: ProjectStats; projectsPath: string }) => {
  const { count, countByScore } = projectStats;
  return (
    <DashboardCard title={`Projects (${count})`} height='100%'>
      <ScoreBreakdown>
        {SCORE_LEVELS.map(level => (
          <ScoreBreakdownLevel
            key={level.name}
            name={level.name}
            count={countByScore[level.key]}
            icon={HiveRoundedIcon}
            color={level.color}
            path={`${projectsPath}?scoreLevel=${level.key}`}
          />
        ))}
      </ScoreBreakdown>
    </DashboardCard>
  );
};

//const ProjectsNeedingAttention = ({ projects }: { projects: ProjectInterface[] }) => {
//  const { workspace } = useWorkspace();
//  const sortedProjects = sortByScore(projects);
//
//  return (
//    <DashboardCard title='Projects Needing Attention' px={0} flex={1}>
//      {projects.length > 0 ? (
//        <List>
//          {sortedProjects.slice(0, 5).map(project => {
//            return (
//              <React.Fragment key={project.id}>
//                <Divider />
//                <ListItem sx={{ padding: 0 }} key={project.id} secondaryAction={<EntityScoreIcon score={project.health} filled={project.member} />}>
//                  <ListItemButton component={RouterLink} to={`/workspace/${workspace.id}/projects/${project.id}`} sx={{ paddingRight: 2 }}>
//                    <ListItemIcon>
//                      <ClientAvatar client={project.client!} />
//                    </ListItemIcon>
//                    <ListItemText primary={project.name} secondary={project.client!.name} sx={{ fontSize: 14 }} />
//                  </ListItemButton>
//                </ListItem>
//              </React.Fragment>
//            );
//          })}
//        </List>
//      ) : (
//        <Typography align='center'>No projects need attention at this time.</Typography>
//      )}
//    </DashboardCard>
//  );
//};
