import React, { Fragment } from 'react';
import { useQuery, gql } from '@apollo/client';

import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import { SCORABLE_SCORE_FIELDS_FRAGMENT } from '../Scores/ScorableBase';
import { TEAM_FIELDS_FRAGMENT } from './TeamType';
import PageStyle from '../UI/PageStyle';
import { useTimeframe } from '../User/useTimeframe';
import { CATEGORY_SCORE_FIELDS_FRAGMENT } from '../Category/Category';
import DashboardSummary from '../Dashboard/DashboardSummary';
import { DashboardSurface } from '../Dashboard/Dashboard';
import DashboardCategories from '../Dashboard/DashboardCategories';
import { MembershipDashboardStats } from '../Dashboard/DashboardStats';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import { MEMBER_SCORES_FRAGMENT } from '../Membership/MemberScores';
import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import { useTeamContext } from './TeamContext';
import { CLIENT_BREAKDOWN_FRAGMENT } from '../Client/ClientCollector';

interface TeamDashboardProps {
  notice?: string;
}

const TeamDashboard = ({ notice }: TeamDashboardProps) => {
  const { team } = useTeamContext();
  const { id } = team;
  const { days } = useTimeframe();
  const { data } = useQuery(TEAM_SCORES_QUERY, { variables: { id: id, days: days } });

  const teamScores = data?.team;

  return (
    <Fragment>
      {teamScores && <IntelligenceNotice scorable={teamScores} />}
      <PageStyle theme='dark' notice={notice} fullWidth>
        <DashboardSurface variant='primary' title={team.name}>
          <DashboardSummary scorable={teamScores} scorableLabel={'Hyve'} scopePrefix={team.name} icon={SupervisedUserCircleRoundedIcon} />
          <MembershipDashboardStats scorable={teamScores} intelligenceLabel={'Team Hyve Intelligence'} />
        </DashboardSurface>

        <DashboardCategories scorable={teamScores} />
      </PageStyle>
    </Fragment>
  );
};

// TODO: review overlap between this and the corresponding workspace scores query
const TEAM_SCORES_QUERY = gql`
  query TeamScores($id: ID!, $scope: ScopeInput, $days: Int) {
    team(id: $id, scope: $scope) {
      ...TeamFields

      surveyStats {
        count
        recentlyCompletedCount: count(completedWithin: $days)
      }
      memberStats {
        count
      }
      clientStats {
        count
      }
      intelligenceStats(lookback: $days) {
        relativeContribution
        contributorPercentage
      }

      member

      confidentialityThresholdMet
      ...MemberScores

      ...ScoreFields
      ...ClientBreakdown

      categories {
        id
        name
        type
        ...CategoryScoreFields
        ...ClientBreakdown
      }

      ...ScorableSurveyFields
    }
  }

  ${MEMBER_SCORES_FRAGMENT}
  ${TEAM_FIELDS_FRAGMENT}
  ${CLIENT_BREAKDOWN_FRAGMENT}
  ${SCORABLE_SCORE_FIELDS_FRAGMENT}
  ${CATEGORY_SCORE_FIELDS_FRAGMENT}
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;

export default TeamDashboard;
