import React, { Fragment } from 'react';

import { useContributeIntelligence, useCollectIntelligence } from './ContributeAndCollect';
import PageNotice from '../UI/PageNotice';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { ActionInterface } from '../UI/ActionInterface';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import { ScoreDimension } from '../Scores/ScoreDimension';
import { Scorable } from '../Scores/Scorable';

interface IntelligenceNoticeProps {
  scorable: Scorable;
  scoreDimension?: ScoreDimension;
  rounded?: boolean;
}

const IntelligenceNotice = ({ scorable, scoreDimension, rounded }: IntelligenceNoticeProps) => {
  const scoreSubject = scoreDimension || scorable;
  const { contributeIntelligence, contributeIntelligenceDisabled } = useContributeIntelligence(scorable, scoreDimension);
  const { collectIntelligence, collectIntelligenceDisabled, collectIntelligenceDialog } = useCollectIntelligence(scorable, scoreDimension);
  const { canCollectIntelligence, hasIncompleteIntelligenceRequest } = scoreSubject;

  const applicable: boolean = (scoreSubject.canCollectIntelligence || scoreSubject.canContributeIntelligence) && scoreSubject.hasLowIntelligence;

  if (!applicable) {
    return null;
  }

  const contributeAction: ActionInterface = {
    id: 'contribute',
    onSelect: async () => {
      await contributeIntelligence();
    },
    icon: WaterDropRoundedIcon,
    label: 'Contribute Intelligence',
    disabled: contributeIntelligenceDisabled,
    loading: contributeIntelligenceDisabled,
  };

  const collectAction: ActionInterface = {
    id: 'collect',
    onSelect: () => {
      collectIntelligence();
    },
    icon: GroupRoundedIcon,
    label: 'Collect Intelligence',
    disabled: collectIntelligenceDisabled,
    loading: collectIntelligenceDisabled,
  };

  const actions = [contributeAction];

  const showCollectIntelligenceNotice = canCollectIntelligence && !hasIncompleteIntelligenceRequest;
  if (showCollectIntelligenceNotice) {
    actions.push(collectAction);
  }

  const callToAction = showCollectIntelligenceNotice ? 'Give it a boost or collect it from the team!' : 'Give it a boost by contributing now!';
  const message = `${scoreSubject.name} intelligence is a bit low. ${callToAction}`;

  return (
    <Fragment>
      <PageNotice
        noticeKey={`low-intelligence-${scorable.__typename}:${scorable.id}`}
        applicable={applicable}
        level='high'
        message={message}
        actions={actions}
        rounded={rounded}
      />
      {collectIntelligenceDialog}
    </Fragment>
  );
};

export default IntelligenceNotice;
