import React from 'react';
import Stack from '@mui/material/Stack';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DashboardCategory, { DashboardCategoryProps } from '../Dashboard/DashboardCategory';
import PrivacyTipRoundedIcon from '@mui/icons-material/PrivacyTipRounded';
import Typography from '@mui/material/Typography';
import { useScope } from '../Scope/useScope';
import MemberScoreBreakdown from '../Membership/MemberScoreBreakdown';
import { MembershipInterface } from '../Membership/Membership';
import { Category } from '../Category/Category';
import { PersonalCategoryDetails } from '../Category/CategoryDashboardDetails';
import { ActionInterface } from '../UI/ActionInterface';
import { useTheme } from '@mui/material/styles';
import { Scorable } from '../Scores/Scorable';

interface EmployeeExperienceDashboardSummaryProps extends DashboardCategoryProps {}

const EmployeeExperienceDashboardCategory = ({ scorable, category, includeScoreCircle }: EmployeeExperienceDashboardSummaryProps) => {
  const { scope } = useScope();

  const actions: ActionInterface[] = [{ label: 'View Dashboard', path: `employee-experience` }];

  if (scope.type === 'Personal') {
    const navigationActions = [
      {
        icon: DashboardRoundedIcon,
        label: 'View Employee Experience Dashboard',
        path: `employee-experience`,
      },
    ];
    const notice = <PrivacyNotice>Only you can see your personal employee experience scores.</PrivacyNotice>;

    return (
      <DashboardCategory
        scorable={scorable}
        category={category}
        includeScoreCircle={includeScoreCircle}
        navigationActions={navigationActions}
        actions={actions}
        notice={notice}
      >
        <PersonalCategoryDetails category={category} />
      </DashboardCategory>
    );
  } else {
    const navigationActions = [
      {
        icon: DashboardRoundedIcon,
        label: 'View Employee Experience Dashboard',
        path: `employee-experience`,
      },
    ];

    let notice;
    if (scope.type === 'Unscoped') {
      notice = <PrivacyNotice>Only senior leaders and admins can see workspace employee experience scores.</PrivacyNotice>;
    } else if (scope.type === 'Team') {
      notice = <PrivacyNotice>Only team leaders, senior leaders and admins can see team employee experience scores.</PrivacyNotice>;
    }

    return (
      <DashboardCategory
        scorable={scorable}
        category={category}
        includeScoreCircle={includeScoreCircle}
        navigationActions={navigationActions}
        actions={actions}
        notice={notice}
      >
        <GroupEmployeeExperienceDetails scorable={scorable as Scorable & MembershipInterface} category={category} />
      </DashboardCategory>
    );
  }
};

interface GroupEmployeeExperienceDetailsProps {
  scorable: Scorable & MembershipInterface;
  category: Category;
}

export const GroupEmployeeExperienceDetails = ({ scorable, category }: GroupEmployeeExperienceDetailsProps) => {
  return <MemberScoreBreakdown scorable={scorable} scoreDimension={category} />;
};

const PrivacyNotice = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Stack alignItems='center' direction='row' justifyContent='center' spacing={0.5}>
      <PrivacyTipRoundedIcon color='disabled' fontSize='small' />
      <Typography variant='body2' sx={{ color: theme.palette.neutral.main }}>
        Privacy Note - {children}
      </Typography>
    </Stack>
  );
};

export default EmployeeExperienceDashboardCategory;
