import { useQuery, gql } from '@apollo/client';

import Aspects from '../Aspect/Aspects';
import { MODULE_ASPECT_SCORES_FRAGMENT } from '../Aspect/AspectList';
import PageStyle from '../UI/PageStyle';
import { useScope } from '../Scope/useScope';
import { useTimeframe } from '../User/useTimeframe';
import { useClientContext } from './ClientContext';
import { LOWEST_SCORE_PROJECTS_FRAGMENT } from '../Projects/ProjectCollector';

const ClientAspects = () => {
  const { client } = useClientContext();
  const { id } = client;
  const { scope } = useScope();
  const { days } = useTimeframe();
  const { data, loading } = useQuery(CLIENT_ASPECT_SCORES_QUERY, { variables: { id: id, ...scope.variables, days: days } });
  if (!data && loading) {
    return <PageStyle design='standard' loading={true} />;
  }

  const { scorable } = data;

  return (
    <PageStyle design='standard' backgroundProps={{ pt: 3 }}>
      <Aspects scorable={scorable} />
    </PageStyle>
  );
};

export const CLIENT_ASPECT_SCORES_QUERY = gql`
  query ClientAspects($id: ID!, $scope: ScopeInput, $days: Int) {
    scorable: client(id: $id, scope: $scope) {
      id
      projects {
        id
      }

      categories {
        id
        name
        type
        modules {
          ...ModuleAspectScores
          aspects {
            ...LowestScoreProjects
          }
        }
      }
    }
  }
  ${MODULE_ASPECT_SCORES_FRAGMENT}
  ${LOWEST_SCORE_PROJECTS_FRAGMENT}
`;

export default ClientAspects;
