import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';

import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderActions, SectionHeaderIcon, SectionHeaderTitle } from '../UI/Section';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import WorkspaceModuleSettings from './WorkspaceModuleSettings';
import ModuleSettingsDialog from './ModuleSettingsDialog';

type AssessmentSettingsProps = {
  workspaceSettings: WorkspaceInterface;
  refetch: () => Promise<any>;
};

const AssessmentSettings = ({ workspaceSettings, refetch }: AssessmentSettingsProps) => {
  const [open, setOpen] = useState(false);

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <AnalyticsRoundedIcon />
        </SectionHeaderIcon>
        <SectionHeaderTitle>Assessments</SectionHeaderTitle>
        <SectionHeaderActions>
          <Button onClick={() => setOpen(true)}>Manage Assessments</Button>
          <ModuleSettingsDialog workspaceSettings={workspaceSettings} open={open} onClose={() => setOpen(false)} refetch={refetch} />
        </SectionHeaderActions>
      </SectionHeader>

      <Stack p={2}>
        <WorkspaceModuleSettings categories={workspaceSettings.categories} />
      </Stack>
    </AppSurface>
  );
};

export default AssessmentSettings;
