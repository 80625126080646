import { gql, useQuery } from '@apollo/client';

import { EMPLOYEE_EXPERIENCE_MODULE_FIELDS } from '../EmployeeExperience/EngagementModule';
import { PROJECTS_MODULE_FRAGMENT } from './ProjectsModule';
import { MEMBER_SCORES_FRAGMENT } from '../Membership/MemberScores';
import { AGGREGATOR_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import useWorkspace from '../Workspace/useWorkspace';
import { useScope } from '../Scope/useScope';
import { CategoryType } from '../Category/Category';
import { ModuleType } from './Module';
import { useClientContext } from '../Client/ClientContext';
import { Scorable, ScorableType } from '../Scores/Scorable';
import { CLIENT_BREAKDOWN_FRAGMENT } from '../Client/ClientCollector';

export const useWorkspaceModuleScores = (categoryType: CategoryType, moduleType: ModuleType) => {
  const { workspace } = useWorkspace();
  return useModuleScores(workspace, 'Workspace', categoryType, moduleType);
};

export const useClientModuleScores = (categoryType: CategoryType, moduleType: ModuleType) => {
  const { client } = useClientContext();
  return useModuleScores(client, 'Client', categoryType, moduleType);
};

const useModuleScores = (scorable: Scorable, type: ScorableType, categoryType: CategoryType, moduleType: ModuleType) => {
  let query;
  if (type === 'Workspace') {
    query = WORKSPACE_MODULE_SCORES_QUERY;
  } else if (type === 'Client') {
    query = CLIENT_MODULE_SCORES_QUERY;
  } else {
    throw new Error(`Unexpected scorable type: ${type}`);
  }

  const { scope } = useScope();
  const { data, loading } = useQuery(query, {
    variables: {
      id: scorable.id,
      categoryType,
      moduleType,
      ...scope.variables,
    },
  });
  const moduleScores = data?.scorable.categories[0].modules[0];
  return { moduleScores, loading };
};

export const MODULE_SCORE_FIELDS_FRAGMENT = gql`
  fragment ModuleScoreFields on ModuleInterface {
    # id
    name
    active
    confidentialityThresholdMet
    health
    intelligence
    intelligenceStats {
      relativeContribution
      contributorPercentage
    }

    ${EMPLOYEE_EXPERIENCE_MODULE_FIELDS}
    ...ProjectsModuleFragment
    ...MemberScores
    ...AggregatorSurveyFields

    aspects {
      id
      name
      health
    }
  }

  ${PROJECTS_MODULE_FRAGMENT}
  ${MEMBER_SCORES_FRAGMENT}
  ${AGGREGATOR_SURVEY_FIELDS_FRAGMENT}
`;

export const MODULE_SCORES_FRAGMENT = gql`
  fragment ModuleScores on ScorableInterface {
    id
    categories(type: $categoryType) {
      id
      name
      type
      modules(type: $moduleType) {
        id
        name
        type
        ...ModuleScoreFields
        ...MemberScores
        ...ClientBreakdown
      }
    }
  }
  ${MODULE_SCORE_FIELDS_FRAGMENT}
  ${CLIENT_BREAKDOWN_FRAGMENT}
`;

const WORKSPACE_MODULE_SCORES_QUERY = gql`
  query ModuleScores($id: ID!, $categoryType: CategoryType, $moduleType: ModuleType, $scope: ScopeInput) {
    scorable: workspace(id: $id, scope: $scope) {
      memberStats {
        count
      }
      ...ModuleScores
    }
  }
  ${MODULE_SCORES_FRAGMENT}
`;

const CLIENT_MODULE_SCORES_QUERY = gql`
  query ModuleScores($id: ID!, $categoryType: CategoryType, $moduleType: ModuleType, $scope: ScopeInput) {
    scorable: client(id: $id, scope: $scope) {
      memberStats {
        count
      }
      ...ModuleScores
    }
  }
  ${MODULE_SCORES_FRAGMENT}
`;
