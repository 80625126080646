import React from 'react';
import { useScope } from '../Scope/useScope';
import { computeScoreLevel } from '../Scores/Score';
import { Category } from './Category';
import { findCategoryScoreDimensionSettings } from './CategoryScoreDimensionSettings';
import MemberScoreBreakdown from '../Membership/MemberScoreBreakdown';
import { MembershipInterface } from '../Membership/Membership';
import { Stack, Typography } from '@mui/material';
import { Scorable } from '../Scores/Scorable';

interface PersonalAndMemberBreakdownCategoryDetailsProps {
  scorable: Scorable & MembershipInterface;
  category: Category;
}

export const PersonalAndMemberBreakdownCategoryDetails = ({ scorable, category }: PersonalAndMemberBreakdownCategoryDetailsProps) => {
  const { scope } = useScope();

  if (scope.type === 'Personal') {
    return <PersonalCategoryDetails category={category} />;
  } else {
    return <MemberScoreBreakdown scorable={scorable} scoreDimension={category} />;
  }
};

export const PersonalCategoryDetails = ({ category }: { category: Category }) => {
  const scoreLevel = computeScoreLevel(category.health);
  //    console.log(healthLevel);
  const componentSettings = findCategoryScoreDimensionSettings(category.type);
  const level = componentSettings.levels![scoreLevel.level];
  //  console.log(level)

  return (
    <Stack>
      <Typography variant='body1'>{level.headline}</Typography>
      <Typography variant='body2'>{level.description}</Typography>
    </Stack>
  );
};
