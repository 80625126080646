import React from 'react';
import { Stack, Box } from '@mui/material';
import classnames from 'classnames';

interface ProgressBarProps {
  stepIndex: number | undefined;
  stepCount: number | undefined;
}

const ProgressBar = ({ stepIndex, stepCount }: ProgressBarProps) => {
  if (stepIndex === undefined || stepCount === undefined) {
    return null;
  }

  const steps = [...Array(stepCount)];
  return (
    <Stack
      direction='row'
      sx={{
        width: '100%',
        height: '5px',
        backgroundColor: '#666',
        position: 'absolute',
        bottom: 0,
      }}
    >
      {steps.map((_, index) => (
        <Box
          key={index}
          className={classnames({
            active: index === stepIndex,
            completed: index < stepIndex,
          })}
          sx={{
            flexGrow: 1,
            borderLeft: index === 0 ? 'none' : '2px solid #1a1a1a',
            backgroundColor: index < stepIndex ? 'secondary.main' : index === stepIndex ? 'secondary.main' : 'inherit',
          }}
        />
      ))}
    </Stack>
  );
};

export default ProgressBar;
