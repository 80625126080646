import React, { Fragment } from 'react';
import { CATEGORY_SCORE_DIMENSION_SETTINGS } from '../Category/CategoryScoreDimensionSettings';
import { useWorkspace } from '../Workspace/useWorkspace';
import { Scorable } from '../Scores/Scorable';

interface DashboardCategoriesProps {
  scorable: Scorable;
}

const DashboardCategories = ({ scorable }: DashboardCategoriesProps) => {
  const { workspace } = useWorkspace();

  // To support loading state, using workspace categories while score data loads
  const categoryContainer = scorable ? scorable.categories : workspace.categories;
  const categories = categoryContainer.filter(category => category.modules.length);

  return (
    <Fragment>
      {categories.map(category => {
        const { DashboardComponent } = CATEGORY_SCORE_DIMENSION_SETTINGS.find(uiCategory => uiCategory.type === category.type)!;
        return <DashboardComponent key={category.id} scorable={scorable} category={category} scoreCircle={categories.length > 1} />;
      })}
    </Fragment>
  );
};

export default DashboardCategories;
