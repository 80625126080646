import React, { Fragment, useEffect, useState } from 'react';

import hyveApi from '../util/hyveApi';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { GridToolbar } from '@mui/x-data-grid';
import useSystemAdminRoute from '../User/useSystemAdminRoute';

type OddsType = {
  [key: string]: {
    name: string;
    odds: number;
  };
};

type GroupOdds = {
  scorableOdds: OddsType;
  aspectOdds: OddsType;
  aspectMetricOdds: OddsType;
  healthFactorOdds: OddsType;
  healthMetricOdds: OddsType;
  cadenceOdds: OddsType;
  clientOdds: OddsType;
};

type ResponseIdField = 'response_id' | 'aspect_id' | 'aspect_metric_id' | 'client_id' | 'scorable_id' | 'health_factor_id' | 'health_metric_id' | 'cadence';

type LogField =
  | 'winner'
  | 'id'
  | 'user_email'
  | 'scorable_id'
  | 'scorable_name'
  | 'client_name'
  | 'aspect_name'
  | 'aspect_metric_name'
  | 'health_metric_name'
  | 'health_factor_name'
  | 'cadence_name'
  | 'cell_intelligence_gain'
  | 'cell_assignment_weight'
  | 'cell_diversity_adjustment'
  | 'contribution_adjustment'
  | 'assignment_adjustment'
  | 'skip_adjustment'
  | 'ranged_weight'
  | 'won_via'
  | 'odds'
  | 'response_id'
  | 'question_id'
  | 'question_text'
  | 'survey_full_skip'
  | 'already_assigned_skip';

const LOG_FIELDS: LogField[] = [
  'winner',
  'id',
  'user_email',
  'scorable_id',
  'scorable_name',
  'client_name',
  'aspect_name',
  'aspect_metric_name',
  'health_metric_name',
  'health_factor_name',
  'cadence_name',
  'cell_intelligence_gain',
  'cell_assignment_weight',
  'cell_diversity_adjustment',
  'contribution_adjustment',
  'assignment_adjustment',
  'skip_adjustment',
  'ranged_weight',
  'won_via',
  'odds',
  'response_id',
  'question_id',
  'question_text',
  'survey_full_skip',
  'already_assigned_skip',
];

// type ResponseRow = z.infer<typeof ResponseRowSchema>;
type ResponseRowField = ResponseIdField | LogField;

type ResponseRow = {
  [key in ResponseRowField]: string;
};

type LogAccumulator = {
  [key: string]: string | null | undefined; // Define the types for each field
};

type WinningDimensions = {
  aspect_id: string | undefined;
  aspect_metric_id: string | undefined;
  client_id: string | undefined;
  scorable_id: string | undefined;
  health_factor_id: string | undefined;
  health_metric_id: string | undefined;
  cadence: string | undefined;
};

const winningDimensionsInitialState = {
  aspect_id: undefined,
  aspect_metric_id: undefined,
  client_id: undefined,
  scorable_id: undefined,
  health_factor_id: undefined,
  health_metric_id: undefined,
  cadence: undefined,
};

const SurveyLogs = () => {
  useSystemAdminRoute();

  let [success, setSuccess] = useState(false);
  let [data, setData] = useState<ResponseRow[] | null>(null);
  let [groupOdds, setGroupOdds] = useState<GroupOdds>({
    scorableOdds: {},
    aspectOdds: {},
    aspectMetricOdds: {},
    healthFactorOdds: {},
    healthMetricOdds: {},
    cadenceOdds: {},
    clientOdds: {},
  });
  let [winningDimensionIds, setWinningDimensionIds] = useState<WinningDimensions>(winningDimensionsInitialState);
  const { id, iteration } = useParams();

  useEffect(() => {
    hyveApi
      .create()
      .get<ResponseRow[]>(`/survey_logs/${id}/iteration_details/${iteration}`)
      .then(result => {
        setData(result.data);
        setSuccess(true);
        const oddsByGroups = result.data.reduce<GroupOdds>(
          (map, row) => {
            if (row['response_id']) {
              setWinningDimensionIds({
                aspect_id: row['aspect_id'],
                aspect_metric_id: row['aspect_metric_id'],
                client_id: row['client_id'],
                scorable_id: row['scorable_id'],
                health_factor_id: row['health_factor_id'],
                health_metric_id: row['health_metric_id'],
                cadence: row['cadence'],
              });
            }
            map.scorableOdds[row['scorable_id']] = map.scorableOdds[row['scorable_id']] || {
              name: row['scorable_name'],
              odds: 0,
            };
            map.scorableOdds[row['scorable_id']].odds += parseFloat(row['odds']);

            map.aspectOdds[row['aspect_id']] = map.aspectOdds[row['aspect_id']] || { name: row['aspect_name'], odds: 0 };
            map.aspectOdds[row['aspect_id']].odds += parseFloat(row['odds']);

            map.aspectMetricOdds[row['aspect_metric_id']] = map.aspectMetricOdds[row['aspect_metric_id']] || {
              name: row['aspect_metric_name'],
              odds: 0,
            };
            map.aspectMetricOdds[row['aspect_metric_id']].odds += parseFloat(row['odds']);

            map.healthFactorOdds[row['health_factor_id']] = map.healthFactorOdds[row['health_factor_id']] || {
              name: row['health_factor_name'],
              odds: 0,
            };
            map.healthFactorOdds[row['health_factor_id']].odds += parseFloat(row['odds']);

            map.healthMetricOdds[row['health_metric_id']] = map.healthMetricOdds[row['health_metric_id']] || {
              name: row['health_metric_name'],
              odds: 0,
            };
            map.healthMetricOdds[row['health_metric_id']].odds += parseFloat(row['odds']);

            map.cadenceOdds[row['cadence']] = map.cadenceOdds[row['cadence']] || { name: row['cadence_name'], odds: 0 };
            map.cadenceOdds[row['cadence']].odds += parseFloat(row['odds']);

            map.clientOdds[row['client_id']] = map.clientOdds[row['client_id']] || { name: row['client_name'], odds: 0 };
            map.clientOdds[row['client_id']].odds += parseFloat(row['odds']);

            return map;
          },
          {
            scorableOdds: {},
            aspectOdds: {},
            aspectMetricOdds: {},
            healthFactorOdds: {},
            healthMetricOdds: {},
            cadenceOdds: {},
            clientOdds: {},
          }
        );
        setGroupOdds(oddsByGroups);
      })
      .catch(error => {
        setSuccess(false);
        console.error(error);
      });
  }, [id, iteration]);

  const { scorableOdds, aspectOdds, aspectMetricOdds, healthFactorOdds, healthMetricOdds, cadenceOdds, clientOdds } = groupOdds;
  return (
    <Fragment>
      <a style={{ textDecoration: 'underline', fontSize: '20pt' }} href={`/survey_logs/${id}/module_drawings`}>
        Module drawing logs
      </a>
      {success === null ? 'Loading...' : ''}
      {!success ? 'Failed to load data' : ''}
      {success && data ? (
        <Fragment>
          {groupOdds ? (
            <Fragment>
              <h2>Client-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(clientOdds).map(id => ({
                    id,
                    client_id: id,
                    client: clientOdds[id]['name'],
                    odds: clientOdds[id]['odds'],
                    winner: id === winningDimensionIds['client_id'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'client_id', headerName: 'Client Id', width: 150 },
                    { field: 'client', headerName: 'Client', width: 150 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>

              <h2>Base scorable-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(scorableOdds).map(id => ({
                    id,
                    scorable_id: id,
                    scorable: scorableOdds[id]['name'],
                    odds: scorableOdds[id]['odds'],
                    winner: id === winningDimensionIds['scorable_id'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'scorable_id', headerName: 'Scorable Id', width: 150 },
                    { field: 'scorable', headerName: 'Scorable', width: 150 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
              <h2>Aspect-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(aspectOdds).map(id => ({
                    id,
                    aspect_id: id,
                    aspect: aspectOdds[id]['name'],
                    odds: aspectOdds[id]['odds'],
                    winner: id === winningDimensionIds['aspect_id'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'aspect_id', headerName: 'Aspect Id', width: 150 },
                    { field: 'aspect', headerName: 'Aspect', width: 150 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
              <h2>Aspect Metric-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(aspectMetricOdds).map(id => ({
                    id,
                    aspect_metric_id: id,
                    aspect_metric: aspectMetricOdds[id]['name'],
                    odds: aspectMetricOdds[id]['odds'],
                    winner: id === winningDimensionIds['aspect_metric_id'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'aspect_metric_id', headerName: 'Aspect Metric Id', width: 200 },
                    { field: 'aspect_metric', headerName: 'Aspect Metric', width: 200 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
              <h2>Health Factor-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(healthFactorOdds).map(id => ({
                    id,
                    health_factor_id: id,
                    health_factor: healthFactorOdds[id]['name'],
                    odds: healthFactorOdds[id]['odds'],
                    winner: id === winningDimensionIds['health_factor_id'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'health_factor_id', headerName: 'Health Factor Id', width: 200 },
                    { field: 'health_factor', headerName: 'Health Factor', width: 200 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
              <h2>Health Metric-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(healthMetricOdds).map(id => ({
                    id,
                    health_metric_id: id,
                    health_metric: healthMetricOdds[id]['name'],
                    odds: healthMetricOdds[id]['odds'],
                    winner: id === winningDimensionIds['health_metric_id'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'health_metric_id', headerName: 'Health Metric Id', width: 200 },
                    { field: 'health_metric', headerName: 'Health Metric', width: 200 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
              <h2>Cadence-level Odds</h2>
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(cadenceOdds).map(id => ({
                    id,
                    cadence_id: id,
                    cadence: cadenceOdds[id]['name'],
                    odds: cadenceOdds[id]['odds'],
                    winner: id === winningDimensionIds['cadence'] ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'Winner', width: 150 },
                    { field: 'cadence_id', headerName: 'Cadence Id', width: 150 },
                    { field: 'cadence', headerName: 'Cadence', width: 150 },
                    { field: 'odds', headerName: 'Odds', width: 300 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
            </Fragment>
          ) : null}
          <h2>Iteration Log</h2>
          <div className='survey-log-table' style={{ height: '1000px' }}>
            <DataGrid
              autoHeight={true}
              rows={
                // data.map(row => log_fields.map(field => ({[field]: row[field]})))
                data.map(row =>
                  LOG_FIELDS.reduce(
                    (acc: LogAccumulator, cur) => ({
                      ...acc,
                      [cur]: cur === 'winner' && row['response_id'] ? '✅' : row[cur],
                    }),
                    {}
                  )
                )
              }
              columns={LOG_FIELDS.map(field => ({ field, headerName: field, width: 200 }))}
              getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
              slots={{ toolbar: GridToolbar }}
            />
          </div>
        </Fragment>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default SurveyLogs;
