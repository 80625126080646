import React from 'react';
import Stack from '@mui/material/Stack';
import { SCORE_LEVELS } from '../Scores/Score';
import { ScoreBreakdown, ScoreBreakdownLevel } from '../Scores/ScoreBreakdown';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import { ClientCollector } from '../Client/ClientCollector';
import { ScoreDimension } from '../Scores/ScoreDimension';
import useWorkspace from '../Workspace/useWorkspace';

interface ClientsScoreBreakdownProps {
  scoreDimension: ScoreDimension & ClientCollector;
  title?: string;
}

const ClientsScoreBreakdown = ({ scoreDimension, title }: ClientsScoreBreakdownProps) => {
  // console.log(scorable);
  const { clientStats } = scoreDimension;
  const { workspacePath } = useWorkspace();
  const clientScoreLevelCounts = SCORE_LEVELS.reduce((map, level) => {
    map.set(level, clientStats[`${level.key}Count`]);
    return map;
  }, new Map());

  return (
    <Stack alignItems='stretch' bgcolor='pink1'>
      <ScoreBreakdown title={title}>
        {SCORE_LEVELS.map(scoreLevel => (
          <ScoreBreakdownLevel
            key={scoreLevel.key}
            name={scoreLevel.name}
            count={clientScoreLevelCounts.get(scoreLevel)}
            icon={CorporateFareRoundedIcon}
            color={scoreLevel.color}
            //            onClick={() => navigate(`clients?healthLevel=`)}
            path={`${workspacePath}/clients/list?scoreLevel=${scoreLevel.key}`}
          />
        ))}
      </ScoreBreakdown>
    </Stack>
  );
};

export default ClientsScoreBreakdown;
