import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { z } from 'zod';
import { Stack, TextField } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';

interface EditProfileDialogProps {
  open: boolean;
  onClose: () => void;
}

const editProfileSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Email must be valid'),
});

type EditProfileValues = z.infer<typeof editProfileSchema>;

const EditProfileDialog = ({ open, onClose }: EditProfileDialogProps) => {
  const { user } = useAuthenticatedUser();
  const { setSuccess } = useGlobalMessage();
  const [updateUser, { error }] = useMutation(UPDATE_USER_MUTATION);

  const defaultValues = editProfileSchema.parse(user);
  const formDialogProps = useFormDialog<EditProfileValues>({ schema: editProfileSchema, defaultValues });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const handleSubmit = async (values: EditProfileValues) => {
    const response = await updateUser({ variables: { id: user.id, ...values } });
    const success = handleResponse(response, 'updateUser', 'Uh oh. There was a problem updating your profile');
    if (success) {
      setSuccess('Updated profile');
    }
    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='edit-profile'
      open={open}
      onClose={onClose}
      icon={<PersonIcon />}
      title='Update Profile'
      subtitle='Update your full name and email address.'
      submitLabel='Save'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Stack spacing={2}>
        <Controller
          name='firstName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} variant='outlined' fullWidth label='First Name' autoComplete='given-name' error={!!error} helperText={error?.message} />
          )}
        />
        <Controller
          name='lastName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} variant='outlined' fullWidth label='Last Name' autoComplete='family-name' error={!!error} helperText={error?.message} />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => <TextField {...field} variant='outlined' fullWidth label='Email' autoComplete='email' disabled />}
        />
      </Stack>
    </FormDialog>
  );
};

export default EditProfileDialog;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $firstName: String!, $lastName: String!, $email: String!) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email) {
      user {
        id
        firstName
        lastName
        # email
      }
    }
  }
`;
