import React, { useState, createRef, Fragment, useEffect } from 'react';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import SimpleMenu from '../UI/SimpleMenu';
import { useScope } from './useScope';
import { ScopeInterface } from './Scope';
import useWorkspace from '../Workspace/useWorkspace';
import { SxProps } from '@mui/material';

const anchorRef = createRef();

export const useScopeOptions = () => {};

export const useScopeMenu = () => {
  const [open, setOpen] = useState(false);

  return { anchorRef, open, setOpen };
};

// scopeOptions: { label, scope, count }
export interface ScopeSelectorOptionInterface {
  scope: ScopeInterface;
  label: string;
  count?: number;
}

interface ScopeSelectorProps {
  options: ScopeSelectorOptionInterface[];
  includeUnscopedForAllMembers?: boolean;
  sx?: Object;
}

const ScopeSelector = ({ options, includeUnscopedForAllMembers = false, sx = {} }: ScopeSelectorProps) => {
  const { scope, scopes, setScope } = useScope();
  const [open, setOpen] = useState(false);

  const { isAdmin, isSeniorLeader } = useWorkspace();

  // if scope is set to unscoped, but the current component doesn't support it, reset to default scope
  if (!includeUnscopedForAllMembers && !isAdmin && !isSeniorLeader) {
    options = options.filter(option => option.scope.type !== 'Unscoped'); // remove the unscoped scope
  }

  const isValidScope = options.map(option => option.scope.type).includes(scope.type);

  useEffect(() => {
    if (!isValidScope) {
      setScope(scopes[0]);
    }
  }, [isValidScope, setScope, scopes]);

  if (options.length === 0 || !isValidScope) {
    return null;
  }

  const setValue = (option: ScopeSelectorOptionInterface) => {
    // console.log('setting scope to ', value)
    setScope(option.scope);
  };

  const value = options.find(option => option.scope.key === scope.key)!;

  return (
    <Fragment>
      <ScopeMenuButton anchorRef={anchorRef} setOpen={setOpen} value={value} options={options} sx={sx} />
      <ScopeMenu open={open} setOpen={setOpen} value={value} setValue={setValue} options={options} anchorRef={anchorRef} />
    </Fragment>
  );
};

interface ScopeMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: ScopeSelectorOptionInterface;
  setValue: (option: ScopeSelectorOptionInterface) => void;
  options: ScopeSelectorOptionInterface[];
  anchorRef: React.MutableRefObject<any>;
}

const ScopeMenu = ({ open, setOpen, value, setValue, options, anchorRef }: ScopeMenuProps) => {
  return (
    <SimpleMenu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
      {options.map(option => {
        // const countText = value.count !== undefined ? ` (${value.count})` : '';
        const { label: optionText, scope } = option;

        return (
          <MenuItem key={scope.key} onClick={() => setValue(option)}>
            <ListItemIcon>
              <Icon>{option === value && 'check'}</Icon>
            </ListItemIcon>
            {optionText}
          </MenuItem>
        );
      })}
    </SimpleMenu>
  );
};

interface ScopeMenuButtonProps {
  anchorRef: React.MutableRefObject<any>;
  setOpen: (open: boolean) => void;
  value: ScopeSelectorOptionInterface;
  options: ScopeSelectorOptionInterface[];
  sx: SxProps;
}

const ScopeMenuButton = ({ anchorRef, setOpen, value, options, sx }: ScopeMenuButtonProps) => {
  // const selectedScopeOption = options.find(option => option === value);
  const countText = value.count !== undefined ? ` (${value.count})` : '';

  const label = `${value.label}${countText}`;

  if (options.length > 1) {
    return (
      <Fragment>
        <Button
          sx={{
            textTransform: 'none',
            ':hover': { backgroundColor: '' },
            ...sx,
          }}
          ref={anchorRef}
          onClick={() => setOpen(true)}
        >
          {label}
          <ArrowDropDownRoundedIcon />
        </Button>
      </Fragment>
    );
  } else {
    return <span>{label}</span>;
  }
};

export default ScopeSelector;
