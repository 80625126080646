import React from 'react';
import { Stack } from '@mui/material';
import { useLayout } from '../UI/LayoutContext';

const NavigationViewport = ({ children }: { children: React.ReactNode }) => {
  const layout = useLayout();

  return (
    <Stack
      data-component='NavigationViewport'
      sx={{
        flexGrow: 1,
        overflowY: layout !== 'mobile' ? 'scroll' : undefined,
        position: 'relative',
      }}
    >
      {children}
    </Stack>
  );
};

export default NavigationViewport;
