import { useContext, useEffect } from 'react';

import AuthenticationContext from '../User/AuthenticationContext';
import useUser from '../User/useUser';

export const useHelpScout = () => {
  const { authenticated } = useContext(AuthenticationContext);
  const { user } = useUser();

  // const companyName = workspace?.company.name;

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('config', {
        display: {
          zIndex: 1300,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (window.Beacon) {
      if (user) {
        const userObject = {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          // company: companyName,
          // jobTitle: 'Tyre Aficionado',
        };
        if (user.avatar) {
          userObject.avatar = user.avatar;
        }
        window.Beacon('identify', userObject);
      }
      // } else if (window.$crisp.is && window.$crisp.is("session:ongoing")) {
      //   // console.info('Not authenticated and session ongoing. Resetting session');
      //   window.$crisp.push(["do", "session:reset"]); // reset session when signing out
      // }
    }
  }, [authenticated, user]);

  // useEffect(() => {
  //   window.Beacon('on', 'ready', () => {
  //     // showHide(layout)
  //   })
  // }, [])

  // useEffect(() => {
  //   window.Beacon('config', { hideFABOnMobile: true });
  // }, [layout])
};
