import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

import { useProject } from './useProject';
import ProjectInviteDialog from '../Project/ProjectInviteDialog';
import MemberList from '../Membership/MemberList';
import { useGlobalMessage } from '../UI/GlobalMessage';
import PageStyle from '../UI/PageStyle';
import { SET_USER_ROLES_MUTATION, SetUserRolesVariables } from '../User/Roles';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import { UserInterface } from '../User/User';
import { useProjectContext } from './ProjectContext';

const ProjectMembers = () => {
  const { project, refetch } = useProjectContext();
  const { users, owners } = project;
  const { isProjectOwner } = useProject(project);

  const { setSuccess, setError } = useGlobalMessage();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const handleAddMembers = () => {
    setInviteDialogOpen(true);
  };

  const [setUserRoles] = useMutation<any, SetUserRolesVariables>(SET_USER_ROLES_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(project) });
      cache.gc();
    },
  });

  const [removeProjectMembers] = useMutation(REMOVE_PROJECT_MEMBERS_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(project) });
      cache.gc();
    },
  });

  const memberActionsResolver = (user: UserInterface) => {
    const setProjectOwner = {
      icon: StarsRoundedIcon,
      label: 'Set as the Project Owner',
      disabled: false,
      onSelect: async () => {
        const {
          data: {
            setUserRoles: { success, message },
          },
        } = await setUserRoles({
          variables: {
            userId: user.id,
            type: 'Project',
            id: project.id,
            roles: ['Owner'],
            operation: 'Add',
          },
        });
        if (success) {
          setSuccess('Set as the project owner');
        } else {
          setError(message);
        }
      },
    };

    const unsetProjectOwner = {
      icon: StarsRoundedIcon,
      label: 'Unset as the Project Owner',
      disabled: false,
      onSelect: async () => {
        const {
          data: {
            setUserRoles: { success, message },
          },
        } = await setUserRoles({
          variables: {
            userId: user.id,
            type: 'Project',
            id: project.id,
            roles: ['Owner'],
            operation: 'Remove',
          },
        });
        if (success) {
          setSuccess('Unset as the project owner');
        } else {
          setError(message);
        }
      },
    };

    const removeFromProject = {
      icon: PersonRemoveRoundedIcon,
      label: 'Remove from Project',
      onSelect: async () => {
        const {
          data: {
            removeProjectMembers: { success, message },
          },
        } = await removeProjectMembers({ variables: { id: project.id, userIds: [user.id] } });
        if (success) {
          setSuccess(`Removed from project`);
        } else {
          setError(message);
        }
      },
    };

    const actions = [];
    if (!isProjectOwner(user)) {
      actions.push(setProjectOwner);
    } else {
      actions.push(unsetProjectOwner);
    }
    actions.push(removeFromProject);

    return actions;
  };

  return (
    <PageStyle design='simple'>
      <MemberList members={users} owners={owners} memberActionsResolver={memberActionsResolver} onAddMembers={handleAddMembers} />
      <ProjectInviteDialog project={project} open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} refetch={refetch} />
    </PageStyle>
  );
};

export default ProjectMembers;

export const REMOVE_PROJECT_MEMBERS_MUTATION = gql`
  mutation RemoveProjectMembers($id: ID!, $userIds: [ID!]) {
    removeProjectMembers(id: $id, userIds: $userIds) {
      success
    }
  }
`;
