import { useContext, useState, useEffect } from 'react';

import AuthenticationContext from '../User/AuthenticationContext';
import { withErrorHandling } from './error';
import useUser from '../User/useUser';

export const usePendo = withErrorHandling(() => {
  const { authenticated } = useContext(AuthenticationContext);
  const { user } = useUser();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_INCLUDE_PENDO === 'true' && user) {
      const options = {
        visitor: {
          id: user.id, // Required if user is logged in
          email: user.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: `${user.firstName} ${user.lastName}`, // Recommended if using Pendo Feedback
          // role:           // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },
        account: {
          //id: workspace.id, // Highly recommended
          // name: workspace.name, // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      };

      if (!initialized) {
        window.pendo.initialize(options);
        setInitialized(true);
      } else {
        window.pendo.updateOptions(options);
      }
    }
  }, [initialized, authenticated, user]);

  return '';
});
