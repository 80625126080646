import React, { createContext, useContext } from 'react';

import { ClientInterface } from './ClientInterface';
import useWorkspace from '../Workspace/useWorkspace';

type ClientContextValue = {
  client: ClientInterface;
  clientPath: string;
  refetch: () => Promise<any>;
};

const ClientContext = createContext<ClientContextValue | undefined>(undefined);

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('useClientContext must be used within a ClientProvider');
  }
  return context;
};

type ClientProviderProps = Omit<ClientContextValue, 'clientPath'> & {
  children: React.ReactNode;
};

export const ClientProvider = ({ client, refetch, children }: ClientProviderProps) => {
  const { workspacePath } = useWorkspace();
  const clientPath = `${workspacePath}/clients/${client.id}`;
  return <ClientContext.Provider value={{ client, clientPath, refetch }}>{children}</ClientContext.Provider>;
};
