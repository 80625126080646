import React, { Fragment } from 'react';
import { useQuery, gql } from '@apollo/client';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import NewTeamDialog from '../Team/NewTeamDialog';

import EditTeamDialog from '../Team/EditTeamDialog';

import TeamManagementDataGrid from '../Team/TeamManagementDataGrid';
import { useNewTeamAction, useAddSubteamAction, useViewTeamAction, useEditTeamAction, useDeleteTeamAction } from './TeamManagementActions';

import ConfirmationDialog from '../UI/ConfirmationDialog';
import { useLayout } from '../UI/LayoutContext';
import PageStyle from '../UI/PageStyle';
import ListNotice from '../UI/ListNotice';
import PageNotice from '../UI/PageNotice';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import { SectionHeader, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { useWorkspace } from '../Workspace/useWorkspace';
import { TeamInterface } from './TeamSchema';

const TeamManagementTeams = () => {
  const { workspace } = useWorkspace();
  const layout = useLayout();
  const { newTeamDialogOpen, setNewTeamDialogOpen } = useNewTeamAction();

  const { data, error, loading } = useQuery(TEAM_MANAGMENT_QUERY, { variables: { workspaceId: workspace.id } });
  if (!data && loading) return <PageStyle design='standard' loading={true} />;
  if (error) throw error;

  const {
    workspace: { teams },
  } = data;

  const inviteButton =
    layout === 'mobile' ? (
      <IconButton id='add-team' onClick={() => setNewTeamDialogOpen(true)}>
        <Icon>group_add</Icon>
      </IconButton>
    ) : (
      <Button id='add-team' startIcon={<Icon>group_add</Icon>} onClick={() => setNewTeamDialogOpen(true)}>
        Add New Team
      </Button>
    );

  return (
    <Fragment>
      <PageNotice
        noticeKey={`team-management`}
        applicable
        icon={ManageAccountsRoundedIcon}
        message='Note: Only workspace admins can manage teams.'
        actions={[]}
        closable={false}
      />
      <PageStyle design='simple' fullWidth>
        <SectionHeader>
          <SectionHeaderTitle>Teams ({teams.length})</SectionHeaderTitle>
          <SectionHeaderActions>
            {inviteButton}

            <NewTeamDialog open={newTeamDialogOpen} onClose={() => setNewTeamDialogOpen(false)} />
          </SectionHeaderActions>
        </SectionHeader>

        {teams.length > 0 ? (
          <TeamManagementDataGrid teams={teams} actionsComponent={TeamManagementActions} />
        ) : (
          <NoTeamsNotice onAddTeam={() => setNewTeamDialogOpen(true)} />
        )}
      </PageStyle>
    </Fragment>
  );
};

interface TeamManagementActionsProps {
  team: TeamInterface;
}

const TeamManagementActions = ({ team }: TeamManagementActionsProps) => {
  const { addSubteamAction, newTeamDialogOpen, setNewTeamDialogOpen } = useAddSubteamAction();
  const { viewTeamAction } = useViewTeamAction(team);
  const { editTeamAction, editTeamDialogOpen, setEditTeamDialogOpen } = useEditTeamAction();
  const { deleteTeamAction, deleteTeamConfirmationOptions } = useDeleteTeamAction(team);

  const actions: ActionListType = [addSubteamAction, viewTeamAction, editTeamAction, 'divider', deleteTeamAction];

  return (
    <Fragment>
      <OverflowActions className='team-management-actions' actions={actions} />
      <NewTeamDialog open={newTeamDialogOpen} onClose={() => setNewTeamDialogOpen(false)} parentTeam={team} />
      <EditTeamDialog team={team} open={editTeamDialogOpen} onClose={() => setEditTeamDialogOpen(false)} />
      <ConfirmationDialog {...deleteTeamConfirmationOptions} />
      {/*{collectIntelligenceDialog}*/}
    </Fragment>
  );
};

const NoTeamsNotice = ({ onAddTeam }: { onAddTeam: () => void }) => {
  return (
    <ListNotice
      icon={PeopleRoundedIcon}
      title={'No teams to display'}
      message={
        <Fragment>
          <span>It doesn't look like there are any teams.</span>
          <br />
          <span>Add a team to get started!</span>
        </Fragment>
      }
      actions={[
        {
          label: 'Add New Team',
          onSelect: onAddTeam,
        },
      ]}
    />
  );
};

export const TEAM_MANAGMENT_QUERY = gql`
  query Teams($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      teams(scope: { type: Unscoped }) {
        id
        name
        icon {
          symbol
          color
        }
        parentTeam {
          id
          name
        }
        ancestors {
          id
          name
        }
        manager {
          id
          firstName
          lastName
          avatar
        }
        users {
          id
          email
          firstName
          lastName
          avatar
          pendingInvitation
        }
      }
    }
  }
`;

export default TeamManagementTeams;
