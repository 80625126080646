import { gql, useMutation, useSubscription } from '@apollo/client';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import useWorkspace from '../Workspace/useWorkspace';

export const messageSchema = z.object({
  sender: z.enum(['user', 'ai']),
  content: z.string(),
});

export type Message = z.infer<typeof messageSchema>;

type AIContextValue = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  sendMessage: (prompt: string) => Promise<void>;

  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  focusInput: () => void;
  reset: () => void;
};

const AIContext = createContext<AIContextValue | undefined>(undefined);

export const useAI = () => {
  const context = useContext(AIContext);
  if (!context) {
    throw new Error('useAIContext must be used within a AIProvider');
  }
  return context;
};

export const AIProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { workspace } = useWorkspace();

  const { data: subscriptionData, error: subscriptionError } = useSubscription(ASSISTANT_RESPONDED_SUBSCRIPTION, {
    variables: { workspaceId: workspace.id },
    skip: !open,
    shouldResubscribe: true,
  });

  // console.log(subscriptionData);

  if (subscriptionError) {
    console.error(subscriptionError);
  }

  useEffect(() => {
    if (subscriptionData) {
      // console.log(subscriptionData);
      const response = subscriptionData.assistantResponded.response;
      // setMessages(currentMessages => [...currentMessages, newMessage]);
      console.log(response);
      const responseMessage: Message = { sender: 'ai', content: response };
      setMessages(currentMessages => [...currentMessages.slice(0, currentMessages.length - 1), responseMessage]);
    }
  }, [subscriptionData]);

  const [messageAssistant] = useMutation(MESSAGE_ASSISTANT_MUTATION);

  const sendMessage = async (message: string) => {
    const { errors } = await messageAssistant({ variables: { workspaceId: workspace.id, prompt: message } });
    // console.log(data);
    if (errors) {
      console.error(errors);
    }
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const reset = () => {
    setMessages([]);
  };
  return <AIContext.Provider value={{ open, setOpen, inputRef, focusInput, messages, setMessages, sendMessage, reset }}>{children}</AIContext.Provider>;
};

const ASSISTANT_RESPONDED_SUBSCRIPTION = gql`
  subscription assistantResponded($workspaceId: ID!) {
    assistantResponded(workspaceId: $workspaceId) {
      response
    }
  }
`;

const MESSAGE_ASSISTANT_MUTATION = gql`
  mutation messageAssistant($workspaceId: ID!, $prompt: String!) {
    messageAssistant(workspaceId: $workspaceId, prompt: $prompt) {
      success
    }
  }
`;
