import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'; // Import Box
import { ActionInterface } from './ActionInterface';
import { IconType } from './Icon';
import React from 'react';

interface ListNoticeProps {
  icon: IconType;
  title: string;
  message: string | React.ReactNode;
  actions: ActionInterface[];
}

const ListNotice = ({ icon, title, message, actions }: ListNoticeProps) => {
  return (
    <Stack flexGrow={1} spacing={2} alignItems='center' justifyContent='center'>
      <Stack alignItems='center'>
        <Box
          sx={{
            fontSize: '48px',
            mb: 0,
          }}
        >
          {React.createElement(icon)}
        </Box>
        <Typography variant='h2'>{title}</Typography>
      </Stack>

      <Typography variant='body2' textAlign='center'>
        {message}
      </Typography>

      <Stack direction='row' justifyContent='center' spacing={1}>
        {actions.map((action, index) => (
          <Button key={index} variant='contained' disableElevation color='secondary' onClick={action.onSelect}>
            {action.label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default ListNotice;
