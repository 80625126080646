import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { DELETE_TEAM_MUTATION } from './useTeam';
import { useConfirmation } from '../UI/ConfirmationDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { TeamInterface } from './TeamSchema';
import { ActionInterface } from '../UI/ActionInterface';

export const useNewTeamAction = () => {
  const [newTeamDialogOpen, setNewTeamDialogOpen] = useState(false);

  return { newTeamDialogOpen, setNewTeamDialogOpen };
};

export const useAddSubteamAction = () => {
  const [newTeamDialogOpen, setNewTeamDialogOpen] = useState(false);

  const addSubteamAction: ActionInterface = {
    icon: AddCircleRoundedIcon,
    label: 'Add Subteam',
    onSelect: () => {
      setNewTeamDialogOpen(true);
    },
  };

  return { addSubteamAction, newTeamDialogOpen, setNewTeamDialogOpen };
};

export const useViewTeamAction = (team: TeamInterface) => {
  const { id } = team;
  const navigate = useNavigate();

  const viewTeamAction = {
    icon: PeopleRoundedIcon,
    label: 'View/Edit Team Members',
    onSelect: () => {
      navigate(id);
    },
  };

  return { viewTeamAction };
};

export const useEditTeamAction = () => {
  const [editTeamDialogOpen, setEditTeamDialogOpen] = useState(false);

  const editTeamAction = {
    icon: EditRoundedIcon,
    label: 'Edit Team Settings',
    onSelect: () => {
      setEditTeamDialogOpen(true);
    },
  };

  return { editTeamAction, editTeamDialogOpen, setEditTeamDialogOpen };
};

export const useDeleteTeamAction = (team: TeamInterface) => {
  const { id } = team;
  const { setSuccess } = useGlobalMessage();

  const { requestConfirmation, confirmationOptions: deleteTeamConfirmationOptions } = useConfirmation();
  const [deleteTeam] = useMutation(DELETE_TEAM_MUTATION, {
    update: cache => {
      // cache.data.delete(`Workspace:${workspace.id}`)
      cache.evict({ id: cache.identify(team) });
      cache.gc();
    },
  });

  const handleDeleteTeam = async () => {
    const confirmed = await requestConfirmation({
      icon: <DeleteRoundedIcon />,
      title: 'Delete Team?',
      dialogContent: `Are you sure you want to delete the team ${team.name}?`,
      confirmLabel: 'Delete',
    });
    if (confirmed) {
      await deleteTeam({ variables: { id: id } });
      setSuccess('Deleted team');
    }
  };

  const deleteTeamAction: ActionInterface = {
    icon: DeleteRoundedIcon,
    label: 'Delete Team',
    color: 'error',
    onSelect: handleDeleteTeam,
  };

  return { deleteTeamAction, deleteTeamConfirmationOptions };
};
