import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import { IconType } from '../UI/Icon';
import { ScoreDimension } from './ScoreDimension';

export type Score = number | null | undefined;

export interface ScoreLevel {
  name: string;
  key: 'high' | 'medium' | 'low' | 'collecting';
  level: 'high' | 'medium' | 'low' | 'collecting';
  attentionRequirement: string;
  color: string;
  colorOnLight: string;
  min: number;
  max: number;
}

export const SCORE_HIGH: ScoreLevel = {
  name: 'High',
  key: 'high',
  level: 'high',
  attentionRequirement: 'is looking good',
  color: '#48ac4c',
  colorOnLight: '#48ac4c',
  min: 80,
  max: 100,
};
export const SCORE_MEDIUM: ScoreLevel = {
  name: 'Medium',
  key: 'medium',
  level: 'medium',
  attentionRequirement: 'needs improvement',
  color: '#ff8036',
  colorOnLight: '#ff8036',
  min: 65,
  max: 79,
};
export const SCORE_LOW: ScoreLevel = {
  name: 'Low',
  key: 'low',
  level: 'low',
  attentionRequirement: 'requires immediate attention',
  color: '#d0021b',
  colorOnLight: '#d0021b',
  min: 20,
  max: 64,
};
export const SCORE_COLLECTING: ScoreLevel = {
  name: 'Collecting',
  key: 'collecting',
  level: 'collecting',
  attentionRequirement: 'is collecting',
  color: '#333',
  colorOnLight: '#666',
  min: 0,
  max: 19,
};

export const SCORE_LEVEL_PARAMETER = 'scoreLevel';
export const SCORE_LEVELS = [SCORE_HIGH, SCORE_MEDIUM, SCORE_LOW, SCORE_COLLECTING];

export const findScoreLevel = (key: string | undefined) => SCORE_LEVELS.find(level => level.key === key);

export const computeScoreLevel = (health: Score) => {
  if (!health) {
    return SCORE_COLLECTING;
  } else if (health <= SCORE_LOW.max) {
    return SCORE_LOW;
  } else if (health <= SCORE_MEDIUM.max) {
    return SCORE_MEDIUM;
  } else {
    return SCORE_HIGH;
  }
};

export const sortByScore = <T extends ScoreDimension>(scorables: T[]): T[] =>
  // TODO move sorting to server
  [...scorables].sort((a, b) => scoreSortComparator(a.health, b.health));

export const scoreSortComparator = (a: Score, b: Score): number => {
  const normalizedA = a ?? -Infinity; // Treat null/undefined as lowest possible value
  const normalizedB = b ?? -Infinity; // Treat null/undefined as lowest possible value
  return normalizedA - normalizedB;
};

export const getScoreDeltaInfo = (scoreDelta: number) => {
  let scoreDeltaIconName: string;
  let ScoreDeltaIcon: IconType;
  let scoreDeltaSign: string;
  let scoreDeltaColor: string | undefined;

  if (scoreDelta > 0) {
    scoreDeltaIconName = 'trending_up';
    ScoreDeltaIcon = TrendingUpRoundedIcon;
    scoreDeltaSign = '+';
    scoreDeltaColor = 'success';
  } else if (scoreDelta < 0) {
    scoreDeltaIconName = 'trending_down';
    ScoreDeltaIcon = TrendingDownRoundedIcon;
    scoreDeltaSign = ''; // negative deltas already include the '-' sign
    scoreDeltaColor = 'error';
  } else {
    scoreDeltaIconName = 'trending_flat';
    ScoreDeltaIcon = TrendingFlatRoundedIcon;
    scoreDeltaSign = '+';
    // scoreDeltaColor = undefined;
  }

  return { scoreDeltaIconName, ScoreDeltaIcon, scoreDeltaSign, scoreDeltaColor };
};
