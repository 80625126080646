import React from 'react';
import { useScope } from '../Scope/useScope';
import { ActionInterface } from '../UI/ActionInterface';
import { Category } from '../Category/Category';
import { DashboardSurface } from './Dashboard';
import DashboardCategoryDetails from './DashboardCategoryDetails';
import LoadingSection from '../UI/LoadingSection';
import { Scorable } from '../Scores/Scorable';

export interface DashboardCategoryProps {
  scorable: Scorable;
  category: Category;
  count?: number;
  includeScoreCircle: boolean;
  navigationActions: ActionInterface[];
  actions?: ActionInterface[];
  alignActions?: 'center' | 'start';
  children?: React.ReactNode;
  notice?: React.ReactNode;
}

const DashboardCategory = ({
  scorable,
  category,
  count,
  includeScoreCircle,
  navigationActions,
  actions = [],
  alignActions = 'start',
  children,
  notice,
}: DashboardCategoryProps) => {
  const { scope } = useScope();

  const { prefix } = scope;
  const titleCount = count !== undefined ? ` (${count})` : '';
  const title = prefix + category.name + titleCount;

  if (!scorable) {
    return (
      <DashboardSurface title={title} actions={navigationActions}>
        <LoadingSection />
      </DashboardSurface>
    );
  }

  return (
    <DashboardSurface title={title} actions={navigationActions}>
      <DashboardCategoryDetails
        scorable={scorable}
        category={category}
        notice={notice}
        includeScoreCircle={includeScoreCircle}
        actions={actions}
        alignActions={alignActions}
      >
        {children}
      </DashboardCategoryDetails>
    </DashboardSurface>
  );
};

export default DashboardCategory;
