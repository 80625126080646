import { useHelpScout } from './useHelpScout';
import { useAnalytics } from './useAnalytics';
import { useFullstory } from './useFullstory';
import { usePendo } from './usePendo';

const Partners = () => {
  useHelpScout();
  useAnalytics();
  useFullstory();
  usePendo();
  return null;
};

export default Partners;
