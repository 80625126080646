import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

interface Version {
  version: string;
}

const VersionCheck = () => {
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      axios.get<Version>('/version.json').then(res => {
        const version = res.data.version;

        const sessionVersion = sessionStorage.getItem('version');
        if (sessionVersion && sessionVersion !== version) {
          setShowReload(true);
        }

        sessionStorage.setItem('version', version);
      });
    }, 300000); // 5 minutes in milliseconds

    // Initial load
    axios.get<Version>('/version.json').then(res => {
      const version = res.data.version;
      sessionStorage.setItem('version', version);
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message='A new version of Hyve is available. Please reload the app.'
      action={
        <Button variant='contained' color='secondary' size='small' onClick={handleReload}>
          Reload
        </Button>
      }
    />
  );
};

export default VersionCheck;
