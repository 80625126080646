import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import StyledDialog, { AppDialogProps } from '../UI/StyledDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

interface ConfidentialitySettingsDialogProps extends AppDialogProps {
  workspaceSettings: WorkspaceInterface;
}

const ConfidentialitySettingsDialog = ({ workspaceSettings, open, onClose }: ConfidentialitySettingsDialogProps) => {
  const { categories } = workspaceSettings;
  const { setSuccess } = useGlobalMessage();

  const [confidentialityThresholds, setConfidentialityThresholds] = useState(categories.map(category => category.confidentialityThreshold));
  const confidentialityThresholdOptions = [3, 4, 5, 6, 7];

  const [updateCategorySettings] = useMutation(UPDATE_CATEGORY_SETTINGS);

  const handleConfidentialityThresholdChange = (index: number, value: number) => {
    const updatedConfidentialityThresholds = [...confidentialityThresholds];
    updatedConfidentialityThresholds[index] = value;
    setConfidentialityThresholds(updatedConfidentialityThresholds);
  };

  const handleSubmit = async () => {
    const responses = await Promise.all(
      categories.map(async (category, index) => {
        return await updateCategorySettings({
          variables: {
            workspaceId: workspaceSettings.id,
            categoryType: category.type,
            confidentialityThreshold: confidentialityThresholds[index],
          },
        });
      })
    );

    const response = responses[0];
    const result = response.data.updateCategorySettings;
    if (!result.errors) {
      setSuccess('Updated confidentiality settings');
      onClose();
    } else {
      console.error(result.errors);
    }
    return response;
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>{<AdminPanelSettingsRoundedIcon />}Confidentiality Settings</DialogTitle>
      <DialogContent>
        <Stack alignItems='stretch'>
          <DialogContentText>Set the minimum number of members needed to respond to view data for the workspace and teams.</DialogContentText>
          <Stack spacing={3}>
            {categories.map((category, index) => (
              <Stack key={category.type} spacing={1}>
                <Typography variant='h6'>{category.name}</Typography>
                <ToggleButtonGroup
                  exclusive
                  value={confidentialityThresholds[index]}
                  onChange={(event, value) => handleConfidentialityThresholdChange(index, value)}
                  aria-label='contained secondary button group'
                >
                  {confidentialityThresholdOptions.map(value => (
                    <ToggleButton key={value} value={value} sx={{ flexGrow: 1 }}>
                      {value}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-button' variant='contained' disableElevation color='primary' onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant='contained' disableElevation color='primary' type='submit' onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConfidentialitySettingsDialog;

const UPDATE_CATEGORY_SETTINGS = gql`
  mutation UpdateCategorySettings($workspaceId: ID!, $categoryType: CategoryType!, $confidentialityThreshold: Int) {
    updateCategorySettings(workspaceId: $workspaceId, categoryType: $categoryType, confidentialityThreshold: $confidentialityThreshold) {
      category {
        id
        confidentialityThreshold
      }
      errors {
        type
        fullMessage
      }
    }
  }
`;
