export interface IntelligenceLevel {
  name: string;
  min: number;
  max: number;
  color?: string;
}

export const INTELLIGENCE_COLLECTING: IntelligenceLevel = {
  name: 'Collecting',
  min: 0,
  max: 19,
};

export const INTELLIGENCE_LOW: IntelligenceLevel = {
  name: 'Low',
  min: 20,
  max: 64,
};

export const INTELLIGENCE_MODERATE: IntelligenceLevel = {
  name: 'Moderate',
  min: 65,
  max: 79,
};

export const INTELLIGENCE_HIGH: IntelligenceLevel = {
  name: 'High',
  min: 80,
  max: 100,
};

export const INTELLIGENCE_LEVELS = [INTELLIGENCE_COLLECTING, INTELLIGENCE_LOW, INTELLIGENCE_MODERATE, INTELLIGENCE_HIGH];

export const computeIntelligenceLevel = (intelligence: number | undefined) => {
  if (!intelligence) {
    return INTELLIGENCE_COLLECTING;
  } else if (intelligence <= INTELLIGENCE_LOW.max) {
    return INTELLIGENCE_LOW;
  } else if (intelligence <= INTELLIGENCE_MODERATE.max) {
    return INTELLIGENCE_MODERATE;
  } else {
    return INTELLIGENCE_HIGH;
  }
};
