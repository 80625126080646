import React, { createContext, useContext } from 'react';
import { Category } from './Category';

type CategoryContextValue = {
  category: Category;
  //  refetch: () => Promise<any>;
};

const CategoryContext = createContext<CategoryContextValue | undefined>(undefined);

export const useCategoryContext = () => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error('useClientContext must be used within a ClientProvider');
  }
  return context;
};

type CategoryProviderProps = CategoryContextValue & {
  children: React.ReactNode;
};

export const CategoryProvider = ({ category, children }: CategoryProviderProps) => {
  return <CategoryContext.Provider value={{ category }}>{children}</CategoryContext.Provider>;
};
