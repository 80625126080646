import React from 'react';
import { Chart, ChartData, ChartOptions, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { useTimeframe } from '../User/useTimeframe';
import { Box, Theme } from '@mui/material';
import { HistoricalScoreDataPoint } from './useHistoricalScores';

Chart.register(Filler);

interface ScoreSparklineProps {
  historicalScores: HistoricalScoreDataPoint[];
}

const ScoreSparkline = ({ historicalScores }: ScoreSparklineProps) => {
  const { days } = useTimeframe();
  const theme = useTheme();

  if (!historicalScores) {
    return null;
  }

  const chartData = getChartData(historicalScores, days, theme);
  const chartOptions = getChartOptions(days);

  return (
    <Box
      width={44}
      height={20}
      //      bgcolor={theme.palette.background.default}
    >
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

export default ScoreSparkline;

type DatePoint = {
  x: string;
  y: number;
};

const getChartData = (queryData: HistoricalScoreDataPoint[], days: number, theme: Theme): ChartData<'line', DatePoint[]> => {
  const labels = Array(days)
    .fill(null)
    .map((_, index) => dayjs().subtract(index, 'day').format('YYYY-MM-DD'));
  const scoreData = queryData.map(entry => ({ x: entry.day, y: entry.health }));

  return {
    labels: labels,
    datasets: [
      {
        label: 'Score',
        data: scoreData,
        pointRadius: 0,
        backgroundColor: theme.custom.chart.backgroundColor,
        borderColor: theme.custom.chart.healthColor,
        fill: true,
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 1,
      },
    ],
  };
};

const getChartOptions = (days: number): ChartOptions<'line'> => {
  const unit = 'day';
  const min = dayjs().subtract(days, 'day').format('YYYY-MM-DD');
  const max = dayjs().format('YYYY-MM-DD');

  return {
    animation: false,
    aspectRatio: 2,
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    events: [],
    scales: {
      x: {
        display: false,
        type: 'time',
        min: min,
        max: max,
        time: {
          unit: unit,
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        // height: 200,
        min: 0,
        max: 100,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };
};
