import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import InviteDialog from '../Invitation/InviteDialog';
import { useLayout } from '../UI/LayoutContext';
import PageStyle from '../UI/PageStyle';
import UserActions from '../User/UserActions';
import { SectionHeader, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { useWorkspace } from '../Workspace/useWorkspace';
import { UserInterface } from '../User/User';
import { Avatar, Button, Icon, IconButton, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const Members = () => {
  const { workspace } = useWorkspace();
  const layout = useLayout();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const { data, error, loading } = useQuery(WORKSPACE_USERS_QUERY, { variables: { workspaceId: workspace.id } });
  if (!data && loading) return <PageStyle design='standard' loading={true} />;
  if (error) throw error;

  const {
    workspace: { users },
  } = data;

  const handleInviteTeamMembers = () => {
    setInviteDialogOpen(true);
  };

  const inviteButton =
    layout === 'mobile' ? (
      <IconButton id='invite-button' onClick={handleInviteTeamMembers}>
        <Icon>group_add</Icon>
      </IconButton>
    ) : (
      <Button id='invite-button' startIcon={<Icon>group_add</Icon>} onClick={handleInviteTeamMembers}>
        Invite Teammates
      </Button>
    );

  return (
    <PageStyle design='simple' fullWidth>
      <SectionHeader>
        <SectionHeaderTitle>Workspace Members ({users.length})</SectionHeaderTitle>
        <SectionHeaderActions>
          {inviteButton}
          <InviteDialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} />
        </SectionHeaderActions>
      </SectionHeader>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {layout !== 'mobile' && <TableCell>Email</TableCell>}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: UserInterface) => {
            const roles: string[] = [];
            if (user.parentRoles.includes('SystemAdmin')) {
              roles.push('System Admin');
            }
            if (user.parentRoles.includes('Admin')) {
              roles.push('Admin');
            }
            if (user.parentRoles.includes('SeniorLeader')) {
              roles.push('Senior Leader');
            }
            if (user.parentRoles.includes('Inactive')) {
              roles.push('Inactive');
            }
            const rolesLabel = roles.length > 0 ? ` (${roles.join(', ')})` : '';
            const name = !user.pendingInvitation ? `${user.firstName} ${user.lastName}${rolesLabel}` : `${user.email} (Invite Pending)`;

            return (
              <TableRow key={user.id} className='member'>
                <TableCell className='name-cell'>
                  <ListItem sx={{ padding: 0 }}>
                    <ListItemIcon>
                      <Avatar src={user.avatar} />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                </TableCell>
                {layout !== 'mobile' && <TableCell>{user.email}</TableCell>}
                <TableCell align='right' className='table-actions'>
                  <UserActions user={user} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </PageStyle>
  );
};

const WORKSPACE_USERS_QUERY = gql`
  query WorkspaceUsers($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      users(includeInactive: true) {
        id
        firstName
        lastName
        email
        avatar
        effectiveRoles
        parentRoles
        pendingInvitation
      }
    }
  }
`;

export default Members;
