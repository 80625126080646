import isPropValid from '@emotion/is-prop-valid';
import { styled, ThemeProvider } from '@mui/material/styles';

import { Menu, MenuProps } from '@mui/material';
import { useAppTheme } from '../Theme/AppThemeProvider';

const isMenuPropValid = (prop: string) => ['anchorEl', 'anchorOrigin', 'transformOrigin'].includes(prop) || isPropValid(prop);

const StyledMenu = (props: MenuProps) => {
  const appTheme = useAppTheme();
  return (
    <ThemeProvider theme={appTheme} >
      <StyledMenuInternal {...props} />
    </ThemeProvider>
  );
};

const StyledMenuInternal = styled(Menu, { shouldForwardProp: isMenuPropValid })`
  .MuiMenuItem-root {
    .MuiListItemIcon-root {
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      color: inherit;
      svg {
        height: 24px;
        width: 24px;
        padding: 0px;
      }
    }
  }
`;

// const StyledMenu = styled(Menu).attrs(standardProps)

export default StyledMenu;
