import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { StackProps } from '@mui/material/Stack';

export interface FieldSetContextInterface {
  namespace?: string;
  getValuesWithNamespace: () => any;
  setValueWithNamespace: (name: string, value: any) => void;
  withNamespace: (name: string) => string;
}

export const FieldSetContext = createContext<FieldSetContextInterface | null>(null);

interface FieldSetProps extends StackProps {
  namespace?: string;
  children: React.ReactNode;
}

const FieldSet = ({ namespace, children, ...props }: FieldSetProps) => {
  return (
    <Stack {...props}>
      <FieldSetProvider namespace={namespace} children={children} />
    </Stack>
  );
};

export default FieldSet;

export const useFieldSet = () => {
  const context = useContext(FieldSetContext);
  if (!context) {
    throw new Error('useFieldSet must be called within a FieldSetProvider');
  }
  return context;
};

export const FieldSetProvider = ({ namespace, children }: FieldSetProps) => {
  const { getValues, setValue } = useFormContext();
  const withNamespace = (name: string) => (namespace ? `${namespace}.${name}` : name);

  const getValuesWithNamespace = () => (namespace ? getValues()[namespace] : getValues());

  const setValueWithNamespace = (name: string, value: any) => {
    setValue(withNamespace(name), value);
  };

  return (
    <FieldSetContext.Provider
      value={{
        namespace,
        getValuesWithNamespace,
        setValueWithNamespace,
        withNamespace,
      }}
    >
      {children}
    </FieldSetContext.Provider>
  );
};
