import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import SecondaryNavigation, { SecondaryNavigationActions, SecondaryNavigationHeading } from '../Navigation/SecondaryNavigation';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { ChevronRightRounded } from '@mui/icons-material';
import { useScope } from '../Scope/useScope';
import { findCategoryScoreDimensionSettings } from '../Category/CategoryScoreDimensionSettings';
import { SecondaryNavigationTabs, useSecondaryNavigationTabs } from '../Navigation/SecondaryNavigationTabs';
import useActiveFeatures from '../Module/useActiveFeatures';
import ScopeSelector from '../Scope/ScopeSelector';
import { CategoryProvider, useCategoryContext } from '../Category/CategoryContext';
import { useCategory } from '../Category/useCategory';
import { CategoryParentProvider } from '../Category/CategoryParentContext';
import useWorkspace from '../Workspace/useWorkspace';
import { Scorable } from '../Scores/Scorable';

const Company = () => {
  const { scorable } = useOutletContext<{ scorable: Scorable }>();
  const { workspacePath } = useWorkspace();
  const category = scorable.categories.find(category => category.type === 'CompanyCategory')!;

  return (
    <CategoryParentProvider parentPath={workspacePath}>
      <CategoryProvider category={category}>
        <CompanySecondaryNavigation />
        <Outlet />
      </CategoryProvider>
    </CategoryParentProvider>
  );
};

const useCompanyTabs = () => {
  const { category } = useCategoryContext();
  const { companyProjectsActive } = useActiveFeatures();

  const dashboardTab = { key: 'dashboard', label: 'Dashboard', path: '' };
  const projectsTab = { key: 'projects', label: 'Projects', path: 'projects' };
  const aspectsTab = { key: 'aspects', label: 'Aspects', path: `modules/${category.modules[0].id}/aspects` };

  const tabs = [dashboardTab];
  if (companyProjectsActive) {
    tabs.push(projectsTab);
  }
  tabs.push(aspectsTab);

  return tabs;
};

const CompanySecondaryNavigation = () => {
  const { categoryPath } = useCategory('CompanyCategory');
  const { scopes } = useScope();
  const scopeOptions = scopes.map(scope => {
    //    const count = scope.key === availableScope.key ? clients.length : undefined;
    return { label: `${scope.selectorPrefix} Scores`, scope: scope, count: undefined };
  });

  const tabs = useCompanyTabs();

  const secondaryNavigationTabsProps = useSecondaryNavigationTabs(tabs, categoryPath);

  const iconColor = findCategoryScoreDimensionSettings('CompanyCategory').color;

  return (
    <SecondaryNavigation>
      <SecondaryNavigationHeading>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Avatar variant='rounded' sx={{ color: '#000', bgcolor: iconColor }}>
            <ApartmentRoundedIcon sx={{ fontSize: '24px' }} />
          </Avatar>
          <Stack direction='row' alignItems='center' sx={{ fontSize: '14px', lineHeight: '1em' }}>
            Company
            <ChevronRightRounded />
            <ScopeSelector options={scopeOptions} />
          </Stack>
        </Stack>
      </SecondaryNavigationHeading>

      <SecondaryNavigationActions>
        <SecondaryNavigationTabs {...secondaryNavigationTabsProps} />
      </SecondaryNavigationActions>
    </SecondaryNavigation>
  );
};

export default Company;
