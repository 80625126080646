import React, { createContext, useState } from 'react';

interface NoticeContextInterface {
  registerNotice: (key: string) => void;
  noticeOpen: (key: string) => boolean;
  closeNotice: (key: string) => void;
}

export const NoticeContext = createContext<NoticeContextInterface>(undefined!);

type NoticeMap = {
  [key: string]: 'open' | 'closed';
};

export const NoticeProvider = ({ children }: { children: React.ReactNode }) => {
  const [notices, setNotices] = useState<NoticeMap>({});

  const registerNotice = (key: string) => {
    if (!notices[key]) {
      setNotices({ ...notices, [key]: 'open' });
    }
  };

  const noticeOpen = (key: string) => {
    return notices[key] === 'open';
  };

  const closeNotice = (key: string) => {
    setNotices({ ...notices, [key]: 'closed' });
  };

  return <NoticeContext.Provider value={{ registerNotice, noticeOpen, closeNotice }}>{children}</NoticeContext.Provider>;
};

export default NoticeContext;
