import { ClientCollector } from '../Client/ClientCollector';
import { ProjectCollector } from '../Projects/ProjectCollector';
import { UserInterface, userSchema } from '../User/User';
import { MembershipInterface } from '../Membership/Membership';
import { ScorableBase } from '../Scores/ScorableBase';
import { z } from 'zod';
import { ChannelConnectable } from '../Chat/ChannelConnectable';

export interface TeamInterface extends ScorableBase, ClientCollector, ProjectCollector, MembershipInterface, ChannelConnectable {
  __typename: 'Team';
  type: 'Team';
  parentTeam: ParentTeamType | null;
  icon: TeamIconType;
  manager?: UserInterface;
  users: UserInterface[];
  ancestors: TeamInterface[];
}

const ParentTeamSchema = z.object({
  id: z.string(),
  name: z.string(),
});
type ParentTeamType = z.infer<typeof ParentTeamSchema>;

export const TeamIconSchema = z.object({
  symbol: z.string().default('people'),
  color: z.string().default('#000000'),
});
export type TeamIconType = z.infer<typeof TeamIconSchema>;

export const TeamSchema = z.object({
  id: z.string(),
  parentTeam: ParentTeamSchema.nullable().default(null),
  name: z.string().min(1, 'Name is required'),
  icon: TeamIconSchema,
  manager: userSchema.nullable().default(null),
  members: z.array(userSchema).default([]),
});
