import React from 'react';
import useWorkspace from '../Workspace/useWorkspace';
import hyveApi from '../util/hyveApi';

export type SlackAuthType = 'app' | 'user';

export type SlackUpdateStatus = 'revoking' | 'error' | 'revoked';

const BASELINE_SLACK_APP_SCOPES = [
  'channels:join', // Join public channels in a workspace
  'channels:manage', // Manage public channels that your slack app has been added to and create new ones
  'channels:read', // View basic information about public channels in a workspace
  'chat:write', // Post messages in approved channels & conversations
  'chat:write.public', // Send messages to channels @your_slack_app isn't a member of
  'im:history', // View messages and other content in direct messages that your slack app has been added to
  'im:read', // View basic information about direct messages that your slack app has been added to
  'im:write', // Start direct messages with people
  'reactions:read', // View emoji reactions and their associated content in channels and conversations that your slack app has been added to
  'reactions:write', // Add and edit emoji reactions
];

const USER_MANAGEMENT_SLACK_APP_SCOPES = [
  'team:read', // View the name, email domain, and icon for workspaces your slack app is connected to
  'users:read', // View people in a workspace
  'users:read.email', // View email addresses of people in a workspace
  'dnd:read', // View Do Not Disturb settings for people in a workspace
];

const CHANNEL_CONNECTION_SLACK_APP_SCOPES = [
  'bookmarks:read', // List bookmarks
  'bookmarks:write', // Create, edit, and remove bookmarks
  'groups:history', // View messages and other content in private channels that your slack app has been added to
  'groups:read', // View basic information about private channels that your slack app has been added to
  'groups:write', // Manage private channels that your slack app has been added to and create new ones
];

const PRIVATE_CHANNEL_CONNECTION_SLACK_USER_SCOPES = [
  'channels:read', // View basic information about public channels in a workspace
  'groups:read', // View basic information about private channels that your slack app has been added to
  'groups:write.invites', // Invite members to private channels
];

const SLACK_SCOPES = {
  app: [...BASELINE_SLACK_APP_SCOPES, USER_MANAGEMENT_SLACK_APP_SCOPES, ...CHANNEL_CONNECTION_SLACK_APP_SCOPES],
  user: PRIVATE_CHANNEL_CONNECTION_SLACK_USER_SCOPES,
};

const generateSlackUrl = (authType: SlackAuthType, state: string, slackRedirectUri: string) => {
  const baseUrl = 'https://slack.com/oauth/v2/authorize';
  const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
  const scopes = SLACK_SCOPES[authType].join(',');
  const userScopeValue = authType === 'user' ? scopes : '';
  const scopeValue = authType === 'app' ? scopes : '';

  return `${baseUrl}?scope=${scopeValue}&user_scope=${userScopeValue}&client_id=${clientId}&state=${encodeURIComponent(
    state
  )}&redirect_uri=${encodeURIComponent(slackRedirectUri)}`;
};

export const useAuthorizeSlack = () => {
  const { workspace } = useWorkspace();
  const postAuthRedirect = window.location.pathname;
  const slackRedirectUri = `${process.env.REACT_APP_SLACK_AUTH_REDIRECT_ORIGIN || window.location.origin}/account/slack_auth`;

  const generateAuthorizeURL = (authType: SlackAuthType) => {
    const state = JSON.stringify({
      workspaceId: workspace.id,
      postAuthRedirect,
      authType,
    });

    return generateSlackUrl(authType, state, slackRedirectUri);
  };

  return {
    authorizeAppScopesURL: generateAuthorizeURL('app'),
    authorizeUserScopesURL: generateAuthorizeURL('user'),
  };
};

export const useRevokeSlackToken = (
  authType: SlackAuthType,
  workspaceId: string,
  refetch: () => Promise<void>,
  setSlackUpdateStatus: React.Dispatch<React.SetStateAction<SlackUpdateStatus | null>>
) => {
  return async () => {
    try {
      setSlackUpdateStatus('revoking');
      const revokeUrl = authType === 'app' ? '/slack/revoke_app' : '/slack/revoke_user';

      await hyveApi.create().post(revokeUrl, { workspace_id: workspaceId });
      await refetch();
      setSlackUpdateStatus('revoked');
    } catch (error) {
      console.error(`Error when revoking: ${error}`);
      setSlackUpdateStatus('error');
    }
  };
};
