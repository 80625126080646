import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import dayjs from 'dayjs';
import 'chartjs-adapter-luxon';
import { Theme, useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, Legend, LinearScale, TimeScale, Tooltip, ChartData, ChartOptions, TooltipItem } from 'chart.js';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { getScoreDeltaInfo } from './Score';
import SimpleMenu, { useMenu } from '../UI/SimpleMenu';
import { useLayout } from '../UI/LayoutContext';
import { useTimeframe } from '../User/useTimeframe';
import { useScope } from '../Scope/useScope';
import { Aspect } from '../Aspect/Aspect';
import { Scorable } from './Scorable';
import { ScoreDimension } from './ScoreDimension';
import Typography from '@mui/material/Typography';

Chart.register(LineElement, PointElement, Legend, LinearScale, TimeScale, Tooltip);

interface HistoricalScoresProps {
  scorable: Scorable;
  scoreDimension: ScoreDimension;
  aspect?: Aspect;
  aspectRatio?: number;
}

type HistoricalScoresDataPoint = {
  day: string;
  health: number | undefined;
  healthDelta: number;
  intelligence: number | undefined;
  intelligenceDelta: number;
};

const HistoricalScores = ({ scorable, scoreDimension, aspect = undefined, aspectRatio = 3 }: HistoricalScoresProps) => {
  const { scope } = useScope();
  const theme = useTheme();
  const layout = useLayout();
  const { days, setDays } = useTimeframe();
  const { open, setOpen, onClose, anchorRef } = useMenu<HTMLButtonElement>();
  const [chartData, setChartData] = useState(getChartData([], days, theme));

  if (layout === 'mobile') {
    aspectRatio = 2;
  }

  const variables = scorable
    ? {
        scorable: { type: scorable.__typename, id: scorable.id },
        scorableContainerId: scorable !== scoreDimension ? scoreDimension.id : undefined,
        ...scope.variables,
        aspectIds: aspect ? [aspect.id] : [],
        days: days,
      }
    : {};

  const { data, error } = useQuery<{ historicalScores: HistoricalScoresDataPoint[] }>(QUERY, { variables, skip: !scorable });

  useEffect(() => {
    setChartData(getChartData(data?.historicalScores || [], days, theme));
  }, [data, days, theme]);

  if (error) {
    console.error(error);
  }

  const chartOptions = getChartOptions(days, theme, aspectRatio);

  const scoreDelta = data?.historicalScores[0]?.healthDelta || 0;
  const { scoreDeltaIconName, scoreDeltaSign } = getScoreDeltaInfo(scoreDelta);

  return (
    <Stack spacing={2} width='100%'>
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <Stack alignItems='center' direction='row'>
          <Icon sx={{ marginRight: '6px' }}>{scoreDeltaIconName}</Icon>
          <Typography fontSize={16} fontWeight='bold' marginRight='6px'>
            Trend{' '}
          </Typography>
          <Typography>
            ({scoreDeltaSign}
            {scoreDelta}%)
          </Typography>
        </Stack>
        <Stack position='absolute' right={0} alignItems='center' fontSize='12px'>
          <Button
            sx={{ textTransform: 'none', cursor: 'pointer' }}
            ref={anchorRef}
            size='small'
            endIcon={<Icon sx={{ lineHeight: '1.2em', marginLeft: 0 }}>arrow_drop_down</Icon>}
            className='days-selector'
            onClick={() => setOpen(true)}
          >
            {days} Days
          </Button>
        </Stack>
      </Stack>
      <SimpleMenu open={open} onClose={onClose} anchorEl={anchorRef.current}>
        <MenuItem onClick={() => setDays(30)}>30 Days</MenuItem>
        <MenuItem onClick={() => setDays(90)}>90 Days</MenuItem>
        <MenuItem onClick={() => setDays(180)}>180 Days</MenuItem>
      </SimpleMenu>
      <Stack direction='row' width='100%' height='200px'>
        {/*position absolute to prevent the canvas from affecting the container size*/}
        <Line style={{ position: 'absolute', width: '100%', height: '100%' }} data={chartData} options={chartOptions} key={layout} />
      </Stack>
    </Stack>
  );
};

type DatePoint = {
  x: string;
  y: number | undefined;
};

const getChartData = (queryData: HistoricalScoresDataPoint[], days: number, theme: Theme): ChartData<'line', DatePoint[]> => {
  const labels = Array(days)
    .fill(null)
    .map((_, index) => dayjs().subtract(index, 'day').format('YYYY-MM-DD'));

  const scoreData: DatePoint[] = queryData.map(entry => ({ x: entry.day, y: entry.health }));

  //  const scoreData = [
  //    { x: '2023-12-10', y: 80 },
  //    { x: '2023-12-09', y: 60 },
  //    { x: '2023-12-08', y: 40 },
  //   ];

  const intelligenceData: DatePoint[] = queryData.map(entry => ({ x: entry.day, y: entry.intelligence }));

  //  const intelligenceData = [
  //    { x: '2023-12-10', y: 80 },
  //    { x: '2023-12-09', y: 60 },
  //    { x: '2023-12-08', y: 40 },
  //  ];

  return {
    labels: labels,
    datasets: [
      {
        label: 'Score',
        data: scoreData,
        pointRadius: 0,
        backgroundColor: theme.custom.chart.healthColor,
        borderColor: theme.custom.chart.healthColor,
        borderWidth: 1.5,
        cubicInterpolationMode: 'monotone',
        tension: 1,
      },
      {
        label: 'Intelligence',
        data: intelligenceData,
        pointRadius: 0,
        backgroundColor: theme.custom.chart.intelligenceColor,
        borderColor: theme.custom.chart.intelligenceColor,
        borderWidth: 1.5,
        borderDash: [2, 2],
        cubicInterpolationMode: 'monotone',
        tension: 1,
      },
    ],
  };
};

const getChartOptions = (days: number, theme: Theme, aspectRatio: number): ChartOptions<'line'> => {
  let unit: 'day' | 'year';
  let stepSize: number;
  if (days === 30) {
    stepSize = 7;
    unit = 'day';
  } else if (days === 90) {
    stepSize = 14;
    unit = 'day';
  } else if (days === 180) {
    stepSize = 1;
    unit = 'year';
  } else {
    throw new Error(`Unexpected historical days: ${days}`);
  }

  return {
    aspectRatio: aspectRatio,
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    layout: {
      padding: {
        top: 2,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 30,
          boxHeight: 0,
          color: theme.custom.chart.legend.labelColor,
          font: {
            size: 12,
          },
          usePointStyle: true,
          pointStyle: 'line',
        },
      },
      tooltip: {
        // position: 'nearest',
        // bodySpacing: 10,
        padding: 10,
        displayColors: false,
        backgroundColor: theme.custom.chart.tooltip.backgroundColor,
        bodyColor: theme.custom.chart.tooltip.color,
        titleColor: theme.custom.chart.tooltip.color,
        callbacks: {
          label: (e: TooltipItem<'line'>) => {
            const data: ChartData = e.chart.data;
            const dataSets = data.datasets;
            // @ts-ignore
            const [score, intelligence] = [dataSets[0].data[e.dataIndex].y, dataSets[1].data[e.dataIndex].y];
            const labels = [`Score: ${score}`, `Intelligence: ${intelligence}`];

            // When score and intelligence are equal, only show labels for the first dataset.
            if (score === intelligence && e.datasetIndex !== 0) {
              return [];
            }

            return labels;
          },
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },

    scales: {
      // backgroundColor: 'red',
      // ticks: {
      //   display: false
      // },

      x: {
        type: 'time',
        time: {
          unit: unit,
          // parser: 'YYYY-MM-DD',
          displayFormats: {
            month: 'MMM D',
            quarter: 'MMM YY',
          },
          tooltipFormat: 'DDD',
        },
        ticks: {
          color: theme.custom.chart.tickColor,
          font: {
            size: 10,
          },
          stepSize: stepSize,
          // position: 'center',
          // offset: true,
          // autoSkip: false,
          // align: 'end',
          // padding: 20
        },
        grid: {
          drawOnChartArea: false,
          lineWidth: 2,
          color: theme.custom.chart.gridColor,
          tickColor: theme.custom.chart.gridColor,
        },
      },
      y: {
        min: 0,
        max: 100,
        beginAtZero: false,
        ticks: {
          count: 5,
          color: theme.custom.chart.tickColor,
          font: {
            size: 10,
          },
        },
        // offset: true,
        grid: {
          lineWidth: 2,
          //          borderDash: [2, 2],
          color: theme.custom.chart.gridColor,
          tickColor: theme.custom.chart.gridColor,
        },
      },
    },
  };
};

export default HistoricalScores;

const QUERY = gql`
  query HistoricalScores($scorable: ScorableInput!, $scorableContainerId: ID, $scope: ScopeInput, $aspectIds: [ID!], $days: Int!) {
    historicalScores(scorable: $scorable, scorableContainerId: $scorableContainerId, scope: $scope, aspectIds: $aspectIds, days: $days, deltaReference: $days) {
      day
      health
      intelligence
      healthDelta
      intelligenceDelta
    }
  }
`;
