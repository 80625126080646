import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { z } from 'zod';

import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

import ClientFields, { clientSchema } from '../Client/ClientFields';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { useWorkspace } from '../Workspace/useWorkspace';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { CLIENT_FIELDS_FRAGMENT, ClientInterface } from './ClientInterface';
import FieldSet from '../UI/FieldSet';
import { userSelectOrInviteEmailSchema } from '../User/User';

const newClientSchema = clientSchema.omit({ id: true }).extend({
  users: z.array(userSelectOrInviteEmailSchema),
});

type NewClientValues = z.infer<typeof newClientSchema>;

type NewClientDialogProps = {
  open: boolean;
  onClose: () => void;
};

type CreateClientData = {
  createClient: {
    client: ClientInterface;
  };
};

const NewClientDialog = ({ open, onClose }: NewClientDialogProps) => {
  const defaultValues = { name: '', imageUrl: '', domain: '', users: [] };
  const { workspace, refetch } = useWorkspace();
  const { setSuccess } = useGlobalMessage();
  const navigate = useNavigate();
  const formDialogProps = useFormDialog<NewClientValues>({ schema: newClientSchema, defaultValues });
  const { handleResponse } = formDialogProps;

  const [createClient, { error }] = useMutation<CreateClientData>(CREATE_CLIENT_MUTATION);

  const handleSubmit = async (values: NewClientValues) => {
    values.domain = values.domain || null;
    // console.log(values);
    const verticalId = values.vertical?.id;
    const subVerticalId = values.subVertical?.id;

    const response = await createClient({
      variables: {
        workspaceId: workspace.id,
        verticalId,
        subVerticalId,
        ...values,
      },
    });
    //    console.log(response);
    const success = handleResponse(response, 'createClient', 'Uh oh. There was an error adding the client');
    if (success) {
      setSuccess('Added client');
      navigate(`/workspace/${workspace.id}/clients/${response.data!.createClient.client.id}`);
      await refetch();
    }
    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='new-client'
      icon={<BusinessRoundedIcon />}
      title='New Client'
      subtitle='Enter the information below to add a client to your workspace.'
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitLabel='Add Client'
      mutationError={error}
    >
      <FieldSet>
        <ClientFields />
      </FieldSet>
    </FormDialog>
  );
};

export default NewClientDialog;

const CREATE_CLIENT_MUTATION = gql`
  mutation CreateClient($workspaceId: ID!, $name: String!, $imageUrl: String, $domain: String, $importance: Int, $verticalId: ID, $subVerticalId: ID) {
    createClient(
      workspaceId: $workspaceId
      name: $name
      imageUrl: $imageUrl
      domain: $domain
      importance: $importance
      verticalId: $verticalId
      subVerticalId: $subVerticalId
    ) {
      client {
        ...ClientFields
      }
      errors {
        type
        fullMessage
      }
    }
  }
  ${CLIENT_FIELDS_FRAGMENT}
`;
