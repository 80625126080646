import React from 'react';
import { useMutation } from '@apollo/client';

import BusinessIcon from '@mui/icons-material/Business';

import ClientFields, { clientSchema } from '../Client/ClientFields';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { UPDATE_CLIENT_MUTATION, ClientInterface } from './ClientInterface';
import { AppDialogProps } from '../UI/StyledDialog';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { z } from 'zod';
import FieldSet from '../UI/FieldSet';

type EditClientValues = z.infer<typeof clientSchema>;

interface EditClientDialogProps extends AppDialogProps {
  client: ClientInterface;
}

const EditClientDialog = ({ client, open, onClose }: EditClientDialogProps) => {
  // console.log(client);
  const defaultValues = clientSchema.parse(client);
  const formDialogProps = useFormDialog<EditClientValues>({ schema: clientSchema, defaultValues });
  const { handleResponse } = formDialogProps;
  const { setSuccess } = useGlobalMessage();

  const [updateClient, { error }] = useMutation(UPDATE_CLIENT_MUTATION);

  const handleSubmit = async (values: EditClientValues) => {
    // console.log(values);
    const { name, importance } = values;
    const imageUrl = values.imageUrl || null;
    const domain = values.domain || null;
    const verticalId = values.vertical?.id || null;
    const subVerticalId = values.subVertical?.id || null;

    const response = await updateClient({
      variables: {
        id: client.id,
        name,
        imageUrl,
        domain,
        importance,
        verticalId,
        subVerticalId,
      },
    });

    const success = handleResponse(response, 'updateClient', 'Uh oh. There was an error editing the client.');
    if (success) {
      setSuccess('Updated client');
    }

    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='edit-client'
      open={open}
      onClose={onClose}
      icon={<BusinessIcon />}
      title='Edit Client'
      subtitle="Enter the information below to update your client's information."
      submitLabel='Save'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <FieldSet>
        <ClientFields />
      </FieldSet>
    </FormDialog>
  );
};

export default EditClientDialog;
