import { Archivable } from '../Archive/Archivable';
import { ClientInterface } from '../Client/ClientInterface';
import { ScorableBase } from '../Scores/ScorableBase';
import { MembershipInterface } from '../Membership/Membership';
import { UserInterface } from '../User/User';
import { gql } from '@apollo/client';
import { ProjectStatus } from './ProjectStatus';
import { CHANNEL_CONNECTIONS_FRAGMENT, ChannelConnectable } from '../Chat/ChannelConnectable';

export interface ProjectInterface extends ScorableBase, MembershipInterface, Archivable, ChannelConnectable {
  __typename: 'Project';
  type: 'Project';
  description: string;
  importance: number | null;
  client: ClientInterface;
  status: ProjectStatus;
  member: boolean;
  owners: UserInterface[];
  users: UserInterface[];
  startDate?: string;
  endDate?: string;
}

export const PROJECT_FIELDS_FRAGMENT = gql`
  fragment ProjectFields on Project {
    id
    name
    description
    startDate
    endDate
    importance
    status
    archived
    member
    ...ChannelConnections
  }
  ${CHANNEL_CONNECTIONS_FRAGMENT}
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $id: ID!
    $name: String!
    $description: String
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $importance: Int
    $client: ClientInput!
    $userIds: [ID!]
  ) {
    updateProject(
      id: $id
      name: $name
      description: $description
      startDate: $startDate
      endDate: $endDate
      importance: $importance
      client: $client
      userIds: $userIds
    ) {
      project {
        ...ProjectFields
        users {
          id
        }
      }
      errors {
        fullMessages
      }
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;
