import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { THEME_DEFAULTS } from './AppTheme';

const DARK_GARBAGE = {
  // backgroundColor: '#111',
  paragraphColor: '#aaa', // TODO deprecate
  subsection: {
    contained: {
      backgroundColor: '#111',
    },
  },
  button: {
    // todo move to palette
    text: {
      color: '#aaa',
      hover: {
        color: '#fff',
        backgroundColor: '#333',
      },
    },
  },
  custom: {
    chart: {
      // TODO move to custom component
      healthColor: '#fff',
      intelligenceColor: '#aaa',
      gridColor: '#333',
      tickColor: '#fff',
      backgroundColor: '#444',
      legend: {
        labelColor: '#aaa',
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000',
      },
    },
  },
  card: {
    // TODO move to custom component
    backgroundColor: '#1a1a1a',
    backgroundHoverColor: '#252525',
    button: {
      backgroundColor: '#252525',
      backgroundHoverColor: '#333',
    },
  },
  score: {
    // TODO move to custom component
    hover: {
      backgroundColor: '#333',
    },
    bar: {
      backgroundColor: '#333',
    },
  },
};

const DARK_OPTIONS = deepmerge(
  {
    name: 'Dark',
    mode: 'dark',

    palette: {
      mode: 'dark',
      action: {
        hover: 'rgba(255, 255, 255, 0.04)',
      },
      primary: {
        main: '#fff',
      },
      subtle: {
        main: 'rgba(255, 255, 255, 0.08)',
      },
      //    secondary: {
      //      main: '#0FF',
      //    },
      background: {
        default: '#111',
        paper: '#1d1d1d',
      },
      text: {
        primary: '#fff',
      },
      body2: {
        color: '#aaa',
      },
      divider: 'rgb(255, 255, 255, 0.08)',
    },

    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&:hover': {
              //            opacity: 0.9,
              backgroundColor: 'rgb(255, 255, 255, 0.04)',
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            color: '#000',
            backgroundColor: '#fff',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            color: '#fff',
          },
          columnHeader: {
            color: '#fff',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#111',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.MuiPaper-elevation2': {
              backgroundColor: '#161616',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#fff  ',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: 'rgb(255, 255, 255, 0.08)',
            },
          },
        },
      },
    },
  },
  DARK_GARBAGE
);

export const DARK_THEME = createTheme(deepmerge(THEME_DEFAULTS, DARK_OPTIONS));
