import CategoryDashboard, { useCategoryDashboardData } from '../Category/CategoryDashboard';
import ClientsScoreBreakdown from '../Clients/ClientsScoreBreakdown';
import React from 'react';
import { TertiaryNavigationTab } from '../Navigation/TertiaryNavigationTabs';
import { useScope } from '../Scope/useScope';
import { Category } from '../Category/Category';
import { MembershipDashboardStats, PersonalDashboardStats } from '../Dashboard/DashboardStats';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { TeamInterface } from '../Team/TeamSchema';
import { useOutletContext } from 'react-router-dom';
import { Scorable } from '../Scores/Scorable';

interface ClientsCategoryDashboardProps {
  additionalTabs?: TertiaryNavigationTab[];
}

const ClientsCategoryDashboard = () => {
  const { additionalTabs } = useOutletContext<ClientsCategoryDashboardProps>();

  const { scope } = useScope();
  const data = useCategoryDashboardData('ClientsCategory');
  if (!data) {
    return null;
  }

  const { scorable, category } = data;
  const clientCount = scorable.clientStats.count;

  const breakdownTitle = `${scope.prefix} Clients (${clientCount})`;
  const stats = <ClientsDashboardStats scorable={scorable} category={category} />;

  // {layout !== 'mobile' && <Button variant='outlined' component={RouterLink} to={listPath} >View Clients</Button>}

  return (
    <CategoryDashboard scorable={scorable} category={category} additionalTabs={additionalTabs} stats={stats} detailsTitle={breakdownTitle}>
      <ClientsScoreBreakdown scoreDimension={category} title={breakdownTitle} />
    </CategoryDashboard>
  );
};

interface ClientsDashboardStatsProps {
  scorable: Scorable;
  category: Category;
}

const ClientsDashboardStats = ({ scorable, category }: ClientsDashboardStatsProps) => {
  const { scope } = useScope();

  if (scope.type === 'Personal') {
    return <PersonalDashboardStats scorable={scorable} scoreDimension={category} intelligenceLabel='My Clients Intelligence' />;
  } else if (scope.type === 'Unscoped') {
    const workspace = scorable as WorkspaceInterface;
    return <MembershipDashboardStats scorable={workspace} scoreDimension={category} intelligenceLabel='Clients Intelligence' />;
  } else if (scope.type === 'Team') {
    const team = scorable as TeamInterface;
    return <MembershipDashboardStats scorable={team} scoreDimension={category} intelligenceLabel='Team Clients Intelligence' />;
  } else {
    return null;
  }
};

export default ClientsCategoryDashboard;
