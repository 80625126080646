import React, { useState, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { useQuery, gql } from '@apollo/client';
import { Avatar, Button, Container, Icon, IconButton, ListItemIcon, MenuItem, Stack, TextField, Typography } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MySettings from './MySettings';
import EditPasswordDialog from '../Account/EditPasswordDialog';
import EditProfileDialog from '../Account/EditProfileDialog';
import PageStyle from '../UI/PageStyle';
import ProfileWorkspaceSettings from './ProfileWorkspaceSettings';
import SimpleMenu, { useMenu } from '../UI/SimpleMenu';
import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import hyveApi from '../util/hyveApi';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { useLayout } from '../UI/LayoutContext';
import { useWorkspace } from '../Workspace/useWorkspace';
import useAuthenticatedUser from '../User/useAuthenticatedUser';

const Profile = () => {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  const { workspace } = useWorkspace();
  const {
    user: { id: userId },
  } = useAuthenticatedUser();
  const { data, loading, refetch } = useQuery(WORKSPACE_USER_QUERY, {
    variables: {
      workspaceId: workspace.id,
      userId: userId,
    },
  });

  if (!data && loading) {
    return <PageStyle design='custom' loading={true} />;
  }

  const user = data.workspace.user;
  const { firstName, lastName, email } = user;

  return (
    <StyledProfile>
      <PageStyle design='standard'>
        <Stack alignItems='center'>
          <ProfileImage />

          <Stack className='profile-name' direction='row' alignItems='center'>
            <Typography variant='h2'>
              {firstName} {lastName}
            </Typography>
            <IconButton
              id='edit-name-button'
              edge='end'
              size='medium'
              color='primary'
              aria-label='upload picture'
              component='span'
              onClick={() => setProfileDialogOpen(true)}
            >
              <EditRoundedIcon fontSize='small' />
            </IconButton>
          </Stack>

          <Typography variant='subtitle2'>{email}</Typography>
        </Stack>

        <EditProfileDialog open={profileDialogOpen} onClose={() => setProfileDialogOpen(false)} />

        <AppSurface>
          <SectionHeader>
            <SectionHeaderIcon>
              <Icon>lock</Icon>
            </SectionHeaderIcon>
            <SectionHeaderTitle>Password</SectionHeaderTitle>
            <SectionHeaderActions>
              <Button onClick={() => setPasswordDialogOpen(true)}>Edit</Button>
              <EditPasswordDialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)} user={user} />
            </SectionHeaderActions>
          </SectionHeader>

          <Container>
            <form>
              <div className='form-row' />
              <div className='form-row' />
              <div className='form-row'>
                <TextField variant='outlined' disabled label='Password' value='••••••••••••••••' fullWidth />
              </div>
            </form>
          </Container>
        </AppSurface>

        <MySettings user={user} refetch={refetch} />

        <ProfileWorkspaceSettings />
      </PageStyle>
    </StyledProfile>
  );
};

interface ApiResponse {
  success: boolean;
  details: string[];
}

const ProfileImage = () => {
  const { user, refetch } = useAuthenticatedUser();
  const { avatar } = user;
  const { open, setOpen, onClose, anchorRef } = useMenu<HTMLButtonElement>();
  const layout = useLayout();

  const { setError } = useGlobalMessage();

  const handleSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      await uploadPhoto(file);
    }
  };

  const uploadPhoto = async (file: File) => {
    const formData = new FormData();
    formData.append('avatar', file);

    //    for (let key of formData.keys()) {
    //      console.log(key, formData.get(key));
    //    }

    try {
      const response = await hyveApi.create({ isFormData: true }).post<ApiResponse>(`/user/avatar`, formData);

      if (response.data.success) {
        await refetch();
      } else {
        console.error(response);
        newrelic.noticeError(new Error('Failed to upload photo'), { details: response.data.details });
        setError('Failed to upload photo: ' + response.data.details.join(', '));
      }
    } catch (error: any) {
      console.error(error);
      newrelic.noticeError(error);
      if (!error.response) {
        // Network error or no response from the server
        setError('Network error: Unable to connect to the server.');
      } else if (error.response.status >= 500) {
        // Server-side error
        setError(`Server error: Please try again later.`);
      } else if (error.response.status === 413) {
        // File too large
        setError('File too large: Please choose a smaller photo.');
      } else {
        // Other client-side errors
        setError('Upload failed: ' + error.response.data.details.join(', '));
      }
    }
  };

  const removePhoto = async () => {
    try {
      const response = await hyveApi.create().delete(`/user/avatar`);
      if (response.data.success) {
        await refetch();
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='user-avatar'>
      {user.avatar ? (
        <Fragment>
          <Button ref={anchorRef} onClick={() => setOpen(true)}>
            <Avatar data-cy='profile-photo' className='photo' src={avatar} sx={{ width: '100px', height: '100px' }} />
          </Button>

          {layout !== 'mobile' && (
            <IconButton ref={anchorRef} size='small' className='avatar-edit-button' onClick={() => setOpen(true)}>
              <EditRoundedIcon fontSize='small' />
            </IconButton>
          )}

          <SimpleMenu open={open} onClose={onClose} anchorEl={anchorRef.current}>
            <label htmlFor='replace-photo'>
              <MenuItem key={'upload-photo'}>
                <ListItemIcon>
                  <Icon>portrait</Icon>
                </ListItemIcon>
                Upload Photo
              </MenuItem>
            </label>
            <MenuItem key={'remove-photo'} onClick={() => removePhoto()}>
              <ListItemIcon>
                <Icon>delete</Icon>
              </ListItemIcon>
              Remove Photo
            </MenuItem>
          </SimpleMenu>

          <input style={{ display: 'none' }} id='replace-photo' type='file' accept='image/*' multiple={false} onChange={handleSelectImage} />
        </Fragment>
      ) : (
        <ProfileImageSelector onSelectImage={handleSelectImage} />
      )}
    </div>
  );
};

interface ProfileImageSelectorProps {
  onSelectImage: (event: any) => Promise<void>;
}

const ProfileImageSelector = ({ onSelectImage }: ProfileImageSelectorProps) => {
  return (
    <Fragment>
      <input style={{ display: 'none' }} id='contained-button-file' type='file' accept='image/*' multiple={false} onChange={onSelectImage} />
      <label htmlFor='contained-button-file'>
        <Stack
          data-cy='add-photo'
          className='add-photo photo'
          width='100px'
          height='100px'
          alignItems='center'
          justifyContent='center'
          sx={{
            borderRadius: '50%',
            color: '#000',
          }}
        >
          <Icon>add_circle</Icon>
          <Typography>Add Photo</Typography>
        </Stack>
      </label>
    </Fragment>
  );
};

const StyledProfile = styled('div')`
  .user-avatar {
    position: relative;
    .avatar-edit-button {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 7px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      &:hover {
        background-color: #000;
      }
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }

  .add-photo {
    background-color: ${props => props.theme.palette.secondary.main};

    &:hover {
      color: #fff;
      background-color: #000;
      cursor: pointer;
    }
  }
`;

export default Profile;

const WORKSPACE_USER_QUERY = gql`
  query WorkspaceUser($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      user {
        id
        firstName
        lastName
        email
        officeType
        timeZone
        theme
        hasSlackUserAccess
      }
    }
  }
`;
