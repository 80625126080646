import CategoryDashboard, { useCategoryDashboardData } from '../Category/CategoryDashboard';
import { PersonalCategoryDetails } from '../Category/CategoryDashboardDetails';
import { PersonalDashboardStats, MembershipDashboardStats } from '../Dashboard/DashboardStats';
import { useScope } from '../Scope/useScope';
import { TeamInterface } from '../Team/TeamSchema';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { GroupEmployeeExperienceDetails } from './EmployeeExperienceDashboardCategory';

const EmployeeExperienceCategoryDashboard = () => {
  const data = useCategoryDashboardData('EmployeeExperienceCategory');
  const { scope } = useScope();

  if (!data) {
    return null;
  }

  const { scorable, category } = data;

  let stats;
  let details;

  if (scope.type === 'Personal') {
    stats = <PersonalDashboardStats scorable={scorable} scoreDimension={category} intelligenceLabel='My Experience Intelligence' />;
    details = <PersonalCategoryDetails category={category} />;
  } else if (scope.type === 'Unscoped') {
    const workspace = scorable as WorkspaceInterface;
    stats = <MembershipDashboardStats scorable={workspace} scoreDimension={category} intelligenceLabel='Experience Intelligence' />;
    details = <GroupEmployeeExperienceDetails scorable={scorable} category={category} />;
  } else if (scope.type === 'Team') {
    const team = scorable as TeamInterface;
    stats = <MembershipDashboardStats scorable={team} scoreDimension={category} intelligenceLabel='Team Intelligence' />;
    details = <GroupEmployeeExperienceDetails scorable={scorable} category={category} />;
  }

  return (
    <CategoryDashboard scorable={scorable} category={category} stats={stats}>
      {details}
    </CategoryDashboard>
  );
};

export default EmployeeExperienceCategoryDashboard;
