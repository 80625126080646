import React from 'react';
import CategoryDashboard, { useCategoryDashboardData } from '../Category/CategoryDashboard';
import { MembershipDashboardStats, PersonalDashboardStats } from '../Dashboard/DashboardStats';
import { useWorkspace } from '../Workspace/useWorkspace';
import { CompanyInterface } from './CompanyInterface';
import { useScope } from '../Scope/useScope';
import { TeamInterface } from '../Team/TeamSchema';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { Category } from '../Category/Category';
import MemberScoreBreakdown from '../Membership/MemberScoreBreakdown';
import { PersonalCategoryDetails } from '../Category/CategoryDashboardDetails';
import { Scorable } from '../Scores/Scorable';

const CompanyCategoryDashboard = () => {
  const { workspace } = useWorkspace();
  const { scope } = useScope();

  const data = useCategoryDashboardData('CompanyCategory');
  if (!data) {
    return null;
  }
  const { scorable, category } = data;

  let details;
  if (scope.type === 'Personal') {
    details = <PersonalCategoryDetails category={category} />;
  } else {
    details = <MemberScoreBreakdown scorable={scorable} scoreDimension={category} />;
  }

  return (
    <CategoryDashboard
      scorable={scorable}
      category={category}
      stats={<CompanyDashboardStats scorable={scorable} category={category} company={workspace.company} />}
    >
      {details}
    </CategoryDashboard>
  );
};

interface CompanyDashboardStatsProps {
  scorable: Scorable;
  category: Category;
  company: CompanyInterface;
}

const CompanyDashboardStats = ({ scorable, category, company }: CompanyDashboardStatsProps) => {
  const { scope } = useScope();

  if (scope.type === 'Personal') {
    return <PersonalDashboardStats scorable={scorable} scoreDimension={category} intelligenceLabel='My Company Intelligence' />;
  } else if (scope.type === 'Unscoped') {
    const workspace = scorable as WorkspaceInterface;
    return <MembershipDashboardStats scorable={workspace} scoreDimension={category} intelligenceLabel='Company Intelligence' />;
  } else if (scope.type === 'Team') {
    const team = scorable as TeamInterface;
    return <MembershipDashboardStats scorable={team} scoreDimension={category} intelligenceLabel='Team Company Intelligence' />;
  } else {
    return null;
  }
};

export default CompanyCategoryDashboard;
