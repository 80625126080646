import ClientsDashboardCategory from '../Clients/ClientsDashboardCategory';
import ExployeeExperienceDashboardSummary from '../EmployeeExperience/EmployeeExperienceDashboardCategory';
import CompanyDashboardCategory from '../Company/CompanyDashboardCategory';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { CategoryType } from './Category';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { ScoreDimensionSettings } from '../Scores/ScoreDimensionSettings';

export interface CategoryScoreDimensionSettings extends ScoreDimensionSettings {
  path: string;
  color: string;
}

export const CLIENTS_CATEGORY_SCORE_DIMENSION_SETTINGS: CategoryScoreDimensionSettings = {
  name: 'Clients',
  type: 'ClientsCategory',
  path: 'clients',
  icon: CorporateFareRoundedIcon,
  color: '#ffeba3',
  DashboardComponent: ClientsDashboardCategory,
};

export const EMPLOYEE_EXPERIENCE_CATEGORY_SCORE_DIMENSION_SETTINGS: CategoryScoreDimensionSettings = {
  name: 'Employee Experience',
  type: 'EmployeeExperienceCategory',
  path: 'employee-experience',
  icon: AutoAwesomeRoundedIcon,
  color: '#b6e0e1',
  DashboardComponent: ExployeeExperienceDashboardSummary,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        'Your employee experience score indicates a high level of satisfaction and a strong connection to your organization. Check the aspects below for more insight into what is driving your score and areas that may need a little attention.',
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        'Your employee experience score indicates a moderate level of satisfaction and connection to your organization. Check the aspects below for more insight into what is driving your score and areas that may need some attention.',
    },
    low: {
      headline: 'Employee experience is low',
      description:
        'Your employee experience score indicates a low level of satisfaction and a weak connection to your organization. Check the aspects below for more insight into what is driving your scores and areas that need special attention.',
    },
    collecting: {
      headline: 'Collecting',
      description: "There isn't enough intelligence to display your employee experience score.",
    },
    unavailable: {
      headline: 'Company assessment breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

export const COMPANY_CATEGORY_SCORE_DIMENSION_SETTINGS: CategoryScoreDimensionSettings = {
  name: 'Company',
  type: 'CompanyCategory',
  path: 'company',
  icon: ApartmentRoundedIcon,
  color: '#ffcc95',
  DashboardComponent: CompanyDashboardCategory,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        'Your score indicates a high level of satisfaction for your company assessment. Check the Company aspects below for more insight into what is driving your score and areas that can be improved.',
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        'Your score indicates a moderate level of satisfaction for your company assessment. Check the Company aspects below for more insight into what is driving your score and areas that need attention.',
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        'Your score indicates a low level of satisfaction for your company assessment. Check the Company aspects below for more insight into what is driving your score and areas where you need to take action.',
    },
    collecting: {
      headline: 'Collecting Company assessment intelligence',
      description: "There isn't enough intelligence to display your Company score.",
    },
    unavailable: {
      headline: 'Company assessment breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

export const CATEGORY_SCORE_DIMENSION_SETTINGS: CategoryScoreDimensionSettings[] = [
  CLIENTS_CATEGORY_SCORE_DIMENSION_SETTINGS,
  EMPLOYEE_EXPERIENCE_CATEGORY_SCORE_DIMENSION_SETTINGS,
  COMPANY_CATEGORY_SCORE_DIMENSION_SETTINGS,
];

export const findCategoryScoreDimensionSettings = (categoryType: CategoryType) => {
  return CATEGORY_SCORE_DIMENSION_SETTINGS.find(uiCategory => uiCategory.type === categoryType)!;
};
