import { useQuery, gql } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, ListItem, ListItemIcon, ListItemText, Stack, TextField } from '@mui/material';

import ManagerSelectorField from '../Membership/ManagerSelectorField';
import TeamIcon from '../Team/TeamIcon';
import { symbols, colors } from './TeamIcon';
import { TEAM_FIELDS_FRAGMENT } from './TeamType';
import { UserInterface } from '../User/User';
import IconSelect from '../UI/IconSelect';
import { useWorkspace } from '../Workspace/useWorkspace';
import { TeamInterface } from './TeamSchema';
import MemberSelect, { useMemberSelect } from '../Membership/MemberSelect';

type TeamsData = {
  workspace: {
    teams: TeamInterface[];
    users: UserInterface[];
  };
};

export type TeamFieldsMode = 'new' | 'edit';

export const TeamFields = ({ mode }: { mode: TeamFieldsMode }) => {
  const { workspace } = useWorkspace();
  const { control, getValues, watch } = useFormContext();
  const members = watch('members');
  const { workspaceUsers, loading } = useMemberSelect();

  const { data } = useQuery<TeamsData>(TEAMS_USERS_QUERY, { variables: { id: workspace.id } });

  const teams = data?.workspace.teams || [];
  const users = data?.workspace.users || [];

  return (
    <Stack width='100%' spacing={2}>
      <ParentTeamSelector teams={teams} />

      <Controller
        name='name'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} id='name' name='name' variant='outlined' fullWidth label='Enter a team name' error={!!error} helperText={error?.message} />
        )}
      />

      <Controller name='icon' control={control} render={({ field }) => <IconSelect {...field} symbols={symbols} colors={colors} value={getValues('icon')} />} />

      {mode === 'new' && (
        <Controller
          name='manager'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <ManagerSelectorField {...field} users={users} members={members} allowInvites={false} error={!!error} helperText={error?.message} />
          )}
        />
      )}

      {mode === 'new' && (
        <Controller
          name='members'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <MemberSelect
              {...field}
              loading={loading}
              error={error}
              options={workspaceUsers}
              disabledOptions={workspaceUsers.filter(user => user.team !== null)}
              allowInvites={false}
            />
          )}
        />
      )}
    </Stack>
  );
};

const ParentTeamSelector = ({ teams }: { teams: TeamInterface[] }) => {
  const { control, getValues, setValue } = useFormContext();
  const { id } = getValues();

  return (
    <Controller
      name='parentTeam'
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          // id='parentTeam'
          {...field}
          value={field.value || null}
          options={teams.filter(parentTeam => parentTeam.id !== id)}
          getOptionDisabled={(option: TeamInterface) => option.ancestors.map(ancestor => ancestor.id).includes(id)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option?.name}
          autoHighlight
          openOnFocus={false}
          renderOption={(props, team: TeamInterface) => {
            return (
              <ListItem {...props} key={team.id} >
                <ListItemIcon>
                  <TeamIcon icon={team.icon} />
                </ListItemIcon>
                <ListItemText primary={team.name} />
              </ListItem>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              name={'parentTeam'}
              variant='outlined'
              fullWidth
              label='Select a parent team (optional)'
              error={!!error}
              helperText={error?.message}
            />
          )}
          // onInputChange={handleParent}
          onChange={(_: any, value: TeamInterface | null) => setValue('parentTeam', value)}
        />
      )}
    />
  );
};

const TEAMS_USERS_QUERY = gql`
  query Workspace($id: ID!, $scope: ScopeInput) {
    workspace(id: $id, scope: $scope) {
      id
      teams {
        ...TeamFields
      }
      users {
        id
        email
        firstName
        lastName
        avatar
        pendingInvitation
        team {
          id
          name
        }
      }
    }
  }
  ${TEAM_FIELDS_FRAGMENT}
`;

export default TeamFields;
