import { useWorkspace } from '../Workspace/useWorkspace';
import { Category, CategoryType } from './Category';
import { CategoryScoreDimensionSettings, findCategoryScoreDimensionSettings } from './CategoryScoreDimensionSettings';
import { Scorable } from '../Scores/Scorable';

export const useCategory = (
  categoryType: CategoryType
): {
  category: Category;
  active: boolean;
  categoryPath: string;
  categoryComponentSettings: CategoryScoreDimensionSettings;
} => {
  const { workspace } = useWorkspace();

  const category = getCategory(workspace, categoryType);
  const categoryComponentSettings = findCategoryScoreDimensionSettings(categoryType);
  const categoryPath = `/workspace/${workspace.id}/${categoryComponentSettings.path}`;

  const active = category && category.modules.length > 0;

  return { category, active, categoryPath, categoryComponentSettings };
};

export const getCategory = (scorable: Scorable, categoryType: CategoryType): Category => {
  return scorable.categories.find(category => category.type === categoryType)!;
};
