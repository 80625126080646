import { Link } from 'react-router-dom';
import { Tab, Tabs, Tooltip } from '@mui/material';

import { usePrimaryNavigation } from './usePrimaryNavigation';

const AccountNavigation = () => {
  const { areas, activeAreaName } = usePrimaryNavigation();
  const areaList = [areas.account];
  const activeArea = areaList.find(area => area.name === activeAreaName);

  return (
    <Tabs value={activeArea ? 0 : false}>
      {areaList.map(area => {
        return (
          <Tooltip title={area.name} key={area.key} disableInteractive>
            <Tab
              id={`navigation-${area.key}`}
              value={area.key}
              icon={area.icon}
              className={area.label ? 'labeled' : 'unlabeled'}
              component={Link}
              to={area.path!}
            />
          </Tooltip>
        );
      })}
    </Tabs>
  );
};

export default AccountNavigation;
