import { ModuleType } from '../Module/Module';

export const aspectIcon = (moduleType: ModuleType, aspectName: string) => {
  return (ASPECT_ICONS[moduleType] ? ASPECT_ICONS[moduleType][aspectName] : null) || 'error_circle';
};

type AspectIconsType = {
  [key in ModuleType]: { [key: string]: string };
};

const ASPECT_ICONS: AspectIconsType = {
  ClientsProductsAndServicesModule: {
    Quality: 'grade',
    Reliability: 'task_alt',
    Delivery: 'local_shipping',
    Value: 'monetization_on',
    Knowledge: 'school',
  },
  ClientsRelationshipModule: {
    'Client Satisfaction': 'mood',
    Communication: 'chat_bubble',
    Experience: 'sentiment_very_satisfied',
    Goals: 'playlist_add_check_circle',
    Support: 'support',
    Loyalty: 'thumbs_up_down',
  },
  ClientsProjectsModule: {
    Strategy: 'flag',
    Resources: 'group',
    Planning: 'directions',
    'Risk Management': 'report',
    Scope: 'description',
    Schedule: 'event',
    Communication: 'chat_bubble',
    Satisfaction: 'emoji_emotions',
    Quality: 'star',
    Requirements: 'check',
  },
  CompanyProjectsModule: {
    Strategy: 'flag',
    Resources: 'group',
    Planning: 'directions',
    'Risk Management': 'report',
    Scope: 'description',
    Schedule: 'event',
    Communication: 'chat_bubble',
    Satisfaction: 'emoji_emotions',
    Quality: 'star',
    Requirements: 'check',
  },
  EngagementModule: {
    Accomplishment: 'checklist',
    Autonomy: 'fingerprint',
    Diversity: 'diversity_2',
    'Diversity and Inclusion': 'diversity_2',
    Environment: 'home_work',
    'Freedom of Opinion': 'thumb_up_alt',
    'Goal Setting': 'sports_score',
    Growth: 'local_florist',
    Management: 'groups',
    'Organizational Fit': 'merge_type',
    'Employee Relationships': 'forum',
    Recognition: 'emoji_events',
    'Company Strategy': 'follow_the_signs',
  },
  DiversityEquityAndInclusionModule: {
    Diversity: 'groups_3',
    Equity: 'balance',
    Inclusion: 'diversity_3',
  },
  BurnoutModule: {
    'Workload and Stress': 'local_fire_department',
    Empathy: 'heart_broken',
    'Personal Achievement': 'auto_awesome',
    Energy: 'battery_charging_full',
  },
  HealthAndWellnessModule: {
    'Physical Health': 'fitness_center',
    'Mental Wellness': 'psychology',
    'Social Wellness': 'connect_without_contact',
    'Work/Life Balance': 'timelapse',
    'Environmental Wellness': 'nature_people',
    'Intellectual Wellness': 'tungsten',
  },
  CoreValuesModule: {
    Integrity: 'local_police',
    Respect: 'handshake',
    Innovation: 'tips_and_updates',
    Teamwork: 'group_work',
    'Customer Focus': 'support_agent',
    'Continuous Improvement': 'trending_up',
    Accountability: 'verified',
    Empathy: 'favorite',
    Transparency: 'visibility',
    Adaptability: 'extension',
    Responsiveness: 'flash_on',
  },
  SocialResponsibilityModule: {
    'Environmental Sustainability': 'emoji_nature',
    'Ethical Labor Practices': 'safety_divider',
    'Community Engagement': 'location_city',
    Philanthropy: 'volunteer_activism',
    'General Social Responsibility': 'public',
  },
  SeniorLeadershipModule: {
    'Leadership Communication': 'speaker_notes',
    'Decision Making': 'fork_right',
    Performance: 'auto_graph',
    'Vision and Strategy': 'visibility',
    'Management and Development': 'support',
  },
};
