import React, { useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useLayout } from './LayoutContext';
import NoticeContext from '../UI/NoticeContext';
import { IconType } from './Icon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ActionInterface } from './ActionInterface';
import { LIGHT_THEME } from '../Theme/LightTheme';
import { DARK_THEME } from '../Theme/DarkTheme';

interface PageNoticeProps {
  noticeKey: string;
  applicable: boolean;
  level?: 'medium' | 'high';
  icon?: IconType;
  message: string;
  actions?: ActionInterface[];
  closable?: boolean;
  rounded?: boolean;
}

const PageNotice = ({ noticeKey, applicable, level = 'medium', icon, message, actions = [], closable = true, rounded = false }: PageNoticeProps) => {
  if (!noticeKey) {
    throw new Error('noticeKey is required');
  }
  const { registerNotice, noticeOpen, closeNotice } = useContext(NoticeContext);
  const layout = useLayout();
  const verticalLayout = ['mobile', 'tablet'].includes(layout);

  useEffect(() => {
    registerNotice(noticeKey);
  }, [registerNotice, noticeKey]);

  const handleClose = () => {
    closeNotice(noticeKey);
  };

  if (!noticeOpen(noticeKey) || !applicable) {
    return null;
  }

  const noticeTheme = level === 'high' ? LIGHT_THEME : DARK_THEME;

  return (
    <ThemeProvider theme={noticeTheme}>
      <Stack
        position='relative'
        direction='row'
        alignItems='center'
        className={`notice-level-${level}`}
        sx={{
          borderRadius: rounded ? '4px' : undefined,
          backgroundColor: level === 'high' ? '#ffd73e' : '#333',
          color: level === 'medium' ? '#aaa' : undefined,
          '& .MuiButton-root': {
            borderColor: level === 'medium' ? '#aaa' : undefined,
          },
          '& .MuiIconButton-root': {
            color: level === 'medium' ? '#fff' : '#333',
          },
        }}
      >
        <Stack
          direction={verticalLayout ? 'column' : 'row'}
          alignItems='center'
          justifyContent={verticalLayout ? 'center' : undefined}
          flexGrow={1}
          gap={verticalLayout ? 1 : 2}
          px={verticalLayout ? 4 : 2}
          py={1}
        >
          {icon && (
            <Stack alignItems='center' pr={1}>
              {React.createElement(icon)}
            </Stack>
          )}

          <Typography
            flex={1}
            variant='body1'
            color='primary'
            textAlign={verticalLayout ? 'center' : 'left'} // Center text in vertical layout
            px={verticalLayout ? 2 : 0} // Add padding for better centering
            sx={{ textWrap: 'balance' }}
          >
            {message}
          </Typography>

          <Stack
            className='page-notice-actions'
            direction={verticalLayout ? 'column' : 'row'}
            alignItems='center'
            justifyContent='center' // Center actions in vertical layout
            spacing={1}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {actions.map((action, index) => (
              <LoadingButton
                variant='outlined'
                key={index}
                onClick={() => action.onSelect?.()}
                startIcon={action.icon ? React.createElement(action.icon) : undefined}
                disabled={action.disabled}
                loading={action.loading}
                loadingPosition='start'
              >
                {action.label}
              </LoadingButton>
            ))}
          </Stack>

          {closable && (
            <Box
              position={verticalLayout ? 'absolute' : 'static'}
              top={verticalLayout ? 8 : 'auto'} // Add margin for better spacing in vertical layout
              right={verticalLayout ? 8 : 'auto'}
              ml={verticalLayout ? 'auto' : -2}
            >
              <IconButton edge={verticalLayout ? undefined : 'end'} onClick={handleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export const PageNotices = ({ children }: { children: React.ReactNode }) => {
  return <Stack divider={<Divider flexItem sx={{ backgroundColor: '#1a1a1a' }} />}>{children}</Stack>;
};

export default PageNotice;
