import PageNotice from '../UI/PageNotice';
import { useWorkspace } from '../Workspace/useWorkspace';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useAuthorizeSlack } from './useSlackIntegration';

const SlackIntegrationNotice = () => {
  const { workspace, isAdmin } = useWorkspace();
  const { authorizeAppScopesURL } = useAuthorizeSlack();

  if (!workspace) {
    return null;
  }

  const actions = [
    {
      id: 'link-to-slack',
      icon: LinkRoundedIcon,
      label: 'Link to Slack',
      onSelect: () => {
        window.location.href = authorizeAppScopesURL;
      },
    },
  ];

  const applicable = isAdmin && workspace && !workspace.hasSlack;

  return (
    <PageNotice
      noticeKey={`slack-integration-Workspace:${workspace.id}`}
      applicable={applicable}
      level='high'
      message='Link Hyve to your Slack account so members can receive notifications and answer questions directly in Slack.'
      actions={actions}
    />
  );
};

export default SlackIntegrationNotice;
