import GroupRoundedIcon from '@mui/icons-material/GroupRounded';

import { useContributeIntelligence, useCollectIntelligence } from './ContributeAndCollect';
import { ActionInterface } from '../UI/ActionInterface';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import { Scorable } from '../Scores/Scorable';
import { ScoreDimension } from '../Scores/ScoreDimension';

export const useIntelligenceActions = (scorable: Scorable, scoreDimension?: ScoreDimension) => {
  const { contributeIntelligence, contributeIntelligenceDisabled } = useContributeIntelligence(scorable, scoreDimension);
  const { collectIntelligence, collectIntelligenceDisabled, collectIntelligenceDialog } = useCollectIntelligence(scorable, scoreDimension);
  const { canContributeIntelligence, canCollectIntelligence } = scoreDimension || scorable;

  const contributeIntelligenceAction: ActionInterface = {
    icon: WaterDropRoundedIcon,
    label: 'Contribute Intelligence',
    onSelect: contributeIntelligence,
    disabled: contributeIntelligenceDisabled,
    loading: contributeIntelligenceDisabled,
  };

  const collectIntelligenceAction: ActionInterface = {
    icon: GroupRoundedIcon,
    label: `Collect Intelligence`,
    onSelect: collectIntelligence,
    disabled: collectIntelligenceDisabled,
    loading: collectIntelligenceDisabled,
  };

  const intelligenceActions = [];
  if (canContributeIntelligence) {
    intelligenceActions.push(contributeIntelligenceAction);
  }
  if (canCollectIntelligence) {
    intelligenceActions.push(collectIntelligenceAction);
  }

  return { intelligenceActions, collectIntelligenceDialog };
};
