import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';

import DashboardCategory, { DashboardCategoryProps } from '../Dashboard/DashboardCategory';
import { PersonalAndMemberBreakdownCategoryDetails } from '../Category/CategoryDashboardDetails';
import { ActionInterface } from '../UI/ActionInterface';
import { MembershipInterface } from '../Membership/Membership';
import { Scorable } from '../Scores/Scorable';

type CompanyDashboardSummaryProps = DashboardCategoryProps & {
  scorable: Scorable & MembershipInterface;
};

const CompanyDashboardCategory = ({ scorable, category, includeScoreCircle }: CompanyDashboardSummaryProps) => {
  const navigationActions = [
    { icon: DashboardRoundedIcon, label: 'View Company Dashboard', path: `company` },
    { icon: HiveRoundedIcon, label: 'View Company Projects', path: `company/projects` },
  ];

  const actions: ActionInterface[] = [{ label: 'View Dashboard', path: 'company' }];

  return (
    <DashboardCategory scorable={scorable} category={category} navigationActions={navigationActions} actions={actions} includeScoreCircle={includeScoreCircle}>
      <PersonalAndMemberBreakdownCategoryDetails scorable={scorable} category={category} />
    </DashboardCategory>
  );
};

export default CompanyDashboardCategory;
