import React, { useState, useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Avatar, AvatarGroup, Box, Button, Icon, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ElementSize, GridCellParams, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import ClientAvatar from '../Client/ClientAvatar';
import ProjectActions, { useProjectActions } from '../Project/ProjectActions';
import EntityScoreIcon from '../Scores/EntityScoreIcon';
import { scoreSortComparator } from '../Scores/Score';
import StyledDataGrid, { getColumnVisibilityModel } from '../UI/StyledDataGrid';
import { ClientInterface } from '../Client/ClientInterface';
import { ProjectInterface } from '../Project/ProjectInterface';
import Link from '@mui/material/Link';
import { useWorkspace } from '../Workspace/useWorkspace';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import ProjectImportanceRating from '../Project/ProjectImportanceRating';
import { importanceSortComparator } from '../Scores/ImportanceRating';
import ProjectStatusSelect from '../Project/ProjectStatusSelect';
import ProjectDueDate from '../Project/ProjectDueDate';
import { statusSortComparator } from '../Project/ProjectStatus';

type ColumnField = 'name' | 'importance' | 'status' | 'endDate' | 'score' | 'intelligence' | 'owner' | 'members' | 'projectMembership' | 'actions';
const COLUMN_PRIORITY: ColumnField[] = ['name', 'actions', 'score', 'importance', 'status', 'endDate', 'intelligence', 'members', 'projectMembership', 'owner'];

const ROWS_PER_PAGE = 50;

type ProjectColDef = GridColDef & {
  field: ColumnField;
};

type ProjectRow = {
  project: ProjectInterface;
};

type ProjectDataGridProps = {
  client?: ClientInterface | undefined;
  projects: ProjectInterface[];
  loading: boolean;
  slots: Object;
  slotProps: Object;
  refetch: () => Promise<any>;
};

const ProjectDataGrid = ({ client, projects, loading, refetch, ...props }: ProjectDataGridProps) => {
  const { workspace } = useWorkspace();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'status', sort: 'asc' },
    { field: 'score', sort: 'asc' },
  ]);

  const columns = useMemo(() => (loading ? [] : getColumns(workspace, client, refetch)), [loading, workspace, client, refetch]);

  const rows: GridRowsProp = projects.map(project => {
    const owner = project.owners[0];
    const ownerName = owner ? `${owner.firstName} ${owner.lastName}` : null;
    const projectMembership = project.member;

    return {
      id: project.id,
      project: project,
      name: project.name,
      importance: project.importance,
      endDate: project.endDate,
      status: project.status,
      owner: ownerName,
      members: project.users,
      projectMembership: projectMembership,
      score: project.health,
      intelligence: project.intelligence,
    };
  });

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;
    const { project } = row;
    const projectPath = `/workspace/${workspace.id}/projects/${project.id}`;

    switch (field) {
      case 'name':
        navigate(projectPath);
        break;
      case 'owner':
      case 'members':
      case 'projectMembership':
        navigate(`${projectPath}/members`);
        break;
      case 'importance':
      case 'endDate':
      case 'status':
      case 'actions':
        break;
      default:
        navigate(projectPath);
    }
  };

  const handleResize = (containerSize: ElementSize) => {
    setColumnVisibilityModel(getColumnVisibilityModel(columns, COLUMN_PRIORITY, containerSize.width));
  };

  return (
    <StyledDataGrid
      {...props}
      // autoHeight
      rowHeight={70}
      loading={loading}
      columnVisibilityModel={columnVisibilityModel}
      onResize={handleResize}
      sortingOrder={['asc', 'desc']}
      hideFooter={rows.length <= ROWS_PER_PAGE}
      disableColumnMenu={true}
      disableRowSelectionOnClick={true}
      sortModel={sortModel}
      autoPageSize={false}
      onSortModelChange={model => {
        if (model.length > 0 && JSON.stringify(model) !== JSON.stringify(sortModel)) {
          setSortModel(model);
        }
      }}
      onCellClick={handleCellClick}
      rows={rows}
      columns={columns}
    />
  );
};

const getColumns = (workspace: WorkspaceInterface, client: ClientInterface | undefined, refetch: () => Promise<any>): ProjectColDef[] => [
  {
    field: 'name',
    headerName: 'Project Name',
    minWidth: 180,
    flex: 1,
    renderCell: cellValues => {
      const { project } = cellValues.row;
      const { archived } = project;
      return (
        <ListItem key={project.id}>
          <ListItemIcon>
            <ClientAvatar client={project.client} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link component={RouterLink} to={`/workspace/${workspace.id}/projects/${project.id}`}>
                {project.name}
              </Link>
            }
            secondary={client ? undefined : project.client.name}
          />
          {archived && (
            <Icon style={{ margin: 0 }} className='list-item-status-icon'>
              archived
            </Icon>
          )}
        </ListItem>
      );
    },
  },
  {
    field: 'score',
    headerName: 'Score',
    width: 80,
    sortComparator: scoreSortComparator,
    renderCell: cellValues => {
      const { project, score } = cellValues.row;
      return <EntityScoreIcon score={score} filled={project.member} />;
    },
  },
  {
    field: 'importance',
    headerName: 'Importance',
    width: 120,
    sortingOrder: ['desc', 'asc'],
    sortComparator: importanceSortComparator,
    // headerAlign: 'center',
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ProjectRow>) => {
      const { project } = cellValues.row;
      return <ProjectImportanceRating project={project} size='small' />;
    },
  },
  {
    field: 'endDate',
    headerName: 'Due Date',
    headerClassName: 'MuiDataGrid-columnHeader--indented',
    width: 110,
    renderCell: (cellValues: GridRenderCellParams<ProjectRow>) => {
      const { project } = cellValues.row;
      return <ProjectDueDate project={project} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    // headerClassName: 'MuiDataGrid-columnHeader--indented',
    sortComparator: statusSortComparator,
    width: 100,
    renderCell: (cellValues: GridRenderCellParams<ProjectRow>) => {
      const { project } = cellValues.row;
      return (
        <Box ml={0}>
          <ProjectStatusSelect project={project} />
        </Box>
      );
    },
  },
  {
    field: 'intelligence',
    headerName: 'Intelligence',
    width: 100,
    renderCell: cellValues => {
      const { intelligence } = cellValues.row;
      return intelligence || '-';
    },
  },
  {
    field: 'owner',
    headerName: 'Owner',
    width: 120,
    valueGetter: ({ value }) => value || '-',
  },
  {
    field: 'members',
    headerName: 'Members',
    width: 120,
    sortingOrder: ['desc', 'asc'],
    sortComparator: (a, b) => a.length - b.length,
    renderCell: (cellValues: GridRenderCellParams<ProjectRow>) => {
      const { project } = cellValues.row;
      return project.users.length ? (
        <AvatarGroup max={3}>
          {project.users.map(user => (
            <Tooltip key={user.id} title={`${user.firstName} ${user.lastName}`}>
              <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.avatar}>
                {user.firstName[0] + user.lastName[0]}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'projectMembership',
    headerName: 'Member',
    width: 70,
    renderCell: cellValues => {
      const { project } = cellValues.row;
      return project.member ? <Icon className='membership-check'>check</Icon> : <JoinProjectButton project={project} refetch={refetch} />;
    },
  },
  {
    field: 'actions',
    headerName: ' ',
    width: 64,
    sortable: false,
    renderCell: cellValues => {
      const { project } = cellValues.row;
      return <ProjectActions project={project} includeNavigationActions refetch={refetch} />;
    },
  },
];

const JoinProjectButton = ({ project, refetch }: { project: ProjectInterface; refetch: () => Promise<any> }) => {
  const { joinProject } = useProjectActions(project, refetch);
  const handleJoinProject = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await joinProject();
  };
  return (
    <Button startIcon={<Icon>person_add</Icon>} onClick={handleJoinProject}>
      Join
    </Button>
  );
};

export default ProjectDataGrid;
