import { gql, useMutation } from '@apollo/client';
import { z } from 'zod';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

import TeamFields from '../Team/TeamFields';
import { TEAM_FIELDS_FRAGMENT } from './TeamType';
import FieldSet from '../UI/FieldSet';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { TeamInterface, TeamSchema } from './TeamSchema';
import { DialogProps } from '@mui/material/Dialog';
import FormDialog, { useFormDialog } from '../UI/FormDialog';

interface EditTeamDialogProps extends DialogProps {
  onClose: () => void;
  team: TeamInterface;
}

const EditTeamSchema = TeamSchema.omit({ manager: true, members: true });
type EditTeamType = z.infer<typeof EditTeamSchema>;

const EditTeamDialog = ({ team, open, onClose }: EditTeamDialogProps) => {
  const { setSuccess } = useGlobalMessage();

  const defaultValues: EditTeamType = {
    ...team,
  };
  const formDialogProps = useFormDialog<EditTeamType>({ schema: EditTeamSchema, defaultValues });
  const { handleResponse } = formDialogProps;

  const handleSubmit = async ({ parentTeam, ...values }: EditTeamType) => {
    const response = await updateTeam({ variables: { parentTeamId: parentTeam?.id || null, ...values } });
    const success = handleResponse(response, 'updateTeam', 'Uh oh. There was an error editing the team');
    if (success) {
      setSuccess('Updated team');
    }

    return success;
  };

  const [updateTeam, { error }] = useMutation(UPDATE_TEAM_MUTATION);

  return (
    <FormDialog<EditTeamType>
      {...formDialogProps}
      id='edit-team'
      open={open}
      onClose={onClose}
      icon={<PeopleRoundedIcon />}
      title='Edit Team Settings'
      // subtitle="Update the team settings. Update the team settings. Update the team settings."
      submitLabel='Save'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <FieldSet>
        <TeamFields mode='edit' />
      </FieldSet>
    </FormDialog>
  );
};

export default EditTeamDialog;

const UPDATE_TEAM_MUTATION = gql`
  mutation UpdateTeam($id: ID!, $parentTeamId: ID, $name: String, $icon: IconInput) {
    updateTeam(id: $id, parentTeamId: $parentTeamId, name: $name, icon: $icon) {
      team {
        ...TeamFields
      }
    }
  }

  ${TEAM_FIELDS_FRAGMENT}
`;
