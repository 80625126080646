import React from 'react';
import { ApolloError } from '@apollo/client';
import ErrorPage from './ErrorPage';

const useApolloError = (error: ApolloError | undefined, type: QueryEntityType) => {
  console.error(error);
  let errorMessage;
  if (error) {
    const { graphQLErrors } = error;

    newrelic.noticeError(error);

    if (graphQLErrors) {
      if (graphQLErrors.find(error => error.extensions?.code === 'NOT_FOUND')) {
        errorMessage = `${type} not found`;
      } else {
        errorMessage = `Error loading ${type.toLowerCase()}`;
      }
    }
  }
  return errorMessage;
};

type QueryEntityType = 'Workspace' | 'Team' | 'Client' | 'Project';

interface ApolloErrorPageProps {
  error: ApolloError;
  type: QueryEntityType;
  includeHeader?: boolean;
}

const ApolloErrorPage = ({ error, type, includeHeader = false }: ApolloErrorPageProps) => {
  const errorMessage = useApolloError(error, type);

  return <ErrorPage message={errorMessage} includeHeader={includeHeader} />;
};

export default ApolloErrorPage;
