import { MemberScorerInterface } from '../Membership/MemberScores';

export interface EngagementModuleInterface extends MemberScorerInterface {}

// TODO: update to fragment
export const EMPLOYEE_EXPERIENCE_MODULE_FIELDS = `
  confidentialityThreshold
  ... on EngagementModule {
    ... MemberScores
  }
`;
