import { useState, Fragment } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';

import AddTeamMembersDialog from '../Team/AddTeamMembersDialog';
import EditTeamDialog from '../Team/EditTeamDialog';
import NewTeamDialog from '../Team/NewTeamDialog';
import { useAddSubteamAction, useEditTeamAction, useDeleteTeamAction } from './TeamManagementActions';
import { TEAM_FIELDS_FRAGMENT } from './TeamType';

import ConfirmationDialog from '../UI/ConfirmationDialog';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import PageStyle from '../UI/PageStyle';
import { SET_USER_ROLES_MUTATION, SetUserRolesVariables } from '../User/Roles';
import { TeamInterface } from './TeamSchema';
import { UserInterface } from '../User/User';
import MemberList from '../Membership/MemberList';
import TeamIcon from './TeamIcon';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { TeamProvider } from './TeamContext';

const TeamManagementTeam = () => {
  const { teamId: id } = useParams<{ teamId: string }>();
  const [addMembersDialogOpen, setAddMembersDialogOpen] = useState(false);
  const [newTeamDialogOpen, setNewTeamDialogOpen] = useState(false);
  const { setSuccess } = useGlobalMessage();

  const { data, error, loading, refetch } = useQuery(TEAM_MANAGEMENT_TEAM_QUERY, { variables: { id: id } });

  const [removeTeamMember] = useMutation(REMOVE_TEAM_MEMBER_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(team) });
      cache.gc();
    },
  });

  const [setUserRoles] = useMutation<any, SetUserRolesVariables>(SET_USER_ROLES_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(team) });
      cache.gc();
    },
  });

  if (!data && loading) return <PageStyle design='standard' loading={true} />;
  if (error) throw error;

  const team: TeamInterface = data.team;

  const handleAddMembers = () => {
    setAddMembersDialogOpen(true);
  };

  const handleSetManager = async (user: UserInterface) => {
    await setUserRoles({
      variables: {
        userId: user.id,
        type: 'Team',
        id: team.id,
        roles: ['Manager'],
        operation: 'Add',
      },
    });
    await refetch();
    setSuccess('Set team manager');
  };

  const handleUnsetManager = async (user: UserInterface) => {
    await setUserRoles({
      variables: {
        userId: user.id,
        type: 'Team',
        id: team.id,
        roles: ['Manager'],
        operation: 'Remove',
      },
    });
    await refetch();
    setSuccess('Unset team manager');
  };

  const handleRemoveMember = async (member: UserInterface) => {
    await removeTeamMember({ variables: { id: id, userIds: [member.id] } });
    await refetch();
    setSuccess('Removed team member');
  };

  const memberActionsResolver = (member: UserInterface): ActionListType => {
    const setManagerAction = {
      icon: StarsRoundedIcon,
      label: 'Set as the Team Manager',
      onSelect: () => handleSetManager(member),
    };

    const unsetManagerAction = {
      icon: StarsRoundedIcon,
      label: 'Unset as the Team Manager',
      onSelect: () => handleUnsetManager(member),
    };

    const managerAction = member.id === team.manager?.id ? unsetManagerAction : setManagerAction;

    const removeFromTeamAction = {
      icon: PersonRemoveRoundedIcon,
      label: 'Remove from Team',
      onSelect: () => handleRemoveMember(member),
    };

    return [managerAction, 'divider', removeFromTeamAction];
  };

  const { icon, name, manager, users } = team;

  return (
    <TeamProvider team={team} refetch={refetch}>
      <PageStyle design='simple'>
        <MemberList
          Icon={() => <TeamIcon icon={icon} />}
          titlePrefix={`${name} Team`}
          manager={manager}
          members={users}
          onAddMembers={handleAddMembers}
          memberActionsResolver={memberActionsResolver}
          actions={<TeamActions team={team} />}
          parentPath='..'
        />
      </PageStyle>

      <AddTeamMembersDialog open={addMembersDialogOpen} onClose={() => setAddMembersDialogOpen(false)} />

      <NewTeamDialog open={newTeamDialogOpen} onClose={() => setNewTeamDialogOpen(false)} parentTeam={team} />
    </TeamProvider>
  );
};

const TeamActions = ({ team }: { team: TeamInterface }) => {
  const { addSubteamAction, newTeamDialogOpen, setNewTeamDialogOpen } = useAddSubteamAction();
  const { editTeamAction, editTeamDialogOpen, setEditTeamDialogOpen } = useEditTeamAction();
  const { deleteTeamAction, deleteTeamConfirmationOptions } = useDeleteTeamAction(team);

  const actions: ActionListType = [addSubteamAction, editTeamAction, 'divider', deleteTeamAction];

  return (
    <Fragment>
      <OverflowActions actions={actions} />
      <NewTeamDialog open={newTeamDialogOpen} onClose={() => setNewTeamDialogOpen(false)} parentTeam={team} />
      <EditTeamDialog team={team} open={editTeamDialogOpen} onClose={() => setEditTeamDialogOpen(false)} />
      <ConfirmationDialog {...deleteTeamConfirmationOptions} />
    </Fragment>
  );
};

const TEAM_MANAGEMENT_TEAM_QUERY = gql`
  query Team($id: ID!) {
    team(id: $id) {
      ...TeamFields
    }
  }

  ${TEAM_FIELDS_FRAGMENT}
`;

const REMOVE_TEAM_MEMBER_MUTATION = gql`
  mutation RemoveTeamMember($id: ID!, $userIds: [ID!]) {
    removeTeamMembers(id: $id, userIds: $userIds) {
      success
    }
  }
`;

export default TeamManagementTeam;
