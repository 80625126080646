import React, { Fragment, useState } from 'react';
import Stack from '@mui/material/Stack';
import HistoricalScores from '../Scores/HistoricalScores';
import { IconType } from '../UI/Icon';
import { computeScoreLevel, getScoreDeltaInfo } from '../Scores/Score';
import ScoreCircle from '../Scores/ScoreCircle';
import { useLayout } from '../UI/LayoutContext';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ScoreModal } from '../Scores/ScoreModal';
import { Scorable } from '../Scores/Scorable';
import { ScoreDimension } from '../Scores/ScoreDimension';

interface DashboardSummaryProps {
  scorable: Scorable;
  scoreDimension?: ScoreDimension;
  icon: IconType;
  scopePrefix?: React.ReactNode | string;
  scorableLabel: string;
}

const DashboardSummary = ({ scorable, scoreDimension, icon, scopePrefix, scorableLabel }: DashboardSummaryProps) => {
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  if (!scoreDimension) {
    scoreDimension = scorable;
  }

  //  if (!scorable) { return null; }
  const layout = useLayout();
  const [mobileDisplayMode, setMobileDisplayMode] = useState<'score' | 'trend'>('score');

  const health = scoreDimension?.health;
  const healthLevel = computeScoreLevel(health);

  const scoreLabel = (
    <Fragment>
      <span>{scopePrefix}</span>
      <span>{`${scorableLabel} Score`}</span>
    </Fragment>
  );

  const { ScoreDeltaIcon } = getScoreDeltaInfo(scoreDimension?.healthDelta);

  let mobileDisplayModeButton;
  if (layout === 'mobile') {
    if (mobileDisplayMode === 'score') {
      mobileDisplayModeButton = (
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => setMobileDisplayMode('trend')}>
          <ScoreDeltaIcon />
        </IconButton>
      );
    } else {
      mobileDisplayModeButton = (
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => setMobileDisplayMode('score')}>
          <CloseRoundedIcon />
        </IconButton>
      );
    }
  }

  const historicalScoresAspectRatio = layout === 'tablet' ? 2.5 : 2.5;

  let scoreCircleWidth;
  if (layout === 'mobile') {
    scoreCircleWidth = 200;
  } else if (layout === 'tablet' || layout === 'laptop') {
    scoreCircleWidth = 300;
  } else {
    scoreCircleWidth = 400;
  }

  return (
    <Stack position='relative' spacing={0}>
      <ScoreModal
        scorable={scorable}
        scoreDimension={scoreDimension}
        icon={icon}
        label={scoreLabel}
        open={scoreModalOpen}
        onClose={() => setScoreModalOpen(false)}
      />
      {mobileDisplayModeButton}

      <Stack direction={layout === 'mobile' ? 'column' : 'row'} spacing={{ xs: 4, lg: 4 }} alignItems='center' px={{ xs: 2, lg: 2 }}>
        <ScoreCircle
          width={scoreCircleWidth}
          icon={icon}
          score={health}
          scoreFontSize='80px'
          color={healthLevel.color}
          sx={{ maxWidth: '250px' }}
          onClick={() => setScoreModalOpen(true)}
        />
        {(layout !== 'mobile' || mobileDisplayMode === 'trend') && (
          <HistoricalScores scorable={scorable} scoreDimension={scoreDimension} aspectRatio={historicalScoresAspectRatio} />
        )}
      </Stack>
    </Stack>
  );
};

export default DashboardSummary;
