import React, { createContext, useContext } from 'react';
import { TeamInterface } from './TeamSchema';
import useWorkspace from '../Workspace/useWorkspace';

type TeamContextValue = {
  team: TeamInterface;
  teamPath: string;
  refetch: () => Promise<any>;
};

const TeamContext = createContext<TeamContextValue | undefined>(undefined);

export const useTeamContext = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error('useTeamContext must be used within a TeamProvider');
  }
  return context;
};

type TeamProviderProps = Omit<TeamContextValue, 'teamPath'> & {
  children: React.ReactNode;
};

export const TeamProvider = ({ team, refetch, children }: TeamProviderProps) => {
  const { workspacePath } = useWorkspace();
  const teamPath = `${workspacePath}/teams/${team.id}`;
  return <TeamContext.Provider value={{ team, teamPath, refetch }}>{children}</TeamContext.Provider>;
};
