import React, { Fragment } from 'react';
import { useQuery, gql } from '@apollo/client';

import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

import TeamDataGrid from '../Team/TeamDataGrid';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import PageStyle from '../UI/PageStyle';
import { SectionHeader, SectionHeaderTitle } from '../UI/Section';
import { useWorkspace } from '../Workspace/useWorkspace';
import { TeamInterface } from './TeamSchema';
import SecondaryNavigation, { SecondaryNavigationHeading } from '../Navigation/SecondaryNavigation';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import { UNSCOPED_SCOPE } from '../Scope/Scope';

const Teams = () => {
  const { workspace } = useWorkspace();

  const { data, loading } = useQuery(TEAMS_QUERY, { variables: { workspaceId: workspace.id, ...UNSCOPED_SCOPE.variables } });

  const teams = data?.workspace.teams || [];

  return (
    <Fragment>
      <TeamsSecondaryNavigation />
      <PageStyle design='simple' fullWidth>
        <SectionHeader>
          <SectionHeaderTitle>Teams ({teams.length})</SectionHeaderTitle>
        </SectionHeader>

        <TeamDataGrid teams={teams} loading={!data && loading} actionsComponent={TeamDataGridActions} />
      </PageStyle>
    </Fragment>
  );
};

interface TeamDataGridActionsProps {
  team: TeamInterface;
}

const TeamDataGridActions = ({ team }: TeamDataGridActionsProps) => {
  const { workspace } = useWorkspace();
  const { id } = team;

  const actions: ActionListType = [
    {
      icon: AnalyticsRoundedIcon,
      label: 'Dashboard',
      path: `/workspace/${workspace.id}/teams/${id}`,
    },
    {
      icon: CorporateFareRoundedIcon,
      label: 'Clients',
      path: `/workspace/${workspace.id}/teams/${id}/clients`,
    },
    {
      icon: HiveRoundedIcon,
      label: 'Projects',
      path: `/workspace/${workspace.id}/teams/${id}/projects`,
    },
    {
      icon: EmojiObjectsRoundedIcon,
      label: 'Aspects',
      path: `/workspace/${workspace.id}/teams/${id}/aspects`,
    },
    {
      icon: PeopleRoundedIcon,
      label: 'Members',
      path: `/workspace/${workspace.id}/teams/${id}/members`,
    },
  ];

  return <OverflowActions actions={actions} />;
};

export const TEAMS_QUERY = gql`
  query Teams($workspaceId: ID!, $scope: ScopeInput) {
    workspace(id: $workspaceId, scope: $scope) {
      id
      teams {
        clientStats {
          count
        }
        projectStats {
          count
        }
        id
        name
        icon {
          symbol
          color
        }
        ancestors {
          id
          name
        }
        health
        intelligence
        categories {
          id
          type
          health
        }
        manager {
          id
          firstName
          lastName
          avatar
        }
        users {
          id
          firstName
          lastName
          avatar
          pendingInvitation
        }
      }
    }
  }
`;

export default Teams;

const TeamsSecondaryNavigation = () => {
//  const { scopes } = useScope();
//  const scopeOptions = scopes.map(scope => {
//    return { label: `${scope.selectorPrefix} Teams`, scope: scope, count: undefined };
//  });

  return (
    <SecondaryNavigation>
      <SecondaryNavigationHeading>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Avatar variant='rounded'>
            <SupervisedUserCircleRoundedIcon sx={{ fontSize: '24px' }} />
          </Avatar>
          <Stack direction='row' alignItems='center' sx={{ fontSize: '14px', lineHeight: '1em' }}>
            Teams
            {/*<ChevronRightRoundedIcon />*/}
            {/*<TeamsScopeMenu scopeOptions={scopeOptions} />*/}
          </Stack>
        </Stack>
      </SecondaryNavigationHeading>
    </SecondaryNavigation>
  );
};

//const TeamsScopeMenu = ({ scopeOptions }: { scopeOptions: ScopeSelectorOptionInterface[] }) => {
//  const { isAdmin, isSeniorLeader } = useWorkspace();
//
//  if (!isAdmin && !isSeniorLeader) {
//    return null;
//  }
//
//  if (scopeOptions.length === 0) {
//    return null;
//  }
//
//  // const workspaceScope = { ...UNSCOPED_SCOPE, prefix: 'Workspace ' };
//
//  return <ScopeSelector options={scopeOptions} />;
//};
