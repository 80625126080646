import { useQuery } from '@apollo/client';
import { MODULE_ASPECT_SCORES_FRAGMENT } from '../Aspect/AspectList';
import Aspects from '../Aspect/Aspects';
import { useScope } from '../Scope/useScope';
import PageStyle from '../UI/PageStyle';
import { gql } from '@apollo/client';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useCategoryContext } from './CategoryContext';

const CategoryAspects = () => {
  const { workspace } = useWorkspace();
  const { scope } = useScope();
  const { category } = useCategoryContext();

  const { data } = useQuery(CATEGORY_ASPECTS_QUERY, {
    variables: {
      id: workspace.id,
      ...scope.variables,
      categoryType: category.type,
    },
  });

  if (!data) {
    return null;
  }

  const { scorable } = data;

  return (
    <PageStyle design='standard' backgroundProps={{ pt: 3 }}>
      <Aspects scorable={scorable} />
    </PageStyle>
  );
};

export default CategoryAspects;

const CATEGORY_ASPECTS_QUERY = gql`
  query CategoryAspects($id: ID!, $scope: ScopeInput, $days: Int, $categoryType: CategoryType) {
    scorable: workspace(id: $id, scope: $scope) {
      id

      categories(type: $categoryType) {
        id
        name
        type
        modules {
          ...ModuleAspectScores
        }
      }
    }
  }
  ${MODULE_ASPECT_SCORES_FRAGMENT}
`;
