import React, { useState } from 'react';
import { Button, ButtonProps, Menu, MenuItem, Typography } from '@mui/material';

import { ProjectInterface } from './ProjectInterface';
import { ProjectStatus, PROJECT_STATUS_COMPONENT_SETTINGS, UPDATE_PROJECT_STATUS_MUTATION } from './ProjectStatus';
import { useMutation } from '@apollo/client';
import { handleMutationResponse } from '../util/handleMutationResponse';
import { useGlobalMessage } from '../UI/GlobalMessage';

type ProjectStatusProps = ButtonProps & {
  project: ProjectInterface;
};

export const ProjectStatusSelect = ({ project, sx, ...props }: ProjectStatusProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { id, status } = project;
  const [optimisticValue, setOptimisticValue] = useState<ProjectStatus>(status);
  const { name, color } = PROJECT_STATUS_COMPONENT_SETTINGS[optimisticValue];
  const { setSuccess, setError } = useGlobalMessage();

  const [updateProjectStatus] = useMutation(UPDATE_PROJECT_STATUS_MUTATION, {
    onCompleted: response => {
      const { success, errorMessage } = handleMutationResponse(
        { data: response },
        'updateProjectStatus',
        'Uh oh. There was an error updating the project status.'
      );
      if (success) {
        setSuccess('Updated project status');
      } else {
        setError(errorMessage);
        // Revert optimistic update on error
        setOptimisticValue(status);
      }
    },
    onError: error => {
      setError(error.message);
      // Revert optimistic update on network error
      setOptimisticValue(status);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (value: ProjectStatus) => {
    setOptimisticValue(value); // Set optimistic value immediately
    await updateProjectStatus({
      variables: {
        id: id,
        status: value,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateProjectStatus: {
          __typename: 'Project',
          project: {
            id: id,
            status: value,
            __typename: 'Project',
          },
        },
      },
    });

    handleClose();
  };

  return (
    <>
      <Button
        variant='outlined'
        color={color}
        size='small'
        sx={{ textTransform: 'none', minWidth: 'inherit', ...sx }}
        aria-controls='status-menu'
        aria-haspopup='true'
        onClick={handleClick}
        {...props}
      >
        {name}
      </Button>
      <Menu id='status-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.entries(PROJECT_STATUS_COMPONENT_SETTINGS).map(([statusKey, { name, color }]) => (
          <MenuItem key={statusKey} onClick={() => handleStatusChange(statusKey as ProjectStatus)}>
            <Typography>{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProjectStatusSelect;
