import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Chip, IconButton, InputAdornment } from '@mui/material';
import { Stack, TextField, Typography } from '@mui/material';

import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import { messageSchema, Message, useAI } from './AIContext';
import useActiveFeatures from '../Module/useActiveFeatures';
import AIToolbar from './AIToolbar';
import { useLayout } from '../UI/LayoutContext';
import { APP_BAR_HEIGHT } from '../Theme/AppTheme';
import useWorkspace from '../Workspace/useWorkspace';
import AIMessages from './AIMessages';

const AIPanel = () => {
  const { open, messages, sendMessage, inputRef, focusInput, setMessages } = useAI();

  useEffect(() => {
    if (open) {
      focusInput();
    }
  }, [open, focusInput]);

  const {
    control,
    handleSubmit,
    setValue,
    // formState: { isSubmitting },
  } = useForm<Message>({
    resolver: zodResolver(messageSchema),
    defaultValues: {
      sender: 'user',
      content: '',
    },
  });

  const onSubmit = async (message: Message) => {
    if (message.content) {
      setValue('content', '');
      setMessages([...messages, message]);
      focusInput();

      await sendMessage(message.content);

      setTimeout(() => {
        const thinkingMessage: Message = { sender: 'ai', content: '{ellipsis}' };
        setMessages((currentMessages: Message[]) => [...currentMessages, thinkingMessage]);
      }, 500);
    }
  };

  const layout = useLayout();
  const appBarHeight = layout !== 'mobile' ? APP_BAR_HEIGHT : '0px';
  const aiToolbarHeight = layout !== 'mobile' ? '64px' : '56px';

  return (
    <Stack flex={1} position="relative" maxHeight={`calc(100% - ${appBarHeight})`}>
      <AIToolbar />
      <Stack
        sx={{ flexGrow: 1, flexShrink: 1, overflowY: 'auto' }}
        px={3}
        pb={2}
        justifyContent="stretch"
        spacing={2}
        maxHeight={`calc(100% - ${aiToolbarHeight})`}
      >
        {(messages.length === 0 || messages.length === 1) ?
          <SuggestedPrompts onSendMessage={message => onSubmit(message)} /> : <AIMessages messages={messages} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="content"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={inputRef}
                fullWidth
                error={!!error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton disabled={!field.value} edge="end" onClick={handleSubmit(onSubmit)}>
                        <ArrowCircleUpRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </form>
        <Typography variant="body2" fontSize="12px" textAlign="center">
          AI responses can be inaccurate.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AIPanel;

const SuggestedPrompts = ({ onSendMessage }: { onSendMessage: (message: Message) => void }) => {
  const { workspace } = useWorkspace();
  const { clientsActive, employeeExperienceActive, companyActive } = useActiveFeatures();
  const suggestedPrompts = ['What should I do today?'];

  if (clientsActive) {
    suggestedPrompts.push('How can I help my clients?');
  }

  if (workspace.teams.length > 0) {
    suggestedPrompts.push('How can I help my team?');
  }

  if (employeeExperienceActive) {
    suggestedPrompts.push('How can I improve my experience at work?');
  }

  if (companyActive) {
    suggestedPrompts.push('How can our company improve?');
  }

  return (
    <Stack flexGrow={1} alignItems="center" justifyContent="center">
      <Stack gap={1} direction="row" alignItems="center" justifyContent="center" sx={{ flexWrap: 'wrap' }}>
        {suggestedPrompts.map((prompt, index) => (
          <Chip key={index} variant="outlined" label={prompt}
                onClick={() => onSendMessage({ sender: 'user', content: prompt })} sx={{ marginLeft: 0 }} />
        ))}
      </Stack>
    </Stack>
  );
};
