import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, ButtonBase, CardActionArea, Divider, Icon, Stack, Typography } from '@mui/material';
import { computeScoreLevel } from '../Scores/Score';
import { ModuleInterface } from '../Module/Module';
import { aspectIcon } from './AspectIcon';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import { Aspect } from './Aspect';
import ScoreCircle from '../Scores/ScoreCircle';
import ScoreDelta from '../Scores/ScoreDelta';
import ScoreSparkline from '../Scores/ScoreSparkline';
import { DashboardCard } from '../Dashboard/DashboardCard';
import { useHistoricalScores } from '../Scores/useHistoricalScores';
import { Scorable } from '../Scores/Scorable';

interface AspectCardProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspect: Aspect;
  layout?: 'vertical' | 'horizontal';
  onClick: () => void;
}

const AspectCard = ({ scorable, module, aspect, layout = 'vertical' }: AspectCardProps) => {
  return (
    <ButtonBase
      sx={{
        width: 170,
        height: 270,
      }}
    >
      <DashboardCard p={0} sx={{ width: '100%' }}>
        <CardActionArea component={RouterLink} to={aspect.id}>
          <Stack px={1} py={2} spacing={2} alignItems='center' justifyContent='center'>
            <Stack alignItems='center'>
              <Icon>{aspectIcon(module.type, aspect.name)}</Icon>
              <Typography align='center'>{aspect.name}</Typography>
            </Stack>
            <AspectCardContent scorable={scorable} module={module} aspect={aspect} layout={layout} />
          </Stack>
        </CardActionArea>
      </DashboardCard>
    </ButtonBase>
  );
};

interface AspectCardContentProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspect: Aspect;
  layout: 'vertical' | 'horizontal';
  onClickScore?: () => void;
  onClickTrend?: () => void;
}

export const AspectCardContent = ({ scorable, module, aspect, layout, onClickScore, onClickTrend }: AspectCardContentProps) => {
  const { colorOnLight } = computeScoreLevel(aspect.health);

  if (layout === 'vertical') {
    return (
      <Stack spacing={2}>
        <ScoreCircle width='100px' score={aspect.health} scoreFontSize='48px' color={colorOnLight} />

        <Stack direction='row' spacing={1} justifyContent='stretch' divider={<Divider flexItem orientation='vertical' />}>
          <Box flex={1} flexBasis='100%'>
            <Stack alignItems='center'>
              <WaterDropRoundedIcon className='intelligence-icon' />
              <Typography>{aspect.intelligence || '-'}</Typography>
            </Stack>
          </Box>
          <Box flex={1} flexBasis='100%'>
            <Trend scorable={scorable} module={module} aspect={aspect} />
          </Box>
        </Stack>
      </Stack>
    );
  } else {
    return null;
  }
};

interface TrendProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspect: Aspect;
  onClick?: () => void;
}

const Trend = ({ scorable, module, aspect, onClick }: TrendProps) => {
  const historicalScores = useHistoricalScores(scorable, module, aspect);
  const scoreDelta: number = aspect.healthDelta || 0;

  return (
    <ButtonBase component={onClick ? 'button' : 'div'} onClick={onClick} disabled={!onClick}>
      <Stack spacing='4px'>
        <ScoreSparkline historicalScores={historicalScores} />
        <ScoreDelta scoreDelta={scoreDelta} />
      </Stack>
    </ButtonBase>
  );
};

export default AspectCard;
