import { UserInterface } from '../User/User';
import { ProjectInterface } from './ProjectInterface';

export const PROJECT_STATUS_ACTIVE = 'active';

export const PROJECT_MEMBER = 'member';
export const PROJECT_NON_MEMBER = 'non_member';

// TODO: deprecate?
export const useProject = (project: ProjectInterface) => {
  return {
    isProjectOwner: (user: UserInterface) => isProjectOwner(project, user),
  };
};

export const isProjectOwner = (project: ProjectInterface, user: UserInterface) => project.owners.some(owner => owner.id === user.id);
