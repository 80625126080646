import { Stack, Typography } from '@mui/material';

import ScoreBar from '../Scores/ScoreBar';
import { useWorkspace } from '../Workspace/useWorkspace';
import { Aspect } from './Aspect';
import { ClientInterface } from '../Client/ClientInterface';
import { sortByScore } from '../Scores/Score';
import { ProjectInterface } from '../Project/ProjectInterface';
import { useLayout } from '../UI/LayoutContext';

const AspectScorables = ({ aspect }: { aspect: Aspect }) => {
  const layout = useLayout();

  return (
    <Stack direction={layout === 'mobile' ? 'column' : 'row'} spacing={4} width='100%'>
      <AspectClients aspect={aspect} />
      <AspectProjects aspect={aspect} />
    </Stack>
  );
};

const AspectClients = ({ aspect }: { aspect: Aspect }) => {
  const { workspace } = useWorkspace();
  if (aspect.clients && aspect.clients.length > 0) {
    const sortedClients: ClientInterface[] = sortByScore(aspect.clients); // TODO: move sorting to the server
    return (
      <Stack flex={1} spacing={2}>
        <Typography variant='h6'>Lowest {aspect.name.toLowerCase()} scores by client</Typography>
        {sortedClients.map((client: ClientInterface) => {
          const path = `/workspace/${workspace.id}/clients/${client.id}`;
          return <ScoreBar key={client.id} name={client.name} score={client.health} intelligence={client.intelligence} path={path} />;
        })}
      </Stack>
    );
  } else {
    return null;
  }
};

const AspectProjects = ({ aspect }: { aspect: Aspect }) => {
  const { workspace } = useWorkspace();
  if (aspect.projects && aspect.projects.length > 0) {
    const sortedProjects: ProjectInterface[] = sortByScore(aspect.projects); // TODO: move sorting to the server
    return (
      <Stack flex={1} spacing={2}>
        <Typography variant='h6'>Lowest {aspect.name.toLowerCase()} scores by client</Typography>
        {sortedProjects.map((project: ProjectInterface) => {
          const path = `/workspace/${workspace.id}/projects/${project.id}`;
          return <ScoreBar key={project.id} name={project.name} score={project.health} intelligence={project.intelligence} path={path} />;
        })}
      </Stack>
    );
  } else {
    return null;
  }
};

export default AspectScorables;
