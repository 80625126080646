import { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import SurveyScheduleDialog from '../Account/SurveyScheduleDialog';

import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle } from '../UI/Section';
import { getDaysFromSchedule, getHourFromSchedule } from '../util/schedule';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

const SurveySettings = ({ workspaceSettings }: { workspaceSettings: WorkspaceInterface }) => {
  const [surveyScheduleDialogOpen, setSurveyScheduleDialogOpen] = useState(false);
  // const engagementModule = useEngagementModule();
  // const projectsModule = useProjectsModule();
  const { surveySchedule } = workspaceSettings;

  let scheduleString = 'No schedule set';
  if (surveySchedule) {
    const days = getDaysFromSchedule(surveySchedule);
    const daysString = days.map(dayIndex => dayjs().day(dayIndex).format('ddd')).join(', ');
    const hour = getHourFromSchedule(surveySchedule);
    const hourString = dayjs().hour(hour).format('h A');

    scheduleString = daysString + ' at ' + hourString;
  }

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <WaterDropRoundedIcon />
        </SectionHeaderIcon>
        <SectionHeaderTitle>Intelligence</SectionHeaderTitle>
      </SectionHeader>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Setting</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Intelligence Collection Schedule</TableCell>
            <TableCell>{scheduleString}</TableCell>
            <TableCell align='right'>
              <Button onClick={() => setSurveyScheduleDialogOpen(true)}>Edit</Button>
            </TableCell>
          </TableRow>
          <TableRow></TableRow>
        </TableBody>
      </Table>
      <SurveyScheduleDialog workspaceSettings={workspaceSettings} open={surveyScheduleDialogOpen} onClose={() => setSurveyScheduleDialogOpen(false)} />
    </AppSurface>
  );
};

export default SurveySettings;
