import PageNotice from '../UI/PageNotice';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import { MembershipInterface } from './Membership';
import React from 'react';

interface MembershipNoticeProps {
  memberCollector: MembershipInterface;
  onJoin: () => void;
}

const MembershipNotice = ({ memberCollector, onJoin }: MembershipNoticeProps) => {
  const lowercaseName = memberCollector.__typename!.toLowerCase();

  //  console.log(memberCollector);
  //  console.log(memberCollector.member);

  return (
    <PageNotice
      noticeKey={`not-${memberCollector.__typename}-member-${memberCollector.id}`}
      applicable={!memberCollector.member}
      message={`You are not a member of this ${lowercaseName}`}
      actions={[
        {
          icon: PersonAddRoundedIcon,
          label: 'Join',
          onSelect: onJoin,
        },
      ]}
    />
  );
};

export default MembershipNotice;
