import React, { createContext, useContext, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useMachine } from '@xstate/react';

import { SURVEY_RESPONSE_FIELDS_FRAGMENT } from './useSurvey';
import { surveysMachine, SurveysMachineEvent, SurveysMachineTypeState } from './SurveysMachine';
import useWorkspace from '../Workspace/useWorkspace';
import { useIntelligence } from '../Intelligence/IntelligenceContext';
import { useMounted } from '../util/useMounted';
import useAuthenticatedUser from '../User/useAuthenticatedUser';

type SurveysContextValues = {
  state: SurveysMachineTypeState;
  send: (event: SurveysMachineEvent) => void;
  refetch: () => Promise<any>;
};

export const useSurveys = () => {
  const context = useContext<SurveysContextValues | null>(SurveysContext);
  if (!context) {
    throw new Error('useSurveys must be used within SurveysContext.SurveysProvider');
  }
  return context;
};

const SurveysContext = createContext<SurveysContextValues | null>(null);

export const SurveysProvider = ({ children }: { children: React.ReactNode }) => {
  const mounted = useMounted();
  const { user } = useAuthenticatedUser();
  const { workspace } = useWorkspace();
  const { intelligenceAction } = useIntelligence();

  const { data, error, refetch } = useQuery(SURVEYS_QUERY, {
    skip: !workspace?.id || !mounted,
    variables: { workspaceId: workspace.id, userId: user.id },
    // pollInterval: 60000,
  });

  const [state, send] = useMachine(surveysMachine, {
    actions: { refetchSurveys: async context => await refetch() },
  });

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (data) {
      send({ type: 'SET_SURVEYS', surveys: data.surveys });
    }
  }, [data, send]);

  useEffect(() => {
    if (intelligenceAction) {
      const { action } = intelligenceAction;
      if (action === 'refetch') {
        void refetch();
      } else if (action === 'contribute') {
        send({ type: 'CONTRIBUTE' });
        void refetch();
      } else if (action === 'collect') {
        send({ type: 'COLLECT' });
      }
    }
  }, [intelligenceAction, send, refetch]);

  return <SurveysContext.Provider value={{ state: state as SurveysMachineTypeState, send, refetch }}>{children}</SurveysContext.Provider>;
};

const SURVEYS_QUERY = gql`
  query Surveys($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
    }
    surveys(workspaceId: $workspaceId) {
      id
      requester {
        id
        firstName
        lastName
      }
      module {
        id
        name
        type
      }
      scorable {
        id
        ... on Workspace {
          id
          name
          company {
            id
            name
            imageUrl
          }
        }
        ... on Project {
          id
          name
          client {
            id
            name
            imageUrl
          }
        }
        ... on Client {
          id
          name
          imageUrl
        }
        ... on Team {
          id
          name
          icon {
            symbol
            color
          }
        }
      }
      ...SurveyResponseFields
    }
  }
  ${SURVEY_RESPONSE_FIELDS_FRAGMENT}
`;
