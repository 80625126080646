import { gql } from '@apollo/client';

export const UPDATE_TEAM_MUTATION = gql`
  mutation UpdateTeam($id: ID!, $parentTeamId: ID, $name: String, $icon: IconInput, $managerId: ID) {
    updateTeam(id: $id, parentTeamId: $parentTeamId, name: $name, icon: $icon, managerId: $managerId) {
      team {
        id
        parentTeam {
          id
          name
        }
        name
        icon {
          symbol
          color
        }
        manager {
          id
          firstName
          lastName
        }
        users {
          id
          firstName
          lastName
          email
          pendingInvitation
        }
      }
      errors {
        type
        fullMessage
      }
    }
  }
`;

// export const ADD_TEAM_MEMBERS_MUTATION = gql`
//   mutation AddTeamMembers($id:ID!, $userIds:[ID!]) {
//     addTeamMembers(id: $id, userIds: $userIds) {
//       success
//     }
//   }
// `

// export const REMOVE_TEAM_MEMBERS_MUTATION = gql`
//   mutation RemoveTeamMembers($id:ID!, $userIds:[ID!]) {
//     removeTeamMembers(id: $id, userIds: $userIds) {
//       success
//     }
//   }
// `

export const DELETE_TEAM_MUTATION = gql`
  mutation DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      success
      errors {
        type
        fullMessage
      }
    }
  }
`;
