import React, { Fragment } from 'react';
import { useQuery, gql } from '@apollo/client';
import { ReactComponent as WorkspaceIconSvg } from '../Icons/Hive-Icon.svg';
import { SCORABLE_SCORE_FIELDS_FRAGMENT } from '../Scores/ScorableBase';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import WorkspaceNotices from '../Workspace/WorkspaceNotices';
import WorkspaceSecondaryNavigation from './WorkspaceSecondaryNavigation';
import PageStyle from '../UI/PageStyle';
import { useMounted } from '../util/useMounted';
import DashboardCategories from '../Dashboard/DashboardCategories';
import { useWorkspace } from './useWorkspace';
import { useScope } from '../Scope/useScope';
import { CATEGORY_SCORE_FIELDS_FRAGMENT } from '../Category/Category';
import SvgIcon from '@mui/material/SvgIcon';
import { DashboardSurface } from '../Dashboard/Dashboard';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import NoticationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import { DashboardStats, DashboardStat, IntelligenceDashboardStat, PersonalContributionDashboardStat } from '../Dashboard/DashboardStats';
import DashboardSummary from '../Dashboard/DashboardSummary';
import { WorkspaceInterface, WorkspaceScoresInterface } from './WorkspaceInterface';
import { useTimeframe } from '../User/useTimeframe';
import ClientAvatar from '../Client/ClientAvatar';
import { CLIENT_BREAKDOWN_FRAGMENT } from '../Client/ClientCollector';

const WorkspaceIcon = () => {
  return (
    <SvgIcon>
      <WorkspaceIconSvg />
    </SvgIcon>
  );
};

const WorkspaceDashboard = () => {
  const { workspace } = useWorkspace();
  const { scopes } = useScope();
  const scopeOptions = scopes.map(scope => {
    //    const count = scope.key === availableScope.key ? clients.length : undefined;
    return { label: `${scope.selectorPrefix} Scores`, scope: scope, count: undefined };
  });

  const mounted = useMounted();
  if (!mounted) {
    return null;
  }

  return (
    <Fragment>
      <WorkspaceSecondaryNavigation scopeOptions={scopeOptions} iconElement={<ClientAvatar client={workspace.company} />} />
      <WorkspaceNotices />

      <PageStyle design='standard' theme='dark' fullWidth>
        <WorkspaceScores />
      </PageStyle>
    </Fragment>
  );
};

const WorkspaceScores = () => {
  const { workspace } = useWorkspace();
  const { id } = workspace;
  const { scope } = useScope();
  const { days } = useTimeframe();
  const { data } = useQuery(WORKSPACE_SCORES_QUERY, { variables: { id: id, ...scope.variables, days: days } });

  const workspaceScores: WorkspaceInterface = data?.workspace;

  const scopePrefix = scope.type === 'Unscoped' ? '' : scope.prefix;

  return (
    <Fragment>
      <DashboardSurface variant='primary' title={`${scopePrefix}Workspace`}>
        <DashboardSummary scorable={workspaceScores} scorableLabel='Hyve' scopePrefix={scope.prefix} icon={WorkspaceIcon} />
        <WorkspaceDashboardStats workspaceScores={workspaceScores} />
      </DashboardSurface>

      <DashboardCategories scorable={workspaceScores} />
    </Fragment>
  );
};

const WorkspaceDashboardStats = ({ workspaceScores }: { workspaceScores: WorkspaceScoresInterface }) => {
  const { scope } = useScope();
  if (scope.type === 'Personal') {
    return <PersonalWorkspaceDashboardStats workspaceScores={workspaceScores} />;
  } else {
    return <GroupWorkspaceDashboardStats scores={workspaceScores} />;
  }
};

const PersonalWorkspaceDashboardStats = ({ workspaceScores }: { workspaceScores: WorkspaceScoresInterface | undefined }) => {
  return (
    <DashboardStats>
      <IntelligenceDashboardStat scorable={workspaceScores} label='My Hyve Intelligence' />
      <PersonalContributionDashboardStat scorable={workspaceScores} />
      <DashboardStat icon={NoticationsActiveRoundedIcon} label='Pending Surveys' value={workspaceScores?.surveyStats.count || '-'} path={`intelligence`} />
    </DashboardStats>
  );
};

const GroupWorkspaceDashboardStats = ({ scores }: { scores: WorkspaceScoresInterface }) => {
  const contributorPercentage = scores?.intelligenceStats.contributorPercentage;
  const recentlyCompletedSurveyCount = scores?.surveyStats.recentlyCompletedCount;
  const { days } = useTimeframe();
  return (
    <DashboardStats>
      <IntelligenceDashboardStat scorable={scores} label='Hyve Intelligence' />
      <DashboardStat
        icon={BarChartRoundedIcon}
        label='Member Participation'
        value={contributorPercentage ? `${contributorPercentage}%` : '-'}
        valueLabel='of employees'
      />
      <DashboardStat icon={NoticationsActiveRoundedIcon} label='Surveys Answered' value={recentlyCompletedSurveyCount} valueLabel={`(past ${days} days)`} />
    </DashboardStats>
  );
};

export default WorkspaceDashboard;

const WORKSPACE_SCORES_QUERY = gql`
  query WorkspaceScores($id: ID!, $scope: ScopeInput, $days: Int) {
    workspace(id: $id, scope: $scope) {
      id
      name

      surveyStats {
        count
        recentlyCompletedCount: count(completedWithin: $days)
      }
      memberStats {
        count
      }
      clientStats {
        count
      }
      intelligenceStats(lookback: $days) {
        relativeContribution
        contributorPercentage
      }

      member

      ...ScoreFields
      ...ClientBreakdown

      categories {
        id
        name
        type
        ...CategoryScoreFields
        ...ClientBreakdown
      }

      ...ScorableSurveyFields

      company {
        id
        name
        # health
      }
    }
  }
  ${SCORABLE_SCORE_FIELDS_FRAGMENT}
  ${CLIENT_BREAKDOWN_FRAGMENT}
  ${CATEGORY_SCORE_FIELDS_FRAGMENT}
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;
