import { useWorkspace } from '../Workspace/useWorkspace';
import { useContributeIntelligence } from '../Intelligence/ContributeAndCollect';
import PageStyle from '../UI/PageStyle';
import SurveyMessage from './SurveyMessage';
import { Button, Stack, Typography } from '@mui/material';
import { CollectIntelligence } from './SurveyActions';
import Illustration from '../UI/Illustration';

const NoIntelligenceNeeded = () => {
  const { workspace } = useWorkspace();
  const { contributeIntelligence, contributeIntelligenceDisabled } = useContributeIntelligence(workspace);

  const handleContribute = async () => {
    await contributeIntelligence();
  };

  return (
    <PageStyle design='illustrated'>
      <Stack spacing={1} alignItems='center'>
        <SurveyMessage>
          <Stack alignItems='center'>
            <Typography variant='h4' style={{ textAlign: 'center' }}>
              Thanks for checking in.
            </Typography>
            <Typography variant='h5' sx={{ textAlign: 'center', textWrap: 'balance' }}>
              No intelligence is needed from you at the moment, but you can always give it a boost if you'd like.
            </Typography>
          </Stack>

          <Stack direction='row' spacing={1} justifyContent='center'>
            <Button variant='contained' disableElevation color='primary' href={`/workspace/${workspace.id}`}>
              View Dashboard
            </Button>
            <Button variant='contained' disableElevation color='primary' onClick={handleContribute} disabled={contributeIntelligenceDisabled}>
              Contribute Intelligence!
            </Button>
          </Stack>
          {workspace.canCollectIntelligence && <CollectIntelligence />}
        </SurveyMessage>
        <Illustration image='checking-in' alt='No intelligence needed' />
      </Stack>
    </PageStyle>
  );
};

export default NoIntelligenceNeeded;
