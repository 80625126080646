import React from 'react';
import { useTheme } from '@mui/material/styles';

interface IllustrationProps {
  image: string;
  alt: string;
  width?: string;
}

const Illustration = ({ image, alt, width = '546px' }: IllustrationProps) => {
  const theme = useTheme();
  return <img style={{ width: width, maxWidth: '100%', maxHeight: '380px' }} alt={alt} src={`/images/${image}-${theme.palette.mode}.svg`} />;
};

export default Illustration;
