import { z } from 'zod';
import { gql, useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

export const subVerticalSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const verticalSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type Vertical = z.infer<typeof verticalSchema>;
export type SubVertical = z.infer<typeof subVerticalSchema>;

type VerticalWithSubVerticals = Vertical & {
  subVerticals: SubVertical[];
};

type VerticalsData = {
  verticals: VerticalWithSubVerticals[];
};

const VerticalSelect = () => {
  const { control, watch } = useFormContext();
  const selectedVertical: Vertical = watch('vertical');
  const { data: verticalsData } = useQuery<VerticalsData>(VERTICALS_QUERY);

  const verticals = verticalsData?.verticals || [];

  return (
    <Stack spacing={2}>
      <Controller
        name='vertical'
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id='vertical-label'>Industry Vertical</InputLabel>
            <Select
              {...field}
              labelId='vertical-label'
              label='Industry Vertical'
              value={field.value ? field.value.id : ''}
              onChange={e => {
                // Find the selected vertical object based on the ID
                const selectedVertical = verticals.find(v => v.id === e.target.value) || null;
                field.onChange(selectedVertical);
              }}
            >
              <MenuItem value=''>Select a Vertical</MenuItem>
              {verticals.map((vertical: Vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  {vertical.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      {selectedVertical && (
        <FormControl fullWidth>
          <InputLabel id={`subVertical-label`}>Sub-Vertical</InputLabel>
          <Controller
            name={`subVertical`}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId={`subVertical-label`}
                label='Industry Sub-Vertical'
                value={field.value ? field.value.id : ''}
                onChange={e => {
                  const subVerticals = verticals.find(v => v.id === selectedVertical.id)?.subVerticals;
                  const selectedSubVertical = subVerticals?.find(v => v.id === e.target.value) || null;
                  field.onChange(selectedSubVertical);
                }}
              >
                <MenuItem value=''>Select a Sub-Vertical</MenuItem>
                {verticals
                  .find(v => v.id === selectedVertical?.id)
                  ?.subVerticals.map(subVertical => (
                    <MenuItem key={subVertical.id} value={subVertical.id}>
                      {subVertical.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default VerticalSelect;

const VERTICALS_QUERY = gql`
  query Verticals {
    verticals {
      id
      name
      subVerticals {
        id
        name
      }
    }
  }
`;
