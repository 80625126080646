import React from 'react';
import { Stack, Typography } from '@mui/material';

import { useScope } from '../Scope/useScope';
import { getScorableInfo } from './ScorableBase';
import { computeScoreLevel, SCORE_LEVELS } from './Score';
import ScorablePhrase, { determiner } from '../Scores/ScorablePhrase';
import { IconType } from '../UI/Icon';
import MeasureModal, { MeasureLevels } from './MeasureModal';
import ScoreCircle from './ScoreCircle';
import { ScopeType } from '../Scope/Scope';
import { CategoryType } from '../Category/Category';
import { ModuleInterface, MODULES } from '../Module/Module';
import { Scorable, ScorableType } from './Scorable';
import { ScoreDimension } from './ScoreDimension';

type ScoreDescriptionMap = {
  [type in ScorableType | CategoryType]: {
    [key in ScopeType]?: string;
  };
};

const SCORE_DESCRIPTION_MAP: ScoreDescriptionMap = {
  Workspace: {
    Personal: `Your personal Workspace Score is derived from all the assessment scores and their respective aspects across the entire workspace.`,
    Unscoped: `The Workspace Score is derived from all employee assessment scores and their respective aspects across the entire workspace.`,
    Team: `The Team Score is derived from all team member assessment scores and their respective aspects across the entire workspace.`,
  },
  ClientsCategory: {
    Personal: `Your personal Clients Score only includes your clients and is derived from your client assessment scores and their respective aspects.`,
    Unscoped: `The workspace Clients Score is derived from all clients and all employee client assessment scores and their respective aspects.`,
    Team: `The Team Clients Score is derived from all team member client assessment scores and their respective aspects.`,
  },
  EmployeeExperienceCategory: {
    Personal: `Your personal Employee Experience Score is derived from your employee experience assessment scores and their respective aspects.`,
    Unscoped: `This score is derived from all Employee Experience Score assessments and their respective aspects.`,
    Team: `The team workspace Employee Experience Score is derived from all team member employee experience assessments and their respective aspects.`,
  },
  CompanyCategory: {
    Personal: `Your personal Company Score is derived from your company assessment scores and their respective aspects.`,
    Unscoped: `The workspace Company Score is derived from all employee company assessment scores and their respective aspects.`,
    Team: `The team workspace Company Score is derived from all team member company assessment scores and their respective aspects.`,
  },
  Client: {
    Personal: `This client score is derived from project assessment scores and their respective aspects in which you are a member.`,
    Unscoped: `This client score is derived from all project assessment scores and their respective aspects for the client.`,
    Team: `This client score is derived from all the project assessment scores and their respective aspects in which a team member belongs.`,
  },
  Project: {
    Personal: `The project score is derived from all project assessments and calculated based on its respective aspects.`,
    Unscoped: `The project score is derived from all project assessments and calculated based on its respective aspects.`,
    Team: `The project score is derived from all project assessments and calculated based on its respective aspects.`,
  },
  Team: {
    Team: `The Team Score is derived from all team member assessment scores and their respective aspects across the entire workspace.`,
  },
  WorkspaceUser: {},
};

interface ScoreModalProps {
  scorable: Scorable;
  icon: IconType;
  scoreDimension: ScoreDimension;
  label: React.ReactNode | string;
  open: boolean;
  onClose: () => void;
}

export const ScoreModal = ({ scorable, icon, label, scoreDimension, open, onClose }: ScoreModalProps) => {
  const { scope } = useScope();

  if (!scorable || !open) {
    return null;
  }

  const scoreSubject = (scoreDimension || scorable) as ScoreDimension;

  const scoreLevel = computeScoreLevel(scoreDimension.health);
  const scorableInfo = getScorableInfo(scorable);

  if (!label) {
    label = `${scorableInfo.type} Score`;
  }

  let scoreDescription;

  const scoreSubjectType = scoreSubject.__typename;

  switch (scoreSubjectType) {
    case 'Workspace':
    case 'ClientsCategory':
    case 'EmployeeExperienceCategory':
    case 'CompanyCategory':
    case 'Client':
    case 'Project':
    case 'Team':
      scoreDescription = SCORE_DESCRIPTION_MAP[scoreSubjectType][scope.type];
      break;
    default:
      if (MODULES.includes(scoreSubjectType as any)) {
        const module = scoreSubject as ModuleInterface;
        const { name } = module;
        // Your logic for module types
        if (scope.type === 'Personal') {
          scoreDescription = `Your personal ${name} Score is derived from your ${name.toLowerCase()} assessment scores and their respective aspects.`;
        } else if (scope.type === 'Unscoped') {
          scoreDescription = `This score is derived from all employee ${name} Score assessments and their respective aspects.`;
        } else if (scope.type === 'Team') {
          scoreDescription = `The Team ${name} Score is derived from all team member ${name.toLowerCase()} assessments and their respective aspects.`;
        }
      } else {
        throw new Error(`Unexpected score subject type: ${scoreSubjectType}`);
      }
  }

  const summary = (
    <Stack alignItems='center' spacing={4}>
      <ScoreCircle width='200px' icon={icon} score={scoreDimension.health} iconSize='40px' scoreFontSize='60px' />
      <Typography variant='h5' textAlign='center'>
        <ScorablePhrase
          determiner={determiner(scope, 'third')}
          scorable={scorable}
          scoreDimension={scoreDimension}
          // includeType
          includeScore
          capitalization='sentence'
        />
        {` is ${scoreLevel.name.toLowerCase()}.`}
      </Typography>
      <MeasureLevels levels={SCORE_LEVELS} />
    </Stack>
  );

  const details = (
    <Stack alignItems='center' maxWidth='670px' spacing={2}>
      <Typography variant='body1' textAlign='center' sx={{ textWrap: 'balance' }}>
        <span>{scoreDescription}</span>
      </Typography>
    </Stack>
  );

  return <MeasureModal scorable={scorable} summary={summary} details={details} open={open} onClose={onClose} />;
};
