import React, { createContext } from 'react';
import { useQuery } from '@apollo/client';

import { USER_QUERY, UserInterface } from './User';
import { useAuthentication } from './AuthenticationContext';

export interface UserContextInterface {
  user: UserInterface | null;
  isSystemAdmin: boolean;
  loading: boolean;
  refetch: () => Promise<any>;
}

const defaultUserContext = {
  user: null,
  isSystemAdmin: false,
  loading: true,
  refetch: async () => {},
};

const UserContext = createContext<UserContextInterface>(defaultUserContext);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { deauthenticate, authenticated, userId } = useAuthentication();

  const { data, loading, error, refetch } = useQuery(USER_QUERY, { skip: !userId, variables: { id: userId } });

  if (!authenticated) {
    return <UserContext.Provider value={{ user: null, isSystemAdmin: false, loading: false, refetch }}>{children}</UserContext.Provider>;
  }

  if (error) {
    console.error('Authentication failed, returning to sign in');
    deauthenticate();
  }

  const user: UserInterface = data?.user;
  const isSystemAdmin = user?.effectiveRoles?.includes('SystemAdmin') || false;

  return (
    <UserContext.Provider key={user?.id} value={{ user, isSystemAdmin, loading, refetch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
