import { useRef, useEffect, useState } from 'react';
import { Avatar, Box, Link, List, ListItem } from '@mui/material';
import { ListItemIcon, ListItemText, Stack, useTheme } from '@mui/material';

import { ReactComponent as HyveLogoMarkSvg } from '../Icons/Hyve-Logo-Mark.svg';
import useUser from '../User/useUser';
import { Message } from './AIContext';
import { HYVE_BLUE } from '../Theme/AppTheme';

const AIMessages = ({ messages }: { messages: Message[] }) => {
  const { user } = useUser();
  const theme = useTheme();
  const { animatedEllipsis } = useAnimatedEllipsis();
  const messagesEndRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Stack flexGrow={1} sx={{ overflowY: 'auto' }}>
      <Stack flexGrow={1} justifyContent='end'>
        <List sx={{ padding: 0 }}>
          {messages.map((message, index) => {
            // const avatarSrc = message.sender === 'user' ? user?.avatar : '../Icons/Hyve-Logo-Centered.svg';
            const formattedMessageContent = formatMessageContent(message.content.replace('{ellipsis}', animatedEllipsis));

            const avatarStyle = { width: 24, height: 24 };
            const avatar =
              message.sender === 'user' ? (
                <Avatar src={user?.avatar} sx={avatarStyle} />
              ) : (
                <Avatar sx={{ ...avatarStyle, backgroundColor: theme.palette.secondary.main }}>
                  <HyveLogoMarkSvg style={{ width: '20px', height: '20px' }} />
                </Avatar>
              );

            return (
              <ListItem key={index} disableGutters>
                <ListItemIcon sx={{ minWidth: 32, marginTop: '3px', alignSelf: 'flex-start' }}>{avatar}</ListItemIcon>
                <ListItemText style={{ whiteSpace: 'pre-wrap' }}>{formattedMessageContent}</ListItemText>
              </ListItem>
            );
          })}
        </List>
        <Box ref={messagesEndRef} />
      </Stack>
    </Stack>
  );
};

export default AIMessages;

const formatMessageContent = (content: string) => {
  // Regular expression to match URLs, email addresses, and bold text
  const regex = /(\bhttps?:\/\/\S+)|(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b)|(\*\*[^*]+\*\*)/gi;

  // Split text into parts based on URLs, emails, and bold text
  const parts = content.split(regex);

  return parts.map((part, index) => {
    if (!part) return null; // Ignore empty strings

    // Check if part is a URL
    if (part.match(/^https?:\/\//)) {
      return (
        <Link key={index} href={part} target='_blank' rel='noopener noreferrer' color={HYVE_BLUE}>
          {part}
        </Link>
      );
    }

    // Check if part is an email
    if (part.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
      return (
        <Link key={index} href={`mailto:${part}`} target='_blank' rel='noopener noreferrer' color={HYVE_BLUE}>
          {part}
        </Link>
      );
    }

    // Handle bold text
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }

    // Regular text
    return part;
  });
};

const useAnimatedEllipsis = () => {
  const [animatedEllipsis, setAnimatedEllipsis] = useState('');

  useEffect(() => {
    const dotCycle = ['.', '..', '...'];
    let index = 0;

    const interval = setInterval(() => {
      setAnimatedEllipsis(dotCycle[index % dotCycle.length]);
      index++;
      }, 500); // Change the interval as needed

    return () => clearInterval(interval);
    }, []);

  const resetAnimatedEllipsis = () => setAnimatedEllipsis('.');

  return { animatedEllipsis, resetAnimatedEllipsis };
};