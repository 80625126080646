import React, { useState } from 'react';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'react-router-dom';
import { Button, Stack, TextField, Typography } from '@mui/material';

import hyveApi from '../util/hyveApi';
import PageStyle from '../UI/PageStyle';
import BrandedForm, { SubmitButton } from '../UI/BrandedForm';

const schema = z.object({
  email: z.string().email('Email must be valid'),
});

type Schema = z.infer<typeof schema>;

const ForgotPassword = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async ({ email }: { email: string }) => {
    setErrorMessage('');
    try {
      const response = await hyveApi.create({ noAuth: true }).post('/users/password', {
        user: { email: email },
      });

      setSuccessMessage(
        `Instructions for resetting your password have been sent to ${email}. You’ll receive this email within 5 minutes. Be sure to check your spam folder just in case.`
      );
      return response;
    } catch (error) {
      setErrorMessage('There was an error requesting password reset instructions.');
    }
  };

  if (successMessage) {
    return <PasswordResetSuccess />;
  }

  return (
    <PageStyle design='branded'>
      <BrandedForm
        title='It’s alright. Everybody forgets sometimes.'
        subtitle='Enter your email address and we’ll send you a message to reset your password.'
        onSubmit={handleSubmit(onSubmit)}
        successMessage={successMessage}
        errorMessage={errorMessage}
      >
        <Controller
          name='email'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} id='email' name='email' type='email' label='Email' variant='outlined' fullWidth error={!!error} helperText={error?.message} />
          )}
        />
        <SubmitButton isSubmitting={isSubmitting}>Reset Password</SubmitButton>
        <Typography variant='body2' textAlign='center'>
          <Link to={'/sign-in'}>Return to Sign in Page</Link>
        </Typography>
      </BrandedForm>
    </PageStyle>
  );
};

const PasswordResetSuccess = () => {
  return (
    <PageStyle design='branded'>
      <Stack gap={2} alignItems='center'>
        <h3>Time to check your inbox.</h3>
        <Typography variant='h6'></Typography>
        <Typography variant='body1'>We just sent you an email with a link to reset your password.</Typography>
        <Button variant='contained' disableElevation color='primary' href='/sign-in'>
          Back to Sign in Page
        </Button>
      </Stack>
    </PageStyle>
  );
};

export default ForgotPassword;
