import React, { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material';
import { IconType } from '../UI/Icon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, resolvePath, NavLink } from 'react-router-dom';
import { useLayout } from '../UI/LayoutContext';

export interface TertiaryNavigationTab {
  key: string;
  value?: any;
  path: string;
  icon: IconType;
  label: string;
  component?: React.ReactNode;
}

export const useTertiaryNavigationTabs = (tabs: TertiaryNavigationTab[], basePath: string) => {
  const { pathname } = useLocation();

  const reversedTabs = tabs.slice().reverse(); // TODO: this is kind of hacky
  const matchingTab = reversedTabs.find(tab => {
    const resolvedPathname = resolvePath(tab.path, basePath).pathname;
    return pathname.startsWith(resolvedPathname);
  });

  const [selectedTab, setSelectedTab] = useState<TertiaryNavigationTab>(matchingTab || tabs[0]);

  useEffect(() => {
    // TODO: figure out why the tabs are not identical objects. should be able to simply call setSelectedTab
    if (matchingTab && matchingTab.key !== selectedTab.key) {
      setSelectedTab(matchingTab);
    }
  }, [selectedTab, matchingTab]);

  return { tabs, selectedTab, setSelectedTab, basePath };
};

interface TertiaryNavigationTabsProps {
  tabs: TertiaryNavigationTab[];
  selectedTab: TertiaryNavigationTab;
  setSelectedTab: (tab: TertiaryNavigationTab) => void;
  onChangeSelectedTab?: (event: React.SyntheticEvent, selectedTab: TertiaryNavigationTab) => void;
  basePath: string;
}

const TertiaryNavigationTabs = ({ tabs, selectedTab, setSelectedTab, onChangeSelectedTab, basePath }: TertiaryNavigationTabsProps) => {
  const layout = useLayout();
  const theme = useTheme();
  if (tabs.length === 1) {
    // return null;
  }

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    const selectedTab = tabs[tabIndex];
    setSelectedTab(selectedTab);
    if (onChangeSelectedTab) {
      onChangeSelectedTab(event, selectedTab);
    }
  };

  const selectedTabIndex = tabs.findIndex(tab => tab.key === selectedTab.key);

  let tabStyles: any = {};
  if (['laptop', 'tablet'].includes(layout!)) {
    tabStyles.flexGrow = 1;
    tabStyles.flexShrink = 1;
  }

  const iconTabs = layout === 'mobile' && tabs.length > 2;

  if (iconTabs) {
    tabStyles.px = 0;
    tabStyles.minWidth = '48px';
    tabStyles.minHeight = '48px';
  }

  //  let mobileStyles = layout === 'mobile' ? { padding: 0 } : {};

  return (
    <StyledTabs
      value={selectedTabIndex}
      onChange={handleChange}
      textColor='secondary'
      indicatorColor='secondary'
      scrollButtons='auto'
      variant='scrollable'
      allowScrollButtonsMobile={true}
      sx={{ '& .MuiTab-root': { mr: 1 } }}
    >
      {tabs.map((tab, index) => {
        const label = !iconTabs && tab.label;
        return (
          <Tab
            key={index}
            icon={React.createElement(tab.icon)}
            iconPosition='start'
            label={label}
            color='secondary'
            sx={{
              px: '20px',
              py: '4px',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              transition: 'background-color 0.3s',
              ...tabStyles,
            }}
            to={resolvePath(tab.path, basePath)}
            component={NavLink}
          />
        );
      })}
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)`
  width: 100%;
  min-height: 30px;
  // gap: 8px;

  .MuiTab-root {
    max-width: 230px;
    min-height: 30px;
    //    text-align: left;
    text-transform: none;
    border-radius: 3px;
    font-size: 12px;
    &.Mui-selected {
      color: #000;
      background-color: #ffd73e;
    }
  }
  .MuiTabs-indicator {
    background-color: inherit;
  }
  .MuiTabs-scrollButtons {
    color: white;
  }
`;

export default TertiaryNavigationTabs;
