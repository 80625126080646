import React, { useEffect, useRef, useState } from 'react';
import { DefaultValues, FieldValues, FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import isEqual from 'lodash/isEqual';
import { FetchResult } from '@apollo/client';

import { DialogContentText, DialogContent, DialogTitle, Alert, DialogActions, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StyledDialog from './StyledDialog';
import { handleMutationResponse } from '../util/handleMutationResponse';

type UseFormDialogProps<T extends FieldValues> = {
  schema: z.ZodSchema;
  defaultValues?: DefaultValues<T>;
};

type UseFormDialogReturn<T extends FieldValues> = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleResponse: (response: FetchResult<any>, mutationName: string, defaultErrorMessage: string) => boolean;
  responseError: Error | undefined;
  setResponseError: (error: Error | undefined) => void;
  methods: UseFormReturn<T>;
};

export const useFormDialog = <T extends FieldValues>({ schema, defaultValues }: UseFormDialogProps<T>): UseFormDialogReturn<T> => {
  const [open, setOpen] = useState(false);
  const [responseError, setResponseError] = useState<Error | undefined>();
  const methods = useForm<T>({ resolver: zodResolver(schema), defaultValues });
  const prevDefaultValuesRef = useRef<DefaultValues<T> | undefined>(defaultValues);
  const { reset } = methods;

  useEffect(() => {
    if (!isEqual(prevDefaultValuesRef.current, defaultValues)) {
      reset(defaultValues);
      prevDefaultValuesRef.current = defaultValues;
    }
  }, [defaultValues, reset]);

  const handleResponse = (response: FetchResult<any>, mutationName: string, defaultErrorMessage: string) => {
    const { success, errorMessage } = handleMutationResponse(response, mutationName, defaultErrorMessage);
    if (!success) {
      setResponseError(new Error(errorMessage));
    }
    return success;
  };

  return { open, setOpen, handleResponse, responseError, setResponseError, methods };
};

interface FormDialogProps<T extends FieldValues> {
  methods: UseFormReturn<T>;
  id: string;
  icon: React.ReactElement;
  title: string;
  subtitle?: string;
  mutationError: Error | undefined;
  responseError: Error | undefined;
  setResponseError: (error: Error | undefined) => void;
  children: React.ReactNode;
  submitLabel: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: T) => Promise<boolean>;
}

const FormDialog = <T extends FieldValues>({
  methods,
  id,
  icon,
  title,
  subtitle,
  mutationError,
  responseError,
  setResponseError,
  children,
  submitLabel,
  open,
  onClose,
  onSubmit,
}: FormDialogProps<T>) => {
  const { reset, handleSubmit, formState } = methods;
  const { errors, isSubmitting } = formState;

  const handleClose = () => {
    reset();
    setResponseError(undefined);
    onClose();
  };

  const renderError = () => {
    if (mutationError) {
      return <Alert severity='error'>{mutationError.message}</Alert>;
    } else if (responseError) {
      return <Alert severity='error'>{responseError.message}</Alert>;
    }
    return null;
  };

  if (Object.keys(errors).length > 0) {
    console.error(errors);
  }

  const internalHandleSubmit = async (values: T) => {
    const success = await onSubmit(values);
    if (success) {
      handleClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>
        {icon}
        {title}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
          <FormProvider {...methods}>
            {renderError()}

            <form id={id} onSubmit={handleSubmit(internalHandleSubmit)}>
              {children}
            </form>
          </FormProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' disableElevation color='subtle' onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>

        <LoadingButton
          name='submit'
          variant='contained'
          disableElevation
          color='primary'
          type='submit'
          form={id}
          loading={isSubmitting}
          disabled={isSubmitting}
          style={{ whiteSpace: 'nowrap' }}
        >
          {submitLabel}
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default FormDialog;
