import { gql } from '@apollo/client';
import { UserInterface } from '../User/User';
import { ClientInterface } from './ClientInterface';

export const useClient = (client: ClientInterface) => {
  const isOwner = (user: UserInterface) => client.owners.some((owner: UserInterface) => owner.id === user.id);

  return {
    isOwner,
  };
};

export const UPDATE_CLIENT_STATUS_MUTATION = gql`
  mutation updateClientStatus($id: ID!, $status: ClientStatus!) {
    updateClientStatus(id: $id, status: $status) {
      client {
        id
        name
        status
      }
    }
  }
`;

export const ADD_CLIENT_MEMBERS_MUTATION = gql`
  mutation addClientMembers($id: ID!, $userIds: [ID!]!) {
    addClientMembers(id: $id, userIds: $userIds) {
      success
    }
  }
`;

export const REMOVE_CLIENT_MEMBERS_MUTATION = gql`
  mutation removeClientMembers($id: ID!, $userIds: [ID!]!) {
    removeClientMembers(id: $id, userIds: $userIds) {
      success
    }
  }
`;

export const CLIENT_ACTIVE_STATUS = 'active';
