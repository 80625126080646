import { gql } from '@apollo/client';
import { ProjectInterface } from '../Project/ProjectInterface';

export interface ProjectCollector {
  projects: ProjectInterface[];
  lowMediumProjects: ProjectInterface[];
  projectStats: ProjectStats;
}

export interface ProjectStats {
  count: number;
  allProjectsCount?: number;
  personalProjectCount?: number;
  scopedProjectCount?: number;

  countByScore: {
    high: number;
    medium: number;
    low: number;
    collecting: number;
  };
}

export default ProjectCollector;

export const LOW_MEDIUM_PROJECTS_FRAGMENT = `
  fragment LowMediumProjects on ProjectBearerInterface {
    lowMediumProjects: projects(healthLevel: [low, medium]) {
      id
      name
      health
      intelligence
      client {
        id
        name
        imageUrl
      }
    }
  }
`;

// TODO should be on ProjectBearerInterface
export const LOWEST_SCORE_PROJECTS_FRAGMENT = gql`
  fragment LowestScoreProjects on Aspect {
    projects(limit: 5) {
      id
      name
      health
      intelligence
    }
  }
`;
