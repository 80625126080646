import { Theme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const HYVE_YELLOW = '#ffd700';
export const HYVE_BLUE = '#0091FF';
export const APP_BAR_HEIGHT = '64px';

declare module '@mui/material/styles' {
  interface Theme {
    name: string;
    custom: {
      chart: {
        healthColor: string;
        intelligenceColor: string;
        gridColor: string;
        tickColor: string;
        backgroundColor: string;
        legend: {
          labelColor: string;
        };
        tooltip: {
          backgroundColor: string;
          color: string;
        };
      };
    };
  }

  interface Palette {
    tertiary: Palette['primary'];
    neutral: Palette['primary'];
    subtle: Palette['primary'];
    positive: Palette['primary'];
    negative: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    subtle: PaletteOptions['primary'];
    positive: PaletteOptions['primary'];
    negative: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    //    xs: false; // removes the `xs` breakpoint
    //    sm: false;
    //    md: false;
    //    lg: false;
    //    xl: false;
    //    all: true;
    //    mobile: true; // adds the `mobile` breakpoint
    //    tablet: true;
    //    laptop: true;
    //    desktop: true;
  }

  // allow configuration using `createTheme`
  //  interface ThemeOptions {
  //    status?: {
  //      danger?: string;
  //    };
  //  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    positive: true;
    negative: true;
    neutral: true;
    subtle: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true;
    subtle: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    positive: true;
    negative: true;
    neutral: true;
    subtle: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    neutral: true;
    subtle: true;
  }
}

declare module '@mui/material/Link' {
  interface LinkPropsColorOverrides {}
}

const MOBILE_MEDIA_QUERY = '@media (max-width:640px)';

export const THEME_DEFAULTS: ThemeOptions = {
  breakpoints: {
    values: {
      //      all: 0,
      xs: 0, // 0-639, mobile
      sm: 640, // 640-959, tablet
      md: 960, // 960-1199, laptop
      lg: 1200, // 1200-1599, desktop
      xl: 1600, // 1600+
    },
  },

  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: HYVE_YELLOW,
    },
    tertiary: {
      main: HYVE_BLUE,
    },
    error: {
      main: '#d0021b',
    },
    success: {
      main: '#48ac4c',
    },
    warning: {
      main: '#d0021b',
    },
    positive: {
      main: '#48ac4c', // green color
    },
    negative: {
      main: '#d0021b', // red color
    },

    // custom palette
    neutral: {
      main: '#aaa',
    },
    subtle: {
      main: 'rgba(0, 0, 0, 0.08)',
      light: 'rgba(0, 0, 0, 0.04)',
    },
    // info: {
    // },
    // success: {
    // },
    // grey // can use grey[700]
    // text { primary, secondary, disabled }
    // divider
    background: {
      // should this be here?
      paper: '#fff',
      default: '#eeeeee',
    },
    // action // active, hover, disabled, selected, focused states
    // shape: { borderRadius: 4 }
  },

  typography: {
    fontFamily: [
      'Source Sans Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 900,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    // subtitle1,
    // subtitle2
    body1: {
      fontSize: '14px',
      //      color: '#AAA',
      //      fontWeight: 300,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 300,
    },
    // button
    // caption
    // overline
  },

  // transitions
  // zIndex

  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-expanded::before': {
            opacity: 1,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: APP_BAR_HEIGHT,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
        img: {
          height: 'auto',
        },
        colorDefault: {
          backgroundColor: '#000',
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          // This targets buttons with variant="text"
          textTransform: 'none', // Disable uppercase text
        },
      },
    },
    //    MuiButton: {
    //      defaultProps: {
    //        color: 'neutral'
    //      }
    //    },
    //    MuiIconButton: {
    //      defaultProps: {
    //        color: 'neutral'
    //      }
    //    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#111',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          color: '#fff',
        },
      },
    },
    MuiCard: {
      defaultProps: {},
      styleOverrides: {
        root: {
          overflowY: 'inherit',
          '.MuiDivider-fullBleed': {
            marginLeft: '-16px',
            marginRight: '-16px',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          padding: '0 8px',
        },
        cell: {
          padding: '0 8px',
        },
        withBorderColor: ({ theme }: { theme: Theme }) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {},
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          [MOBILE_MEDIA_QUERY]: {
            fontSize: '16px',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // borderRadius: '8px'
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: { color: HYVE_YELLOW },
      },
    },

    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        //        track: { backgroundColor: 'red' }
      },
    },
    MuiToggleButton: {
      defaultProps: {
        // color: 'secondary'
      },
      styleOverrides: {
        root: ({ theme }) => {
          return {
            '&.Mui-selected': {
              color: '#000',
              backgroundColor: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.secondary.light,
              },
            },
          };
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-body2': {
            maxWidth: 600,
          },
        },
      },
    },
  },
};

// TODO: convert to use standard theme properties
// TODO: migrate to components
