import { z } from 'zod';
import { useMutation } from '@apollo/client';

import { INVITE_MUTATION } from './Invite';

export const emailArraySchema = z
  .string()
  .transform(str => str.split(/[\s,]+/).filter(Boolean)) // Split and filter
  .refine(emails => emails.every(email => z.string().email().safeParse(email).success), {
    message: 'Invalid email address found.',
  });

type EmailArray = z.infer<typeof emailArraySchema>;

export const inviteEmailsSchema = z.object({
  emails: z.string(),
});

export type InviteEmailsValues = z.infer<typeof inviteEmailsSchema>;

export const useInviteEmails = () => {
  const [inviteMutation, { error }] = useMutation(INVITE_MUTATION, { refetchQueries: ['WorkspaceUsers'] });

  const validateEmails = (emails: string) => {
    const result = emailArraySchema.safeParse(emails);
    if (result.success) {
      return true;
    } else {
      const errorMessage = result.error.issues[0].message;
      return errorMessage || 'Invalid email format. Please check and try again.';
    }
  };

  const inviteEmails = async (workspaceId: string, emails: EmailArray) => {
    const invites = emails.map(email => ({
      email,
      memberships: [
        {
          id: workspaceId,
          type: 'Workspace',
        },
      ],
    }));

    return await inviteMutation({ variables: { invites: invites } });
  };

  return { validateEmails, inviteEmails, error };
};
