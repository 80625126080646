import { useState, useRef } from 'react';
import StyledMenu from '../UI/StyledMenu';
import { MenuProps } from '@mui/material';

interface SimpleMenuProps extends MenuProps {
  onClose: () => void;
}

const SimpleMenu = ({ onClose, ...props }: SimpleMenuProps) => {
  const standardProps = {
    keepMounted: true,
    anchorOrigin: props.anchorOrigin || { vertical: 'bottom', horizontal: 'left' },
    onClick: onClose,
  };

  return <StyledMenu {...standardProps} {...props} />;
};

export const useMenu = <T extends HTMLElement | null = null>() => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<T>(null);

  const onClose = () => setOpen(false);

  return { open, setOpen, onClose, anchorRef };
};

export default SimpleMenu;
