import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { THEME_DEFAULTS } from './AppTheme';

const LIGHT_GARBAGE = {
  // backgroundColor: '#fff',
  paragraphColor: '#000',
  // dividerColor: 'rgba(0, 0, 0, 0.12)',
  subsection: {
    contained: {
      backgroundColor: '#f6f6f6',
    },
  },
  button: {
    // TODO move to palette
    text: {
      color: 'rgba(0, 0, 0, 0.54)',
      hover: {
        color: '#000',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  custom: {
    chart: {
      // TODO move to custom component
      healthColor: '#000',
      intelligenceColor: '#666',
      gridColor: '#eee',
      tickColor: '#666',
      backgroundColor: '#ccc',
      legend: {
        labelColor: '#666',
      },
      tooltip: {
        backgroundColor: 'rbga(45, 45, 45, 1)',
        color: '#fff',
      },
    },
  },
  card: {
    // TODO move to custom component
    //    backgroundColor: '#eeeeee',
    //    backgroundHoverColor: '#ffffff',
    button: {
      backgroundColor: '#f6f6f6',
      backgroundHoverColor: '#eee',
    },
  },
  score: {
    // TODO move to custom component
    hover: {
      backgroundColor: '#f6f6f6',
    },
    bar: {
      backgroundColor: '#e2e2e2',
      height: '3px',
    },
  },
};

const LIGHT_OPTIONS = deepmerge(
  {
    name: 'Light',
    palette: {
      mode: 'light',
      neutral: {
        main: '#666',
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            color: '#000',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // boxShadow: 'none',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            '&.MuiPaper-elevation2': {
              backgroundColor: '#eee',
            },
            boxShadow: 'none',
          },
        },
      },
    },
  },
  LIGHT_GARBAGE
);

export const LIGHT_THEME = createTheme(deepmerge(THEME_DEFAULTS, LIGHT_OPTIONS));
