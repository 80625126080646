import React from 'react';
import { Card, Stack, StackProps, Typography, useTheme } from '@mui/material';

interface DashboardCardProps extends StackProps {
  elevation?: number;
}

export const DashboardCard = ({ children, elevation = 2, title, sx, divider, ...props }: DashboardCardProps) => {
  const theme = useTheme();
  const boxShadow = theme.palette.mode === 'dark' ? 'none' : undefined;
  return (
    <Stack
      component={Card}
      boxShadow={boxShadow}
      elevation={elevation}
      borderRadius={2}
      flex={1}
      py={2}
      px={2}
      spacing={1}
      alignItems='stretch'
      justifyContent='stretch'
      sx={sx}
      {...props}
    >
      {title && (
        <Typography variant='h6' alignSelf='center'>
          {title}
        </Typography>
      )}
      <Stack flex={1} alignItems='stretch' justifyContent='stretch' spacing={1} divider={divider} {...props}>
        {children}
      </Stack>
    </Stack>
  );
};
