import React, { useState, createRef, Fragment } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';

import HelpMenu, { useHelpMenu } from '../Account/HelpMenu';
import InviteDialog from '../Invitation/InviteDialog';
import MiniDrawer from '../Navigation/MiniDrawer';
import { useWorkspace } from '../Workspace/useWorkspace';
import { NavigationItem, PrimaryNavigationArea, PrimaryNavigationAreas } from './usePrimaryNavigation';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { useDrawer } from './DrawerContext';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

const helpButtonRef = createRef<HTMLDivElement>();

export const DRAWER_WIDTH = 240;

interface PrimaryDrawerNavigationProps {
  areas: PrimaryNavigationAreas;
  children: React.ReactNode;
}

const PrimaryDrawerNavigation = ({ areas, children }: PrimaryDrawerNavigationProps) => {
  const { workspace } = useWorkspace();
  const { open, setOpen } = useDrawer();

  if (!workspace) {
    return null;
  }

  areas.help.ref = helpButtonRef;
  areas.account.label = undefined;

  const drawerAreas = ['workspace', 'intelligence', 'clients', 'projects', 'employee-experience', 'company', 'teams']
    .map(key => areas[key])
    .filter(area => area);

  return (
    <MiniDrawer
      // width={DRAWER_WIDTH}
      open={open}
      setOpen={setOpen}
      drawerHeaderContent={<DrawerHeader workspace={workspace} />}
      renderDrawerContent={open => <DrawerContent open={open} areas={drawerAreas} />}
    >
      {children}
    </MiniDrawer>
  );
};

export const DrawerHeader = ({ workspace }: { workspace: WorkspaceInterface }) => {
  return (
    <Box>
      <Button
        sx={{
          marginLeft: -1,
          textTransform: 'none',
          color: '#fff',
          '&.MuiButton-text:hover': { backgroundColor: '#000' },
        }}
        component={RouterLink}
        to={`/workspace/${workspace.id}/company`}
      >
        {workspace.name}
      </Button>
    </Box>
  );
};

interface DrawerContentProps {
  open: boolean;
  areas: PrimaryNavigationArea[];
  onSelect?: () => void;
}

export const DrawerContent = ({ open, areas, onSelect }: DrawerContentProps) => {
  return (
    <Fragment>
      <Stack flexGrow={1}>
        <PrimaryNavigationItems areas={areas} open={open} onSelect={onSelect} />
        <Divider />
        <AdditionalNavigationItems open={open} />
      </Stack>
    </Fragment>
  );
};

type PrimaryNavigationItemsProps = {
  areas: PrimaryNavigationArea[];
  open: boolean;
  onSelect?: () => void;
};

const PrimaryNavigationItems = ({ areas, open, onSelect }: PrimaryNavigationItemsProps) => {
  const navigate = useNavigate();

  return (
    <List sx={{ flexGrow: 1 }}>
      {areas.map(item => (
        <Tooltip key={item.name} title={open ? '' : item.label} placement='right' disableInteractive>
          <ListItemButton
            // component='a'
            // href={item.path}
            key={item.name}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              '&.Mui-selected': {
                color: 'var(--hyve-yellow)',
                '.MuiListItemIcon-root': {
                  color: 'var(--hyve-yellow)',
                },
              },
            }}
            component={RouterLink}
            to={item.path || ''}
            onClick={() => {
              onSelect && onSelect();
            }}
            // onClick={() => navigate(item.path)}
            selected={item.active}
          >
            <ConditionalBadge visible={!open && item.badge}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
            </ConditionalBadge>

            <ConditionalBadge visible={open && item.badge}>
              <ListItemText
                primary={item.label}
                disableTypography={true}
                sx={{
                  // display: open ? 'auto' : 'none',
                  opacity: open ? 1 : 0,
                  textTransform: 'Uppercase',
                  fontSize: 12,
                  margin: 0,
                  paddingRight: open ? '8px' : 0,
                }}
              />
            </ConditionalBadge>
            {open && item.name === 'Clients' && (
              <ListItemSecondaryAction>
                <Tooltip title={'Client List'} disableInteractive>
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onMouseDown={event => event.stopPropagation()}
                    onClick={event => {
                      event.preventDefault();
                      navigate(`${item.path}/list`);
                    }}
                  >
                    <ListRoundedIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            )}
          </ListItemButton>
        </Tooltip>
      ))}
    </List>
  );
};

const AdditionalNavigationItems = ({ open }: { open: boolean }) => {
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const { helpMenuOpen, setHelpMenuOpen, helpMenuAnchorRef } = useHelpMenu();

  const inviteNavigationItem: NavigationItem = {
    key: 'invite',
    name: 'Invite Teammates',
    icon: <GroupAddRoundedIcon />,
    onClick: () => setInviteDialogOpen(true),
  };

  const helpNavigationItem: NavigationItem = {
    key: 'help',
    name: 'Help',
    icon: <HelpRoundedIcon />,
    onClick: () => setHelpMenuOpen(true),
    ref: helpMenuAnchorRef,
  };

  const additionalNavigationItems = [inviteNavigationItem, helpNavigationItem];

  return (
    <Fragment>
      <List>
        {additionalNavigationItems.map(item => (
          <Tooltip key={item.name} title={open ? '' : item.name} placement='right' disableInteractive>
            <ListItemButton
              key={item.name}
              ref={item.ref}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={item.onClick}
              id={`drawer-navigation-${item.key}`}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} disableTypography={true} sx={{ opacity: open ? 1 : 0, fontSize: 12 }} />
            </ListItemButton>
          </Tooltip>
        ))}
      </List>
      <InviteDialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} />
      <HelpMenu
        open={helpMenuOpen}
        onClose={() => setHelpMenuOpen(false)}
        anchorEl={helpMenuAnchorRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      />
    </Fragment>
  );
};

interface ConditionalBadgeProps extends BadgeProps {
  visible: boolean | undefined;
}

const ConditionalBadge = ({ visible = false, children, ...props }: ConditionalBadgeProps) =>
  visible ? (
    <Stack direction='row' flex={1}>
      <Badge variant='dot' color='warning' {...props}>
        {children}
      </Badge>
    </Stack>
  ) : (
    <Fragment>{children}</Fragment>
  );

export default PrimaryDrawerNavigation;
