import React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { styled } from '@mui/material/styles';
import { defaults as chartjsDefaults } from 'chart.js';

const AppStyle = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppStyles className='app-style'>
      {GLOBAL_STYLES}
      {children}
    </AppStyles>
  );
};

chartjsDefaults.font.family = 'Source Sans Pro';

const GLOBAL_STYLES = (
  <GlobalStyles
    styles={{
      html: {
        scrollPaddingTop: '136px',
      },
      body: {
        overscrollBehavior: 'none',
      },
      a: {
        textDecoration: 'none',
        color: '#000',
      },
      // textOverflow: {
      //  whiteSpace: 'nowrap',
      //  overflow: 'hidden',
      //  textOverflow: 'ellipsis',
      // },
    }}
  />
);

const AppStyles = styled('div')(
  () => `
  .MuiLink-button {
    font-family: 'Source Sans Pro';
  }

  .MuiButton-containedPrimary:hover {
    opacity: 0.85;
  }

  .MuiAvatarGroup-root {
    .MuiAvatar-colorDefault {
      background-color: #666;
      font-size: 12px;
    }
  }

  .MuiButton-text {
    &:hover {
    }
  }

  font-family: 'Source Sans Pro', sans-serif;

  .monospace {
    font-family: 'Source Code Pro', 'Consolas', 'Courier New', Courier, monospace;
  }

  /* TODO move these colors to themes */
  --hyve-yellow: #ffd73e;
  --hyve-theme-primary: #ffd73e;
  --hyve-theme-on-primary: #000;
  --hyve-theme-secondary: #000;
  --hyve-theme-on-secondary: #ffd73e;

  --green: #48ac4c;
  --orange: #ff8036;
  --red: #d0021b;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;

    .form-row {
      margin-bottom: 1.5rem;
    }
  }

  input[data-com-onepassword-filled="light"] { background-color: initial !important; }

  .dashboard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    h3 {
      margin 15px 0 15px 0;
      text-align: center;
      font-size: 16px;
      // text-transform: capitalize;
    }
  }

  .MuiButton-label {
    color: inherit;
  }

  .MuiSnackbar-root {
    &.error .MuiSnackbarContent-root {
      background-color: #d0021b;
    }
  }

  .MuiTable-root {
    .MuiTableCell-head {
      font-weight: bold;
    }
    .MuiButton-root {      
      color: #757575;
      &.Mui-disabled {
        color: #ccc;
      }
    }

    .name-cell {
      line-height: 30px;
      .avatar {
        float: left;
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }
    }
  }
`
);

export const removePasswordManagerStyle = (input: HTMLInputElement) => {
  const observer = new MutationObserver(_ => {
    input.removeAttribute('data-com-onepassword-filled'); // 1Password
  });
  observer.observe(input, { attributes: true });
};

export default AppStyle;
