import React from 'react';
import { useTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import Paper from '@mui/material/Paper';
import Stack, { StackProps } from '@mui/material/Stack';

interface AppSurfaceProps extends StackProps {
  fullWidth?: boolean;
}

const AppSurface = ({ fullWidth = false, children, ...props }: AppSurfaceProps) => {
  //  const layout = useLayout();
  const theme = useTheme();

  let themeProps = {};
  switch (theme.name) {
    case 'standard':
      break;
    case 'branded':
      themeProps = BRANDED_PROPS;
      break;
    case 'illustrated':
      themeProps = ILLUSTRATED_PROPS;
      break;
    case 'floating':
      themeProps = FLOATING_PROPS;
      break;
    default:
    //      throw new Error(`Unexpected className: ${className}`);
  }

  let layoutProps = {};
  //  switch(layout) {
  //    case 'mobile':
  //      layoutProps = { minWidth: '300px' }
  //      break;
  //    case 'tablet':
  //      layoutProps = { minWidth: '480px' };
  //      break;
  //    case 'laptop':
  //      // layoutProps = { minWidth: '640px' };
  //      break;
  //    case 'desktop':
  //      layoutProps = { minWidth: '700px' };
  //      break;
  //    default:
  //      throw new Error('Unexpected layout');
  //  }

  let surfaceProps = {
    elevation: 0,
    flexGrow: 1,
    sx: { borderRadius: '8px', ...layoutProps },
  };
  surfaceProps = deepmerge(surfaceProps, themeProps);
  surfaceProps = deepmerge(surfaceProps, props);

  return (
    <Stack component={Paper} {...surfaceProps}>
      {children}
    </Stack>
  );
};

const BRANDED_PROPS = {
  justifyContent: 'center',
  flexGrow: 0,
  p: 3,
  maxWidth: '450px',
  width: '100%',
  minWidth: 'inherit',
  minHeight: '440px',
};

const FLOATING_PROPS = {};

const ILLUSTRATED_PROPS = {
  elevation: 0,
  sx: {
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default AppSurface;
