import React from 'react';
import { useContributeIntelligence } from '../Intelligence/ContributeAndCollect';
import { computeIntelligenceLevel, INTELLIGENCE_LEVELS } from '../Intelligence/IntelligenceLevels';
import { computeIntelligenceColor } from './Intelligence';
import { Scorable } from './Scorable';
import { MeasureLevels, MeasureModal } from './MeasureModal';
import Stack from '@mui/material/Stack';
import ScorablePhrase, { determiner } from './ScorablePhrase';
import Typography from '@mui/material/Typography';
import { useScope } from '../Scope/useScope';
import { ScoreDimension } from './ScoreDimension';
import ScoreCircle from './ScoreCircle';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import { ActionInterface } from '../UI/ActionInterface';

interface IntelligenceModalProps {
  scorable: Scorable | undefined;
  scoreDimension?: ScoreDimension;
  open: boolean;
  onClose: () => void;
}

export const IntelligenceModal = ({ scorable, scoreDimension, open, onClose }: IntelligenceModalProps) => {
  const { scope } = useScope();
  const { contributeIntelligence, contributeIntelligenceDisabled } = useContributeIntelligence(scorable);

  if (!scorable) {
    return null;
  }

  if (!scoreDimension) {
    scoreDimension = scorable;
  }

  const scoreLevel = computeIntelligenceLevel(scoreDimension?.intelligence);

  const levels = INTELLIGENCE_LEVELS.map(level => ({ ...level, color: computeIntelligenceColor(level.min || 0) }));

  //  const scorableInfo = getScorableInfo(scorable);

  const summary = (
    <Stack alignItems='center' spacing={4}>
      <ScoreCircle width='200px' icon={WaterDropRoundedIcon} score={scoreDimension?.intelligence} iconSize='40px' scoreFontSize='60px' />
      <Typography variant='h5' textAlign='center'>
        <ScorablePhrase
          determiner={determiner(scope, 'third')}
          scorable={scorable}
          scoreDimension={scoreDimension}
          includeName
          //          includeType
          metric='intelligence'
          capitalization='sentence'
        />
        {` is ${scoreLevel.name.toLowerCase()}.`}
      </Typography>
      <MeasureLevels levels={levels} />
    </Stack>
  );

  const details = (
    <Stack alignItems='center' maxWidth='670px' spacing={2}>
      {/*<Typography variant='h5'>What does intelligence represent?</Typography>*/}
      <Typography variant='body1' textAlign='center' sx={{ textWrap: 'balance' }}>
        Intelligence represents the level of knowledge Hyve currently has collected. Intelligence can range from 0-100. The higher the intelligence, the more
        confident you can be in the corresponding score.
      </Typography>
    </Stack>
  );

  const actions: ActionInterface[] = [
    {
      label: 'Contribute Intelligence',
      onSelect: contributeIntelligence,
      disabled: contributeIntelligenceDisabled,
    },
  ];

  return <MeasureModal scorable={scorable} summary={summary} details={details} actions={actions} open={open} onClose={onClose} />;
};

export default IntelligenceModal;
