import { Typography, TypographyProps } from '@mui/material';
import { DateTime } from 'luxon';

type DateInput = Date | string | null | undefined;

type FriendlyDateProps = TypographyProps & {
  dateInput: DateInput;
};

export const FriendlyDate = ({ dateInput, ...props }: FriendlyDateProps) => {
  const formattedDate = friendlyDate(dateInput);

  const parsedDate = parseDate(dateInput);
  const date = parsedDate ? DateTime.fromJSDate(parsedDate) : null;
  const currentDate = DateTime.local().startOf('day');

  let color;
  if (date && date.hasSame(currentDate, 'day')) {
    color = 'success.main';
  } else if (date && date < currentDate) {
    color = 'error.main';
  }

  return (
    <Typography {...props} color={color}>
      {formattedDate}
    </Typography>
  );
};

const parseDate = (date: DateInput) => {
  if (!date) return null;

  if (typeof date === 'string') {
    return DateTime.fromISO(date).startOf('day').toJSDate();
  } else {
    return DateTime.fromJSDate(date).startOf('day').toJSDate();
  }
};

export const friendlyDate = (dateInput: DateInput): string | null => {
  // Convert string date to Date object if provided
  const parsedDate = parseDate(dateInput);
  const date = parsedDate ? DateTime.fromJSDate(parsedDate) : null;
  const currentDate = DateTime.local().startOf('day');
  const formattedDate = date ? date.toFormat('MMM d') : '';

  // Check if date is undefined
  if (!date) {
    return null;
  }

  // Check if date is yesterday
  if (date.hasSame(currentDate.minus({ days: 1 }), 'day')) {
    return 'Yesterday';
  }

  // Check if date is today
  if (date.hasSame(currentDate, 'day')) {
    return 'Today';
  }

  // Check if date is tomorrow
  if (date.hasSame(currentDate.plus({ days: 1 }), 'day')) {
    return 'Tomorrow';
  }

  // Check if date is within the next 6 days
  if (date > currentDate && date.diff(currentDate, 'days').days <= 6) {
    // If the date is within the next 6 days, return its day of the week
    return date.toFormat('cccc');
  }

  // Check if date is in a different year
  if (date.year !== currentDate.year) {
    // Include year if date is not in the current year
    return formattedDate + ', ' + date.year;
  }

  // Default: return formatted date
  return formattedDate;
};
