import { Divider, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import useWorkspace from '../Workspace/useWorkspace';
import ConnectChannelDialog from './ConnectChannelDialog';
import DisconnectChannelDialog from './DisconnectChannelDialog';
import { ReactComponent as SlackLogoMark } from '../Icons/SVG/Slack-Logo-Mark.svg';
import { ReactComponent as SlackLogoMarkWhite } from '../Icons/SVG/Slack-Logo-Mark-White.svg';
import { LinkOffRounded, LinkRounded, Lock, Tag } from '@mui/icons-material';
import StyledMenu from '../UI/StyledMenu';
import EditChannelDialog from './EditChannelDialog';
import { ChannelConnectable, ChannelConnection } from './ChannelConnectable';
import { Scorable } from '../Scores/Scorable';
import openAppLink from '../util/openAppLink';

type ChannelConnectionProps = {
  scorable: Scorable & ChannelConnectable;
  defaultChannelName: string;
};

const ChannelConnectionAction = ({ scorable, defaultChannelName }: ChannelConnectionProps) => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { workspace } = useWorkspace();

  const channelConnections = scorable.channelConnections;
  const channelConnection = channelConnections.find(c => c.channel.platform === 'slack') || null;

  if (!workspace.hasSlack) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title='Slack'>
        <IconButton onClick={handleOpen} ref={menuRef} sx={{ width: '40px', height: '40px' }}>
          {channelConnection ? <SlackLogoMark width={20} height={20} /> : <SlackLogoMarkWhite width={20} height={20} />}
        </IconButton>
      </Tooltip>
      {!channelConnection ? (
        <ConnectChannelMenu scorable={scorable} defaultChannelName={defaultChannelName} anchorEl={menuRef.current} open={open} onClose={handleClose} />
      ) : (
        <ChannelConnectionMenu scorable={scorable} channelConnection={channelConnection} anchorEl={menuRef.current} open={open} onClose={handleClose} />
      )}
    </>
  );
};

type ConnectChannelMenuProps = {
  scorable: Scorable & ChannelConnectable;
  defaultChannelName: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

const ConnectChannelMenu = ({ scorable, defaultChannelName, open, anchorEl, onClose }: ConnectChannelMenuProps) => {
  const [connectDialogOpen, setConnectDialogOpen] = useState(false);
  const handleConnect = () => {
    setConnectDialogOpen(true);
    onClose();
  };

  return (
    <>
      <StyledMenu open={open} onClose={onClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleConnect}>
          <ListItemIcon>
            <LinkRounded />
          </ListItemIcon>
          <ListItemText>Connect to Slack Channel</ListItemText>
        </MenuItem>
      </StyledMenu>
      <ConnectChannelDialog scorable={scorable} defaultChannelName={defaultChannelName} open={connectDialogOpen} onClose={() => setConnectDialogOpen(false)} />
    </>
  );
};

type ChannelConnectionMenuProps = {
  scorable: Scorable & ChannelConnectable;
  channelConnection: ChannelConnection;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
};

const ChannelConnectionMenu = ({ scorable, channelConnection, anchorEl, open, onClose }: ChannelConnectionMenuProps) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const { workspace } = useWorkspace();

  const handleView = () => {
    openAppLink(
      `slack://channel?team=${workspace.slackTeamId}&id=${channelConnection.channel.externalId}`,
      `https://slack.com/app_redirect?channel=${channelConnection.channel.externalId}`
    );
  };

  const handleDisconnect = () => {
    setDisconnectDialogOpen(true);
    onClose();
  };

  return (
    <>
      <StyledMenu open={open} onClose={onClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleView}>
          <ListItemIcon>{channelConnection.channel.isPrivate ? <Lock /> : <Tag />}</ListItemIcon>
          <ListItemText>{channelConnection.channel.name}</ListItemText>
          {/*<ListItemSecondaryAction>*/}

          {/*    <OpenInNewRounded />*/}
          {/*  */}
          {/*</ListItemSecondaryAction>*/}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDisconnect}>
          <ListItemIcon>
            <LinkOffRounded />
          </ListItemIcon>
          <ListItemText>Disconnect from Channel</ListItemText>
        </MenuItem>
      </StyledMenu>

      <EditChannelDialog open={editDialogOpen} channelConnection={channelConnection} onClose={() => setEditDialogOpen(false)} />
      <DisconnectChannelDialog channelConnection={channelConnection} open={disconnectDialogOpen} onClose={() => setDisconnectDialogOpen(false)} />
    </>
  );
};

export default ChannelConnectionAction;
