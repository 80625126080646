import React, { useState, createContext, useContext, useEffect } from 'react';

import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import { useLayout } from './LayoutContext';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Stack } from '@mui/material';

type GlobalMessageContextValue = {
  message: string | null | undefined;
  setMessage: (message: string | null | undefined) => void;
  success: string | null | undefined;
  setSuccess: (success: string | null) => void;
  error: string | null | undefined;
  setError: (error: string | null | undefined) => void;
};

const GlobalMessageContext = createContext<GlobalMessageContextValue | null>(null);

export const useGlobalMessage = () => {
  const context = useContext(GlobalMessageContext);
  if (!context) {
    throw new Error('useGlobalMessage must be used within a GlobalMessageProvider');
  }
  const { message, setMessage, success, setSuccess, error, setError } = context;
  return { message, setMessage, success, setSuccess, error, setError };
};

export const GlobalMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null | undefined>(null);
  const [success, setSuccess] = useState<string | null | undefined>(null);
  const [error, setError] = useState<string | null | undefined>(null);
  const layout = useLayout();

  useEffect(() => {
    if (message || success || error) {
      setOpen(true);
    }
  }, [message, success, error]);

  const handleClose = () => {
    setMessage(null);
    setSuccess(null);
    setError(null);
    setOpen(false);
  };

  return (
    <GlobalMessageContext.Provider value={{ message, setMessage, success, setSuccess, error, setError }}>
      {children}
      <Snackbar
        color='error'
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: layout === 'mobile' ? 'center' : 'left',
        }}
        className={error ? 'error' : undefined}
        autoHideDuration={message ? 4000 : 10000}
        onClose={handleClose}
        message={
          <Stack direction='row' alignItems='center'>
            {success && <CheckRoundedIcon color='success' style={{ marginRight: '8px' }} />}
            {error || success || message}
          </Stack>
        }
        style={layout === 'mobile' ? { bottom: '80px' } : {}}
        action={
          <IconButton aria-label='close' onClick={handleClose} color='inherit'>
            <CloseRoundedIcon />
          </IconButton>
        }
      />
    </GlobalMessageContext.Provider>
  );
};
