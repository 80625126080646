import { useSearchParams } from 'react-router-dom';

const SHOW_ARCHIVED_PARAM = 'showArchived';

export interface ShowArchivedProps {
  showArchived: Boolean;
  setShowArchived: (showArchived: Boolean) => void;
  showArchivedVariables: Object;
}

export const useShowArchived = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const showArchived = searchParams.get(SHOW_ARCHIVED_PARAM) !== null;

  const setShowArchived = (showArchived: Boolean) => {
    if (showArchived) {
      searchParams.set(SHOW_ARCHIVED_PARAM, 'true');
    } else {
      searchParams.delete(SHOW_ARCHIVED_PARAM);
    }
    setSearchParams(searchParams);
  };

  const showArchivedVariables = { archived: showArchived ? [false, true] : [false] };

  return { showArchived, setShowArchived, showArchivedVariables };
};
