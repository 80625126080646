import React, { createContext, useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import useWorkspace from '../Workspace/useWorkspace';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import useAuthenticatedUser from '../User/useAuthenticatedUser';

export type IntelligenceAction = {
  action: 'refetch' | 'contribute' | 'collect';
} | null;

type IntelligenceContextValues = {
  intelligenceAction: IntelligenceAction;
  setIntelligenceAction: (action: IntelligenceAction) => void;
};

const IntelligenceContext = createContext<IntelligenceContextValues | null>(null);

export const useIntelligence = () => {
  const context = useContext(IntelligenceContext);
  if (context === null) {
    throw new Error('useIntelligence must be used within IntelligenceContext.Provider');
  }
  return context;
};

export const IntelligenceProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthenticatedUser();
  const { workspace } = useWorkspace();

  const [intelligenceAction, setIntelligenceAction] = useState<IntelligenceAction | null>(null);

  const { error, refetch } = useQuery(INTELLIGENCE_QUERY, {
    skip: !workspace.id,
    variables: { workspaceId: workspace.id, userId: user.id },
  });

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await refetch();
      setIntelligenceAction({ action: 'refetch' });
    }, 60000);

    return () => clearInterval(intervalId);
  }, [refetch]);

  if (error) {
    console.error(error);
  }

  return <IntelligenceContext.Provider value={{ intelligenceAction, setIntelligenceAction }}>{children}</IntelligenceContext.Provider>;
};

const INTELLIGENCE_QUERY = gql`
  query Intelligence($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      ...ScorableSurveyFields

      needsMyIntelligence
      surveyStats {
        allTimeCount: count(activeOnly: false, scope: { type: Unscoped })
      }

      projectStats {
        count
      }
    }
  }
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;
