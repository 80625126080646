import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PieChart } from 'react-minimal-pie-chart';
import { Box, Divider } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import { Fragment, useState } from 'react';
import { IconType } from '../UI/Icon';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import IntelligenceModal from './IntelligenceModal';
import { Scorable } from './Scorable';
import { ScoreDimension } from './ScoreDimension';
import Typography from '@mui/material/Typography';
import { computeScoreLevel, Score } from './Score';

export interface ScoreCircleProps {
  width?: string | number; // by default, the circle will expand to fit its container
  color?: string;
  icon?: IconType;
  score: Score;
  intelligence?: number | undefined;
  onClick?: () => void;
  sx?: Object;
  iconSize?: string;
  scoreFontSize?: string;
  colorScoreLabel?: boolean;
  showProgress?: boolean;
  children?: React.ReactNode;
}

const ScoreCircle = ({
  width,
  color,
  icon,
  score,
  onClick,
  children,
  sx,
  iconSize,
  scoreFontSize,
  colorScoreLabel = false,
  showProgress = true,
}: ScoreCircleProps) => {
  color = color || computeScoreLevel(score).color;
  const scoreLabelColor = colorScoreLabel ? color : undefined;
  const dataValue = showProgress ? score || 0 : 100;
  const theme = useTheme();

  return (
    <Stack direction='column' alignItems='center' spacing={3} width={width} sx={sx}>
      <ButtonBase
        component={onClick ? 'button' : 'div'}
        disabled={!onClick}
        onClick={onClick}
        style={{ width: '100%', height: '100%', borderRadius: '100%' }}
        flex-direction='column'
        justify-content='center'
        border-radius='100%'
        sx={{
          backgroundColor: theme.palette.background.paper,
          '&:hover': { backgroundColor: theme.palette.action.hover },
          transition: 'background-color 0.3s',
        }}
      >
        <PieChart
          key={dataValue}
          data={[{ value: dataValue, color: color }]}
          totalValue={100}
          startAngle={-90}
          rounded
          animate={showProgress}
          lineWidth={8}
          background={theme.palette.background.default}
        />

        <Stack position='absolute' justifyContent='center' alignItems='stretch' height='100%' px={2} spacing={{ xs: 0, lg: 2 }}>
          {icon && (
            <Stack justifyContent='center' alignItems='center' spacing={0}>
              <ScoreIcon icon={icon} color={scoreLabelColor} size={iconSize} />
            </Stack>
          )}

          <Stack justifyContent='center'>
            <Typography align='center' fontSize={scoreFontSize} fontWeight='bold' lineHeight={scoreFontSize} style={{ color: scoreLabelColor }}>
              {score || '-'}
            </Typography>
          </Stack>
        </Stack>
      </ButtonBase>

      {children}
    </Stack>
  );
};

type ScoreIconProps = {
  icon?: IconType;
  color?: string;
  size?: string;
};

const ScoreIcon = ({ icon, color, size = '45px' }: ScoreIconProps) => {
  return (
    <Box
      sx={{
        '.MuiIcon-root': {
          width: size,
          height: size,
          fontSize: size,
          color: color,
        },
        svg: {
          width: size,
          height: size,
          color: color,
          '&.custom-icon': {
            padding: '2px',
          },
          'g g': {
            fill: color,
          },
        },
      }}
    >
      {icon && React.createElement(icon)}
    </Box>
  );
};

interface ScoreCircleIntelligenceProps {
  scorable: Scorable;
  scoreDimension?: ScoreDimension;
  label: React.ReactNode;
}

export const ScoreCircleIntelligence = ({ scorable, scoreDimension, label }: ScoreCircleIntelligenceProps) => {
  const theme = useTheme();
  //  const layout = useLayout();
  //  showIntelligence = showIntelligence && layout !== 'mobile';
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  if (!scorable) {
    return null;
  }
  if (!scoreDimension) {
    scoreDimension = scorable;
  }

  const borderColor = theme.palette.mode === 'dark' ? 'rgba(170, 170, 170, 0.5)' : 'rgba(102, 102, 102, 0.5)';

  return (
    <Fragment>
      <IntelligenceModal scorable={scorable} scoreDimension={scoreDimension} open={open} onClose={toggleOpen} />
      <ButtonBase onClick={toggleOpen}>
        <Stack
          direction='row'
          border={`1px solid ${borderColor}`}
          borderRadius={1}
          width={100}
          //          px={2} py={1}
          alignItems='stretch'
          //          spacing={1}
          divider={<Divider flexItem sx={{ borderColor: borderColor, height: 'default' }} orientation='vertical' />}
          sx={{ '&:hover': { backgroundColor: theme.palette.action.hover } }}
        >
          <Stack flex={1} py={0.5} alignItems='center' justifyContent='center'>
            <WaterDropRoundedIcon flex={1} sx={{ fontSize: '18px' }} />
          </Stack>
          <Box flex={1} py={0.5}>
            <Typography flex={1} textAlign='center'>
              {scoreDimension.intelligence || '-'}
            </Typography>
          </Box>
        </Stack>
      </ButtonBase>
    </Fragment>
  );
};

export default ScoreCircle;
