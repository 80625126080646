import { useGlobalMessage } from '../UI/GlobalMessage';
import { DocumentNode, useMutation } from '@apollo/client';
import { Archivable } from './Archivable';

export const useArchiveActions = (archivable: Archivable, archiveMutationSchema: DocumentNode, refetch: () => Promise<any>) => {
  const { id, __typename } = archivable;
  const { setSuccess, setError } = useGlobalMessage();

  const [archiveMutation] = useMutation(archiveMutationSchema);

  const typeNounPhrase = __typename!.toLowerCase();

  const archive = async () => {
    const response = await archiveMutation({ variables: { id: id, archive: true } });
    if (response.errors) {
      setError(`Unable to archive ${typeNounPhrase}`);
    } else {
      setSuccess(`Archived ${typeNounPhrase}`);
      await refetch();
    }
  };

  const unarchive = async () => {
    const response = await archiveMutation({ variables: { id: id, archive: false } });
    if (response.errors) {
      setError(`Unable to unarchive ${typeNounPhrase}`);
    } else {
      setSuccess(`Unarchived ${typeNounPhrase}`);
      await refetch();
    }
  };

  return { archive, unarchive };
};
