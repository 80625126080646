import React from 'react';

import { Scorable } from './Scorable';
import { ActionInterface } from '../UI/ActionInterface';
import { Stack, Box, IconButton, Button } from '@mui/material';
import { ScoreLevel } from './Score';
import { IntelligenceLevel } from '../Intelligence/IntelligenceLevels';
import Typography from '@mui/material/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog } from '@mui/material';

interface MeasureModalProps {
  scorable: Scorable;
  summary: React.ReactNode;
  details: React.ReactNode;
  actions?: ActionInterface[];
  open: boolean;
  onClose: () => void;
}

export const MeasureModal = ({ scorable, summary, details, actions = [], open, onClose }: MeasureModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ outline: 0 }}>
      <Stack position='relative' height='100%' sx={{ outline: 0 }} p={4}>
        <Box position='absolute' top={16} right={16}>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>

        <Stack spacing={4}>
          {summary}

          <Stack alignItems='center' p={0}>
            {details}
          </Stack>

          {actions.length > 0 && (
            <Stack alignItems='center'>
              <Stack direction='row' spacing={2}>
                {actions.map(action => (
                  <Button
                    key={action.label}
                    variant='contained'
                    color='primary'
                    onClick={action.onSelect}
                    // href={action.href}
                    disabled={action.disabled}
                  >
                    {action.label}
                  </Button>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default MeasureModal;

export const MeasureLevels = ({ levels }: { levels: ScoreLevel[] | IntelligenceLevel[] }) => (
  <Stack direction='row'>
    {levels.map(level => (
      <Stack key={level.name} width='80px' alignItems='center' spacing={1}>
        <Box
          sx={{
            border: '4px solid #fff',
            borderRadius: '40px',
            borderColor: level.color,
            width: '40px',
            height: '40px',
            marginBottom: '2px',
          }}
        />
        <Stack alignItems='center'>
          <Typography variant='body1'>{level.min || level.max ? `${level.min}-${level.max}` : '-'}</Typography>
          <Typography variant='body1'>{level.name}</Typography>
        </Stack>
      </Stack>
    ))}
  </Stack>
);
