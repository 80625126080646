import React from 'react';

import { DashboardSurface } from '../Dashboard/Dashboard';
import DashboardSummary from '../Dashboard/DashboardSummary';
import { ModuleInterface } from './Module';
import DashboardAspects from '../Dashboard/DashboardAspects';
import { Category } from '../Category/Category';
import { IconType } from '../UI/Icon';
import { computeScoreLevel } from '../Scores/Score';
import Typography from '@mui/material/Typography';
import { useScope } from '../Scope/useScope';
import { MembershipInterface } from '../Membership/Membership';
import { findScoreDimensionSettings } from '../Scores/ScoreDimensionSettings';
import Stack from '@mui/material/Stack';
import { useLayout } from '../UI/LayoutContext';
import { PersonalDashboardStats, MembershipDashboardStats } from '../Dashboard/DashboardStats';
import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import MemberScoreBreakdown from '../Membership/MemberScoreBreakdown';
import { useIntelligenceActions } from '../Intelligence/IntelligenceActions';
import { DashboardCard } from '../Dashboard/DashboardCard';
import { Scorable } from '../Scores/Scorable';

export interface ModuleDashboardProps {
  scorable: Scorable;
  category: Category;
  module: ModuleInterface;
  icon: IconType;
  title?: string;
  scopePrefix?: string;
  direction?: 'row' | 'column'; // children layout direction
  children?: React.ReactNode; // module details
}

const ModuleDashboard = ({ scorable, category, module, icon, title, direction = 'column', children }: ModuleDashboardProps) => {
  const { scope } = useScope();
  const { intelligenceActions, collectIntelligenceDialog } = useIntelligenceActions(scorable, module);

  const layout = useLayout();
  if (layout === 'mobile') {
    direction = 'column';
  }

  if (!title) {
    title = `${scope.prefix}${module.name}`;
  }

  return (
    <React.Fragment>
      <IntelligenceNotice scorable={scorable} scoreDimension={module} rounded />
      <DashboardSurface variant='primary' title={title} overflowActions={intelligenceActions}>
        {collectIntelligenceDialog}
        <DashboardSummary scorable={scorable} scoreDimension={module} icon={icon} scopePrefix={scope.prefix} scorableLabel={module.name} />
        <Stack direction={direction} spacing={{ xs: 2, sm: 4 }}>
          {children}
        </Stack>
      </DashboardSurface>

      <DashboardAspects scorable={scorable} category={category} module={module} />
    </React.Fragment>
  );
};

interface ModuleStatsProps {
  scorable: Scorable & MembershipInterface;
  module: ModuleInterface;
  direction: 'column' | 'row';
}

export const ModuleStats = ({ scorable, module, direction }: ModuleStatsProps) => {
  const { scope } = useScope();

  if (scope.type === 'Personal') {
    return <PersonalDashboardStats direction={direction} scorable={scorable} scoreDimension={module} intelligenceLabel={`${module.name} Intelligence`} />;
  } else if (scope.type === 'Unscoped') {
    return <MembershipDashboardStats direction={direction} scorable={scorable} scoreDimension={module} intelligenceLabel={`${module.name} Intelligence`} />;
  } else if (scope.type === 'Team') {
    return <MembershipDashboardStats direction={direction} scorable={scorable} scoreDimension={module} intelligenceLabel={`${module.name} Intelligence`} />;
  } else {
    return null;
  }
};

interface PersonalAndMemberBreakdownModuleDetailsProps {
  scorable: Scorable & MembershipInterface;
  module: ModuleInterface;
}

export const PersonalAndMemberBreakdownModuleDetails = ({ scorable, module }: PersonalAndMemberBreakdownModuleDetailsProps) => {
  const { scope } = useScope();
  if (scope.type === 'Personal') {
    return <PersonalModuleDetails module={module} />;
  } else {
    return (
      <DashboardCard title={`${module.name} Breakdown`}>
        <MemberScoreBreakdown scorable={scorable} scoreDimension={module} />
      </DashboardCard>
    );
  }
};

const PersonalModuleDetails = ({ module }: { module: ModuleInterface }) => {
  const healthLevel = computeScoreLevel(module.health);
  const componentSettings = findScoreDimensionSettings(module);
  const level = componentSettings.levels![healthLevel.level];

  return (
    <DashboardCard title={`My ${module.name}`}>
      <Stack>
        <Typography variant='body1'>{level.headline}</Typography>
        <Typography variant='body2'>{level.description}</Typography>
      </Stack>
    </DashboardCard>
  );
};

export default ModuleDashboard;
