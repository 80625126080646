import { gql } from '@apollo/client';

export type Role = 'SystemAdmin' | 'Admin' | 'SeniorLeader' | 'Manager' | 'Owner' | 'Inactive';

type UserRoleScope = 'Workspace' | 'Client' | 'Project' | 'Team';

export interface SetUserRolesVariables {
  userId: string;
  type: UserRoleScope;
  id: string;
  roles: Role[];
  operation: 'Add' | 'Remove';
}

export const SET_USER_ROLES_MUTATION = gql`
  mutation ($userId: ID!, $type: RoleScope!, $id: ID!, $roles: [Role!], $operation: SetOperation) {
    setUserRoles(userId: $userId, type: $type, id: $id, roles: $roles, operation: $operation) {
      success
      status
      message
    }
  }
`;
