import useUser from './useUser';

const useAuthenticatedUser = () => {
  const { user, loading, refetch } = useUser();
  if (user === null) {
    throw new Error('useAuthenticatedUser should only be called when the user is authenticated');
  }

  return { user, loading, refetch };
};

export default useAuthenticatedUser;
