import { ScoreBreakdownLevel, ScoreBreakdown } from '../Scores/ScoreBreakdown';
import SentimentVerySatisfiedRounded from '@mui/icons-material/SentimentVerySatisfiedRounded';
import { SCORE_COLLECTING, SCORE_HIGH, SCORE_MEDIUM, SCORE_LOW } from '../Scores/Score';
import SentimentNeutralRounded from '@mui/icons-material/SentimentNeutralRounded';
import MoodBadRounded from '@mui/icons-material/MoodBadRounded';
import RadioButtonUncheckedRounded from '@mui/icons-material/RadioButtonUncheckedRounded';
import { MembershipInterface } from './Membership';
import { MemberScorerInterface } from './MemberScores';
import Typography from '@mui/material/Typography';
import { ScoreDimensionBase } from '../Scores/ScoreDimensionBase';
import { findScoreDimensionSettings } from '../Scores/ScoreDimensionSettings';
import { Stack } from '@mui/material';
import { Scorable } from '../Scores/Scorable';

const useMemberScoreBreakdown = (scorable: Scorable & MembershipInterface, scoreDimension: ScoreDimensionBase & MemberScorerInterface) => {
  const memberCount = scorable.memberStats.count;
  const { memberStats } = scoreDimension;
  const { countByScore } = memberStats;
  const available = countByScore;
  return { memberCount, available, countByScore };
};

interface MemberScoreBreakdownProps {
  scorable: Scorable & MembershipInterface;
  scoreDimension: ScoreDimensionBase & MemberScorerInterface;
  title?: string;
  privacyMessage?: string;
}

const MemberScoreBreakdown = ({ scorable, scoreDimension, title }: MemberScoreBreakdownProps) => {
  const { memberCount, available, countByScore } = useMemberScoreBreakdown(scorable, scoreDimension);

  if (!available) {
    return <MemberBreakdownNotAvailable title={title} component={scoreDimension} />;
  }

  if (!title) {
    title = `Members (${memberCount})`;
  }

  return (
    <ScoreBreakdown title={title}>
      <ScoreBreakdownLevel name='High' count={countByScore.high} icon={SentimentVerySatisfiedRounded} color={SCORE_HIGH.color} />
      <ScoreBreakdownLevel name='Medium' count={countByScore.medium} icon={SentimentNeutralRounded} color={SCORE_MEDIUM.color} />
      <ScoreBreakdownLevel name='Low' count={countByScore.low} icon={MoodBadRounded} color={SCORE_LOW.color} />
      <ScoreBreakdownLevel name='Collecting' count={countByScore.collecting} icon={RadioButtonUncheckedRounded} color={SCORE_COLLECTING.color} />
    </ScoreBreakdown>
  );
};

const MemberBreakdownNotAvailable = ({ title, component }: { title?: string; component: ScoreDimensionBase & MemberScorerInterface }) => {
  const componentSettings = findScoreDimensionSettings(component);
  const level = componentSettings.levels!.unavailable;
  const description = level.description.replace('[confidentiality threshold]', component.confidentialityThreshold.toString());

  return (
    <Stack>
      <Typography variant='h6' alignSelf='center'>
        {title}
      </Typography>
      <Stack>
        <Typography variant='body1'>{level.headline}</Typography>
        <Typography variant='body2'>{description}</Typography>
      </Stack>
    </Stack>
  );
};

export default MemberScoreBreakdown;
