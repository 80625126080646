import { Controller, useController, useFormContext } from 'react-hook-form';
import { Rating, RatingProps, Stack, Typography } from '@mui/material';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const ImportanceRating = (props: RatingProps) => {
  return <Rating {...props} icon={<CircleRoundedIcon fontSize='inherit' />} emptyIcon={<CircleOutlinedIcon fontSize='inherit' />} />;
};

type ImportanceRatingFieldProps = {
  name?: string;
};

export const importanceSortComparator = (a: number, b: number): number => {
  const normalizedA = a ?? -Infinity; // Treat null/undefined as lowest possible value
  const normalizedB = b ?? -Infinity; // Treat null/undefined as lowest possible value
  return normalizedA - normalizedB;
};

export const ImportanceRatingField = ({ name = 'importance' }: ImportanceRatingFieldProps) => {
  const { control } = useFormContext();
  const { field } = useController({ name, control });
  const { value, onChange, ref, ...rest } = field;

  return (
    <Stack alignContent='left' alignItems='flex-start' spacing={0.5} pb={1}>
      <Typography component='legend'>Importance</Typography>
      <Controller
        name='importance'
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <ImportanceRating {...fieldProps} {...rest} value={Number(value)} onChange={(_, newValue) => onChange(Number(newValue))} />
        )}
      />
    </Stack>
  );
};

export default ImportanceRating;
