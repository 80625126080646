import { CategoryType } from '../Category/Category';
import { ModuleType } from '../Module/Module';
import React from 'react';
import { IconType } from '../UI/Icon';
import { ScoreDimensionBase } from './ScoreDimensionBase';
import { CATEGORY_SCORE_DIMENSION_SETTINGS } from '../Category/CategoryScoreDimensionSettings';
import { MODULE_COMPONENT_SETTINGS } from '../Module/ModuleDimensionSettings';

export interface ScoreDimensionSettings {
  name: string;
  type: ModuleType | CategoryType;
  path?: string;
  DashboardComponent: React.FC<any>;
  // fields: string;
  icon: IconType;
  levels?: {
    high: { headline: string; description: string };
    medium: { headline: string; description: string };
    low: { headline: string; description: string };
    collecting: { headline: string; description: string };
    unavailable: { headline: string; description: string };
  };
}

export const findScoreDimensionSettings = (scoreDimension: ScoreDimensionBase): ScoreDimensionSettings => {
  const { type } = scoreDimension;

  const categoryComponentSettings = CATEGORY_SCORE_DIMENSION_SETTINGS.find(settings => settings.type === type);

  if (categoryComponentSettings) {
    return categoryComponentSettings;
  } else {
    return MODULE_COMPONENT_SETTINGS.find(settings => settings.type === type)!;
  }
};
