import React from 'react';
import { Archivable } from './Archivable';
import PageNotice from '../UI/PageNotice';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';

interface ArchivedNoticeProps {
  archivable: Archivable;
}

const ArchivedNotice = ({ archivable }: ArchivedNoticeProps) => {
  const { id, __typename, archived } = archivable;
  return (
    <PageNotice
      noticeKey={`${__typename}-${id}`}
      applicable={archived}
      icon={ArchiveRoundedIcon}
      message={`This ${__typename!.toLowerCase()} has been archived.`}
    />
  );
};

export default ArchivedNotice;
