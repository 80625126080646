import React, { createContext, useState } from 'react';

interface TimeframeContextInterface {
  days: number;
  setDays: (days: number) => void;
}

const TimeframeContext = createContext<TimeframeContextInterface>(undefined!);

export const TimeframeProvider = ({ children }: { children: React.ReactNode }) => {
  const [days, setDays] = useState(90);

  return <TimeframeContext.Provider value={{ days, setDays }}>{children}</TimeframeContext.Provider>;
};

export default TimeframeContext;
