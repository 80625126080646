import React, { Fragment, useState } from 'react';
import { Button, Icon, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { SCORE_LEVELS, SCORE_LEVEL_PARAMETER, ScoreLevel, findScoreLevel } from './Score';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';

export const useScoreFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const scoreLevelKey = searchParams.get(SCORE_LEVEL_PARAMETER) || undefined;
  const scoreLevel = findScoreLevel(scoreLevelKey);

  const filterVariables = {
    // statuses: !showArchived ? ['active'] : ['active', 'archived'],
    healthLevel: scoreLevelKey,
    roles: [],
  };

  const filtersApplied = scoreLevel !== undefined;

  const resetFilters = () => {
    searchParams.delete(SCORE_LEVEL_PARAMETER);
    setSearchParams(searchParams);
  };

  const setScoreLevel = (scoreLevel: ScoreLevel | undefined) => {
    if (scoreLevel) {
      searchParams.set(SCORE_LEVEL_PARAMETER, scoreLevel.key);
    } else {
      searchParams.delete(SCORE_LEVEL_PARAMETER);
    }
    setSearchParams(searchParams);
  };

  return {
    filtersApplied,
    resetFilters,
    scoreLevel,
    setScoreLevel,
    filterVariables,
  };
};

export interface ScoreFiltersValues extends ScoreFiltersProps {
  filterVariables: Object;
  filtersApplied: Boolean;
  resetFilters: () => void;
}

export interface ScoreFiltersProps {
  scoreLevel: ScoreLevel | undefined;
  setScoreLevel: (scoreLevel: ScoreLevel | undefined) => void;
}

const ScoreFilters = ({ scoreLevel, setScoreLevel }: ScoreFiltersProps) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(null);

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(event.currentTarget);
    setFilterMenuOpen(!filterMenuOpen);
  };

  const handleFilterMenuSelect = (level: ScoreLevel | undefined) => {
    setScoreLevel(level);
    setFilterMenuOpen(false);
  };

  return (
    <Fragment>
      <Button size='small' className={scoreLevel && 'highlight'} startIcon={<FilterListRoundedIcon />} onClick={handleFilterButtonClick}>
        Filter{scoreLevel && `: ${scoreLevel.name}`}
      </Button>

      <Menu
        anchorEl={filterMenuAnchor}
        keepMounted
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem key='none' onClick={() => handleFilterMenuSelect(undefined)}>
          <ListItemIcon>{!scoreLevel && <Icon>check</Icon>}</ListItemIcon>None
        </MenuItem>
        {SCORE_LEVELS.map(level => (
          <MenuItem key={level.key} onClick={() => handleFilterMenuSelect(level)}>
            <ListItemIcon>{scoreLevel === level && <Icon>check</Icon>}</ListItemIcon>
            {level.name}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default ScoreFilters;
