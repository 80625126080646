import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, ButtonBase, Stack, Typography } from '@mui/material';

import { computeScoreLevel, Score } from './Score';
import { useDebug } from '../util/debug';

interface ScoreBarProps {
  name: string;
  score: Score;
  intelligence: number | undefined;
  path: string;
}

const ScoreBar = ({ name, score, intelligence, path }: ScoreBarProps) => {
  const debug = useDebug();

  const valueStyle = {
    width: `${score || 0}%`,
    height: '100%',
    backgroundColor: computeScoreLevel(score).color,
  };

  return (
    <ButtonBase component={RouterLink} to={path} disabled={!path}>
      <Stack alignItems='stretch' sx={{ width: '100% ' }}>
        <Stack className='score-bar-name-and-value' direction='row'>
          <Typography flex={1} variant='body1'>
            {name}
          </Typography>
          <Typography variant='body1'>{score || '—'}</Typography>
          {debug && <Typography variant='body1'>{intelligence || '—'}</Typography>}
        </Stack>
        <Stack width='100%' height='3px' mt='4px'>
          <Box sx={valueStyle} />
        </Stack>
      </Stack>
    </ButtonBase>
  );
};

export default ScoreBar;
