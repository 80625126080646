import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { Button, Icon, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getDaysFromSchedule, getHourFromSchedule } from '../util/schedule';
import StyledDialog from '../UI/StyledDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { useWorkspace } from '../Workspace/useWorkspace';
import { DialogProps } from '@mui/material/Dialog';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

interface SurveyScheduleDialogProps extends DialogProps {
  workspaceSettings: WorkspaceInterface;
  onClose: () => void;
}

const SurveyScheduleDialog = ({ workspaceSettings, open, onClose }: SurveyScheduleDialogProps) => {
  const { workspace } = useWorkspace();
  const [days, setDays] = useState(getDaysFromSchedule(workspaceSettings.surveySchedule));
  const [hour, setHour] = useState(getHourFromSchedule(workspaceSettings.surveySchedule));
  const [setSurveySchedule, { error }] = useMutation(SET_SURVEY_SCHEDULE_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(workspace) });
      cache.gc();
    },
  });

  const { setSuccess, setError } = useGlobalMessage();
  if (error) {
    console.error(error);
  }

  const handleSubmit = async () => {
    // 0 16 * * 1,2
    // minute, hour, day of month, month, day of week
    // const hour = 14; // 1 pm UTC, 9 am ET during US daylight saving. 2 pm UTC, 9 am ET during US standard time
    const schedule = `0 ${hour} * * ${days.join(',')}`;
    const response = await setSurveySchedule({
      variables: {
        workspaceId: workspaceSettings.id,
        schedule: schedule,
        enabled: true,
      },
    });
    const {
      data: {
        setSurveySchedule: { success, errors },
      },
    } = response;
    if (success) {
      setSuccess('Updated intelligence collection schedule');
      onClose();
    } else {
      console.error(errors);
      setError('Error updating intelligence collection schedule');
    }
  };

  const handleDays = (_: any, newDays: number[]) => {
    setDays(newDays);
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledSurveyScheduleForm>
        <DialogTitle>{<Icon>schedule</Icon>}Intelligence Collection Schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>Set the times when workspace members receive questions.</DialogContentText>
          <ToggleButtonGroup className='form-row' value={days} onChange={handleDays} aria-label='contained secondary button group'>
            <ToggleButton value={1}>Mon</ToggleButton>
            <ToggleButton value={2}>Tue</ToggleButton>
            <ToggleButton value={3}>Wed</ToggleButton>
            <ToggleButton value={4}>Thu</ToggleButton>
            <ToggleButton value={5}>Fri</ToggleButton>
            <ToggleButton value={6}>Sat</ToggleButton>
            <ToggleButton value={0}>Sun</ToggleButton>
          </ToggleButtonGroup>

          <FormControl variant='outlined' fullWidth>
            <InputLabel id='time-label'>Member Local Time</InputLabel>
            <Select
              variant='outlined'
              fullWidth
              name='time'
              label='Member Local Time'
              labelId='time-label'
              value={hour}
              onChange={event => setHour(event.target.value as number)}
            >
              {[...Array(24).keys()].map(hourOption => (
                <MenuItem key={hourOption} value={hourOption}>
                  {dayjs().hour(hourOption).format('h A')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' variant='contained' disableElevation color='primary' onClick={() => onClose?.()}>
            Cancel
          </Button>
          <Button variant='contained' disableElevation color='primary' type='submit' onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </StyledSurveyScheduleForm>
    </StyledDialog>
  );
};

const StyledSurveyScheduleForm = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .MuiToggleButtonGroup-root {
    display: flex;
    .MuiToggleButton-root {
      flex-grow: 1;
    }
  }
`;

const SET_SURVEY_SCHEDULE_MUTATION = gql`
  mutation SetSurveySchedule($workspaceId: ID!, $schedule: String!, $enabled: Boolean!) {
    setSurveySchedule(workspaceId: $workspaceId, schedule: $schedule, enabled: $enabled) {
      schedule {
        cron
        text
      }
      success
      errors
    }
  }
`;

export default SurveyScheduleDialog;
