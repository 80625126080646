import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { humanize } from '../util/strings';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';

import PageStyle from '../UI/PageStyle';
import { MINIMUM_PASSWORD_LENGTH } from './User';
import hyveApi from '../util/hyveApi';
import BrandedForm, { SubmitButton } from '../UI/BrandedForm';

const schema = z.object({
  password: z
    .string({ required_error: 'Password is required' })
    .min(MINIMUM_PASSWORD_LENGTH, `New password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`),
  passwordConfirmation: z.string({ required_error: 'Password confirmation is required' }),
});

type Schema = z.infer<typeof schema>;

const PasswordReset = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { password: '', passwordConfirmation: '' },
  });

  const onSubmit = async ({ password, passwordConfirmation }: Schema) => {
    setErrorMessage('');
    try {
      // Any 2xx indicates a successful update, no need to parse response
      await hyveApi.create({ noAuth: true }).patch('/users/password', {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: token,
        },
      });
      setSuccessMessage('Updated password. Redirecting to the sign in page');
      setShouldRedirect(true);
      //      setTimeout(() => setShouldRedirect(true), 1000);
    } catch (error: any) {
      const response_errors = error.response?.data?.errors;
      if (response_errors && response_errors.reset_password_token) {
        setErrorMessage("There is an issue with the reset token, this request might have expired or the URL wasn't copied correctly.");
      } else if (response_errors) {
        setErrorMessage(
          Object.keys(response_errors)
            .map(error => `${humanize(error)} ${response_errors[error]}`)
            .join(', ')
        );
      } else {
        setErrorMessage('Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`/sign-in`);
    }
  }, [navigate, shouldRedirect]);

  return (
    <PageStyle design='branded'>
      <BrandedForm
        title='Password Reset'
        subtitle='Enter a new password and confirmation to reset your password.'
        onSubmit={handleSubmit(onSubmit)}
        successMessage={successMessage}
        errorMessage={errorMessage}
      >
        <Controller
          name='password'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id='password'
              type='password'
              variant='outlined'
              fullWidth
              label='New Password'
              autoComplete='new-password'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='passwordConfirmation'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id='passwordConfirmation'
              type='password'
              variant='outlined'
              fullWidth
              label='New Password Confirmation'
              autoComplete='new-password'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <SubmitButton isSubmitting={isSubmitting}>Reset Password</SubmitButton>
      </BrandedForm>
    </PageStyle>
  );
};

export default PasswordReset;
