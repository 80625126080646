import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

import { computeScoreLevel, sortByScore } from '../Scores/Score';
import { Aspect } from './Aspect';
import { DARK_THEME_SCORE_OPACITY } from './AspectContent';

const AspectMetrics = ({ aspect }: { aspect: Aspect }) => {
  const theme = useTheme();
  const { aspectMetrics } = aspect;
  const sortedAspectMetrics = sortByScore(aspectMetrics);

  return (
    <Stack>
      {sortedAspectMetrics.map((aspectMetric, index) => {
        const { name, health } = aspectMetric;
        const level = computeScoreLevel(health);
        return (
          <Stack
            key={index}
            direction='column'
            alignItems='stretch'
            justifyContent='center'
            width='100%'
            spacing={1}
            position='relative'
            height='30px'
            sx={{
              '&:not(:first-of-type)::before': {
                position: 'absolute',
                top: '-1px',
                content: '""',
                width: '100%',
                height: '1px',
                backgroundColor: 'rgb(0, 0, 0, 0.12)',
              },
            }}
          >
            <Stack zIndex={10} direction='row' px={1} spacing={1}>
              <Box flex='1' zIndex={10} fontWeight={300}>
                <Typography variant='body1'>{name}</Typography>
              </Box>
              <Box>{health || '-'}</Box>
            </Stack>
            {health && (
              <Box
                style={{
                  position: 'absolute',
                  margin: 0,
                  width: `${health}%`,
                  height: '100%',
                  backgroundColor: level.color,
                  opacity: theme.palette.mode === 'dark' ? DARK_THEME_SCORE_OPACITY : 0.8,
                }}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default AspectMetrics;
