import React from 'react';
import { z } from 'zod';
import { gql, useMutation } from '@apollo/client';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

import TeamFields from '../Team/TeamFields';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { useWorkspace } from '../Workspace/useWorkspace';
import { TeamSchema, TeamIconSchema, TeamInterface } from './TeamSchema';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { userSelectOrInviteEmailSchema } from '../User/User';

interface NewTeamDialogProps {
  open: boolean;
  onClose: () => void;
  parentTeam?: TeamInterface;
}

const NewTeamSchema = TeamSchema.omit({ id: true }).extend({
  members: z.array(userSelectOrInviteEmailSchema),
});
type NewTeamType = z.infer<typeof NewTeamSchema>;

const NewTeamDialog = ({ open, onClose, parentTeam }: NewTeamDialogProps) => {
  const { workspace, refetch } = useWorkspace();
  const { setSuccess } = useGlobalMessage();

  const defaultValues = {
    parentTeam,
    icon: TeamIconSchema.parse({}),
    manager: null,
    members: [],
  };

  const formDialogProps = useFormDialog<NewTeamType>({ schema: NewTeamSchema, defaultValues });
  const { handleResponse } = formDialogProps;

  const [createTeam, { error }] = useMutation(CREATE_TEAM_MUTATION);

  const handleSubmit = async ({ parentTeam, manager, members, ...values }: NewTeamType) => {
    const response = await createTeam({
      variables: {
        workspaceId: workspace.id,
        parentTeamId: parentTeam?.id,
        managerEmail: manager?.email,
        userEmails: members.map(user => user.email),
        ...values,
      },
    });

    const success = handleResponse(response, 'createTeam', 'Uh oh. There was an error adding the team');
    if (success) {
      setSuccess('Added team');
      await refetch();
    }
    return success;
  };

  return (
    <FormDialog<NewTeamType>
      {...formDialogProps}
      id='new-team'
      open={open}
      onClose={onClose}
      icon={<PeopleRoundedIcon />}
      title='Add New Team'
      subtitle='Create a team so senior leadership and team managers can track the health of the team’s projects and engagement.'
      submitLabel='Add Team'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <TeamFields mode='new' />
    </FormDialog>
  );
};

export default NewTeamDialog;

const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeam($workspaceId: ID!, $parentTeamId: ID, $name: String!, $managerEmail: String, $userEmails: [String!], $icon: IconInput) {
    createTeam(workspaceId: $workspaceId, parentTeamId: $parentTeamId, name: $name, managerEmail: $managerEmail, userEmails: $userEmails, icon: $icon) {
      team {
        id
        name
        manager {
          id
          firstName
          lastName
        }
        users {
          id
          email
          firstName
          lastName
          pendingInvitation
        }
      }

      errors {
        type
        fullMessage
      }
    }
  }
`;
