import { DialogTitle, Icon, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import StyledDialog from "../UI/StyledDialog";

interface SignOutDialogProps {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const SignOutDialog = ({ open, onSubmit, onClose }: SignOutDialogProps) => (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Icon>exit_to_app</Icon>
        Sign Out
      </DialogTitle>
      <DialogContent>
        <DialogContentText>All done for the day? Click "Sign Out" to confirm and sign out of Hyve.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-button' variant='contained' disableElevation color='primary' onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant='contained' disableElevation color='primary' type='submit' onClick={() => onSubmit()}>
          Sign Out
        </Button>
      </DialogActions>
    </StyledDialog>
  );

  export default SignOutDialog;
  