import ClientAvatar from '../Client/ClientAvatar';
import TeamIcon from '../Team/TeamIcon';

import { ReactComponent as WorkspaceIcon } from '../Icons/Bee-Hive-Icon.svg';
import { ReactComponent as ProjectIcon } from '../Icons/Project-Fill-Icon.svg';

import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import { ClientInterface } from '../Client/ClientInterface';

import { Category } from '../Category/Category';
import { IconType } from '../UI/Icon';
import { ScoreDimensionBase } from './ScoreDimensionBase';
import { ProjectInterface } from '../Project/ProjectInterface';
import { ScorableType } from './Scorable';

export interface ScorableBase extends ScoreDimensionBase {
  key: string;
  type: ScorableType;
  categories: Category[];
  intelligenceStats: {
    relativeContribution: number;
    contributorPercentage: number;
  };
}

// TODO extend this to be used more widely, e.g., for DashboardScores
interface ScorableInfo {
  type: ScorableType;
  icon: IconType;
  avatar: IconType;
  name: string;
}

export const getScorableInfo = (scorable: ScorableBase): ScorableInfo => {
  let type: ScorableType;
  let icon: IconType;
  let avatar: IconType;
  let name: string;

  switch (scorable.__typename) {
    case 'Workspace':
      type = 'Workspace';
      icon = WorkspaceIcon;
      avatar = icon;
      name = scorable.name;
      break;
    case 'Client':
      type = 'Client';
      icon = BusinessRoundedIcon;
      avatar = () => <ClientAvatar client={scorable as ClientInterface} />;
      name = scorable.name;
      break;
    case 'Project':
      type = 'Project';
      icon = ProjectIcon;
      const project = scorable as ProjectInterface;
      avatar = () => <ClientAvatar client={project.client} />;
      name = scorable.name;
      break;
    case 'Team':
      type = 'Team';
      icon = SupervisedUserCircleRoundedIcon;
      avatar = (props: any) => <TeamIcon icon={scorable.icon} {...props} />;
      name = scorable.name;
      break;
    default:
      throw new Error('Unexpected scorable');
  }
  return { type, icon, avatar, name };
};

export const SCORABLE_SCORE_FIELDS_FRAGMENT = `
  fragment ScoreFields on ScorableInterface {
    health
    healthDelta
    intelligence

    intelligenceStats(lookback: $days) {
      relativeContribution
      contributorPercentage
    }
  }
`;

// TODO when available in the API, filter to internal projects
export const scorableProjectBreakdownFragment = (type: string) => `
  fragment ${type}ProjectBreakdown on ${type} {
    projectStats {
      count
      countByScore {
        high
        medium
        low
        collecting
      }
    }
  }
`;
