import React from "react";
import { NoticeProvider } from './NoticeContext';

const Main = ({ children }: { children: React.ReactNode}) => {
  return (
    <NoticeProvider>
      {children}
    </NoticeProvider>
  );
};

export default Main;
