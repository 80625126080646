import React, { Fragment, useState } from 'react';
import { Card, Stack, useTheme } from '@mui/material';
import { Aspect } from './Aspect';
import AspectScorables from '../Aspect/AspectScorables';
import { AspectSummaryText } from './AspectSummary';
import { computeScoreLevel } from '../Scores/Score';
import { ModuleInterface } from '../Module/Module';
import ScoreCircle from '../Scores/ScoreCircle';
import IntelligenceAndTrend from '../Scores/IntelligenceAndTrend';
import AspectRecommendations from '../Aspect/AspectRecommendations';
import AspectMetrics from './AspectMetrics';
import { Scorable } from '../Scores/Scorable';

export const DARK_THEME_SCORE_OPACITY = 0.4;

interface DashboardAspectDetailProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspect: Aspect;
  onCollapse?: () => void;
}

const AspectContent = ({ scorable, module, aspect }: DashboardAspectDetailProps) => {
  const { health } = aspect;
  const healthLevel = computeScoreLevel(health);
  const theme = useTheme();
  const [showRecommendations, setShowRecommendations] = useState(false);

  return (
    <Fragment>
      <Card elevation={3} sx={{ backgroundColor: theme.palette.subtle.light, borderRadius: 0 }}>
        <Stack p={4} spacing={4}>
          <Stack spacing={4}>
            <Stack direction='row' gap={4} alignItems='center' flexWrap='wrap'>
              <ScoreCircle width='130px' color={healthLevel.color} score={health} scoreFontSize='48px' />
              <IntelligenceAndTrend scorable={scorable} module={module} scoreDimension={aspect} />
              <AspectSummaryText
                flex={1}
                minWidth={240}
                scorable={scorable}
                module={module}
                aspect={aspect}
                showRecommendations={showRecommendations}
                setShowRecommendations={setShowRecommendations}
              />
            </Stack>

            {showRecommendations && <AspectRecommendations aspect={aspect} />}

            <AspectMetrics key={aspect.id} aspect={aspect} />
          </Stack>

          <AspectScorables aspect={aspect} />
        </Stack>
      </Card>
    </Fragment>
  );
};

export default AspectContent;
