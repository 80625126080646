import { gql } from '@apollo/client';

import ProjectCollector from '../Projects/ProjectCollector';
import { UserInterface } from '../User/User';
import { MembershipInterface } from '../Membership/Membership';
import { ScorableBase } from '../Scores/ScorableBase';
import { CompanyInterface } from '../Company/CompanyInterface';
import { Archivable } from '../Archive/Archivable';
import { CHANNEL_CONNECTIONS_FRAGMENT, ChannelConnectable } from '../Chat/ChannelConnectable';

// TODO: Convert this interface into a type

export interface ClientInterface extends ScorableBase, CompanyInterface, ProjectCollector, MembershipInterface, Archivable, ChannelConnectable {
  __typename: 'Client';
  type: 'Client';
  imageUrl?: string;
  domain?: string;
  importance: number | null;
  projectMember?: boolean;
  owners: UserInterface[];
  users: UserInterface[];
}

export const CLIENT_FIELDS_FRAGMENT = gql`
  fragment ClientFields on Client {
    id
    name
    internal
    domain
    importance
    vertical {
      id
      name
    }
    subVertical {
      id
      name
    }
    status
    archived
    imageUrl

    ...ChannelConnections
  }
  ${CHANNEL_CONNECTIONS_FRAGMENT}
`;

export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClient($id: ID!, $name: String!, $imageUrl: String, $domain: String, $importance: Int, $verticalId: ID, $subVerticalId: ID) {
    updateClient(id: $id, name: $name, imageUrl: $imageUrl, domain: $domain, importance: $importance, verticalId: $verticalId, subVerticalId: $subVerticalId) {
      client {
        ...ClientFields
      }
    }
  }
  ${CLIENT_FIELDS_FRAGMENT}
`;
