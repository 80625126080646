import { gql } from '@apollo/client';
import ProjectList from '../Projects/ProjectList';
import useProjectList, { PROJECT_COLLECTOR_FRAGMENT } from '../Projects/useProjectList';
import AppSurface from '../UI/AppSurface';
import PageStyle from '../UI/PageStyle';
import { useWorkspace } from '../Workspace/useWorkspace';

const ClientsProjects = () => {
  const { workspace } = useWorkspace();
  const projectListProps = useProjectList(CLIENTS_PROJECTS_QUERY, { id: workspace.id }, data => data.workspace.categories[0].modules[0]);

  return (
    <PageStyle>
      <AppSurface>
        <ProjectList mode='client' {...projectListProps} />
      </AppSurface>
    </PageStyle>
  );
};

export default ClientsProjects;

const CLIENTS_PROJECTS_QUERY = gql`
  query ClientsProjects($id: ID!, $healthLevel: [HealthLevel!], $statuses: [ProjectStatus!], $scope: ScopeInput, $archived: [Boolean!]) {
    workspace(id: $id, scope: $scope) {
      id
      categories(type: ClientsCategory) {
        modules(type: ClientsProjectsModule) {
          ...ProjectCollector
        }
      }
    }
  }
  ${PROJECT_COLLECTOR_FRAGMENT}
`;
