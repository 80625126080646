import { useQuery, gql } from '@apollo/client';
import { useParams, Outlet } from 'react-router-dom';

import { ScopeProvider } from '../Scope/ScopeContext';
import TeamSecondaryNavigation from './TeamSecondaryNavigation';
import { TEAM_FIELDS_FRAGMENT } from './TeamType';
import PageStyle from '../UI/PageStyle';
import ApolloErrorPage from '../UI/ApolloErrorPage';
import { TeamInterface } from './TeamSchema';
import { TeamProvider } from './TeamContext';
import useWorkspace from '../Workspace/useWorkspace';
import { CategoryParentProvider } from '../Category/CategoryParentContext';

const Team = () => {
  const { teamId: id } = useParams<{ teamId: string }>();
  const { workspace } = useWorkspace();

  const { data, error, loading, refetch } = useQuery(TEAM_QUERY, { variables: { id: id } });
  if (!data && loading) return <PageStyle design='standard' loading={true} />;
  if (error) {
    return <ApolloErrorPage error={error} type='Team' />;
  }

  const { team }: { team: TeamInterface } = data;
  const teamPath = `/workspace/${workspace.id}/teams/${id}`;

  return (
    <TeamProvider team={team} refetch={refetch}>
      <CategoryParentProvider parentPath={teamPath}>
        <TeamSecondaryNavigation team={team} />
        <ScopeProvider scorable={team}>
          <Outlet />
        </ScopeProvider>
      </CategoryParentProvider>
    </TeamProvider>
  );
};

export const TEAM_QUERY = gql`
  query Team($id: ID!, $scope: ScopeInput) {
    team(id: $id, scope: $scope) {
      ...TeamFields
    }
  }

  ${TEAM_FIELDS_FRAGMENT}
`;

export default Team;
