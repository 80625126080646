import React, { Fragment } from 'react';

import { ClientInterface } from '../Client/ClientInterface';
import { useLayout } from '../UI/LayoutContext';
import { AddProjectMode, useAddProject } from './useAddProject';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import NewProjectDialog from '../Project/NewProjectDialog';
import { ActionInterface } from '../UI/ActionInterface';

interface AddProjectButtonProps {
  mode: AddProjectMode;
  client?: ClientInterface;
}

const AddProjectButton = ({ mode, client }: AddProjectButtonProps) => {
  const layout = useLayout();
  const { addProjectActions, addProjectClient, addProjectDialogOpen, setAddProjectDialogOpen } = useAddProject(mode, client);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: ActionInterface) => {
    action.onSelect!();
    setAnchorEl(null);
  };

  const handleAddProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (addProjectActions.length === 1) {
      setAddProjectDialogOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <Fragment>
      <NewProjectDialog key={addProjectClient?.id} client={addProjectClient} open={addProjectDialogOpen} onClose={() => setAddProjectDialogOpen(false)} />

      {layout === 'mobile' ? (
        <IconButton onClick={handleAddProject}>
          <AddCircleRoundedIcon />
        </IconButton>
      ) : (
        <Button startIcon={<AddCircleRoundedIcon />} onClick={handleAddProject}>
          Add New Project
        </Button>
      )}

      {/* Menu for multiple actions */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {addProjectActions.map((action, index) => (
          <MenuItem key={index} onClick={() => handleAction(action)}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default AddProjectButton;
