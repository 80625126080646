import { gql } from '@apollo/client';
import { Channel } from './Channel';

export type ChannelConnection = {
  id: string;
  channel: Channel;
};

export type ChannelConnectable = {
  channelConnections: ChannelConnection[];
};

export const CHANNEL_CONNECTIONS_FRAGMENT = gql`
  fragment ChannelConnections on ChannelConnectableInterface {
    channelConnections {
      id
      channel {
        id
        platform
        externalId
        name
        isPrivate
      }
    }
  }
`;
