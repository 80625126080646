import React from 'react';
import ModuleDashboard, { ModuleDashboardProps, ModuleStats, PersonalAndMemberBreakdownModuleDetails } from '../Module/ModuleDashboard';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { MembershipInterface } from '../Membership/Membership';
import { Scorable } from '../Scores/Scorable';

type CompanyModuleDashboardProps = ModuleDashboardProps & {
  scorable: Scorable & MembershipInterface;
};

const CompanyModuleDashboard = ({ scorable, category, module, scopePrefix }: CompanyModuleDashboardProps) => {
  const { icon } = findModuleScoreDimensionSettings(module.type);

  return (
    <ModuleDashboard scorable={scorable} category={category} module={module} icon={icon} scopePrefix={scopePrefix} direction='row'>
      <ModuleStats scorable={scorable} module={module} direction='column' />
      <PersonalAndMemberBreakdownModuleDetails scorable={scorable} module={module} />
    </ModuleDashboard>
  );
};

export default CompanyModuleDashboard;
