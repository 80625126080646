import React, { useLayoutEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import { useLayout } from './LayoutContext';

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  // Manually scroll the window to the top when a new location is pushed to history
  // Allow the browser to automatically return to the former scroll position when navigating back and forward
  useLayoutEffect(() => {
    if (navigationType === 'PUSH' || navigationType === 'REPLACE') {
      window.history.scrollRestoration = 'manual';
      window.scrollTo(0, 0);
    } else if (navigationType === 'POP') {
      window.history.scrollRestoration = 'auto';
    }
  }, [location, navigationType]);

  const layout = useLayout();
  return <div className={layout}>{children}</div>;
};

export default AppLayout;
