import { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import ConfidentialitySettingsDialog from './ConfidentialitySettingsDialog';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

const ConfidentialitySettings = ({ workspaceSettings }: { workspaceSettings: WorkspaceInterface }) => {
  const [open, setOpen] = useState(false);

  const categories = workspaceSettings.categories;

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <AdminPanelSettingsRoundedIcon />
        </SectionHeaderIcon>
        <SectionHeaderTitle>Confidentiality Settings</SectionHeaderTitle>
        <SectionHeaderActions>
          <Button onClick={() => setOpen(true)}>Manage</Button>
        </SectionHeaderActions>
      </SectionHeader>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category Data Visibility</TableCell>
            <TableCell>Team Member Minimum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map(category => (
            <TableRow key={category.type}>
              <TableCell>{category.name}</TableCell>
              <TableCell>{category.confidentialityThreshold}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ConfidentialitySettingsDialog workspaceSettings={workspaceSettings} open={open} onClose={() => setOpen(false)} />
    </AppSurface>
  );
};

export default ConfidentialitySettings;
