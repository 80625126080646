import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react'; // Import React and useState
import { ClientInterface } from './ClientInterface';
import { RatingProps } from '@mui/material';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { handleMutationResponse } from '../util/handleMutationResponse';
import ImportanceRating from '../Scores/ImportanceRating';

type ClientImportanceRatingProps = RatingProps & {
  client: ClientInterface;
};

const ClientImportanceRating = ({ client, ...props }: ClientImportanceRatingProps) => {
  const { setSuccess, setError } = useGlobalMessage();
  const [optimisticValue, setOptimisticValue] = useState<number | null>(client.importance);

  const [updateClient] = useMutation(UPDATE_CLIENT_IMPORTANCE_MUTATION, {
    onCompleted: response => {
      const { success, errorMessage } = handleMutationResponse({ data: response }, 'updateClientImportance', 'Uh oh. There was an error updating the client.');
      if (success) {
        setSuccess('Updated client importance');
      } else {
        setError(errorMessage);
        // Revert optimistic update on error
        setOptimisticValue(client.importance);
      }
    },
    onError: error => {
      setError(error.message);
      // Revert optimistic update on network error
      setOptimisticValue(client.importance);
    },
  });

  const handleChange = async (_: any, value: number | null) => {
    setOptimisticValue(value); // Set optimistic value immediately

    await updateClient({
      variables: {
        id: client.id,
        importance: value,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateClientImportance: {
          __typename: 'Client',
          client: {
            id: client.id,
            importance: value,
            __typename: 'Client',
          },
        },
      },
    });
  };

  return <ImportanceRating value={optimisticValue} onChange={handleChange} {...props} />;
};

export default ClientImportanceRating;

export const UPDATE_CLIENT_IMPORTANCE_MUTATION = gql`
  mutation UpdateClientImportance($id: ID!, $importance: Int) {
    updateClientImportance(id: $id, importance: $importance) {
      client {
        id
        importance
      }
    }
  }
`;
