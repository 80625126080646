import React from 'react';
import { Controller } from 'react-hook-form';
import { z } from 'zod';

import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import { INVITE_MUTATION, inviteMembersSchema } from '../Invitation/Invite';
import { useGlobalMessage } from '../UI/GlobalMessage';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import MemberSelect, { useMemberSelect } from '../Membership/MemberSelect';
import { useTeamContext } from './TeamContext';
import { useMutation } from '@apollo/client';

const teamInviteSchema = inviteMembersSchema;
type TeamInviteValues = z.infer<typeof teamInviteSchema>;

interface AddTeamMembersDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddTeamMembersDialog = ({ open, onClose }: AddTeamMembersDialogProps) => {
  const { team, refetch } = useTeamContext();
  const { setSuccess } = useGlobalMessage();
  const { workspaceUsers, loading } = useMemberSelect();
  const formDialogProps = useFormDialog<TeamInviteValues>({ schema: teamInviteSchema });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const [invite, { error }] = useMutation(INVITE_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(team) });
      cache.gc();
    },
  });

  const handleSubmit = async (values: TeamInviteValues) => {
    const { members } = values;

    const invites = members.map(user => ({
      email: user.email,
      memberships: [{ id: team.id, type: 'Team' }],
    }));

    const response = await invite({ variables: { invites: invites } });
    const success = handleResponse(response, 'invite', `Uh oh. There was an error inviting members.`);
    if (success) {
      setSuccess(`Added team members`);
      await refetch();
    }
    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='invite-to-team'
      open={open}
      onClose={onClose}
      icon={<GroupAddRoundedIcon />}
      title={`Add Members to the ${team.name} Team`}
      subtitle='Add people to the team.'
      submitLabel='Add'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Controller
        name='members'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <MemberSelect {...field} loading={loading} error={error} options={workspaceUsers} disabledOptions={team.users} autoFocus />
        )}
      />
    </FormDialog>
  );
};

export default AddTeamMembersDialog;
