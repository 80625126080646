import Stack from '@mui/material/Stack';

import { IconButton, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import BoltIconRounded from '@mui/icons-material/BoltRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import useKeyboardShortcut, { KeyboardShortcutTooltip } from '../UI/useKeyboardShortcut';
import { useAI } from './AIContext';

export const AI_TOOLBAR_HEIGHT = '64px';

const AIToolbar = () => {
  const { setOpen, reset } = useAI();
  useKeyboardShortcut(() => setOpen(false), 'Escape');
  const theme = useTheme();

  return (
    <Toolbar
      // ${theme.palette.common.black};
      sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      // sx={{ position: 'fixed', width: '100%', zIndex: 1300, backgroundColor: 'color.paper' }}
    >
      <Stack direction='row' ml={-1} spacing={1} alignItems='center' flexGrow={1}>
        <BoltIconRounded />
        <Typography variant='h2' flexGrow={1}>
          Hyve AI
        </Typography>
      </Stack>
      <Tooltip title='Reset Conversation'>
        <IconButton onClick={() => reset()}>
          <RestartAltRoundedIcon />
        </IconButton>
      </Tooltip>
      <KeyboardShortcutTooltip title='Close' shortcut='Escape'>
        <IconButton edge='end' onClick={() => setOpen(false)}>
          <CloseRoundedIcon />
        </IconButton>
      </KeyboardShortcutTooltip>
    </Toolbar>
  );
};

export default AIToolbar;
