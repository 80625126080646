import React from 'react';
import { useQuery, gql } from '@apollo/client';

import Aspects from '../Aspect/Aspects';
import { MODULE_ASPECT_SCORES_FRAGMENT } from '../Aspect/AspectList';
import PageStyle from '../UI/PageStyle';
import { useTimeframe } from '../User/useTimeframe';
import { useProjectContext } from './ProjectContext';

const ProjectAspects = () => {
  const { project } = useProjectContext();
  const { id } = project;
  const { days } = useTimeframe();

  const { data, error, loading } = useQuery(PROJECT_ASPECT_SCORES_QUERY, { variables: { id: id, days: days } });
  if (!data && loading) {
    return <PageStyle design='standard' loading={true} />;
  }
  if (error) throw error;

  const scorable = data.scorable;

  if (!scorable) {
    return null;
  }

  return (
    <PageStyle design='standard'>
      <Aspects scorable={scorable} />
    </PageStyle>
  );
};

export default ProjectAspects;

export const PROJECT_ASPECT_SCORES_QUERY = gql`
  query ProjectAspectScores($id: ID!, $scope: ScopeInput, $days: Int) {
    scorable: project(id: $id, scope: $scope) {
      id
      categories {
        id
        name
        type
        modules {
          ...ModuleAspectScores
        }
      }
    }
  }
  ${MODULE_ASPECT_SCORES_FRAGMENT}
`;
