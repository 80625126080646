import SlackIntegrationNotice from '../Account/SlackIntegrationNotice';
import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import { PageNotices } from '../UI/PageNotice';
import { useWorkspace } from './useWorkspace';

const WorkspaceNotices = () => {

  return (
    <PageNotices>
      <SlackIntegrationNotice />
      <WorkspaceIntelligenceNotice />
    </PageNotices>
  );
};

const WorkspaceIntelligenceNotice = () => {
  const { workspace } = useWorkspace();
//  const { projectsActive, employeeExperienceActive } = useActiveFeatures();

//  const projectsApplicable = projectsActive && workspace.projectStats.count > 0;
  // const applicable = projectsApplicable || employeeExperienceActive;

  return <IntelligenceNotice scorable={workspace} />;
};

export default WorkspaceNotices;
