import axios from 'axios';
import { TOKEN } from '../User/User';

export type ValidationError = {
  type: string;
  // message: string; // do not use message
  fullMessage: string;
  attribute: string;
};

export type ApiResponse = {
  success: boolean;
  errors: ValidationError[];
};

export const UNKNOWN_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again or get in touch.';

const hyveApi = {
  create({ noAuth = false, isFormData = false } = {}) {
    const config = {
      baseURL: process.env.REACT_APP_SERVER,
      withCredentials: true,
      headers: {
        common: {
          ...(!isFormData && { 'Content-Type': 'application/json' }),
        },
      },
    };
    const token = localStorage.getItem(TOKEN);
    if (token && !noAuth) {
      (config.headers.common as { [key: string]: string })['Authorization'] = `Bearer ${token}`;
    }
    return axios.create(config);
  },
};
export default hyveApi;
