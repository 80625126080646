import React from 'react';
import ModuleDashboard, { ModuleDashboardProps, ModuleStats } from '../Module/ModuleDashboard';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { MembershipInterface } from '../Membership/Membership';
import ClientsScoreBreakdown from './ClientsScoreBreakdown';
import { DashboardCard } from '../Dashboard/DashboardCard';
import { ClientCollector } from '../Client/ClientCollector';
import { Scorable } from '../Scores/Scorable';

type ClientsModuleDashboardProps = ModuleDashboardProps & {
  scorable: Scorable & MembershipInterface & ClientCollector;
};

const ClientsModuleDashboard = ({ scorable, category, module, scopePrefix }: ClientsModuleDashboardProps) => {
  const { icon } = findModuleScoreDimensionSettings(module.type);

  let title;
  let children;

  if (scorable.__typename === 'Client') {
    // Single client
    title = module.name;
    children = <ModuleStats scorable={scorable} module={module} direction='row' />;
  } else {
    // Collection of clients
    title = `${module.name} - ${scopePrefix}Clients`;
    children = (
      <>
        <DashboardCard title={`Clients (${scorable.clientStats.count})`} justifyContent='center'>
          <ClientsScoreBreakdown scoreDimension={module} />
        </DashboardCard>
      </>
    );
  }

  return (
    <ModuleDashboard scorable={scorable} category={category} module={module} icon={icon} title={title} direction='row'>
      {children}
    </ModuleDashboard>
  );
};

export default ClientsModuleDashboard;
