import { useTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { HYVE_YELLOW } from '../Theme/AppTheme';
import { LIGHT_THEME } from '../Theme/LightTheme';

export type DesignType = 'standard' | 'simple' | 'custom' | 'illustrated' | 'branded' | 'floating';

export const useDesignTheme = (design: DesignType) => {
  const theme = useTheme();

  const designThemes: { [K in DesignType]?: Object } = {
    standard: {
      name: 'standard',
      components: {
        MuiButton: {
          defaultProps: {
            color: 'neutral',
          },
        },
        MuiIconButton: {
          defaultProps: {
            color: 'neutral',
          },
        },
      },
    },
    floating: {
      name: 'floating',
      palette: {
        background: {
          default: theme.palette.background.paper,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: { boxShadow: 'none' },
          },
        },
      },
    },
    illustrated: {
      name: 'illustrated',
      palette: {
        background: {
          default: theme.palette.background.paper,
        },
      },
    },
    branded: deepmerge(LIGHT_THEME, {
      name: 'branded',
      palette: {
        background: {
          default: HYVE_YELLOW,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              width: '100%',
            },
          },
        },
      },
    }),
  };

  return designThemes[design];
};
