import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { z } from 'zod';

import { useGlobalMessage } from '../UI/GlobalMessage';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { LinkRounded } from '@mui/icons-material';
import { ChannelConnectable } from './ChannelConnectable';
import { Scorable } from '../Scores/Scorable';
import ChannelConnectionFields from './ChannelConnectionFields';

const addChannelSchema = z.object({
  platform: z.string(),
  channelType: z.enum(['new', 'existing']),
  newChannel: z.object({
    name: z.string(),
    isPrivate: z.boolean().default(true),
  }),
  existingChannel: z
    .object({
      id: z.string().optional(),
      name: z.string(),
    })
    .nullable(),
  addMembers: z.boolean().default(true),
});

type AddChannelValues = z.infer<typeof addChannelSchema>;

type ConnectChannelDialogProps = {
  scorable: Scorable & ChannelConnectable;
  defaultChannelName: string;
  open: boolean;
  onClose: () => void;
};

const ConnectChannelDialog = ({ scorable, defaultChannelName, open, onClose }: ConnectChannelDialogProps) => {
  const defaultValues: AddChannelValues = {
    channelType: 'new',
    platform: 'slack',
    newChannel: { name: defaultChannelName, isPrivate: true },
    existingChannel: null,
    addMembers: true,
  };

  const formDialogProps = useFormDialog<AddChannelValues>({ schema: addChannelSchema, defaultValues });
  const { setSuccess } = useGlobalMessage();
  const { handleResponse } = formDialogProps;
  const [addChannelConnectionMutation, { error }] = useMutation(ADD_CHANNEL_CONNECTION_MUTATION);

  const handleSubmit = async (values: AddChannelValues) => {
    const { platform, channelType, newChannel, existingChannel, addMembers } = values;
    if (channelType === 'existing' && existingChannel === null) {
      return false;
    }

    const variables: any = { scorableId: scorable.id, scorableType: scorable.__typename, platform, addMembers };

    if (channelType === 'new') {
      variables.channelName = newChannel.name;
      variables.isPrivate = newChannel.isPrivate;
    } else {
      variables.platformChannelId = existingChannel!.id;
      variables.channelName = existingChannel!.name;
    }

    const response = await addChannelConnectionMutation({ variables });

    const success = handleResponse(response, 'addChannelConnection', 'Uh oh. There was a problem connecting to the channel');
    if (success) {
      setSuccess('Connected to channel');
    }
    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='channel-connection'
      open={open}
      onClose={onClose}
      icon={<LinkRounded />}
      title='Connect to a Slack Channel'
      // subtitle='Enter a new Slack channel name or select an existing channel to connect.'
      submitLabel='Connect Channel'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <ChannelConnectionFields scorable={scorable} />
    </FormDialog>
  );
};

export default ConnectChannelDialog;

const ADD_CHANNEL_CONNECTION_MUTATION = gql`
  mutation AddChannelConnection(
    $scorableId: ID!
    $scorableType: String!
    $platform: ChannelConnectionPlatform!
    $platformChannelId: ID
    $channelName: String!
    $isPrivate: Boolean
    $addMembers: Boolean!
  ) {
    addChannelConnection(
      scorableId: $scorableId
      scorableType: $scorableType
      platform: $platform
      platformChannelId: $platformChannelId
      channelName: $channelName
      isPrivate: $isPrivate
      addMembers: $addMembers
    ) {
      channelConnectable {
        id
        name
        channelConnections {
          id
        }
      }
      success
      errors {
        type
        fullMessage
      }
    }
  }
`;
