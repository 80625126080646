import React, { ReactNode, useLayoutEffect, useState, useRef } from 'react';
import { Stack } from '@mui/material';
import { createContext, useContext } from 'react';
import { debounce } from 'lodash';

// Create the ScrollContext to share the overflow state with children
const HorizontalOverflowContext = createContext<{ isScrollVisible: boolean }>({ isScrollVisible: false });

// Custom hook to consume the ScrollContext in child components
export const useHorizontalOverflow = () => useContext(HorizontalOverflowContext);

type HorizontalOverflowWrapperProps = {
  children: ReactNode;
};

const HorizontalOverflowWrapper: React.FC<HorizontalOverflowWrapperProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  useLayoutEffect(() => {
    const container = containerRef.current;

    const checkForScrollbar = () => {
      if (container) {
        const hasOverflow = container.scrollWidth > container.clientWidth;
        if (hasOverflow !== isScrollVisible) {
          setIsScrollVisible(hasOverflow); // Only update if the overflow state has changed
        }
      }
    };

    // Debounce the resize event handler to avoid excessive re-renders
    const debouncedCheckForScrollbar = debounce(checkForScrollbar, 100);

    // Initial check and on window resize
    checkForScrollbar();
    window.addEventListener('resize', debouncedCheckForScrollbar);

    return () => {
      window.removeEventListener('resize', debouncedCheckForScrollbar);
      debouncedCheckForScrollbar.cancel(); // Ensure debounced calls are canceled on unmount
    };
  }, [isScrollVisible]); // Add isScrollVisible as a dependency

  return (
    <HorizontalOverflowContext.Provider value={{ isScrollVisible }}>
      <Stack
        direction='row'
        ref={containerRef}
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap', // Prevent wrapping
        }}
      >
        {children}
      </Stack>
    </HorizontalOverflowContext.Provider>
  );
};

export default HorizontalOverflowWrapper;
