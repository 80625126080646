import React from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { DialogProps } from '@mui/material';
import HexagonRoundedIcon from '@mui/icons-material/HexagonRounded';
import { z } from 'zod';

import ProjectFields, { projectSchema } from '../Project/ProjectFields';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { useWorkspace } from '../Workspace/useWorkspace';
import { ClientInterface } from '../Client/ClientInterface';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { userSelectOrInviteEmailSchema } from '../User/User';
import { PROJECT_FIELDS_FRAGMENT } from './ProjectInterface';

const NewProjectSchema = projectSchema.omit({ id: true }).extend({
  users: z.array(userSelectOrInviteEmailSchema),
});
type NewProjectValues = z.infer<typeof NewProjectSchema>;

interface NewProjectDialogProps extends DialogProps {
  onClose: () => void;
  client?: ClientInterface;
}

const NewProjectDialog = ({ open, onClose, client }: NewProjectDialogProps) => {
  const navigate = useNavigate();
  const { setSuccess } = useGlobalMessage();
  const { user } = useAuthenticatedUser();
  const { workspace } = useWorkspace();
  const internal = client?.id === workspace.company.id;

  const defaultValues = {
    name: '',
    client: client,
    users: [user],
  };

  const formDialogProps = useFormDialog<NewProjectValues>({ schema: NewProjectSchema, defaultValues });
  const { handleResponse } = formDialogProps;

  const [createProject, { error }] = useMutation(CREATE_PROJECT_MUTATION);

  const handleSubmit = async (values: NewProjectValues) => {
    // console.log(values);

    const userEmails = values.users.map(user => user.email);

    const response = await createProject({
      variables: {
        workspaceId: workspace.id,
        ...values,
        userEmails: userEmails,
      },
    });
    const success = handleResponse(response, 'createProject', 'Uh oh. There was an error adding the project.');
    if (success) {
      setSuccess('Added project');
      onClose();
      navigate(`/workspace/${workspace.id}/projects/${response.data.createProject.project.id}`);
    }

    return success;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='new-project'
      open={open}
      onClose={onClose}
      icon={<HexagonRoundedIcon />}
      title={internal ? 'New Internal Project' : 'New Client Project'}
      subtitle='Enter the information below to add a new project to your workspace.'
      submitLabel='Add Project'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <ProjectFields mode={client?.id ? 'new-project-for-client' : 'new-project'} />
    </FormDialog>
  );
};

export default NewProjectDialog;

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject(
    $workspaceId: ID!
    $name: String!
    $description: String
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $importance: Int
    $client: ClientInput!
    $userEmails: [String!]!
  ) {
    createProject(
      workspaceId: $workspaceId
      name: $name
      description: $description
      startDate: $startDate
      endDate: $endDate
      importance: $importance
      client: $client
      userEmails: $userEmails
    ) {
      project {
        ...ProjectFields
        client {
          id
        }
        users {
          id
          firstName
          lastName
          email
          pendingInvitation
        }
      }
      errors {
        fullMessage
      }
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;
