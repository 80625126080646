import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useArchiveActions } from '../Archive/ArchiveActions';
import { INVITE_MUTATION } from '../Invitation/Invite';
import { useIntelligenceActions } from '../Intelligence/IntelligenceActions';
import { REMOVE_PROJECT_MEMBERS_MUTATION } from './ProjectMembers';
import EditProjectDialog from '../Project/EditProjectDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import { ProjectInterface } from './ProjectInterface';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import { ActionInterface } from '../UI/ActionInterface';
import { useWorkspace } from '../Workspace/useWorkspace';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import { Stack } from '@mui/material';

export const useProjectActions = (project: ProjectInterface, refetch: () => Promise<any>) => {
  const { user } = useAuthenticatedUser();
  const { setSuccess } = useGlobalMessage();

  const [joinProjectMutation] = useMutation(INVITE_MUTATION);

  const [leaveProjectMutation] = useMutation(REMOVE_PROJECT_MEMBERS_MUTATION);

  const leaveProject = async () => {
    await leaveProjectMutation({ variables: { id: project.id, userIds: [user.id] } });
    setSuccess('Left project');
    await refetch();
  };

  const joinProject = async () => {
    await joinProjectMutation({
      variables: {
        invites: [
          {
            email: user.email,
            memberships: [{ id: project.id, type: 'Project' }],
          },
        ],
      },
    });
    await refetch();
    setSuccess('Joined project');
  };

  return { joinProject, leaveProject };
};

interface ProjectActionsProps {
  project: ProjectInterface;
  includeNavigationActions?: boolean;
  refetch: () => Promise<any>;
}

const ProjectActions = ({ project, includeNavigationActions, refetch }: ProjectActionsProps) => {
  const { workspace } = useWorkspace();
  const { id, member, archived } = project;
  const { intelligenceActions, collectIntelligenceDialog } = useIntelligenceActions(project);
  const { joinProject, leaveProject } = useProjectActions(project, refetch);
  const [editProjectDialogOpen, setEditProjectDialogOpen] = useState(false);
  const { archive, unarchive } = useArchiveActions(project, ARCHIVE_PROJECT_MUTATION, refetch);

  const membershipAction: ActionInterface = !member
    ? { icon: PersonAddRoundedIcon, label: 'Join Project', onSelect: joinProject }
    : { icon: PersonRemoveRoundedIcon, label: 'Leave Project', color: 'error', onSelect: leaveProject };

  const archiveAction: ActionInterface = !archived
    ? { icon: ArchiveRoundedIcon, label: 'Archive Project', color: 'error', onSelect: archive }
    : { icon: UnarchiveRoundedIcon, label: 'Unarchive Project', onSelect: unarchive };

  const actions: ActionListType = [];

  if (includeNavigationActions) {
    const navigationActions = [
      {
        icon: AnalyticsRoundedIcon,
        label: 'Dashboard',
        path: `/workspace/${workspace.id}/projects/${id}`,
      },
      {
        icon: EmojiObjectsRoundedIcon,
        label: 'Aspects',
        path: `/workspace/${workspace.id}/projects/${id}/aspects`,
      },
      {
        icon: PeopleRoundedIcon,
        label: 'Members',
        path: `/workspace/${workspace.id}/projects/${id}/members`,
      },
    ];

    actions.push(...navigationActions);
    actions.push('divider');
  }

  if (intelligenceActions.length > 0) {
    actions.push(...intelligenceActions);
    actions.push('divider');
  }

  actions.push({
    icon: EditRoundedIcon,
    label: 'Edit Project',
    onSelect: () => {
      setEditProjectDialogOpen(true);
    },
  });

  actions.push('divider');

  if (membershipAction) {
    actions.push(membershipAction);
  }

  actions.push('divider');

  if (archiveAction) {
    actions.push(archiveAction);
  }

  return (
    <Stack direction='row'>
      <OverflowActions actions={actions} />
      <EditProjectDialog project={project} refetch={refetch} open={editProjectDialogOpen} onClose={() => setEditProjectDialogOpen(false)} />
      {collectIntelligenceDialog}
    </Stack>
  );
};

export default ProjectActions;

const ARCHIVE_PROJECT_MUTATION = gql`
  mutation archiveProject($id: ID!, $archive: Boolean!) {
    archiveProject(id: $id, archive: $archive) {
      project {
        id
        name
        archived
      }
    }
  }
`;
