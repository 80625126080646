import React, { useEffect, useState } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';

import { LIGHT_THEME } from './LightTheme';
import { DARK_THEME } from './DarkTheme';
import { ThemeType } from '../User/User';
import useUser from '../User/useUser';

const AppThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;

const getTheme = (themeType: ThemeType) => {
  if (themeType === 'Dark') {
    return DARK_THEME;
  } else if (themeType === 'Light') {
    return LIGHT_THEME;
  } else {
    // System
    const prefersDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersDarkTheme ? DARK_THEME : LIGHT_THEME;
  }
};

export const useAppTheme = () => {
  const { user } = useUser();
  const themeType: ThemeType = user?.theme || 'System';

  const [theme, setTheme] = useState(getTheme(themeType));

  useEffect(() => {
    setTheme(getTheme(themeType));
  }, [themeType, setTheme]);

  useEffect(() => {
    const handleSystemThemeChange = (event: MediaQueryListEvent) => {
      setTheme(getTheme(themeType));
    };

    if (themeType === 'System') {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleSystemThemeChange);
    }
    return window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleSystemThemeChange);
  }, [themeType]);

  return theme;
};
