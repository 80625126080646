import React, { useState, useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import isequal from 'lodash.isequal';

import AvatarGroup from '@mui/material/AvatarGroup';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { GridCellParams, GridColDef, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';

import TeamIcon from '../Team/TeamIcon';
import { Layout, useLayout } from '../UI/LayoutContext';
import StyledDataGrid, { getColumnVisibilityModel } from '../UI/StyledDataGrid';
import UserAvatar from '../User/UserAvatar';
import { TeamInterface } from './TeamSchema';
import { UserInterface } from '../User/User';

type TeamColumnType = 'name' | 'manager' | 'members' | 'actions';

const COLUMN_PRIORITY: TeamColumnType[] = ['name', 'actions', 'members', 'manager'];
const ROWS_PER_PAGE = 50;

interface TeamManagementDataGridProps {
  teams: TeamInterface[];
  actionsComponent: React.FC<{ team: TeamInterface }>;
}

type TeamGridColDef = GridColDef & {
  field: TeamColumnType;
};

interface TeamRow {
  team: TeamInterface;
  id: string;
  name: string;
  ancestors: TeamInterface[];
  manager: string | null;
  members: UserInterface[];
}

const TeamManagementDataGrid = ({ teams, actionsComponent }: TeamManagementDataGridProps) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'score', sort: 'asc' }]);
  const layout = useLayout();

  const columns = useMemo(() => getColumns({ layout, actionsComponent }), [layout, actionsComponent]);

  const rows: GridRowsProp<TeamRow> = teams.map(team => {
    const { id, name, ancestors, manager, users } = team;
    const managerName = manager ? `${manager.firstName} ${manager.lastName}` : null;

    return {
      team: team,
      id,
      name,
      ancestors,
      manager: managerName,
      members: users,
    };
  });

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;
    const { team } = row;

    if (['name', 'manager', 'members'].includes(field)) {
      navigate(team.id);
    }
  };

  const handleResize = (containerSize: { width: number }) => {
    setColumnVisibilityModel(getColumnVisibilityModel(columns, COLUMN_PRIORITY, containerSize.width));
  };

  return (
    <StyledDataGrid
      rowHeight={70}
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      onResize={handleResize}
      sortingOrder={['asc', 'desc']}
      hideFooter={rows.length <= ROWS_PER_PAGE}
      disableColumnMenu={true}
      disableRowSelectionOnClick={true}
      sortModel={sortModel}
      onSortModelChange={model => {
        if (!isequal(model, sortModel)) {
          setSortModel(model);
        }
      }}
      onCellClick={handleCellClick}
    />
  );
};

const getColumns = ({ layout, actionsComponent }: { layout: Layout; actionsComponent: React.FC<{ team: TeamInterface }> }): TeamGridColDef[] => [
  {
    field: 'name',
    headerName: 'Team Name',
    minWidth: layout === 'desktop' ? 240 : 200,
    flex: 1,
    renderCell: (cellValues: GridRenderCellParams<TeamRow>) => {
      const { team, ancestors } = cellValues.row;
      const ancestorText = ancestors.map(ancestor => ancestor.name).join(' > ');

      return (
        <ListItem key={team.id}>
          <ListItemIcon>
            <TeamIcon icon={team.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to={`${team.id}`} component={RouterLink} onClick={event => event.stopPropagation()} order={1}>
                {team.name}
              </Link>
            }
            secondary={ancestorText}
          />
        </ListItem>
      );
    },
  },
  {
    field: 'manager',
    headerName: 'Manager',
    width: 120,
    valueGetter: ({ value }) => value || '-',
  },
  {
    field: 'members',
    headerName: 'Members',
    width: 120,
    sortComparator: (a, b) => a.length - b.length,
    renderCell: (cellValues: GridRenderCellParams<TeamRow>) => {
      const { team } = cellValues.row;
      return team.users.length ? (
        <AvatarGroup max={3}>
          {team.users.map((user, index) => (
            <UserAvatar key={index} user={user} />
          ))}
        </AvatarGroup>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'actions',
    headerName: ' ',
    width: 64,
    sortable: false,
    renderCell: (cellValues: GridRenderCellParams<TeamRow>) => {
      const { team } = cellValues.row;
      const ActionsComponent = actionsComponent;
      return <ActionsComponent team={team} />;
    },
  },
];

export default TeamManagementDataGrid;
