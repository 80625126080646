import { gql } from '@apollo/client';
import { z } from 'zod';
import { userSelectOrInviteEmailSchema } from '../User/User';

export const inviteMembersSchema = z.object({ members: z.array(userSelectOrInviteEmailSchema).min(1, 'At least one member is required').default([]) });

export const INVITE_MUTATION = gql`
  mutation Invite($invites: [InviteInput!]!) {
    invite(invites: $invites) {
      success
    }
  }
`;

export const emailStringToInvites = (workspaceId: string, emails: string) => {
  const emailsArray = emails
    .split(',')
    .map(email => email.trim())
    .filter(email => email);
  return emailsArray.map(email => ({
    email,
    memberships: [
      {
        id: workspaceId,
        type: 'Workspace',
      },
    ],
  }));
};
