import React, { useState, Fragment } from 'react';
import ListNotice from '../UI/ListNotice';
import ProjectDataGrid from '../Projects/ProjectDataGrid';
import NewProjectDialog from '../Project/NewProjectDialog';
import { UNSCOPED_SCOPE } from '../Scope/Scope';
import { SectionHeader, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { useScope } from '../Scope/useScope';
import { ClientInterface } from '../Client/ClientInterface';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import { GridOverlay } from '@mui/x-data-grid';
import { ProjectStats } from './ProjectCollector';
import { ProjectInterface } from '../Project/ProjectInterface';
import ScoreFilters, { ScoreFiltersProps, ScoreFiltersValues } from '../Scores/ScoreFilters';
import { Stack } from '@mui/material';
import OverflowActions from '../UI/OverflowActions';
import { ShowArchivedProps } from '../Archive/useShowArchived';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import AddProjectButton from './AddProjectButton';
import { AddProjectMode } from './useAddProject';

interface ProjectListProps {
  mode: AddProjectMode;
  client?: ClientInterface; // specifying a client hides the client name in project rows and initialized new project dialogs with the client
  scoreFilters: ScoreFiltersProps;
  showArchivedProps: ShowArchivedProps;
  projects: ProjectInterface[];
  projectStats: ProjectStats | undefined;
  loading: boolean;
  refetch: () => Promise<any>;
}

const ProjectList = ({ mode, client, scoreFilters, showArchivedProps, projects, projectStats, loading, refetch }: ProjectListProps) => {
  const { showArchived, setShowArchived } = showArchivedProps;

  return (
    <Fragment>
      <SectionHeader>
        <SectionHeaderTitle>Projects ({projects.length})</SectionHeaderTitle>

        <SectionHeaderActions>
          <Stack direction='row' spacing={1}>
            <ScoreFilters {...scoreFilters} />
            <AddProjectButton mode={mode} client={client} />

            <OverflowActions
              actions={[
                {
                  icon: showArchived ? CheckBoxRoundedIcon : CheckBoxOutlineBlankRoundedIcon,
                  label: 'Show Archived Projects',
                  onSelect: () => setShowArchived(!showArchived),
                },
              ]}
            />
          </Stack>
        </SectionHeaderActions>
      </SectionHeader>

      <ProjectDataGrid
        loading={loading}
        client={client}
        projects={projects}
        refetch={refetch}
        slots={{
          noRowsOverlay: NoProjectsListNotice,
          noResultsOverlay: NoProjectsListNotice,
        }}
        slotProps={{
          noRowsOverlay: { projects, projectStats, client, scoreFilters },
          noResultsOverlay: { projects, projectStats, client, scoreFilters },
        }}
      />
    </Fragment>
  );
};

interface NoProjectsListNoticeProps {
  projects: ProjectInterface[];
  projectStats: ProjectStats;
  client?: ClientInterface;
  scoreFilters: ScoreFiltersValues;
}

const NoProjectsListNotice = ({ projects, projectStats, client, scoreFilters }: NoProjectsListNoticeProps) => {
  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(false);
  const { scope, setScope } = useScope();

  if (!projectStats) {
    return null;
  }

  let message;
  let actions = [];

  if (scoreFilters.filtersApplied && projects.length === 0) {
    message = (
      <Fragment>
        Sorry, there&apos;s nothing to see here.
        <br />
        Try resetting your filters.
      </Fragment>
    );
    actions.push({ label: 'Reset Filters', onSelect: scoreFilters.resetFilters });
  } else {
    actions.push({ label: 'Add New Project', onSelect: () => setNewProjectDialogOpen(true) });

    if (scope.type === 'Team') {
      message = (
        <Fragment>
          It doesn&apos;t look like this team has any projects.
          <br />
          Add a project or join an existing one.
        </Fragment>
      );
      actions.push({ label: 'Join a Project', onSelect: () => setScope(UNSCOPED_SCOPE) });
    } else {
      if (projectStats.allProjectsCount === 0) {
        message = (
          <Fragment>
            It doesn&apos;t look like there are any projects.
            <br />
            Add a project to get started!
          </Fragment>
        );
      } else {
        message = (
          <Fragment>
            It doesn&apos;t look like you are a member of any projects.
            <br />
            Add a project or join an existing one.
          </Fragment>
        );
        actions.push({ label: 'Join a Project', onSelect: () => setScope(UNSCOPED_SCOPE) });
      }
    }
  }

  if (projects.length) {
    return null;
  }

  return (
    <GridOverlay>
      <ListNotice icon={HiveRoundedIcon} title={'No projects to display'} message={message} actions={actions} />
      <NewProjectDialog open={newProjectDialogOpen} onClose={() => setNewProjectDialogOpen(false)} client={client} />
    </GridOverlay>
  );
};

export default ProjectList;
