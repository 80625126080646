import React from 'react';
import { useMutation } from '@apollo/client';
import HexagonRoundedIcon from '@mui/icons-material/HexagonRounded';

import ProjectFields, { projectSchema, ProjectValues } from '../Project/ProjectFields';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { ProjectInterface, UPDATE_PROJECT_MUTATION } from './ProjectInterface';
import FormDialog, { useFormDialog } from '../UI/FormDialog';

interface EditProjectDialogProps {
  project: ProjectInterface;
  refetch?: () => Promise<any>;
  open: boolean;
  onClose: () => void;
}

const EditProjectDialog = ({ project, refetch, open, onClose }: EditProjectDialogProps) => {
  const defaultValues = projectSchema.parse(project);
  const { setSuccess } = useGlobalMessage();

  const formDialogProps = useFormDialog({ schema: projectSchema, defaultValues });
  const { handleResponse } = formDialogProps;

  const [updateProject, { error }] = useMutation(UPDATE_PROJECT_MUTATION);

  const handleSubmit = async (values: ProjectValues) => {
    // console.log(values);
    const userIds = values.users.map(user => user.id);

    const response = await updateProject({ variables: { ...values, userIds } });
    const success = handleResponse(response, 'updateProject', `Uh oh. There was an error updating the project.`);
    if (success) {
      setSuccess(`Updated project`);
      if (refetch) {
        await refetch();
      }
    }

    return success;
  };

  return (
    <FormDialog<ProjectValues>
      {...formDialogProps}
      id='edit-project'
      open={open}
      onClose={onClose}
      icon={<HexagonRoundedIcon />}
      title='Edit Project'
      subtitle='Enter the information below to edit the project.'
      onSubmit={handleSubmit}
      submitLabel='Save'
      mutationError={error}
    >
      <ProjectFields mode={'edit-project'} />
    </FormDialog>
  );
};

export default EditProjectDialog;
