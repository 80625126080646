import { DocumentNode, gql, useQuery } from '@apollo/client';
import { useScope } from '../Scope/useScope';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import ProjectCollector from './ProjectCollector';
import { useShowArchived } from '../Archive/useShowArchived';
import { useScoreFilters } from '../Scores/ScoreFilters';
import { PROJECT_FIELDS_FRAGMENT } from '../Project/ProjectInterface';

const useProjectList = (query: DocumentNode, variables: Object, projectCollectorResolver: (data: any) => ProjectCollector) => {
  const { scope } = useScope();
  const scoreFilters = useScoreFilters();
  const { filterVariables } = scoreFilters;
  const showArchivedProps = useShowArchived();
  const { showArchivedVariables } = showArchivedProps;

  const { data, loading, refetch } = useQuery(query, { variables: { ...variables, ...scope.variables, ...filterVariables, ...showArchivedVariables } });

  const projectCollector = data ? projectCollectorResolver(data) : undefined;
  const projects = projectCollector?.projects || [];
  const projectStats = projectCollector?.projectStats;

  return { projects, projectStats, loading, refetch, scoreFilters, showArchivedProps };
};

export default useProjectList;

export const PROJECT_LIST_PROJECT_FRAGMENT = gql`
  fragment ProjectListProject on Project {
    ...ProjectFields

    health
    intelligence
    ...ScorableSurveyFields

    owners: users(withRole: Owner) {
      id
      firstName
      lastName
    }
    users {
      id
      firstName
      lastName
      email
      avatar
      pendingInvitation
    }
    client {
      id
      name
      imageUrl
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;

export const PROJECT_COLLECTOR_FRAGMENT = gql`
  fragment ProjectCollector on ProjectBearerInterface {
    projects(healthLevel: $healthLevel, statuses: $statuses, archived: $archived) {
      ...ProjectListProject
    }
    projectStats {
      allProjectsCount: count(scope: { type: Unscoped })
    }
  }
  ${PROJECT_LIST_PROJECT_FRAGMENT}
`;
