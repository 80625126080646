import PageStyle from '../UI/PageStyle';

const Workspaces = () => {
  return (
    <PageStyle design='standard'>
      <h1>Workspace creation/selection coming soon</h1>
    </PageStyle>
  );
};

export default Workspaces;
