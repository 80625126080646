import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { IconType } from '../UI/Icon';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useLayout } from '../UI/LayoutContext';

export const ScoreBreakdown = ({ title, children }: { title?: string; children: React.ReactNode }) => {
  const layout = useLayout();

  const levels = React.Children.map(children, scoreLevel => scoreLevel)!;

  if (layout === 'mobile') {
    return (
      <Stack alignItems='stretch' spacing={1}>
        {levels.map((scoreLevel, index: number) => (
          <Stack key={index} alignItems='stretch'>
            {scoreLevel}
          </Stack>
        ))}
      </Stack>
    );
  } else {
    return (
      <Stack direction='row' spacing={1} alignItems='stretch'>
        {levels.map((scoreLevel, index: number) => (
          <Stack key={index} flex={1} alignItems='stretch'>
            {scoreLevel}
          </Stack>
        ))}
      </Stack>
    );
  }
};

export interface ScoreLevelProps {
  name: string;
  count: number;
  icon: IconType;
  color: string;
  onClick?: () => void;
  path?: string;
}

export const ScoreBreakdownLevel = ({ name, count, icon, color, path = '', onClick }: ScoreLevelProps) => {
  const theme = useTheme();
  const layout = useLayout();
  const handleClick = () => {
    if (count && onClick) {
      onClick();
    }
  };

  const enabled = count && (onClick || path);

  if (layout === 'mobile') {
    return (
      <ButtonBase disabled={!enabled} component={RouterLink} to={path} sx={{ borderRadius: 2 }}>
        <Stack direction='row' spacing={1} justifyContent='stretch' width='100%'>
          {React.createElement(icon, { sx: { width: '21px', height: '21px', color: color } })}
          <Typography flex={1} variant='body2'>
            {name}
          </Typography>
          <Typography variant='body1'>{count || '-'}</Typography>
        </Stack>
      </ButtonBase>
    );
  }

  return (
    <ButtonBase disabled={!enabled} component={RouterLink} to={path} sx={{ borderRadius: 2 }}>
      <Stack
        width='100%'
        p={2}
        pb={1}
        spacing={1}
        alignItems='center'
        onClick={handleClick}
        borderRadius={2}
        sx={{ '&:hover': { backgroundColor: theme.palette.action.hover } }}
      >
        {React.createElement(icon, { sx: { width: '44px', height: '44px', color: color } })}
        <Typography variant='body1' fontWeight='300'>
          {name}
        </Typography>
        <Typography variant='body1' fontSize={42} fontWeight='300'>
          {count || '-'}
        </Typography>
      </Stack>
    </ButtonBase>
  );
};
