import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import CreateWorkspaceSettingsForm, { CreateWorkspaceSettingsValues } from './CreateWorkspaceSettingsForm';
import { WorkspaceInterface } from './WorkspaceInterface';
import { useModuleSettings } from '../Account/WorkspaceModuleSettings';

interface CreateWorkspaceResponse {
  createWorkspace: {
    success: boolean;
    errors: any;
    workspace: WorkspaceInterface;
  };
}

export const useCreateWorkspaceSettings = () => {
  const [values, setValues] = useState<CreateWorkspaceSettingsValues>();
  const [createWorkspaceMutation] = useMutation<CreateWorkspaceResponse>(CREATE_WORKSPACE_MUTATION);

  const create = async (values: CreateWorkspaceSettingsValues) => {
    const { name, imageUrl, domain, activeModules } = values;

    const modules = Object.entries(activeModules)
      .filter(([, value]) => value)
      .map(([key]) => key);
    const response = await createWorkspaceMutation({
      variables: {
        name,
        imageUrl: imageUrl || undefined,
        domain: domain || undefined,
        modules: modules,
      },
    });

    if (response.data?.createWorkspace.success) {
      const workspaceId = response.data.createWorkspace.workspace.id;
      return { success: true, workspaceId };
    } else {
      console.error('error creating workspace');
      return { success: false };
    }
  };

  return { values, setValues, create };
};

interface CreateWorkspaceSettingsProps {
  onSubmit: (values: CreateWorkspaceSettingsValues) => void;
}

const CreateWorkspaceSettings = ({ onSubmit }: CreateWorkspaceSettingsProps) => {
  const { data } = useQuery(CATEGORIES_QUERY);
  const { activeModules } = useModuleSettings(data?.categories);

  if (!data) {
    return null;
  }

  return <CreateWorkspaceSettingsForm activeModules={activeModules} onSubmit={onSubmit} categories={data?.categories} />;
};

export default CreateWorkspaceSettings;

const CATEGORIES_QUERY = gql`
  query Categories {
    categories {
      id
      name
      type
      modules {
        id
        name
        summary
        description
        type
        active @client
      }
    }
  }
`;

const CREATE_WORKSPACE_MUTATION = gql`
  mutation CreateWorkspace($name: String!, $imageUrl: String, $domain: String, $modules: [ModuleType!]!) {
    createWorkspace(name: $name, imageUrl: $imageUrl, domain: $domain, modules: $modules) {
      success
      workspace {
        id
      }
    }
  }
`;
