import { Typography } from '@mui/material';
import { getScoreDeltaInfo } from './Score';

const ScoreDelta = ({ scoreDelta }: { scoreDelta: number }) => {
  const { scoreDeltaSign, scoreDeltaColor } = getScoreDeltaInfo(scoreDelta);

  return <Typography color={scoreDeltaColor}>{`${scoreDeltaSign}${scoreDelta || 0}%`}</Typography>;
};

export default ScoreDelta;
