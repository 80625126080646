import React, { useState, Fragment } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Button, IconButton, Stack } from '@mui/material';
import ClientDataGrid from '../Client/ClientDataGrid';
import NewClientDialog from '../Client/NewClientDialog';
import ListNotice from '../UI/ListNotice';
import { SectionHeader, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { useLayout } from '../UI/LayoutContext';
import OverflowActions from '../UI/OverflowActions';
import { useScope } from '../Scope/useScope';
import AppSurface from '../UI/AppSurface';
import { GridOverlay } from '@mui/x-data-grid';
import ScoreFilters, { ScoreFiltersProps, ScoreFiltersValues } from '../Scores/ScoreFilters';
import { ShowArchivedProps } from '../Archive/useShowArchived';
import { ClientInterface } from './ClientInterface';
import { ClientStats } from './ClientCollector';
import { UNSCOPED_SCOPE } from '../Scope/Scope';

interface ClientListProps {
  scoreFilters: ScoreFiltersProps;
  showArchivedProps: ShowArchivedProps;
  loading: boolean;
  clients: ClientInterface[];
  clientStats: ClientStats | undefined;
  refetch: () => Promise<any>;
}

const ClientList = ({ scoreFilters, showArchivedProps, loading, clients, clientStats, refetch }: ClientListProps) => {
  const { scope } = useScope();
  const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);
  const layout = useLayout();
  const { showArchived, setShowArchived } = showArchivedProps;

  const title = `${scope.prefix} Clients (${clients.length})`;

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderTitle>{title}</SectionHeaderTitle>

        <SectionHeaderActions>
          <Stack direction='row' spacing={1}>
            <ScoreFilters {...scoreFilters} />

            {layout === 'mobile' ? (
              <IconButton onClick={() => setNewClientDialogOpen(true)}>
                <AddCircleRoundedIcon />
              </IconButton>
            ) : (
              <Button startIcon={<AddCircleRoundedIcon />} onClick={() => setNewClientDialogOpen(true)}>
                Add New Client
              </Button>
            )}
            <NewClientDialog open={newClientDialogOpen} onClose={() => setNewClientDialogOpen(false)} />

            <OverflowActions
              actions={[
                {
                  icon: showArchived ? CheckBoxRoundedIcon : CheckBoxOutlineBlankRoundedIcon,
                  label: 'Show Archived Clients',
                  onSelect: () => setShowArchived(!showArchived),
                },
              ]}
            />
          </Stack>
        </SectionHeaderActions>
      </SectionHeader>

      <ClientDataGrid
        clients={clients}
        loading={loading}
        refetch={refetch}
        slots={{ noRowsOverlay: NoClientsListNotice, noResultsOverlay: NoClientsListNotice }}
        slotProps={{
          noRowsOverlay: { clients, clientStats, scoreFilters },
          noResultsOverlay: { clients, clientStats, scoreFilters },
        }}
      />
    </AppSurface>
  );
};

interface NoClientsListNoticeProps {
  clients: ClientInterface[];
  clientStats: ClientStats;
  scoreFilters: ScoreFiltersValues;
}

const NoClientsListNotice = ({ clients, clientStats, scoreFilters }: NoClientsListNoticeProps) => {
  const { scope, setScope } = useScope();
  const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);

  if (!clientStats) {
    return null;
  }

  let message;
  let actions = [];

  if (scoreFilters.filtersApplied && clients.length === 0) {
    message = (
      <Fragment>
        Sorry, there&apos;s nothing to see here.
        <br />
        Try resetting your filters.
      </Fragment>
    );
    actions.push({ label: 'Reset Filters', onSelect: scoreFilters.resetFilters });
  } else {
    actions.push({ label: 'Add New Client', onSelect: () => setNewClientDialogOpen(true) });

    if (scope.type === 'Team') {
      message = (
        <Fragment>
          It doesn&apos;t look like this team has any clients.
          <br />
          Add a client or join an existing one.
        </Fragment>
      );
      actions.push({ label: 'Join a Client', onSelect: () => setScope(UNSCOPED_SCOPE) });
    } else {
      if (clientStats.allClientsCount === 0) {
        message = (
          <Fragment>
            It doesn&apos;t look like there are any clients.
            <br />
            Add a client to get started!
          </Fragment>
        );
      } else {
        message = (
          <Fragment>
            It doesn&apos;t look like you are a member of any clients.
            <br />
            Add a client or join an existing one.
          </Fragment>
        );
        actions.push({ label: 'Join a Client', onSelect: () => setScope(UNSCOPED_SCOPE) });
      }
    }
  }

  if (clients.length) {
    return null;
  }

  return (
    <GridOverlay>
      <ListNotice icon={BusinessRoundedIcon} title={'No clients to display'} message={message} actions={actions} />
      <NewClientDialog open={newClientDialogOpen} onClose={() => setNewClientDialogOpen(false)} />
    </GridOverlay>
  );
};

export default ClientList;
