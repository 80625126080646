import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { IconType } from '../UI/Icon';
import { Category } from './Category';
import { DashboardSurface } from '../Dashboard/Dashboard';
import DashboardSummary from '../Dashboard/DashboardSummary';
import DashboardCategoryDetails from '../Dashboard/DashboardCategoryDetails';
import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import { useIntelligenceActions } from '../Intelligence/IntelligenceActions';
import { findCategoryScoreDimensionSettings } from './CategoryScoreDimensionSettings';
import { Scorable } from '../Scores/Scorable';

interface CategoryDashboardOverviewProps {
  scorable: Scorable;
  category: Category;
  scopePrefix: string;
  icon: IconType;
  stats: React.ReactNode;
  detailsTitle?: string;
  children: React.ReactNode;
}

const CategoryDashboardOverview = () => {
  const { scorable, category, scopePrefix, icon, stats, detailsTitle, children } = useOutletContext<CategoryDashboardOverviewProps>();

  const { intelligenceActions, collectIntelligenceDialog } = useIntelligenceActions(scorable, category);

  if (!category) {
    return null;
  }

  const { path } = findCategoryScoreDimensionSettings(category.type);

  return (
    <>
      <IntelligenceNotice scorable={scorable} scoreDimension={category} rounded />
      <DashboardSurface name={path} variant='primary' title={`${scopePrefix}${category.name}`} overflowActions={intelligenceActions}>
        {collectIntelligenceDialog}
        <DashboardSummary scorable={scorable} scorableLabel={category?.name} scoreDimension={category} scopePrefix={scopePrefix} icon={icon} />

        {stats}

        <DashboardCategoryDetails scorable={scorable} category={category} title={detailsTitle} includeScoreCircle={false}>
          {children}
        </DashboardCategoryDetails>
      </DashboardSurface>
    </>
  );
};

export default CategoryDashboardOverview;
