import { DocumentNode, gql, useQuery } from '@apollo/client';
import { useShowArchived } from '../Archive/useShowArchived';
import { useScope } from '../Scope/useScope';
import { useScoreFilters } from '../Scores/ScoreFilters';
import { ClientCollector } from './ClientCollector';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import { CLIENT_FIELDS_FRAGMENT } from './ClientInterface';

const useClientList = (query: DocumentNode, variables: Object, clientCollectorResolver: (data: any) => ClientCollector) => {
  const { scope } = useScope();
  const scoreFilters = useScoreFilters();
  const showArchivedProps = useShowArchived();
  const { showArchivedVariables } = showArchivedProps;

  //  const variables = {
  //    roles: []
  //  }

  const { data, loading, refetch } = useQuery(query, {
    variables: {
      ...variables,
      ...scope.variables,
      ...scoreFilters.filterVariables,
      ...showArchivedVariables,
    },
  });

  const clientCollector = data && clientCollectorResolver(data);
  const clients = clientCollector?.clients || [];
  const clientStats = clientCollector?.clientStats;

  return {
    scoreFilters,
    showArchivedProps,
    loading,
    clients,
    clientStats,
    refetch,
  };
};

export default useClientList;

export const CLIENT_COLLECTOR_FRAGMENT = gql`
  fragment ClientCollector on ClientBearerInterface {
    clientStats {
      allClientsCount: count(scope: { type: Unscoped })
    }
    clients(internal: false, scope: $scope, healthLevel: $healthLevel, statuses: $statuses, archived: $archived) {
      ...ClientFields

      health
      intelligence
      ...ScorableSurveyFields
      categories {
        id
        modules {
          id
          type
          health
        }
      }

      projectStats {
        personalProjectCount: count(scope: { type: Personal })
        scopedProjectCount: count(scope: $scope)
        allProjectsCount: count(scope: { type: Unscoped })
      }

      member
      projectMember
      owners: users(withRole: Owner) {
        id
        firstName
        lastName
      }
      users {
        id
        email
        firstName
        lastName
        avatar
        pendingInvitation
      }
    }
  }
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
  ${CLIENT_FIELDS_FRAGMENT}
`;
