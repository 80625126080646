import { useEffect } from 'react';
import PageStyle from '../UI/PageStyle';
import Survey from '../Survey/Survey';
import { useSurveys } from './SurveysContext';
import { useWorkspace } from '../Workspace/useWorkspace';
import StartFirstSurvey from './StartFirstSurvey';
import NoIntelligenceNeeded from './NoIntelligenceNeeded';
import SurveySelector from './SurveySelector';
import SurveysComplete from './SurveysComplete';

const Surveys = () => {
  const { workspace, isAdmin } = useWorkspace();
  const { state, send, refetch } = useSurveys();

  // TODO: is this needed?
  useEffect(() => {
    void refetch();
  }, [refetch]); // refresh surveys when navigating to intelligence

  switch (state.value) {
    case 'initializing':
      return <PageStyle design='floating' loading={true} />;
    case 'waiting':
      if (isAdmin && workspace.surveyStats.allTimeCount === 0) {
        return <StartFirstSurvey />;
      } else {
        return <NoIntelligenceNeeded />;
      }
    case 'contributing':
    case 'collecting':
      return <PageStyle design='floating' loading={true} />;
    case 'selecting':
      return (
        <SurveySelector
          thanks={false}
          surveys={state.context.surveys}
          onSelect={survey => send({ type: 'SELECT', survey: survey })}
          onSelectAll={() => send({ type: 'SELECT_ALL' })}
        />
      );
    case 'selectingAfterCompletion':
      return (
        <SurveySelector
          thanks={true}
          surveys={state.context.surveys}
          onSelect={survey => send({ type: 'SELECT', survey: survey })}
          onSelectAll={() => send({ type: 'SELECT_ALL' })}
        />
      );
    case 'answering':
      const survey = state.context.queue[0];
      return <Survey key={survey.id} survey={survey} onStop={() => send({ type: 'STOP' })} onComplete={() => send({ type: 'COMPLETE', survey: survey })} />;
    case 'thanking':
      return <SurveysComplete />;
    default:
      throw new Error(`Unexpected state ${state.value}`);
  }
};

export default Surveys;
