import React from 'react';
import CompanyAvatar, { CompanyAvatarInterface } from '../Company/CompanyAvatar';
import { AvatarProps } from '@mui/material';

interface ClientAvatarProps extends AvatarProps {
  client: CompanyAvatarInterface;
}

const ClientAvatar = ({ client, ...props }: ClientAvatarProps) => {
  return <CompanyAvatar company={client} {...props} />;
};

export default ClientAvatar;
