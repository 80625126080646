import { gql } from '@apollo/client';
import ProjectList from '../Projects/ProjectList';
import useProjectList, { PROJECT_COLLECTOR_FRAGMENT } from '../Projects/useProjectList';
import PageStyle from '../UI/PageStyle';
import { useClientContext } from './ClientContext';

const ClientProjects = () => {
  const { client } = useClientContext();
  const projectListProps = useProjectList(CLIENT_PROJECTS_QUERY, { id: client.id }, data => data.client);

  return (
    <PageStyle design='simple'>
      <ProjectList mode='client' {...projectListProps} client={client} />
    </PageStyle>
  );
};

export default ClientProjects;

export const CLIENT_PROJECTS_QUERY = gql`
  query ClientProjects($id: ID!, $healthLevel: [HealthLevel!], $statuses: [ProjectStatus!], $scope: ScopeInput, $archived: [Boolean!]) {
    client(id: $id, scope: $scope) {
      ...ProjectCollector
    }
  }
  ${PROJECT_COLLECTOR_FRAGMENT}
`;
