import { useMutation, gql } from '@apollo/client';

import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';

import { SET_USER_ROLES_MUTATION, Role } from './Roles';
import { useGlobalMessage } from '../UI/GlobalMessage';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import { useWorkspace } from '../Workspace/useWorkspace';
import { UserInterface } from './User';
import { Scorable } from '../Scores/Scorable';

export const useUserActions = (user: UserInterface) => {
  const { setSuccess, setError } = useGlobalMessage();
  const { workspace } = useWorkspace();

  const [setUserRolesMutation] = useMutation(SET_USER_ROLES_MUTATION, {
    update: cache => {
      cache.evict({ id: cache.identify(workspace) });
      cache.gc();
    },
  });

  const addUserRole = async (entity: Scorable, role: Role, successMessage: string, errorMessage: string) => {
    const {
      data: { setUserRoles },
    } = await setUserRolesMutation({
      variables: {
        userId: user.id,
        type: entity.__typename,
        id: entity.id,
        roles: [role],
        operation: 'Add',
      },
    });
    if (setUserRoles.success) {
      setSuccess(successMessage || 'Added role');
    } else {
      console.error(setUserRoles);
      setError(errorMessage || 'Error adding role');
    }
  };

  const removeUserRole = async (entity: Scorable, role: Role, successMessage: string, errorMessage: string) => {
    const {
      data: { setUserRoles },
    } = await setUserRolesMutation({
      variables: {
        userId: user.id,
        type: entity.__typename,
        id: entity.id,
        roles: [role],
        operation: 'Remove',
      },
    });
    if (setUserRoles.success) {
      setSuccess(successMessage || 'Removed role');
    } else {
      console.error(setUserRoles);
      setError(errorMessage || 'Error removing role');
    }
  };

  const [resendInviteMutation] = useMutation(RESEND_INVITE_MUTATION);

  const resendInvite = async (user: UserInterface) => {
    const {
      data: {
        resendInvite: { success },
      },
    } = await resendInviteMutation({ variables: { userId: user.id } });
    if (success) {
      setSuccess('Resent invitation');
    } else {
      setError('Error resending invitation');
    }
  };

  return { addUserRole, removeUserRole, resendInvite };
};

const UserActions = ({ user: targetUser }: { user: UserInterface }) => {
  const { workspace } = useWorkspace();
  const { addUserRole, removeUserRole, resendInvite } = useUserActions(targetUser);

  // console.log('signedInUser', signedInUser);
  // console.log('targetUser', targetUser);
  const isSignedInUserAdmin = workspace.effectiveRoles.some(role => ['Admin', 'SystemAdmin'].includes(role));

  const adminAction = isSignedInUserAdmin
    ? !targetUser.parentRoles.includes('Admin')
      ? {
          icon: ManageAccountsRoundedIcon,
          label: 'Set as Admin',
          onSelect: async () => {
            await addUserRole(workspace, 'Admin', 'Set as admin', 'Error setting as admin');
          },
        }
      : {
          icon: ManageAccountsRoundedIcon,
          label: 'Unset as Admin',
          onSelect: async () => {
            await removeUserRole(workspace, 'Admin', 'Unset as admin', 'Error unsetting as admin');
          },
        }
    : null;

  const seniorLeaderAction = isSignedInUserAdmin
    ? !targetUser.parentRoles.includes('SeniorLeader')
      ? {
          icon: SupervisorAccountRoundedIcon,
          label: 'Set as Senior Leader',
          onSelect: async () => {
            await addUserRole(workspace, 'SeniorLeader', 'Set as senior leader', 'Error setting as senior leader');
          },
        }
      : {
          icon: SupervisorAccountRoundedIcon,
          label: 'Unset as Senior Leader',
          onSelect: async () => {
            await removeUserRole(workspace, 'SeniorLeader', 'Unset as senior leader', 'Error unsetting as senior leader');
          },
        }
    : null;

  const resendInviteAction = targetUser.pendingInvitation
    ? {
        icon: SendRoundedIcon,
        label: 'Resend Invitation',
        onSelect: async () => {
          await resendInvite(targetUser);
        },
      }
    : null;

  const activationAction = isSignedInUserAdmin
    ? !targetUser.parentRoles.includes('Inactive')
      ? {
          icon: PersonRemoveRoundedIcon,
          label: 'Deactivate Account',
          onSelect: async () => {
            await addUserRole(workspace, 'Inactive', 'Deactivated account', 'Error deactivating account');
          },
        }
      : {
          icon: PersonAddRoundedIcon,
          label: 'Activate Account',
          onSelect: async () => {
            await removeUserRole(workspace, 'Inactive', 'Activated account', 'Error activating account');
          },
        }
    : null;

  const actions: ActionListType = [];
  if (adminAction) {
    actions.push(adminAction);
  }
  if (seniorLeaderAction) {
    actions.push(seniorLeaderAction);
  }
  if (resendInviteAction) {
    actions.push(resendInviteAction);
  }
  if (activationAction) {
    actions.push(activationAction);
  }

  return <OverflowActions actions={actions} />;
};

export default UserActions;

const RESEND_INVITE_MUTATION = gql`
  mutation ($userId: ID!) {
    resendInvite(userId: $userId) {
      success
    }
  }
`;
