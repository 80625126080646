import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';

import PageStyle from '../UI/PageStyle';
import Illustration from '../UI/Illustration';
import HyveLogoCentered from '../UI/HyveLogoCentered';
import { useAuthentication } from '../User/AuthenticationContext';
import BrandedForm from '../UI/BrandedForm';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
  email: z.string().email(),
});

type Schema = z.infer<typeof schema>;

const CreateWorkspaceRegister = () => {
  const [emailEntered, setEmailEntered] = useState(false);

  return !emailEntered ? <EnterEmail onSuccess={() => setEmailEntered(true)} /> : <VerifyEmail />;
};

const EnterEmail = ({ onSuccess }: { onSuccess: () => void }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [register] = useMutation(REGISTER_MUTATION);
  const { authenticate } = useAuthentication();

  const initialValues = { email: '' };
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });
  const onSubmit = async ({ email }: Schema) => {
    setErrorMessage(null);
    await register({ variables: { email: email } });
    authenticate();

    onSuccess();
  };

  return (
    <PageStyle design='floating'>
      <Stack alignItems='center' spacing={2} flex={1}>
        <Stack alignItems='center'>
          <HyveLogoCentered width='90px' clickable={false} />
          <Typography variant='h1'>Enter your work email</Typography>
        </Stack>

        <Stack pt={2}>
          <BrandedForm onSubmit={handleSubmit(onSubmit)} errorMessage={errorMessage}>
            <Stack width='330px' spacing={2}>
              <Controller
                name='email'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    id='email'
                    type='email'
                    variant='outlined'
                    fullWidth
                    label='name@work-email.com'
                    autoComplete='email'
                    autoFocus
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />

              <Button variant='contained' disabled={isSubmitting} disableElevation color='primary' className='submit form-row' type='submit'>
                Continue
              </Button>
            </Stack>
          </BrandedForm>
        </Stack>

        <Stack>
          <Typography variant='body2' textAlign='center'>
            <Link component={RouterLink} to='/sign-in'>
              Sign in to an existing workspace
            </Link>
          </Typography>
          <Typography variant='body2' textAlign='center'>
            By signing up, I agree to the Hyve Technologies{' '}
            <Link href='https://www.hyve.app/privacy' target='_blank'>
              Privacy Policy
            </Link>
            &nbsp;and&nbsp;
            <Link href='https://www.hyve.app/terms-of-service' target='_blank'>
              Terms of Service
            </Link>
            .
          </Typography>
        </Stack>

        <Stack flex={1} pt={4}>
          <Illustration image={'surveys'} alt={'Get started'} width='100%' />
        </Stack>
      </Stack>
    </PageStyle>
  );
};

const VerifyEmail = () => {
  return (
    <PageStyle design='floating'>
      <Stack alignItems='center' spacing={2} flex={1}>
        <Stack alignItems='center' spacing={2}>
          <HyveLogoCentered width='90px' clickable={false} />
          <Typography variant='h1'>Please verify your email</Typography>
        </Stack>

        <Stack pt={2}>
          <Typography variant='h1' textAlign='center' sx={{ fontWeight: 300, maxWidth: '600px', textWrap: 'balance' }}>
            Once you verify your email address, you can get started in Hyve.
          </Typography>
        </Stack>

        <Stack pt={2} spacing={2}>
          <Stack direction='row' justifyContent='center' spacing={2}>
            <Button
              onClick={() => window.open('https://mail.google.com', '_blank')}
              startIcon={<img src='/images/gmail.png' alt='gmail' style={{ height: '16px' }} />}
              sx={{ textTransform: 'none' }}
            >
              Open Gmail
            </Button>
            <Button
              onClick={() => window.open('https://outlook.office.com/mail/', '_blank')}
              startIcon={<img src='/images/outlook.png' alt='outlook' style={{ height: '20px' }} />}
              sx={{ textTransform: 'none' }}
            >
              Open Outlook
            </Button>
          </Stack>
          <Typography variant='body2' textAlign='center'>
            Can't find the email? Check your spam folder!
          </Typography>
        </Stack>

        <Stack flex={1} pt={4}>
          <Illustration image={'all-set'} alt={'Celebrating'} width='100%' />
        </Stack>
      </Stack>
    </PageStyle>
  );
};

export default CreateWorkspaceRegister;

const REGISTER_MUTATION = gql`
  mutation Register($email: String!) {
    register(email: $email) {
      success
      error
    }
  }
`;
