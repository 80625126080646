import { Avatar, Divider, Stack, Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import HyveLogoCentered from '../UI/HyveLogoCentered';
import React from 'react';
import PageStyle from '../UI/PageStyle';
import { useLayout } from '../UI/LayoutContext';

const CreateWorkspaceSteps = ({ step, children }: { step: number; children: React.ReactNode }) => {
  const layout = useLayout();

  return (
    <PageStyle design='branded' includeBrandedLogo={false} surfaceProps={{ maxWidth: 900, pb: 4 }}>
      <Stack bgcolor='#f7f7f7' padding={6} mt={-3} mx={-3} mb={6} sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} spacing={4}>
        <HyveLogoCentered width='90px' clickable={false} />

        <Stack direction={layout === 'mobile' ? 'column' : 'row'} spacing={2} justifyContent='center'>
          <Step number={1} label='Profile Information' state={step === 1 ? 'active' : 'complete'} />
          {layout !== 'mobile' && <Divider orientation='horizontal' sx={{ width: '72px', height: '14px' }} />}
          <Step number={2} label='Workspace Settings' state={step === 2 ? 'active' : step === 1 ? 'incomplete' : 'complete'} />
          {layout !== 'mobile' && <Divider orientation='horizontal' sx={{ width: '72px', height: '14px' }} />}
          <Step number={3} label='Invite Team Members (Optional)' state={step === 3 ? 'active' : 'incomplete'} />
        </Stack>
      </Stack>

      <Stack alignItems='center'>
        <Stack width='100%' maxWidth={700}>
          {children}
        </Stack>
      </Stack>
    </PageStyle>
  );
};

type StepState = 'active' | 'complete' | 'incomplete';

interface StepProps {
  number: number;
  label: string;
  state: StepState;
}

const Step = ({ number, label, state }: StepProps) => {
  const layout = useLayout();
  const icon = state === 'complete' ? <CheckRoundedIcon sx={{ fontSize: 14 }} /> : number;
  const iconWidth = layout === 'mobile' ? 25 : 25;
  const iconColor = state === 'incomplete' ? '#666' : '#000';

  const fontWeight = state === 'active' ? 'bold' : 'normal';

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Avatar sx={{ width: iconWidth, height: iconWidth, fontSize: 14, bgcolor: iconColor }}>{icon}</Avatar>
      <Typography fontWeight={fontWeight}>{label}</Typography>
    </Stack>
  );
};

export default CreateWorkspaceSteps;
