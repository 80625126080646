import MemberList from '../Membership/MemberList';
import TeamIcon from '../Team/TeamIcon';
import PageStyle from '../UI/PageStyle';
import { useTeamContext } from './TeamContext';

const TeamMembers = () => {
  const { team } = useTeamContext();
  const { icon, name, manager, users } = team;

  return (
    <PageStyle design='simple'>
      <MemberList Icon={() => <TeamIcon icon={icon} />} titlePrefix={`${name} Team`} manager={manager} members={users} />
    </PageStyle>
  );
};

export default TeamMembers;
