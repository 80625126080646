import React, { useState } from 'react';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { MINIMUM_PASSWORD_LENGTH } from './User';
import { SubmitButton } from '../UI/BrandedForm';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { z } from 'zod';
import { Control, Controller } from 'react-hook-form';

const schema = z.object({
  firstName: z.string({ required_error: 'First name is required' }),
  lastName: z.string({ required_error: 'Last name is required' }),
  password: z.string().min(MINIMUM_PASSWORD_LENGTH, `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`),
  timeZone: z.string(),
});

export type Schema = z.infer<typeof schema>;

export const useSignUpForm = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const initialValues = { firstName: '', lastName: '', password: '', timeZone: timeZone };

  return { schema, initialValues };
};

interface SignUpFormContentProps {
  control: Control<Schema>;
  isSubmitting: boolean;
  email?: string; // email isn't required when registering to create a new workspace
  submitButtonText: string;
}

const SignUpFormContent = ({ control, isSubmitting, email, submitButtonText }: SignUpFormContentProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Controller
          name='firstName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant='outlined'
              fullWidth
              label='First Name'
              required
              autoComplete='given-name'
              autoFocus
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='lastName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              variant='outlined'
              fullWidth
              label='Last Name'
              autoComplete='family-name'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <input autoComplete='email' defaultValue={email} hidden />

        <Controller
          name='password'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              type={showPassword ? 'text' : 'password'}
              variant='outlined'
              fullWidth
              label='Password'
              required
              autoComplete='new-password'
              error={!!error}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClickShowPassword}>{showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>
      <SubmitButton isSubmitting={isSubmitting}>{submitButtonText}</SubmitButton>
    </Stack>
  );
};

export default SignUpFormContent;
