import { ChatPlatform } from './ChatPlatform';

export type Channel = {
  id?: string;
  externalId?: string;
  name: string;
  platform: ChatPlatform;
  isPrivate: boolean;
};

export const generateChannelName = (name: string, prefix: string) => {
  let channelName = name
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/\./g, '-')
    .replace(/[^a-z0-9-_]/g, '');

  channelName = `${prefix}-${channelName}`;

  if (channelName.length > 80) {
    channelName = channelName.substring(0, 80);
  }

  channelName = channelName.replace(/-+$/, '');

  return channelName;
};
