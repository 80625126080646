import React, { useState } from 'react';

import { ButtonBase, Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as VeryUnhappySvg } from '../Icons/Responses/Very-Unhappy.svg';
import { ReactComponent as UnhappySvg } from '../Icons/Responses/Unhappy.svg';
import { ReactComponent as IndifferentSvg } from '../Icons/Responses/Indifferent.svg';
import { ReactComponent as HappySvg } from '../Icons/Responses/Happy.svg';
import { ReactComponent as VeryHappySvg } from '../Icons/Responses/Very-Happy.svg';

import ClientAvatar from '../Client/ClientAvatar';
import { useWorkspace } from '../Workspace/useWorkspace';
import { SurveyAnswerInterface, SurveyResponseInterface } from './SurveyInterface';
import { Scorable } from '../Scores/Scorable';
import { ClientInterface } from '../Client/ClientInterface';
import { useLayout } from '../UI/LayoutContext';
import { HYVE_YELLOW } from '../Theme/AppTheme';
import CompanyAvatar from '../Company/CompanyAvatar';

interface ResponseProps {
  response: SurveyResponseInterface;
  onAnswer: (response: SurveyResponseInterface, answer: SurveyAnswerInterface) => Promise<void>;
  submitting: boolean;
}

const Response = ({ response, onAnswer, submitting }: ResponseProps) => {
  const { question, scorable } = response;
  const [, setSelectedAnswer] = useState<SurveyAnswerInterface | null>(null);
  const layout = useLayout();

  const handleAnswer = async (response: SurveyResponseInterface, answer: SurveyAnswerInterface) => {
    setSelectedAnswer(answer);
    await onAnswer(response, answer);
    setSelectedAnswer(null);
  };

  const responseIcons = [VeryUnhappySvg, UnhappySvg, IndifferentSvg, HappySvg, VeryHappySvg];

  const buttonStyle = {
    bgcolor: HYVE_YELLOW,
    color: '#000',
    // borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    sx: {
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': { backgroundColor: '#ffdf66' },
    },
  };

  const answers =
    layout !== 'mobile' ? (
      <Stack direction='row' spacing='2px' justifyContent='center'>
        {question.answers.map((answer, index) => (
          <ButtonBase key={index} disabled={submitting} onClick={() => handleAnswer(response, answer)}>
            <Stack width='160px' height='160px' spacing='8px' {...buttonStyle}>
              <SvgIcon style={{ width: 36, height: 36 }}>
                {React.createElement(responseIcons[index], {
                  width: '48px',
                  height: '48px',
                })}
              </SvgIcon>

              <Typography fontSize='16px'>{answer.text}</Typography>
            </Stack>
          </ButtonBase>
        ))}
      </Stack>
    ) : (
      <Stack width='100%' alignItems='stretch' spacing='1px' justifyContent='end'>
        {question.answers.map((answer, index) => (
          <ButtonBase key={index} sx={{ width: '100%' }} disabled={submitting} onClick={() => handleAnswer(response, answer)}>
            <Stack mx={-2} flex={1} width='100%' height='68px' {...buttonStyle}>
              <SvgIcon style={{ width: 24, height: 24 }}>
                {React.createElement(responseIcons[index], {
                  width: '48px',
                  height: '48px',
                })}
              </SvgIcon>

              <Typography fontSize='16px'>{answer.text}</Typography>
            </Stack>
          </ButtonBase>
        ))}
      </Stack>
    );

  return (
    <Stack flex={1} alignItems='stretch' justifyContent='stretch'>
      <Stack flex={1} justifyContent='center' spacing={3}>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <ScorableAvatar scorable={scorable} />
        </Stack>
        <Typography variant='h2' fontSize='28px' minHeight='4em' textAlign='center' sx={{ textWrap: 'balance ' }}>
          {question.text}
        </Typography>
      </Stack>
      <Stack flex={1}>{answers}</Stack>
    </Stack>
  );
};

const ScorableAvatar = ({ scorable }: { scorable: Scorable }) => {
  const { workspace } = useWorkspace();
  const { company } = workspace;

  const avatarProps = { sx: { width: '48px', height: '48px' } };
  switch (scorable.__typename) {
    case 'Project':
      return <ClientAvatar client={scorable.client as ClientInterface} {...avatarProps} />;
    case 'WorkspaceUser':
      return <CompanyAvatar company={company} {...avatarProps} />;
    case 'Client':
      return <ClientAvatar client={scorable as ClientInterface} {...avatarProps} />;
    default:
      throw new Error(`Unexpected scorable: ${scorable.__typename}`);
  }
};

export default Response;
