import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react'; // Import React and useState
import { RatingProps } from '@mui/material';

import { useGlobalMessage } from '../UI/GlobalMessage';
import { handleMutationResponse } from '../util/handleMutationResponse';
import ImportanceRating from '../Scores/ImportanceRating';
import { ProjectInterface } from './ProjectInterface';

type ProjectImportanceRatingProps = RatingProps & {
  project: ProjectInterface;
};

const ProjectImportanceRating = ({ project, ...props }: ProjectImportanceRatingProps) => {
  const { setSuccess, setError } = useGlobalMessage();
  // Initialize state with the project's importance, which can be null
  const [optimisticValue, setOptimisticValue] = useState<number | null>(project.importance);
  const [updateProjectImportance] = useMutation(UPDATE_PROJECT_IMPORTANCE_MUTATION, {
    onCompleted: response => {
      const { success, errorMessage } = handleMutationResponse(
        { data: response },
        'updateProjectImportance',
        'Uh oh. There was an error updating the project.'
      );
      if (success) {
        setSuccess('Updated project importance');
      } else {
        setError(errorMessage);
        // Revert optimistic update on error to the original value, which can be null
        setOptimisticValue(project.importance);
      }
    },
    onError: error => {
      setError(error.message);
      // Revert optimistic update on network error to the original value, which can be null
      setOptimisticValue(project.importance);
    },
    // Configure optimistic response for immediate UI feedback
    optimisticResponse: {
      __typename: 'Mutation',
      updateProjectImportance: {
        __typename: 'Project',
        project: {
          id: project.id,
          importance: optimisticValue,
          __typename: 'Project',
        },
      },
    },
  });

  const handleChange = async (_: any, value: number | null) => {
    // Immediately set the optimistic value, which can be null
    setOptimisticValue(value);

    await updateProjectImportance({
      variables: {
        id: project.id,
        importance: value,
      },
    });
  };

  return <ImportanceRating value={optimisticValue} onChange={handleChange} {...props} />;
};

export default ProjectImportanceRating;

export const UPDATE_PROJECT_IMPORTANCE_MUTATION = gql`
  mutation UpdateProjectImportance($id: ID!, $importance: Int) {
    updateProjectImportance(id: $id, importance: $importance) {
      project {
        id
        importance
      }
    }
  }
`;
