import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AppBar, IconButton, Stack, StackProps } from '@mui/material';
import { NAVIGATION_THEME } from '../UI/NavigationTheme';
import ProgressBar from './ProgressBar';
import { useLayout } from '../UI/LayoutContext';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useDrawer } from './DrawerContext';
import HorizontalOverflowWrapper from '../UI/HorizontalOverflowWrapper';

interface SecondaryNavigationProps {
  children: React.ReactNode;
  progressStepIndex?: number;
  progressStepCount?: number;
}

const SecondaryNavigation = ({ children, progressStepIndex, progressStepCount }: SecondaryNavigationProps) => {
  return (
    <ThemeProvider theme={NAVIGATION_THEME}>
      <AppBar position='sticky' sx={{ top: 0, boxShadow: 'none', minHeight: '64px', height: 'auto' }}>
        <HorizontalOverflowWrapper>
          <Stack flex={1} direction='row' justifyContent='space-between' flexWrap='wrap' bgcolor='#111' borderBottom='1px solid #313131'>
            {children}
          </Stack>
        </HorizontalOverflowWrapper>
        <ProgressBar stepIndex={progressStepIndex} stepCount={progressStepCount} />
      </AppBar>
    </ThemeProvider>
  );
};

interface SecondaryNavigationHeadingProps extends StackProps {
  children: React.ReactNode;
}

export const SecondaryNavigationHeading = ({ children, ...props }: SecondaryNavigationHeadingProps) => {
  const layout = useLayout();
  const { setOpen } = useDrawer();

  return (
    <Stack
      direction='row'
      gap={layout === 'mobile' ? 0 : 1}
      alignItems='center'
      pr={1}
      pl={1}
      height='64px'
      {...props}
      sx={{ '.MuiTypography-root, .MuiButton-text': { whiteSpace: 'nowrap' } }}
    >
      {layout === 'mobile' && (
        <IconButton onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
      )}
      {children}
    </Stack>
  );
};

export const SecondaryNavigationActions = ({ children, ...props }: StackProps) => (
  <Stack ml='auto' direction='row' alignItems='center' justifyContent='flex-end' maxWidth='100%' pr={1} {...props}>
    {children}
  </Stack>
);

export default SecondaryNavigation;
