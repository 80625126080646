import { z } from 'zod';
import { gql, useMutation } from '@apollo/client';

import useWorkspace from '../Workspace/useWorkspace';
import { UserInterface } from '../User/User';
import { Icon, Radio, FormControlLabel, RadioGroup, Select, FormControl, MenuItem } from '@mui/material';
import { TableCell, TableRow, TableBody, Table } from '@mui/material';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { Controller } from 'react-hook-form';

const mySettingsSchema = z.object({
  workplace: z.string(),
  timeZone: z.string(),
  theme: z.string(),
});

type MySettingsValues = z.infer<typeof mySettingsSchema>;

interface MySettingsDialogProps {
  user: UserInterface;
  refetch: () => Promise<any>;
  open: boolean;
  onClose: () => void;
}

const MySettingsDialog = ({ user, open, onClose, refetch }: MySettingsDialogProps) => {
  const { workspace } = useWorkspace();
  const [updateMyWorkspaceSettings] = useMutation(UPDATE_MY_WORKSPACE_SETTINGS_MUTATION);
  const [updateUser, { error }] = useMutation(UPDATE_USER);
  const { setSuccess } = useGlobalMessage();

  const defaultValues = { workplace: user.officeType, timeZone: user.timeZone, theme: user.theme };
  const formDialogProps = useFormDialog<MySettingsValues>({ schema: mySettingsSchema, defaultValues });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const handleSubmit = async (values: MySettingsValues) => {
    const settingsResponse = await updateMyWorkspaceSettings({
      variables: {
        workspaceId: workspace.id,
        officeType: values.workplace,
      },
    });
    const settingsSuccess = handleResponse(settingsResponse, 'updateMyWorkspaceSettings', 'Uh oh. There was an error updating your settings');

    const userResponse = await updateUser({
      variables: {
        id: user.id,
        timeZone: values.timeZone,
        theme: values.theme,
      },
    });
    const userSuccess = handleResponse(userResponse, 'updateUser', 'Uh oh. There was an error updating your settings');

    if (settingsSuccess && userSuccess) {
      await refetch();
      setSuccess('Updated your settings');
    }

    return settingsSuccess && userSuccess;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id='edit-my-settings'
      open={open}
      onClose={onClose}
      icon={<Icon>person</Icon>}
      title='My Settings'
      subtitle='Edit the information below to update your profile settings.'
      submitLabel='Save'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Workplace</TableCell>
            <TableCell>
              <Controller
                name='workplace'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel value={'Remote'} control={<Radio name='workplace-remote' />} label='Remote' />
                    <FormControlLabel value={'Hybrid'} control={<Radio name='workplace-hybrid' />} label='Hybrid' />
                    <FormControlLabel value={'Office'} control={<Radio name='workplace-office' />} label='Office' />
                  </RadioGroup>
                )}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant='head'>Time Zone</TableCell>
            <TableCell>
              <Controller
                name='timeZone'
                control={control}
                render={({ field }) => (
                  <FormControl variant='outlined' fullWidth>
                    <Select {...field} variant='outlined' fullWidth name='timeZone'>
                      {Intl.supportedValuesOf('timeZone').map((timeZoneOption, index) => (
                        <MenuItem key={index} value={timeZoneOption}>
                          {timeZoneOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant='head'>Theme</TableCell>
            <TableCell>
              <Controller
                name='theme'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel value={'Light'} control={<Radio name='theme-light' />} label='Light' />
                    <FormControlLabel value={'Dark'} control={<Radio name='theme-dark' />} label='Dark' />
                    <FormControlLabel value={'System'} control={<Radio name='theme-system' />} label='System' />
                  </RadioGroup>
                )}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </FormDialog>
  );
};

export default MySettingsDialog;

const UPDATE_MY_WORKSPACE_SETTINGS_MUTATION = gql`
  mutation UpdateMyWorkspaceSettings($officeType: OfficeType!, $workspaceId: ID!) {
    updateMyWorkspaceSettings(officeType: $officeType, workspaceId: $workspaceId) {
      errors {
        fullMessages
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $timeZone: String!, $theme: Theme!) {
    updateUser(id: $id, timeZone: $timeZone, theme: $theme) {
      errors {
        type
        fullMessage
      }
    }
  }
`;
