import React from 'react';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import WaterDropRoundedIcon from '@mui/icons-material/WaterDropRounded';
import ScoreSparkline from './ScoreSparkline';
import { ScoreDimensionBase } from './ScoreDimensionBase';
import ScoreDelta from './ScoreDelta';
import { ModuleInterface } from '../Module/Module';
import { useHistoricalScores } from './useHistoricalScores';
import { Scorable } from './Scorable';

interface IntelligenceAndTrendProps {
  scorable: Scorable;
  scoreDimension: ScoreDimensionBase;
  module?: ModuleInterface;
  direction?: 'vertical' | 'horizontal';
}

const IntelligenceAndTrend = ({ scorable, module, scoreDimension, direction = 'vertical' }: IntelligenceAndTrendProps) => {
  const theme = useTheme();
  const historicalScores = useHistoricalScores(scorable, module, scoreDimension);

  return (
    <Stack
      sx={{ width: '64px ' }}
      direction={direction === 'horizontal' ? 'row' : 'column'}
      bgcolor={theme.palette.background.paper}
      borderRadius='8px'
      border={`1px solid ${theme.palette.divider}`}
      alignItems='center'
      divider={<Divider flexItem />}
    >
      <Stack p={2} alignItems='center'>
        <WaterDropRoundedIcon fontSize='small' />
        <Typography>{scoreDimension.intelligence}</Typography>
      </Stack>
      <Stack p={2} alignItems='center'>
        <ScoreSparkline historicalScores={historicalScores} />
        <ScoreDelta scoreDelta={scoreDimension.healthDelta} />
      </Stack>
    </Stack>
  );
};

export default IntelligenceAndTrend;
