import React from 'react';
import { z } from 'zod';
import { Controller } from 'react-hook-form';

import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import { TextField } from '@mui/material';

import { AppDialogProps } from '../UI/StyledDialog';
import { ProjectInterface } from '../Project/ProjectInterface';
import FormDialog, { useFormDialog } from '../UI/FormDialog';

import { Scorable } from '../Scores/Scorable';
const schema = z.object({
  message: z.string().max(140).optional(),
});

export type CollectIntelligenceValues = z.infer<typeof schema>;

interface CollectIntelligenceDialogProps extends AppDialogProps {
  scorable: Scorable;
  onSubmit: (values: CollectIntelligenceValues) => Promise<boolean>;
  error: Error | undefined;
}

const CollectIntelligenceDialog = ({ scorable, open, onClose, onSubmit, error }: CollectIntelligenceDialogProps) => {
  const { __typename, name } = scorable;

  let title, subtitle;
  switch (__typename) {
    case 'Workspace':
      title = 'Collect intelligence for the workspace';
      subtitle = 'This will notify all members in your workspace to contribute intelligence.';
      break;
    case 'Client':
      title = `Collect intelligence for ${name}`;
      subtitle = 'This will notify all client members to contribute intelligence.';
      break;
    case 'Project':
      const project = scorable as ProjectInterface;
      title = `Collect intelligence for the ${project.client.name} - ${name} Project`;
      subtitle = 'This will notify all project members to contribute intelligence.';
      break;
    default:
      title = `Collect intelligence for ${name}`;
      subtitle = 'This will notify all members to contribute intelligence.';
  }

  const formDialogProps = useFormDialog<CollectIntelligenceValues>({ schema });
  const { methods } = formDialogProps;
  const { control } = methods;

  return (
    <FormDialog
      {...formDialogProps}
      id='collect-intelligence-dialog'
      open={open}
      onClose={onClose}
      icon={<WaterDropRoundedIcon />}
      title={title}
      subtitle={subtitle}
      submitLabel='Collect Intelligence'
      onSubmit={onSubmit}
      mutationError={error}
    >
      <Controller
        name='message'
        control={control}
        render={({ field }) => <TextField {...field} variant='outlined' fullWidth multiline rows={2} label='Add a short message (optional)' />}
      />
    </FormDialog>
  );
};

export default CollectIntelligenceDialog;
