import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ADD_CLIENT_MEMBERS_MUTATION, REMOVE_CLIENT_MEMBERS_MUTATION } from './useClient';
import EditClientDialog from '../Client/EditClientDialog';
import { useIntelligenceActions } from '../Intelligence/IntelligenceActions';
import OverflowActions, { ActionListType } from '../UI/OverflowActions';
import { ActionInterface } from '../UI/ActionInterface';
import { useGlobalMessage } from '../UI/GlobalMessage';
import ConfirmationDialog, { useConfirmation } from '../UI/ConfirmationDialog';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useClientContext } from './ClientContext';
import { Stack } from '@mui/material';
import { useArchiveActions } from '../Archive/ArchiveActions';

type AddClientResponseData = {
  addClientMembers: {
    success: boolean;
  };
};

export const useClientActions = () => {
  const { client, refetch } = useClientContext();
  const { user } = useAuthenticatedUser();
  const { id } = client;
  const { setSuccess, setError } = useGlobalMessage();

  const { archive, unarchive } = useArchiveActions(client, ARCHIVE_CLIENT_MUTATION, refetch);

  const [addClientMembers] = useMutation<AddClientResponseData>(ADD_CLIENT_MEMBERS_MUTATION);

  const [removeClientMembers] = useMutation(REMOVE_CLIENT_MEMBERS_MUTATION);

  const { requestConfirmation, confirmationOptions: archiveClientConfirmationOptions } = useConfirmation();

  const joinClient = async () => {
    const response = await addClientMembers({ variables: { id: id, userIds: [user.id] } });
    if (response.data?.addClientMembers.success) {
      await refetch();
      setSuccess('Joined client');
    } else {
      console.error(response);
      setError('Error joining client');
    }
  };

  const leaveClient = async () => {
    const response = await removeClientMembers({ variables: { id: id, userIds: [user.id] } });
    if (response.data.removeClientMembers.success) {
      await refetch();
      setSuccess('Left client');
    } else {
      console.error(response);
      setError('Error leaving client');
    }
  };

  const archiveClient = async () => {
    const confirmed = await requestConfirmation({
      icon: <ArchiveRoundedIcon />,
      title: 'Archive Client?',
      dialogContent: (
        <span>
          Are you sure you want to archive the client {client.name}?<br />
          All of its projects will be archived as well!
        </span>
      ),
      confirmLabel: 'Archive',
    });

    if (confirmed) {
      await archive();
      await refetch();
      setSuccess('Archived client');
    }
  };

  const unarchiveClient = async () => {
    await unarchive();
    await refetch();
    setSuccess('Unarchived client');
  };

  return { joinClient, leaveClient, archiveClient, archiveClientConfirmationOptions, unarchiveClient };
};

interface ClientActionsProps {
  includeNavigationActions?: boolean;
}

const ClientActions = ({ includeNavigationActions = false }: ClientActionsProps) => {
  const { workspace, isAdmin } = useWorkspace();
  const { client } = useClientContext();
  const { id, member, archived } = client;
  const { joinClient, leaveClient, archiveClient, archiveClientConfirmationOptions, unarchiveClient } = useClientActions();
  const { intelligenceActions, collectIntelligenceDialog } = useIntelligenceActions(client);
  const [editClientDialogOpen, setEditClientDialogOpen] = useState(false);

  const membershipAction: ActionInterface | null = client.internal
    ? null
    : !member
    ? { icon: PersonAddRoundedIcon, label: 'Join Client', onSelect: joinClient }
    : { icon: PersonRemoveRoundedIcon, label: 'Leave Client', color: 'error', onSelect: leaveClient };

  const archiveAction: ActionInterface | null = client.internal
    ? null
    : !archived
    ? { icon: ArchiveRoundedIcon, label: 'Archive Client', color: 'error', onSelect: archiveClient }
    : { icon: UnarchiveRoundedIcon, label: 'Unarchive Client', onSelect: unarchiveClient };

  const navigationActions: ActionInterface[] = [
    {
      icon: AnalyticsRoundedIcon,
      label: 'Dashboard',
      path: `/workspace/${workspace.id}/clients/${id}`,
    },
    {
      // icon: <ProjectFillIcon/>,
      icon: HiveRoundedIcon,
      label: 'Projects',
      path: `/workspace/${workspace.id}/clients/${id}/projects`,
    },
    {
      icon: EmojiObjectsRoundedIcon,
      label: 'Aspects',
      path: `/workspace/${workspace.id}/clients/${id}/aspects`,
    },
    {
      icon: GroupRoundedIcon,
      label: 'Members',
      path: `/workspace/${workspace.id}/clients/${id}/members`,
    },
  ];

  const actions: ActionListType = [];

  if (includeNavigationActions) {
    actions.push(...navigationActions);
    actions.push('divider');
  }

  if (intelligenceActions.length > 0) {
    actions.push(...intelligenceActions);
    actions.push('divider');
  }

  if (!client.internal || isAdmin) {
    actions.push({
      icon: EditRoundedIcon,
      label: 'Edit Client',
      onSelect: () => {
        setEditClientDialogOpen(true);
      },
    });
  }

  if (!client.internal) {
    actions.push('divider');
    if (membershipAction) {
      actions.push(membershipAction);
    }
    actions.push('divider');
    if (archiveAction) {
      actions.push(archiveAction);
    }
  }

  return (
    <Stack direction='row'>
      <OverflowActions actions={actions} />
      <EditClientDialog client={client} open={editClientDialogOpen} onClose={() => setEditClientDialogOpen(false)} />
      <ConfirmationDialog {...archiveClientConfirmationOptions} />
      {collectIntelligenceDialog}
    </Stack>
  );
};

export default ClientActions;

const ARCHIVE_CLIENT_MUTATION = gql`
  mutation archiveClient($id: ID!, $archive: Boolean!) {
    archiveClient(id: $id, archive: $archive) {
      client {
        id
        name
        status
      }
      errors {
        fullMessage
      }
    }
  }
`;
