import { gql } from '@apollo/client';
import { ClientInterface } from './ClientInterface';

export interface ClientCollector {
  clients: ClientInterface[];
  clientStats: {
    count: number;
    highCount: number;
    mediumCount: number;
    lowCount: number;
    collectingCount: number;
  };
}

export interface ClientStats {
  count: number;
  allClientsCount?: number;
  countByScore: {
    high: number;
    medium: number;
    low: number;
    collecting: number;
  };
}

export const CLIENT_BREAKDOWN_FRAGMENT = gql`
  fragment ClientBreakdown on ClientBearerInterface {
    clientStats {
      count
      highCount: count(healthLevel: high)
      mediumCount: count(healthLevel: medium)
      lowCount: count(healthLevel: low)
      collectingCount: count(healthLevel: collecting)
    }
  }
`;

// TODO should be on ClientBearerInterface
export const LOWEST_SCORE_CLIENTS_FRAGMENT = gql`
  fragment LowestScoreClients on Aspect {
    clients(limit: 5) {
      id
      name
      health
      intelligence
    }
  }
`;
