import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { useGlobalMessage } from '../UI/GlobalMessage';
import { useWorkspace } from '../Workspace/useWorkspace';
import { AppDialogProps } from '../UI/StyledDialog';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { InviteEmailsValues, inviteEmailsSchema, useInviteEmails, emailArraySchema } from './InviteEmails';

const InviteDialog = ({ open, onClose }: AppDialogProps) => {
  const { workspace } = useWorkspace();
  const { setSuccess, setError } = useGlobalMessage();
  const formDialogProps = useFormDialog<InviteEmailsValues>({ schema: inviteEmailsSchema });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const { validateEmails, inviteEmails, error } = useInviteEmails();

  if (!workspace) {
    return null;
  }

  const handleSubmit = async (values: InviteEmailsValues) => {
    const { emails } = values;
    const result = emailArraySchema.safeParse(emails);
    if (result.success) {
      const response = await inviteEmails(workspace.id, result.data);
      const success = handleResponse(response, 'invite', 'Uh oh. There was an error inviting members to the workspace');
      if (success) {
        setSuccess(`Invited teammates`);
      }
      return success;
    } else {
      setError(`Error Inviting teammates`);
      return false;
    }
  };

  return (
    <FormDialog<InviteEmailsValues>
      {...formDialogProps}
      id='invite-dialog'
      open={open}
      onClose={onClose}
      icon={<GroupAddIcon />}
      title={`Invite Teammates to ${workspace.name}`}
      subtitle='Your teammates will receive an email that allows them to register and grants them access to your workspace.'
      submitLabel='Invite'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Controller
        name='emails'
        control={control}
        rules={{ validate: validateEmails }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            variant='outlined'
            fullWidth
            multiline
            rows={4}
            label='Email Addresses'
            error={!!error}
            helperText={error?.message || 'Enter emails comma separated'}
          />
        )}
      />
    </FormDialog>
  );
};

export default InviteDialog;
