import { ModuleType, ModuleInterface } from './Module';
import { Category } from '../Category/Category';
import { Scorable } from '../Scores/Scorable';

const useModules = (scorable: Scorable) => {
  if (!scorable) {
    // for loading states
    return { modules: [], findModule: () => undefined };
  }

  const { categories } = scorable;
  const modules = categories.flatMap(category => category.modules);

  const findModule = (moduleType: ModuleType) => {
    return modules.find((module: ModuleInterface) => module.type === moduleType);
  };

  return { modules, findModule };
};

export default useModules;

export const findModule = (category: Category, moduleType: ModuleType) => {
  return category.modules.find((module: ModuleInterface) => module.type === moduleType);
};
