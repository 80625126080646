import { SurveyInterface, SurveyResponseInterface } from './SurveyInterface';

export const IN_PROGRESS = 'in progress';
export const COMPLETED = 'completed';

interface SurveyState {
  responses: SurveyResponseInterface[];
  currentResponseIndex: number;
  currentResponse: SurveyResponseInterface;
  status: 'in progress' | 'completed';
}

interface SurveyAction {
  type: 'start' | 'answer' | 'skip' | 'back';
}

const SurveyReducer = (state: SurveyState, action: SurveyAction): SurveyState => {
  const { responses } = state;
  const update: Partial<SurveyState> = {};

  switch (action.type) {
    case 'answer':
    case 'skip':
      update.currentResponseIndex = state.currentResponseIndex + 1;
      break;
    case 'back':
      update.currentResponseIndex = Math.max(state.currentResponseIndex - 1, 0);
      break;
    default:
      return state;
  }

  const nextResponseIndex = update.currentResponseIndex ?? state.currentResponseIndex;
  update.currentResponse = responses[nextResponseIndex];
  update.status = getStatus(responses.length, nextResponseIndex);

  return { ...state, ...update } as SurveyState;
};
export default SurveyReducer;

const getStatus = (responseCount: number, currentResponseIndex: number): 'in progress' | 'completed' => {
  return currentResponseIndex < responseCount ? IN_PROGRESS : COMPLETED;
};

export const initialState = (survey: SurveyInterface) => {
  const responses = survey.responses.edges.map(edge => edge.node);
  const reducer = (highestIndex: number, response: SurveyResponseInterface, currentIndex: number) =>
    response.answer !== null || response.skippedAt !== null ? currentIndex : highestIndex;
  const highestAnsweredOrSkippedResponseIndex = responses.reduce(reducer, -1);
  const initialResponseIndex = highestAnsweredOrSkippedResponseIndex !== -1 ? highestAnsweredOrSkippedResponseIndex + 1 : 0;

  return {
    responses: responses,
    currentResponseIndex: initialResponseIndex,
    currentResponse: responses[initialResponseIndex],
    status: getStatus(responses.length, initialResponseIndex),
  };
};
