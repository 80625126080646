import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Alert, Snackbar, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface BrandedFormProps {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  onSubmit: (event: React.BaseSyntheticEvent) => Promise<void>;
  successMessage?: string | null;
  errorMessage?: string | null;
}

const BrandedForm = ({ icon, title, subtitle, children, onSubmit, successMessage, errorMessage }: BrandedFormProps) => {
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);

  return (
    <StyledBrandedForm>
      <form onSubmit={onSubmit} noValidate>
        <Stack spacing={2}>
          {icon && icon}
          {title && (
            <Typography variant='h5' textAlign='center'>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography textAlign='center' sx={{ textWrap: 'balance' }}>
              {subtitle}
            </Typography>
          )}

          {errorMessage && (
            <div className='form-row'>
              <Alert severity='error'>{errorMessage || 'Sorry, something went wrong. Please try again or get in touch.'}</Alert>
            </div>
          )}
          {children}
        </Stack>
      </form>

      <Snackbar open={successMessageOpen} onClose={() => setSuccessMessageOpen(false)}>
        <Alert severity='success'>{successMessage || 'Success!'}</Alert>
      </Snackbar>
    </StyledBrandedForm>
  );
};

/* Most primary page styles are set in PageStyle.js */
const StyledBrandedForm = styled('div')`
  .MuiAvatar-root {
    margin-left: auto;
    margin-right: auto;
  }
`;

export default BrandedForm;

export const SubmitButton = ({ children, isSubmitting }: { children: React.ReactNode; isSubmitting: boolean }) => {
  return (
    <LoadingButton
      name='submit'
      className='submit'
      variant='contained'
      loading={isSubmitting}
      disabled={isSubmitting}
      disableElevation
      color='primary'
      type='submit'
      sx={{ width: '100%', maxWidth: 330, alignSelf: 'center' }}
    >
      {children}
    </LoadingButton>
  );
};
