import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Score, computeScoreLevel } from './Score';
import HexagonRoundedIcon from '@mui/icons-material/HexagonRounded';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import { Stack, Typography } from '@mui/material';

interface EntityScoreIconProps {
  score: Score;
  filled: boolean;
}

const EntityScoreIcon = ({ score, filled }: EntityScoreIconProps) => {
  const scoreLevel = computeScoreLevel(score);
  const width = 37,
    height = 37;

  return (
    <Tooltip title={scoreLevel.name} placement='left'>
      <Stack
        position='relative'
        alignItems='center'
        justifyContent='center'
        color={scoreLevel.colorOnLight}
        sx={{
          '& svg': {
            width: width,
            height: height,
            transform: 'rotate(-90deg)',
            '& g g': {
              fill: scoreLevel.colorOnLight,
            },
          },
        }}
      >
        {filled ? <HexagonRoundedIcon /> : <HexagonOutlinedIcon />}
        <Stack
          position='absolute'
          top={0}
          width='100%'
          height='100%'
          lineHeight={height}
          alignItems='center'
          justifyContent='center'
          sx={{ cursor: 'default' }}
        >
          <Typography textAlign='center' fontSize={14} fontWeight={700} color={filled ? 'white' : scoreLevel.colorOnLight} sx={{}}>
            {score || '-'}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
};

export default EntityScoreIcon;
