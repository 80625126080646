import { useState, Fragment } from 'react';
import { Button, Container } from '@mui/material';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import CreateWorkspaceDialog from '../Workspace/CreateWorkspaceDialog';
import WorkspaceSelector from './WorkspaceSelector';
import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { useWorkspace } from '../Workspace/useWorkspace';

const ProfileWorkspaceSettings = () => {
  const { workspace } = useWorkspace();
  const [createWorkspaceDialogOpen, setCreateWorkspaceDialogOpen] = useState(false);

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <HiveRoundedIcon />
        </SectionHeaderIcon>
        <SectionHeaderTitle>Workspace</SectionHeaderTitle>
        <SectionHeaderActions>
          {workspace.effectiveRoles.includes('SystemAdmin') && (
            <Fragment>
              <Button onClick={() => setCreateWorkspaceDialogOpen(true)}>Create New Workspace</Button>
              <CreateWorkspaceDialog open={createWorkspaceDialogOpen} onClose={() => setCreateWorkspaceDialogOpen(false)} />
            </Fragment>
          )}
        </SectionHeaderActions>
      </SectionHeader>

      <Container>
        <form>
          <div className='form-row' />
          <div className='form-row' />
          <div className='form-row'>
            <WorkspaceSelector />
          </div>
        </form>
      </Container>
    </AppSurface>
  );
};

export default ProfileWorkspaceSettings;
