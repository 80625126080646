import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const LoadingSection = () => (
  <Box
    component='section'
    sx={{
      width: '100%',
      height: '100%',
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress
      color='subtle'
      sx={{
        width: 44,
        height: 44,
      }}
    />
  </Box>
);

export default LoadingSection;
