import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useWorkspace from "../Workspace/useWorkspace";

const useAdminRoute = () => {
  const navigate = useNavigate();
  const { isAdmin } = useWorkspace();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/not-authorized');
    }  
  }, [isAdmin, navigate])
};

export default useAdminRoute;
