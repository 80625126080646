import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ProjectActions from '../Project/ProjectActions';
import ProjectInviteDialog from '../Project/ProjectInviteDialog';
import ClientAvatar from '../Client/ClientAvatar';
import { SecondaryNavigationTab, SecondaryNavigationTabs, useSecondaryNavigationTabs } from '../Navigation/SecondaryNavigationTabs';
import { useProjectContext } from './ProjectContext';
import SecondaryNavigation, { SecondaryNavigationHeading, SecondaryNavigationActions } from '../Navigation/SecondaryNavigation';
import { useCategory } from '../Category/useCategory';
import { generateChannelName } from '../Chat/Channel';
import ChannelConnectionAction from '../Chat/ChannelConnectionAction';
import { Tooltip } from '@mui/material';

const TABS: SecondaryNavigationTab[] = [
  { key: 'dashboard', label: 'Dashboard', path: '' },
  { key: 'aspects', label: 'Aspects', path: 'aspects' },
  { key: 'members', label: 'Members', path: 'members' },
];

const ProjectSecondaryNavigation = () => {
  const { project, projectPath, refetch } = useProjectContext();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const { name, client } = project;
  const secondaryNavigationTabsProps = useSecondaryNavigationTabs(TABS, projectPath);
  const { categoryPath } = useCategory('CompanyCategory');

  return (
    <SecondaryNavigation>
      <SecondaryNavigationHeading>
        <IconButton component={RouterLink} to={`../projects`}>
          <ArrowBackRoundedIcon />
        </IconButton>
        {client.internal ? (
          <IconButton component={RouterLink} to={categoryPath}>
            <ClientAvatar client={client} />
          </IconButton>
        ) : (
          <IconButton component={RouterLink} to={`../clients/${client.id}`}>
            <ClientAvatar client={client} />
          </IconButton>
        )}

        <Typography>
          {client.name} - {name}
        </Typography>
      </SecondaryNavigationHeading>

      <SecondaryNavigationActions>
        <SecondaryNavigationTabs {...secondaryNavigationTabsProps} />
        <Tooltip title='Invite'>
          <IconButton onClick={() => setInviteDialogOpen(true)}>
            <GroupAddRoundedIcon />
          </IconButton>
        </Tooltip>
        <ChannelConnectionAction scorable={project} defaultChannelName={generateChannelName(`${client.name} ${project.name}`, 'project')} />
        <ProjectActions project={project} refetch={refetch} />
        <ProjectInviteDialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} project={project} refetch={refetch} />
      </SecondaryNavigationActions>
    </SecondaryNavigation>
  );
};

export default ProjectSecondaryNavigation;
