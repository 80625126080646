import { gql } from '@apollo/client';
import ClientList from '../Client/ClientList';
import PageStyle from '../UI/PageStyle';
import { useWorkspace } from './useWorkspace';
import useClientList, { CLIENT_COLLECTOR_FRAGMENT } from '../Client/useClientList';

const WorkspaceClients = () => {
  const { workspace } = useWorkspace();
  const clientListProps = useClientList(WORKSPACE_CLIENTS_QUERY, { id: workspace.id }, data => data.workspace);

  return (
    <PageStyle>
      <ClientList {...clientListProps} />
    </PageStyle>
  );
};

export default WorkspaceClients;

export const WORKSPACE_CLIENTS_QUERY = gql`
  query WorkspaceClients($id: ID!, $healthLevel: [HealthLevel!], $statuses: [ClientStatus!], $scope: ScopeInput, $archived: [Boolean!]) {
    workspace(id: $id, scope: $scope) {
      id
      ...ClientCollector
    }
  }
  ${CLIENT_COLLECTOR_FRAGMENT}
`;
