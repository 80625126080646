import { useTheme } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import { useLayout } from './LayoutContext';
import React from 'react';

interface AppBackgroundProps {
  children: React.ReactNode;
}

const AppBackground = ({ children, ...props }: AppBackgroundProps) => {
  const theme = useTheme();
  const layout = useLayout();

  let paddingProps: any = { p: 6, pb: 10 };
  if (layout === 'mobile') {
    paddingProps = { px: 2, pt: 2, pb: 12 };
  }

  return (
    <Stack
      className='app-background'
      flexGrow='1'
      alignItems='center'
      justifyContent='center'
      position='relative'
      sx={{ backgroundColor: theme.palette.background.default }}
      {...paddingProps}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default AppBackground;
