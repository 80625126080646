import { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';

import ClientSelector, { ClientOption } from '../Client/ClientSelector';

export type ClientModeType = 'new-client' | 'existing-client';

interface ClientModeFormControlLabelProps extends FormControlLabelProps {
  value: ClientModeType;
}

const ClientModeFormControlLabel = (props: ClientModeFormControlLabelProps) => <FormControlLabel {...props} />;

const ProjectClientSelector = ({ onSelect }: { onSelect: (client: ClientOption) => void }) => {
  const { getValues, setValue } = useFormContext();
  const [clientMode, setClientMode] = useState<ClientModeType>('existing-client');
  const { client } = getValues();

  const handleModeChange = (selectedClientMode: ClientModeType) => {
    setClientMode(selectedClientMode);
    if (selectedClientMode === 'new-client' && client?.id) {
      setValue('client', client);
    } else if (selectedClientMode === 'existing-client') {
      setValue('client', client);
    }
  };

  return (
    <Fragment>
      {/*<FormControl className='form-row' component='fieldset'>*/}
      {/*  <RadioGroup row value={clientMode} name='client-mode' onChange={event => handleModeChange(event.target.value as ClientModeType)}>*/}
      {/*    <ClientModeFormControlLabel value={'existing-client'} control={<Radio />} label='Existing Client' />*/}
      {/*    <ClientModeFormControlLabel value={'new-client'} control={<Radio />} label='New Client' />*/}
      {/*  </RadioGroup>*/}
      {/*</FormControl>*/}

      {clientMode === 'existing-client' && <ClientSelector />}
      {/*{clientMode === 'new-client' && (*/}
      {/*  <FieldSet namespace='client'>*/}
      {/*    <ClientFields />*/}
      {/*  </FieldSet>*/}
      {/*)}*/}
    </Fragment>
  );
};

export default ProjectClientSelector;
