import React, { Fragment } from 'react';
import { Box, Link, Typography } from '@mui/material';

import { useWorkspace } from '../Workspace/useWorkspace';
import { useCollectIntelligence } from '../Intelligence/ContributeAndCollect';

export const CollectIntelligence = () => {
  const { workspace } = useWorkspace();
  const { collectIntelligence, collectIntelligenceDialog } = useCollectIntelligence(workspace);

  return (
    <Fragment>
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          button: {
            cursor: 'pointer',
            color: '#0091ff',
            '&:hover': {
              color: 'inherit',
            },
            '&:active': {
              color: '#000',
            },
          },
        }}
      >
        <Typography variant='body2'>Want to collect intelligence from the entire team?</Typography>
        <Link
          component='button'
          onClick={collectIntelligence}
          sx={{
            cursor: 'pointer',
            color: '#0091ff',
            '&:hover': {
              color: 'inherit',
            },
            '&:active': {
              color: '#000',
            },
          }}
        >
          Click here!
        </Link>
      </Box>
      {collectIntelligenceDialog}
    </Fragment>
  );
};
