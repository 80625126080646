import { Card, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Aspect } from './Aspect';
import { Fragment } from 'react';

interface AspectRecommendationsProps {
  aspect: Aspect;
}

const AspectRecommendations = ({ aspect }: AspectRecommendationsProps) => {
  const { recommendations } = aspect;
  //  const recommendations = { entries: [{ name: 'Name', description: 'Description alaslkfjs aslkjs fldjklfaskjf lkfjsadfl jsa fksljdf aslkdjf slakdjf aslkjflasdjf lsadkjf lsakjf lsakdjf lsakjdf laskjdf lsakjf laskjf alskjdf alskdjf alskjf alskdjf asl;kdjf aslkj '}], preamble: 'preamble', summary: 'summary'}

  const { preamble, summary, entries } = recommendations;

  return (
    <Card>
      <Stack padding={2}>
        <Typography>{preamble}</Typography>
        <List>
          {entries.map((recommendation, index) => (
            <Fragment key={index}>
              {index === 0 && <Divider />}
              <ListItem key={index} sx={{ paddingLeft: 1 }}>
                <ListItemText
                  primary={<Typography variant="body1" fontWeight='bold'>{recommendation.name}</Typography>}
                  secondary={<Typography variant="body2" maxWidth='600px' >{recommendation.description}</Typography>}
                />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <Typography>{summary}</Typography>
      </Stack>
    </Card>
  );
};

export default AspectRecommendations;
