import { gql } from '@apollo/client';
import { MembershipInterface } from "./Membership";

export interface MemberScorerInterface extends MembershipInterface {
  confidentialityThreshold: number;
  confidentialityThresholdMet: boolean;
}

export const MEMBER_SCORES_FRAGMENT = gql`
  fragment MemberScores on MembershipInterface {
    memberStats {
      count
      countByScore {
        high
        medium
        low
        collecting
      }
    }
  }
`;
