import { stringToArray } from 'cron-converter';

export interface ScheduleInterface {
  cron: string;
}

// cron array structure: minute, hour, day of the month, month, and the day of the week

export const getDaysFromSchedule = (schedule: ScheduleInterface): number[] => {
  if (schedule) {
    const scheduleArray = stringToArray(schedule.cron);
    return scheduleArray[4]; // days of week
  } else {
    return [1, 2, 3, 4, 5];
  }
};

export const getHourFromSchedule = (schedule: ScheduleInterface) => {
  if (schedule) {
    const scheduleArray = stringToArray(schedule.cron);
    return scheduleArray[1][0]; // first hour
  } else {
    return 10;
  }
};
