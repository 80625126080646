import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Stack } from '@mui/material';

import { SubmitButton } from '../UI/BrandedForm';
import CompanyField from '../UI/CompanyField';
import { ActiveModules, ModuleSettingsFormContent, activeModulesSchema } from '../Account/WorkspaceModuleSettings';
import { Category } from '../Category/Category';

const createWorkspaceSettingsSchema = z.object({
  name: z.string({ required_error: 'Workspace name is required' }),
  imageUrl: z.string().optional(),
  domain: z.string().optional(),
  activeModules: activeModulesSchema,
});

export type CreateWorkspaceSettingsValues = z.infer<typeof createWorkspaceSettingsSchema>;

interface WorkspaceSettingsFormProps {
  name?: string;
  activeModules: ActiveModules;
  categories?: Category[];
  onSubmit: (values: CreateWorkspaceSettingsValues) => void;
}

const CreateWorkspaceSettingsForm = ({ name = '', activeModules, categories, onSubmit }: WorkspaceSettingsFormProps) => {
  const methods = useForm<CreateWorkspaceSettingsValues>({
    resolver: zodResolver(createWorkspaceSettingsSchema),
    defaultValues: {
      name,
      imageUrl: '',
      domain: '',
      activeModules,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <CompanyField name='name' label='Workspace Name' autoFocus />
          {categories && <ModuleSettingsFormContent categories={categories} />}
          <SubmitButton isSubmitting={isSubmitting}>Continue</SubmitButton>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default CreateWorkspaceSettingsForm;
