import { Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';

interface Modifiers {
  shift?: boolean;
  ctrl?: boolean;
  alt?: boolean;
  meta?: boolean; // Meta is usually the Command key on Mac
}

const useKeyboardShortcut = (onPress: () => void, key: string, modifiers: Modifiers = {}, enabled = true) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const isCorrectModifier = (modifierKey: boolean | undefined, eventKey: boolean) => (modifierKey !== undefined ? modifierKey === eventKey : true);

      const isShiftPressed = isCorrectModifier(modifiers.shift, event.shiftKey);
      const isCtrlPressed = isCorrectModifier(modifiers.ctrl, event.ctrlKey);
      const isAltPressed = isCorrectModifier(modifiers.alt, event.altKey);
      const isMetaPressed = isCorrectModifier(modifiers.meta, event.metaKey);

      if (enabled && event.key === key && isShiftPressed && isCtrlPressed && isAltPressed && isMetaPressed) {
        onPress();
      }
    };

    if (enabled) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onPress, key, modifiers, enabled]);
};

export default useKeyboardShortcut;

type KeyboardShortcutTooltipProps = {
  title: string;
  shortcut: string;
  children: React.ReactElement;
};

export const KeyboardShortcutTooltip = ({ title, shortcut, children }: KeyboardShortcutTooltipProps) => {
  return (
    <Tooltip
      title={
        <Stack alignItems='center'>
          <Typography fontSize={11}>{title}</Typography>
          <Typography fontSize={9} sx={{ opacity: 0.75 }}>
            {shortcut}
          </Typography>
        </Stack>
      }
    >
      {children}
    </Tooltip>
  );
};
