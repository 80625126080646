import React, { useState } from 'react';

import { styled, ThemeProvider } from '@mui/material/styles';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useModalContainer } from './ModalContainer';
import { useAppTheme } from '../Theme/AppThemeProvider';

export interface AppDialogProps {
  open: boolean;
  onClose: () => void;
}

export const useDialog = () => {
  const [open] = useState(false);
  return { open };
};

const StyledDialog = (props: DialogProps) => {
  const appTheme = useAppTheme();
  const container = useModalContainer();
  if (!props.open) {
    return null;
  }

  return (
    <ThemeProvider theme={appTheme}>
      <StyledDialogInternal container={container} {...props} />
    </ThemeProvider>
  );
};

const StyledDialogInternal = styled(Dialog)(
  ({ theme }) => `
  &.MuiDialog-root {
    .MuiDialogTitle-root {
      padding-top: 24px;
      &.MuiTypography-root {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }

        font-size: 19px;
        font-weight: 700;
      }
    }
    .MuiDialogContent-root {
      .tablet &, .laptop &, .desktop & {
        min-width: 500px;
      }
      .mobile & {
        min-width: 326px;
      }
      .form-row {
        margin-bottom: 1.5em;
      }
      .MuiDialogContentText-root {
        font-size: 14px;
        text-align: center;
        // margin-bottom: 30px;
        // padding: 0 30px;
      }
      .MuiTable-root {
        margin-bottom: 30px;
      }
    }
    .MuiDialogActions-root {
      padding: 0 24px 24px 24px;
      button {
        margin-left: 10px;
        &.cancel-button {
          background-color: ${theme.palette.grey[700]}
        }
      }
    }

  }
`
);

export default StyledDialog;
