import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, gql, useMutation } from '@apollo/client';
import { z } from 'zod';

import { ReactComponent as WorkspaceIcon } from '../Icons/Bee-Hive-Icon.svg';
import { WORKSPACE_FIELDS_FRAGMENT, WorkspaceInterface } from './WorkspaceInterface';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { AppDialogProps } from '../UI/StyledDialog';
import useWorkspaceSelection from './useWorkspaceSelection';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

interface CreateWorkspaceResponse {
  createWorkspace: {
    workspace: WorkspaceInterface;
  };
}

const createWorkspaceSchema = z.object({ name: z.string().min(1, 'Workspace name is required') });
type CreateWorkspaceValues = z.infer<typeof createWorkspaceSchema>;

const CreateWorkspaceDialog = ({ open, onClose }: AppDialogProps) => {
  const { setWorkspaceId } = useWorkspaceSelection();
  const client = useApolloClient();
  const navigate = useNavigate();
  const [createWorkspace, { error }] = useMutation<CreateWorkspaceResponse, CreateWorkspaceValues>(CREATE_WORKSPACE_MUTATION);
  const { setSuccess } = useGlobalMessage();

  const formDialogProps = useFormDialog<CreateWorkspaceValues>({ schema: createWorkspaceSchema });
  const { methods, handleResponse } = formDialogProps;
  const { control } = methods;

  const handleSubmit = async (values: CreateWorkspaceValues) => {
    const response = await createWorkspace({ variables: { name: values.name } });
    const success = handleResponse(response, 'createWorkspace', 'Uh oh. There was an error creating the workspace');
    if (success) {
      const workspace = response.data!.createWorkspace.workspace;
      await client.resetStore();

      setSuccess('Created workspace');
      setWorkspaceId(workspace.id);
      navigate(`/workspace/${workspace.id}`);
    }
    return success;
  };

  return (
    <FormDialog<CreateWorkspaceValues>
      {...formDialogProps}
      id='edit-workspace'
      open={open}
      onClose={onClose}
      icon={<WorkspaceIcon />}
      title='Create Workspace'
      subtitle='Enter the information below to create a new workspace.'
      submitLabel='Create'
      onSubmit={handleSubmit}
      mutationError={error}
    >
      <Controller
        name='name'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} variant='outlined' fullWidth label='Workspace Name' error={!!error} helperText={error?.message} />
        )}
      />
    </FormDialog>
  );
};

export default CreateWorkspaceDialog;

export const CREATE_WORKSPACE_MUTATION = gql`
  mutation CreateWorkspace($name: String!) {
    createWorkspace(name: $name) {
      workspace {
        ...WorkspaceFields
      }
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;
