import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import { formatUser, UserInterface } from './User';

const UserAvatar = ({ user }: { user: UserInterface }) => {
  const label = formatUser(user);
  const initials = user.firstName ? user.firstName[0] + user.lastName[0] : undefined;

  return (
    <Tooltip key={user.id} title={formatUser(user)}>
      <Avatar alt={label} src={user.avatar}>
        {initials}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
