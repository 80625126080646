import { gql } from '@apollo/client';
import { ModuleInterface } from '../Module/Module';
import { MEMBER_SCORES_FRAGMENT, MemberScorerInterface } from '../Membership/MemberScores';
import { ScoreDimensionBase } from '../Scores/ScoreDimensionBase';
import { AGGREGATOR_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import { NEEDS_ATTENTION_FRAGMENT } from '../Dashboard/NeedsAttention';
import { ScoreDimension } from '../Scores/ScoreDimension';
import { CLIENT_BREAKDOWN_FRAGMENT } from '../Client/ClientCollector';

export type CategoryType = 'ClientsCategory' | 'EmployeeExperienceCategory' | 'CompanyCategory';

export interface Category extends ScoreDimensionBase, MemberScorerInterface {
  key: string;
  __typename: CategoryType;
  type: CategoryType;
  color: string;
  modules: Array<ModuleInterface>;
  needsAttention: ScoreDimension[];
}

export const CATEGORY_SCORE_FIELDS_FRAGMENT = gql`
  fragment CategoryScoreFields on CategoryInterface {
    id
    name
    type
    health
    intelligence
    intelligenceStats {
      relativeContribution
      contributorPercentage
    }
    confidentialityThreshold
    ...AggregatorSurveyFields

    ...MemberScores

    modules {
      id
      name
      type
      health
      intelligence
    }

    ...NeedsAttention
  }
  ${MEMBER_SCORES_FRAGMENT}
  ${AGGREGATOR_SURVEY_FIELDS_FRAGMENT}
  ${NEEDS_ATTENTION_FRAGMENT}
`;

export const CATEGORY_CLIENT_BREAKDOWN_FRAGMENT = gql`
  fragment WorkspaceCategoryClientBreakdown on Workspace {
    categories {
      ...ClientBreakdown
    }
  }
  fragment TeamCategoryClientBreakdown on Team {
    categories {
      ...ClientBreakdown
    }
  }
  ${CLIENT_BREAKDOWN_FRAGMENT}
`;
