import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import Diversity1RoundedIcon from '@mui/icons-material/Diversity1Rounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import SelfImprovementRoundedIcon from '@mui/icons-material/SelfImprovementRounded';
import LocalFireDepartmentRounded from '@mui/icons-material/LocalFireDepartmentRounded';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import PublicRounded from '@mui/icons-material/PublicRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';

import { ModuleType } from './Module';
import { ModuleDimensionSettingsInterface } from './ModuleDimensionSettingsInterface';
import { EMPLOYEE_EXPERIENCE_MODULE_FIELDS } from '../EmployeeExperience/EngagementModule';
import EmployeeExperienceModuleDashboard from '../EmployeeExperience/EmployeeExperienceModuleDashboard';
import ProjectsModuleDashboard from '../Projects/ProjectsModuleDashboard';
import CompanyModuleDashboard from '../Company/CompanyModuleDashboard';
import ClientsModuleDashboard from '../Clients/ClientsModuleDashboard';

const CLIENTS_PROJECTS_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Projects',
  type: 'ClientsProjectsModule',
  fields: '',
  DashboardComponent: ProjectsModuleDashboard,
  icon: HiveRoundedIcon,
};

const CLIENTS_PRODUCTS_AND_SERVICES_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Products & Services',
  type: 'ClientsProductsAndServicesModule',
  fields: '',
  DashboardComponent: ClientsModuleDashboard,
  icon: CategoryRoundedIcon,
};

const CLIENTS_RELATIONSHIP_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Relationship',
  type: 'ClientsRelationshipModule',
  fields: '',
  DashboardComponent: ClientsModuleDashboard,
  icon: AccountBoxRoundedIcon,
};

const ENGAGEMENT_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Engagement',
  type: 'EngagementModule',
  DashboardComponent: EmployeeExperienceModuleDashboard,
  fields: EMPLOYEE_EXPERIENCE_MODULE_FIELDS,
  icon: Diversity1RoundedIcon,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        'Your engagement score indicates a high level of satisfaction and a strong connection to your organization. Check the engagement aspects below for more insight into what is driving your score and areas that may need a little attention.',
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        'Your engagement score indicates a moderate level of satisfaction and connection to your organization. Check the engagement aspects below for more insight into what is driving your score and areas that need attention.',
    },
    low: {
      headline: 'Engagement is low',
      description:
        'Your engagement score indicates a low level of satisfaction and a weak connection to your organization. Check the engagement aspects below for more insight into what is driving your score and areas where you need to take action.',
    },
    collecting: {
      headline: 'Collecting engagement intelligence',
      description: "There isn't enough intelligence to display your engagement score.",
    },
    unavailable: {
      headline: 'Engagement breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

const DIVERSITY_AND_INCLUSION: ModuleDimensionSettingsInterface = {
  name: 'Diversity, Equity and Inclusion',
  type: 'DiversityEquityAndInclusionModule',
  DashboardComponent: EmployeeExperienceModuleDashboard,
  fields: EMPLOYEE_EXPERIENCE_MODULE_FIELDS,
  icon: Diversity2RoundedIcon,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        'Your score indicates a high level of satisfaction for Diversity, Equity and Inclusion (DE&I) with your organization. Check the DE&I aspects below for more insight into what is driving your score and areas that you may be able to improve upon.',
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        'Your score indicates a moderate level of satisfaction for Diversity, Equity and Inclusion (DE&I) with your organization. Check the DE&I aspects below for more insight into what is driving your score and areas that need attention.',
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        'Your score indicates a low level of satisfaction for Diversity, Equity and Inclusion with your organization. Check the aspects below for more insight into what is driving your score and areas where you need to take action.',
    },
    collecting: {
      headline: 'Collecting intelligence',
      description: "There isn't enough intelligence to display your Diversity, Equity and Inclusion score.",
    },
    unavailable: {
      headline: 'Diversity, Equity and Inclusion breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

const BURNOUT_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Burnout',
  type: 'BurnoutModule',
  DashboardComponent: EmployeeExperienceModuleDashboard,
  fields: EMPLOYEE_EXPERIENCE_MODULE_FIELDS,
  icon: LocalFireDepartmentRounded,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        'Your score indicates a low level of employee burnout. Check the employee burnout aspects below to gain insight into what is driving your score and any areas that can be improved.',
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        'Your score indicates a moderate level of employee burnout. Check the employee burnout aspects below to gain insight into what is driving your score and areas that need attention.',
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        'Your score indicates a high level of employee burnout. Check the employee burnout aspects below to gain insight into what is driving your score and areas where you need to take action.',
    },
    collecting: {
      headline: 'Collecting Employee Burnout intelligence',
      description: "There isn't enough intelligence to display your Employee Burnout score.",
    },
    unavailable: {
      headline: 'Burnout breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

const HEALTH_AND_WELLNESS_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Health and Wellness',
  type: 'HealthAndWellnessModule',
  DashboardComponent: EmployeeExperienceModuleDashboard,
  fields: EMPLOYEE_EXPERIENCE_MODULE_FIELDS,
  icon: SelfImprovementRoundedIcon,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        'Your score indicates a high level of satisfaction for your Health and Wellness and the support your organization provides. Check the Health and Wellness aspects below for more insight into what is driving your score and areas that can be improved.',
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        'Your score indicates a moderate level of satisfaction for your Health and Wellness and the support your organization provides. Check the Health and Wellness aspects below for more insight into what is driving your score and areas that need attention.',
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        'Your score indicates a low level of satisfaction for your Health and Wellness and the support your organization provides. Check the Health and Wellness aspects below for more insight into what is driving your score and areas where you need to take action.',
    },
    collecting: {
      headline: 'Collecting Health and Wellness intelligence',
      description: "There isn't enough intelligence to display your Health and Wellness score.",
    },
    unavailable: {
      headline: 'Health and Wellness breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

const COMPANY_PROJECTS_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Projects',
  type: 'CompanyProjectsModule',
  DashboardComponent: ProjectsModuleDashboard,
  fields: '',
  icon: HiveRoundedIcon,
  //  levels: {
  //    unavailable: {
  //      headline: "Project data visibility not available",
  //      description: "The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached."
  //    }
  //  }
};

const CORE_VALUES_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Core Values',
  type: 'CoreValuesModule',
  DashboardComponent: CompanyModuleDashboard,
  fields: '',
  icon: HubRoundedIcon,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        "Your score indicates a high level of satisfaction for your company's Core Values. Check the aspects below for more insight into what is driving your score and areas that can be improved.",
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        "Your score indicates a moderate level of satisfaction for your company's Core Values. Check the aspects below for more insight into what is driving your score and areas that need attention.",
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        "Your score indicates a low level of satisfaction for your company's Core Values. Check the aspects below for more insight into what is driving your score and areas where you need to take action.",
    },
    collecting: {
      headline: 'Collecting Core Values intelligence',
      description: "There isn't enough intelligence to display your Core Values score.",
    },
    unavailable: {
      headline: 'Core Values breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

const SOCIAL_RESPONSIBILITY_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Social Responsiblity',
  type: 'SocialResponsibilityModule',
  DashboardComponent: CompanyModuleDashboard,
  fields: '',
  icon: PublicRounded,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        "Your score indicates a high level of satisfaction for your company's Social Responsibility. Check the aspects below for more insight into what is driving your score and areas that can be improved.",
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        "Your score indicates a moderate level of satisfaction for your company's Social Responsibility. Check the aspects below for more insight into what is driving your score and areas that need attention.",
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        "Your score indicates a low level of satisfaction for your company's Social Responsibility. Check the aspects below for more insight into what is driving your score and areas where you need to take action.",
    },
    collecting: {
      headline: 'Collecting Social Responsibility intelligence',
      description: "There isn't enough intelligence to display your Social Responsibility score.",
    },
    unavailable: {
      headline: 'Social Responsibility breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

const SENIOR_LEADERSHIP_MODULE_COMPONENT_SETTINGS: ModuleDimensionSettingsInterface = {
  name: 'Senior Leadership',
  type: 'SeniorLeadershipModule',
  DashboardComponent: CompanyModuleDashboard,
  fields: '',
  icon: StarBorderRoundedIcon,
  levels: {
    high: {
      headline: 'Looking good!',
      description:
        "Your score indicates a high level of satisfaction for your company's Senior Leadership. Check the aspects below for more insight into what is driving your score and areas that can be improved.",
    },
    medium: {
      headline: 'Some aspects need attention',
      description:
        "Your score indicates a moderate level of satisfaction for your company's Senior Leadership. Check the aspects below for more insight into what is driving your score and areas that need attention.",
    },
    low: {
      headline: 'Satisfaction is low',
      description:
        "Your score indicates a low level of satisfaction for your company's Senior Leadership. Check the aspects below for more insight into what is driving your score and areas where you need to take action.",
    },
    collecting: {
      headline: 'Collecting Senior Leadership intelligence',
      description: "There isn't enough intelligence to display your Senior Leadership score.",
    },
    unavailable: {
      headline: 'Senior Leadership breakdown not available',
      description: 'The minimum number of members ([confidentiality threshold]) needed to respond to view this data has not been reached.',
    },
  },
};

export const MODULE_COMPONENT_SETTINGS = [
  CLIENTS_PROJECTS_MODULE_COMPONENT_SETTINGS,
  CLIENTS_PRODUCTS_AND_SERVICES_COMPONENT_SETTINGS,
  CLIENTS_RELATIONSHIP_COMPONENT_SETTINGS,
  ENGAGEMENT_MODULE_COMPONENT_SETTINGS,
  DIVERSITY_AND_INCLUSION,
  HEALTH_AND_WELLNESS_MODULE_COMPONENT_SETTINGS,
  BURNOUT_MODULE_COMPONENT_SETTINGS,
  COMPANY_PROJECTS_MODULE_COMPONENT_SETTINGS,
  CORE_VALUES_MODULE_COMPONENT_SETTINGS,
  SOCIAL_RESPONSIBILITY_MODULE_COMPONENT_SETTINGS,
  SENIOR_LEADERSHIP_MODULE_COMPONENT_SETTINGS,
];

export const findModuleScoreDimensionSettings = (moduleType: ModuleType) => {
  const settings = MODULE_COMPONENT_SETTINGS.find(settings => settings.type === moduleType)!;
  if (!settings) {
    throw new Error(`Unexpected module type: ${moduleType}`);
  }
  return settings;
};
